import { Skeleton, createStyles } from "@mantine/core";
import {IoPricetagOutline} from 'react-icons/io5';
import {MdOutlineSquareFoot} from 'react-icons/md';
import {IconLayout} from 'src/assets/icons/IconLayout';

type PropertyCardSkeletonProps = {
    iconSize: number;
}

export default function ProperyCardSkeleton({iconSize}:PropertyCardSkeletonProps) {

    const {classes} = useStyles();

    return (
        <div className={classes.card}>
            <Skeleton className={classes.img} />
            <div className={classes.titleWrapper} >
                <Skeleton className={classes.title} />
                <Skeleton className={classes.city} />
            </div>
            <div className={classes.iconsWrapper} > 
                <div className={classes.iconWrapper} >
                    <IconLayout className={classes.detailsIcon} />
                    <Skeleton className={classes.iconText} />
                </div>
                <div className={classes.iconWrapper} >
                    <IoPricetagOutline className={classes.detailsIcon} size={iconSize} />
                    <Skeleton className={classes.iconText} />
                </div>
                <div className={classes.iconWrapper} >
                    <MdOutlineSquareFoot className={classes.detailsIcon} size={iconSize} />
                    <Skeleton className={classes.iconText} />
                </div>
            </div>
        </div>
    );
}

const useStyles = createStyles(theme => ({
    card: {
        backgroundColor: theme.white,
        width: 256,
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: "10px"
    },
    img: {
        height: 214,
        width: "100%",
    },
    titleWrapper: {
        padding: theme.spacing.md,
        paddingTop: theme.spacing.lg,
        paddingBottom: theme.spacing.lg,
        display: "flex",
        flexDirection: "column",
        gap: "0.8125rem",
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
    },
    title: {
        width: "80%",
        height: "1.25rem",
    },
    city: {
        width: "70%",
        height: "1rem"
    },
    detailsIcon: {
        color: `${theme.colors.gray[6]}`,
        display: 'block',
        width: '32px',
        height: '32px',
        margin: 'auto',
    },
    iconsWrapper: {
        width: "100%",
        padding: `${theme.spacing.md} 0`,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center"
    },
    iconWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem"
    },
    iconText: {
        width: "150%",
        height: "1rem"
    }
}));