import classnames from 'classnames';
import {createStyles, Modal, ModalProps} from '@mantine/core';

export default function ModalTemplate(props: ModalProps): JSX.Element {
  const {classes} = useStyles();

  return (
    <Modal
      {...props}
      zIndex={9999}
      radius="xl"
      centered
      classNames={{
        inner: classes.modalInner,
        content: classes.modalContent,
        title: classes.modalTitle,
        close: classes.modalClose,
        body: classnames(classes.modalBody, props.className),
      }}>
      {props.children}
    </Modal>
  );
}

const useStyles = createStyles(() => ({
  modalInner: {
    boxSizing: 'border-box',
  },
  modalContent: {
    boxSizing: 'border-box',
    textAlign: 'center',
    fontSize: '1.2rem',
    flex: '0 0 600px',
  },
  modalTitle: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '.5rem',
    lineHeight: '1.5rem',
    margin: 'auto',
  },
  modalClose: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    width: '32px',
    height: '32px',
    svg: {
      width: '100%',
      height: '100%',
    }
  },
  modalBody: {
    padding: '2rem',
    textAlign: 'center',
    fontSize: '1.4rem',
  },
}));
