import { createStyles, HoverCard, Text } from '@mantine/core';
import { BsQuestionCircle } from 'react-icons/bs';
import Transaction from 'src/services/transaction';

type BudgetLegendLineProps = {
    name: string,
    description: string,
    color: string,
    amount: string,
    currency: string,
}


export default function BudgetLegendLine(props: BudgetLegendLineProps): JSX.Element {
    const {classes} = useStyles();
    return (
        <div className={classes.legendLine}>
              <div className={classes.legendLineTitle}>
                <div className={classes.legendColorCircle} style={{backgroundColor: props.color}} />
                {props.name}
                <HoverCard withArrow={true} width="280px" shadow="md">
                  <HoverCard.Target>
                    <div>
                      <BsQuestionCircle size=".9rem" />
                    </div>
                  </HoverCard.Target>
                  <HoverCard.Dropdown className={classes.questionDropdown}>
                    <Text size="sm">{props.description}</Text>
                  </HoverCard.Dropdown>
                </HoverCard>
              </div>
              {props.amount } {props.currency}
            </div>
    )
 }


const useStyles = createStyles(theme => ({
  legendLine: {
    display: 'flex',
    gap: '5rem',
    justifyContent: 'space-between',
    margin: '1.5rem 0',
  },
  legendLineTitle: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  legendColorCircle: {
    borderRadius: '50%',
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: 'red',
  },
  questionDropdown: {
    color: theme.black,
  },
}));
