import classnames from 'classnames';
import React, {useRef, useState} from 'react';
import {useEffect} from 'react';
import {createStyles, Group} from '@mantine/core';
import {IconArrowForward} from 'src/assets/icons/IconArrowForward';
import ProgressDetails from 'src/app/components/progress-details/ProgressDetails';

class SlideScroll {
  speed: number;
  smooth: number;
  onscroll?: (dir: number) => void;
  onupdate?: (pos: number, delta: number) => void;
  _target: HTMLElement;
  _pos: number = 0;
  _left: number = 0;
  _updating: boolean = false;

  constructor(target: HTMLElement, speed: number, smooth: number) {
    // Set properties.
    this.speed = speed;
    this.smooth = smooth;
    this._target = target;

    // Set variables.
    let width = this.viewportWidth;
    let touch: {
      id: number;
      startX: number;
      startY: number;
      posX: number;
      posY: number;
    };

    let observer = new ResizeObserver(() => {
      if (this.viewportWidth) {
        let delta = this.viewportWidth / width;

        // Set correct position.
        this._pos *= delta;
        this._left *= delta;

        // Set window width.
        width = this.viewportWidth;

        // Render slide.
        this._render();
      }
    });

    observer.observe(target);

    // @TODO: For the time being, we're preventing scroll navigation to allow users
    // to scroll through interior elements.

    // This is because we can't determine whether the user has finished scrolling an element
    // since the scrollTop property of mantine's ScrollArea is never updated.
    // Being able to scroll over scrolling elements is often a sign of poor user experience (UX).

    /* target.addEventListener('wheel', (e) => {
      // A scrollable child element takes precedence over the event.
      if (e.target) {
        let divHeight = (e.target as HTMLDivElement).offsetHeight;
        let parentHeight = ((e.target as HTMLDivElement).parentNode as HTMLDivElement).offsetHeight;
        let total = parentHeight - divHeight;

        if (total < 0) {
          return;
        }
      }

      // Disable default behavior.
      e.preventDefault();

      // Set position and update.
      this.pos += Math.sign(e.deltaY) * this.speed;
      this.onscroll?.(Math.sign(e.deltaY));
    }); */

    screen.orientation.addEventListener("change", () => {
      width = document.documentElement.clientWidth
    })

    if(width > 1020){
      target.addEventListener('touchstart', e => {
        touch = {
          id: e.touches[0].identifier,
          startX: e.touches[0].clientX,
          startY: e.touches[0].clientY,
          posX: e.touches[0].clientX,
          posY: e.touches[0].clientY,
        };
      });
  
      target.addEventListener('touchend', e => {
        for (let {identifier, clientX} of e.changedTouches)
          if (identifier == touch.id)
            return this.onscroll?.(Math.sign(touch.startX - clientX));
      });
  
      target.addEventListener('touchmove', e => {
        let movementX = 0;
  
        if (touch) {
          // Get horizontal movement.
          for (let {identifier, clientX} of e.changedTouches) {
            if (identifier == touch.id) {
              movementX = clientX - touch.posX;
              touch.posX = clientX;
              break;
            }
          }
  
          // Set correct position.
          this._pos -= movementX;
          this._pos = Math.max(0, Math.min(this._pos, this.viewportWidth * (this.viewportCount - 1)));
          this._left = this._pos;
          this._render();
        }
      });
    }
  }

  _render(): void {
    this._target.style.transform = `translateX(${-this._left}px)`;
  }

  _update(): void {
    if (this._updating)
      return;

    let frame = (): void => {
      let delta = (this._pos - this._left) / this.smooth;

      // Smoothly update.
      if (Math.abs(delta) > 0.05) {
        this._left += delta;
        requestAnimationFrame(frame);

      } else {
        this._left = this._pos;
        this._updating = false;
      }

      // Call update callback and render.
      this.onupdate?.(this._left, delta);
      this._render();
    };

    // Start animation.
    this._updating = true;
    requestAnimationFrame(frame);
  }

  get target(): HTMLElement {
    return this._target;
  }

  get viewportWidth(): number {
    return this._target.offsetWidth;
  }

  get viewportHeight(): number {
    return this._target.offsetHeight;
  }

  get viewportCount(): number {
    return this._target.childElementCount;
  }

  get left(): number {
    return this._left;
  }

  get pos(): number {
    return this._pos;
  }

  set pos(pos) {
    this._pos = Math.max(0, Math.min(pos, this.viewportWidth * (this.viewportCount - 1)));
    this._update();
  }
}

type SlideDescriptionDetails = {
  long: string;
  short: string;
};

type SlideProps = {
  className?: string;
  children: JSX.Element[];
  onChange?: (index: number) => void;
  position?: number;
  icons: JSX.Element[];
  descriptions: (string | SlideDescriptionDetails)[];
  setNbSlides: (nb: number) => void;
  investor: boolean;
};

enum DescriptionPurpose {
  Title,
  Arrow,
};

export default function Slide(props: SlideProps): JSX.Element {
  const {classes} = useStyles();
  const refWindow: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const refSlide: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const refLeftArrow: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const refRightArrow: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const refDescriptionItem: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [nbSlides, setNbSlides] = useState(1);

  const children = React.Children.map(props.children, (child: JSX.Element) => {
    return <div className={classnames(classes.scrollWindowItem, classes.scrollWindowItemSelected)}>{child}</div>;
  });

  const position: number = Math.max(0, Math.min((children.length - 1), (props.position || 0)));
  const hasPrevSlide: boolean = position > 0;
  const hasNextSlide: boolean = position < (nbSlides - 1);

  function showDescriptionSlide(slideIdx: number, purpose: DescriptionPurpose): string {
    let description = props.descriptions[slideIdx];

    if (typeof description === "string")
      return description;

    return (purpose === DescriptionPurpose.Title && document.documentElement.clientWidth > 500)
      ? description.long
      : description.short;
  }

  useEffect(() => {
    let slideScroll = new SlideScroll(refWindow.current as HTMLElement, 120, 12);
    let pages = refSlide.current?.querySelector('.' + classes.scrollPages)?.children as HTMLCollectionOf<HTMLDivElement>;
    let windows = refSlide.current?.querySelector('.' + classes.scrollWindow)?.children as HTMLCollectionOf<HTMLDivElement>;
    let currentPosition: number = position;

    setNbSlides(pages.length);
    props.setNbSlides(pages.length);

    function updateIndex(index: number): void {
      // Clamp index.
      index = Math.max(0, Math.min(index, slideScroll.viewportCount - 1));

      // Remove selected.
      for (let node of windows) node.classList.remove(classes.scrollWindowItemSelected);
      for (let node of pages) node.classList.remove(classes.scrollPagesItemSelected);

      // Set selected.
      windows[index].classList.add(classes.scrollWindowItemSelected);
      pages[index].classList.add(classes.scrollPagesItemSelected);

      // Set position.
      currentPosition = index;
    };

    // Attach scroll listener.
    slideScroll.onscroll = (index: number) => {
      // Set and normalize page index.
      index += Math.round(slideScroll.left / slideScroll.viewportWidth);
      index = Math.max(0, Math.min(index, slideScroll.viewportCount - 1));

      // Set position to page bound.
      slideScroll.pos = index * slideScroll.viewportWidth;
    };

    // Attach update listener.
    slideScroll.onupdate = (pos: number) => {
      let index = Math.round(pos / slideScroll.viewportWidth);

      // Call change handler.
      if (index != currentPosition) {
        updateIndex(index);
        props.onChange?.(index);
      }
    };

    // Attach click listener.
    if(document.documentElement.clientWidth > 1020) {
      for (let i = 0; i < pages.length; i++) {
        pages[i].onclick = function () {
          slideScroll.pos = i * slideScroll.viewportWidth;
        };
      }
    }
    else {
      for (let i = 0; i < pages.length; i++){
        pages[i].onclick = () => {
          props.onChange?.(i)
        }
      }
    }
      
    // Attach keyboard listener.
    (refSlide.current as HTMLDivElement).onkeydown = (e: KeyboardEvent) => {
      if (refSlide.current != e.target) return;
      if (e.code == 'ArrowRight' || e.code == 'ArrowDown') slideScroll.pos += slideScroll.viewportWidth;
      if (e.code == 'ArrowLeft' || e.code == 'ArrowUp') slideScroll.pos -= slideScroll.viewportWidth;
      e.stopImmediatePropagation();
    };

    // Attach button listener
    if (refLeftArrow.current && refRightArrow.current) {
      refLeftArrow.current.onclick = function () {
        slideScroll.pos -= slideScroll.viewportWidth;
      };

      refRightArrow.current.onclick = function () {
        slideScroll.pos += slideScroll.viewportWidth;
      };
    }

    // Focus slide.
    (refSlide.current as HTMLDivElement).focus();

    // Update pagination and scroll.
    updateIndex(position);
    slideScroll._pos = position * slideScroll.viewportWidth;
    slideScroll._left = slideScroll._pos;

    return () => {
      slideScroll.onupdate = undefined;
    };
  }, [props.investor]);

  return (
    <div className={classnames(classes.wrapper, props.className)} ref={refSlide}>
      <div className={classes.scrollPages}>
        {children.map((_, i) => (
          <div className={classes.scrollPagesItem} key={i}>
            {props.icons[i]}
          </div>
        ))}
      </div>

      <div className={classes.scrollPagesDescription} ref={refDescriptionItem}>
        {showDescriptionSlide(position, DescriptionPurpose.Title)}
      </div>

      <div className={classnames(classes.scrollSlide, props.className)} tabIndex={-1}>
        <div className={classes.scrollWindow} ref={refWindow}>
          {children}
        </div>

        <div className={classes.scrollPagesLeftArrow} ref={refLeftArrow}>
          {hasPrevSlide && (
            <Group>
              <IconArrowForward className={classes.scrollPagesArrowIconPrev} fill="#0f1d2e" height="30" />

              <div className={classes.scrollPagesArrowText}>
                {/** Manually adding a class name to select all the elements in the style (on wrapper hover)*/}
                <div className={'arrow-description ' + classes.backgroundLight}>
                  {showDescriptionSlide((position - 1), DescriptionPurpose.Arrow)}
                </div>
              </div>
            </Group>
          )}
        </div>

        <div className={classes.scrollPagesRightArrow} ref={refRightArrow}>
          {hasNextSlide && (
            <Group>
              <div className={classes.scrollPagesArrowText}>
                <div className={'arrow-description ' + classes.backgroundLight}>
                  {showDescriptionSlide((position + 1), DescriptionPurpose.Arrow)}
                </div>
              </div>
              
              <IconArrowForward fill="#0f1d2e" height="30"/>
            </Group>
          )}
        </div>
      </div>

      {document.documentElement.offsetWidth > 1020 ?
        <ProgressDetails progress={((position + 1) / nbSlides) * 100} /> : 
        <></>
      }
    </div>
  );
}

const useStyles = createStyles(theme => ({
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: 1020px)`]: {
      height: CSS.supports('height: 100svh') ? 'calc(100svh - 80px)' : 'calc(100vh - 80px)',
    },
  },
  scrollSlide: {
    overflow: 'hidden',
    position: 'relative',
    flex: '1 0 0',
  },
  scrollWindow: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    paddingTop: '2rem',
    [`@media (max-width: 1020px)`]: {
      overflow: 'hidden auto',
      flexDirection: 'column',
    },
  },
  scrollWindowItem: {
    overflow: 'hidden',
    transition: 'opacity 3s cubic-bezier(0.075, 0.82, 0.165, 1)',
    opacity: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 100%',
    fontSize: '4rem',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      opacity: 1,
    },
  },
  scrollWindowItemSelected: {
    opacity: '1',
  },
  scrollPages: {
    boxSizing: 'border-box',
    userSelect: 'none',
    height: '1.15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    gap: '1rem',
    zIndex: 10,
    backgroundColor: theme.colors.local_red[0],
  },
  scrollPagesItem: {
    color: theme.white,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background 0.25s cubic-bezier(0.075, 0.82, 0.165, 1)',
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '0.5rem',
    width: '5rem',
    height: '2rem',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.75)',
    },
  },
  scrollPagesItemSelected: {
    [`@media (min-width: 1020px)`]: {
      background: theme.colors.red,
    },
  },
  scrollPagesDescription: {
    position: 'absolute',
    zIndex: 9,
    transform: 'translateX(-50%)',
    left: '50%',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '5rem',
    width: '60%',
    borderRadius: '50%',
    background: theme.colors.local_red[0],
    color: theme.white,
    paddingTop: '.25rem',
    paddingBottom: '.75rem',
    [`@media (max-width: 1020px)`]: {
      width: '100%',
    },
  },
  scrollPagesLeftArrow: {
    position: 'absolute',
    left: '1.5rem',
    cursor: 'pointer',
    top: '50%',
    transform: 'translateY(-50%)',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
    ['&:hover .arrow-description']:{
      color: theme.colors.black_pearl[0],
      opacity: 1,
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      borderRadius: "10px"
    }
  },
  scrollPagesRightArrow: {
    position: 'absolute',
    right: '1.5rem',
    cursor: 'pointer',
    top: '50%',
    transform: 'translateY(-50%)',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
    ['&:hover .arrow-description']:{
      color: theme.colors.black_pearl[0],
      opacity: 1,
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      borderRadius: "10px"
    }
  },
  scrollPagesArrowIconPrev: {
    transform: 'rotate(180deg)',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
  },
  scrollPagesArrowText: {
    fontSize: '1rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'left',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
    opacity: 0,
    fontSize: "0.85rem",
    transitionDuration: '300ms',
    padding: "0.5rem",
  },
  backgroundDark: {
    color: 'rgba(255, 255, 255, 0.75)',
  },
}));
