import {useNavigate} from 'react-router-dom';
import {Button, createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Immeuble} from 'src/assets/images/accueil/Immeuble';
import {IconError} from 'src/assets/icons/IconError';

type ModalLoadingProps = {
  showError: boolean;
};

export default function ModalLoading(props: ModalLoadingProps): JSX.Element {
  let {classes} = useStyles();
  let {t} = useTranslation();
  let navigate = useNavigate();

  return (
    <div className={classes.loadingPage}>
      <div className={classes.loadingIllustration}>
        <Immeuble data-error={props.showError} />

        {props.showError && (
          <div className={classes.loadingError}>
            <IconError className={classes.loadingErrorIcon} />
          </div>
        )}
      </div>

      <div className={classes.loadingText}>
        <div className={classes.loadingTitle}>
          {props.showError
            ? t('loading.errorLoading')
            : t('loading.loadingProgress')}
        </div>

        {props.showError && (
          <Button color='red' onClick={() => navigate("/")}>
            {t('loading.returnToHome')}
          </Button>
        )}
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  loadingPage: {
    flex: '1 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  loadingIllustration: {
    position: 'relative',
    marginBottom: '-.5rem',
    '[data-error=true]': {
      filter: 'grayscale(.75)',
    },
    '> svg': {
      display: 'block',
      width: '256px',
      height: '256px',
      marginTop: '-3.5rem',
    },
  },
  loadingError: {
    border: '0.25rem solid ' + theme.white,
    background: theme.colors.local_red[0],
    color: '#FFF',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
  },
  loadingErrorIcon: {
    width: '32px',
    height: '32px',
  },
  loadingText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
  },
  loadingTitle: {
    fontSize: '1.5rem',
    fontWeight: 700,
  },
  loadingDescription: {
    fontSize: '1.25rem',
  },
}));
