import {createStyles} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import classNames from 'classnames';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MdDelete} from 'react-icons/md';

type UploadVideoProps = {
  updateVideoList: (videos: File[]) => void;
  deleteVideoFromList: (key: number) => void;
};

export default function UploadVideo(props: UploadVideoProps): JSX.Element {
  const {classes} = useStyles();
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [fileSize, setFileSize] = useState<number[]>([]);
  const [fileList, setFileList] = useState<File[]>([]);
  const [sizeTotal, setSizeTotal] = useState(0);
  const {t} = useTranslation();

  // il faut toujours ajouter le file et le fileName au même endroit
  function handleDrop(acceptedFiles: File[]): void {
    let listNamesTemp: string[] = [];
    let listSizeTemp: number[] = [];
    let listFilesTemp: File[] = [];
    let sizeTotalTemp = sizeTotal;

    for (let i = 0; i < acceptedFiles.length; i++) {
      if (fileNames.indexOf(acceptedFiles[i].name) !== -1) {
        // elt deja dans la liste
        alert(t('estimationUpload.videoAlreadySelected', {file: acceptedFiles[i].name}));
      } else if (sizeTotalTemp + acceptedFiles[i].size > 125000000) {
        // elt qui rend la taille totale trop grande
        alert(`${t('estimationUpload.tooHeavy1', {file: acceptedFiles[i].name, sizeFile: acceptedFiles[i].size})}\n${t('estimationUpload.tooHeavy2')}`);
      } else {
        listNamesTemp = [...listNamesTemp, acceptedFiles[i].name];
        sizeTotalTemp += acceptedFiles[i].size;
        listSizeTemp = [...listSizeTemp, acceptedFiles[i].size];
        listFilesTemp = [...listFilesTemp, acceptedFiles[i]];
      }
    }

    props.updateVideoList(listFilesTemp);
    setFileNames([...fileNames, ...listNamesTemp]);
    setSizeTotal(sizeTotalTemp);
    setFileSize([...fileSize, ...listSizeTemp]);
  }

  function handleDelete(key: number): void {
    setFileNames(fileNames.filter((nameFile, index) => key !== index));
    setSizeTotal(sizeTotal - fileSize[key]);
    setFileSize(fileSize.filter((nameFile, index) => key !== index));
    setFileList(fileList.filter((nameFile, index) => key !== index));
    props.deleteVideoFromList(key);
  }

  return (
    <div className={classes.uploadVideo}>
      <Dropzone className={fileNames.length ? classNames(classes.dropzone, classes.borderRadiusWithVideos) : classNames(classes.dropzone, classes.borderWithoutVideos)} onDrop={handleDrop} accept={{'video/*': []}} multiple={true}>
        <p className={classes.textDropzone}>{t('estimationUpload.uploadText')}</p>
      </Dropzone>

      {fileNames.length !== 0 ? (
        <div className={classes.uploadRowGroup}>
          <div className={classes.listTitle}>{t('estimationUpload.filesSelected')}</div>
          {fileNames.map((fileName, index) => (
            <div className={classes.uploadRow} key={fileName}>
              {fileName}
              <div className={classes.uploadRowSeparator} />
              {(fileSize[index] / 1000000).toFixed(3)} Mo
              <MdDelete onClick={() => handleDelete(index)} className={classes.deleteIcon} size="1.7rem" />
            </div>
          ))}
        </div>
      ) : null}
      <div className={classes.warningSizeUpload}>{t('estimationUpload.warningSizeUpload')}</div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  uploadVideo: {
    borderRadius: '1.5rem',
    background: theme.white,
    color: theme.black,
  },
  dropzone: {
    background: theme.colors.red,
    color: theme.black,
    padding: '1rem',
    cursor: 'pointer',
    borderColor: theme.white,
    '&:hover': {
      backgroundColor: theme.colors.dark_red[0],
    },
  },
  borderRadiusWithVideos: {
    borderRadius: '1.5rem 1.5rem 0 0',
  },
  borderColorWithVideos: {
    border: 'solid 1px rgb(211, 211, 211)',
  },
  borderWithoutVideos: {
    borderRadius: '1.5rem 1.5rem 0 0',
  },
  textDropzone: {
    color: theme.white,
    fontSize: '1.5rem',
    textAlign: 'center',
    padding: '0 4rem',
  },
  warningSizeUpload: {
    border: 'solid 1px rgb(211, 211, 211)',
    borderTop: 0,
    borderRadius: '0 0 1.5rem 1.5rem',
    color: theme.black,
    padding: '1rem',
  },
  deleteIcon: {
    cursor: 'pointer',
    paddingLeft: '1rem',
    color: theme.colors.black_pearl[0],
  },
  listTitle: {
    fontWeight: 700,
    fontSize: '1.3rem',
    marginTop: '1rem',
  },
  uploadRowGroup: {
    padding: '0 2rem 0 1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
    border: 'solid 1px rgb(211,211,211)',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  uploadRow: {
    display: 'flex',
    margin: '0.7rem 0',
  },
  uploadRowSeparator: {
    flex: '1 0 0',
    margin: 'auto 1rem',
    borderTop: `4px dotted ${theme.colors.black_pearl[0]}`,
  },
}));
