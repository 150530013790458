import classnames from 'classnames';
import {createStyles, useMantineTheme} from '@mantine/core';
import {AiOutlineMail} from 'react-icons/ai';
import {BsFillTelephoneFill, BsWhatsapp} from 'react-icons/bs';
import LeafletMap from 'src/app/components/leaflet-map/LeafletMap';
import SlideContactData from 'src/app/components/slides/SlideContactData';
import {useState} from 'react';
import ReactWhatsapp from 'react-whatsapp';
import {useTranslation} from 'react-i18next';
import ModalTemplate from '../modals/ModalTemplate';

export default function Slidecontact(): JSX.Element {
  const theme = useMantineTheme();
  const {classes} = useStyles();
  const {t} = useTranslation();

  const [isModal1Opened, setIsModal1Opened] = useState(false);
  const [isModal3Opened, setIsModal3Opened] = useState(false);

  //to do : ouvrir l'application correspondante
  function pressIcon(i: number): void {
    switch (i) {
      case 1:
        setIsModal1Opened(true);
        break;

      case 3:
        setIsModal3Opened(true);
        window.open(`mailto:${SlideContactData}`);
        break;

      default:
        break;
    }
  }

  const isBackgroundLight = 1;
  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;

  return (
    <div id='4' className={classes.locationDetails}>
      <div className={classes.rows}>
        <div className={classes.columnLeft}>
          <div className={classes.rebootLogo}>
            <img src="/assets/img/icon.png" alt="Logo Reboot Immobilier" />
          </div>

          <div className={classnames(classes.address, backgroundShade)}>
            <div className={classes.largeText}>Reboot Immobilier</div>
            <div>{SlideContactData.address_street}</div>
            <div>{SlideContactData.address_city}</div>
          </div>

          <div className={classes.buttons}>
            <button className={classnames(classes.iconButton, backgroundShade)} onClick={() => pressIcon(1)}>
              <BsFillTelephoneFill className={classes.iconButtonIcon} />
            </button>

            <button className={classnames(classes.iconButton, classes.iconWhatsApp)} onClick={() => pressIcon(2)}>
              <ReactWhatsapp number={SlideContactData.phone} element="div">
                <BsWhatsapp className={classes.iconButtonIcon} />
              </ReactWhatsapp>
            </button>

            <button className={classnames(classes.iconButton, classes.iconMail)} onClick={() => pressIcon(3)}>
              <AiOutlineMail className={classes.iconButtonIcon} />
            </button>
          </div>
        </div>

        <LeafletMap
          coordinatesArray={[48.59378699072496, 7.720505009954904]}
          borderRadius="20px"
          height={document.body.clientWidth > 1020 ? '30em' : '20em'}
          width={document.body.clientWidth > 1020 ? '40em' : '100%'}
          dragging={true}
          doubleClickZoom={false}
          displayPin={true}
        />
      </div>

      <ModalTemplate opened={isModal1Opened} onClose={() => setIsModal1Opened(false)} withCloseButton={false} className={classes.modal}>
        {t('transactionContact.textForPhone')} {SlideContactData.phone}
      </ModalTemplate>

      <ModalTemplate opened={isModal3Opened} onClose={() => setIsModal3Opened(false)} withCloseButton={false} className={classes.modal}>
        {t('transactionContact.textForEmail')} {SlideContactData.email}
      </ModalTemplate>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  locationDetails: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
  },
  rebootLogo: {
    width: '10rem',
    height: 'auto',
    padding: '0',
    backgroundColor: 'transparent',
    img: {
      width: '100%',
    },
  },
  largeText: {
    fontSize: '2.25rem',
    fontWeight: 700,
    marginBottom: '1.5rem',
  },
  address: {
    marginTop: '2rem',
    textAlign: 'center',
    fontWeight: 400,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3rem',
  },
  iconButton: {
    backgroundColor: 'transparent',
    border: '0',
    outline: '0',
    margin: '0 .5rem',
    '&:hover': {color: `${theme.colors.gray[5]}`},
  },
  iconButtonIcon: {
    cursor: 'pointer',
    width: '3rem',
    height: '3rem',
  },
  iconWhatsApp: {
    color: '#00ff11',
  },
  iconMail: {
    color: '#ff3800',
  },
  rows: {
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column', // align divs vertically
      padding: '0 1rem 0 1rem',
      width: '100%',
    }
  },
  columnLeft: {
    marginRight: '8rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [`@media (max-width: 1020px)`]: {
      marginRight: '0',
      paddingBottom: '1rem',
      width: '100%',
    }
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
}));
