export default [
    {
        photo: "/assets/img/equipe/dan.jpg",
        name: "Dan MEDINA",
        role: "Président",
        description: "J'évolue depuis 12 ans dans le monde de l'immobilier. Mon crédo ? La satisfaction client ! Je place l'humain au cœur de chaque projet et suis en constante recherche de l'excellence. Imitateur à mes heures perdues, je suis cependant bien moi-même pour vous accompagner dans tous vos projets immobiliers ! Vente, achat, conseil ou gestion, une seule solution sans tergiverser : Reboot Immobilier !",
        phone: '06 95 42 95 13',
        email: 'dan.medina@reboot-immobilier.com',
    },
    {
        photo: "/assets/img/equipe/jules.jpg",
        name: "Jules EEKELS",
        role: "Gestionnaire locatif",
        description: "« Je s'occupe de tout, tu s'occupes de rien ! Si Jamel en a fait un sketch, j'en ai fait ma devise » Toujours là pour vous servir ! C'est ça, le service gestion chez Reboot Immobilier.",
        phone: '07 67 33 49 92',
        email: 'jules.eeckels@reboot-immobilier.com',
    },
    {
        photo: "/assets/img/equipe/lydie.jpg",
        name: "Lydie LEBLANC",
        role: "Agent commercial / transaction et gestion",
        description: "Votre projet est le mien, je mets toute mon énergie pour le mener à bien. Active sur Nancy et toute la Lorraine, vous serez entre de bonnes mains.",
        phone: '06 98 23 48 74',
        email: 'lydie.leblanc@reboot-immobilier.com',
    },
    {
        photo: "/assets/img/equipe/fiona.jpg",
        name: "Fiona GUIRA",
        role: "Gestionnaire junior",
        description: "J'ai eu le plaisir d'intégrer l'équipe Reboot Immobilier depuis septembre  2022 dans le cadre de mon Bachelor RM2C - spécialisation métiers de l'immobilier. Ayant accumulé diverses expériences professionnelles, c'est vers l'immobilier, qu'enfin, je décide d'évoluer. J'ai hâte de relever ce nouveau challenge et d'aider nos clients à trouver leur bonheur !",
        phone: '07 49 70 12 77',
        email: 'fiona.guira@reboot-immobilier.com',
    },
];
