import classnames from 'classnames';
import {createStyles, Group, HoverCard, Text} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import FavoriteButton from 'src/app/components/favorite/Favorite';
import {HypothesesFields, PropertyDetailsProps} from 'src/types/locationDetails';
import Transaction from 'src/services/transaction';
import {BsQuestionCircle} from 'react-icons/bs';
import ModalInterested from '../modal-interested/ModalInterested';
import {useDisclosure} from '@mantine/hooks';
import * as SlideInvestorData from './SlideInvestorData';

type SlideDescriptionProps = {
  propertyDetails: PropertyDetailsProps;
  hypotheses: HypothesesFields;
};

export default function SlideDescriptionAchatInvestor(props: SlideDescriptionProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = props.propertyDetails.property;
  const [opened, {open, close}] = useDisclosure(false);

  let backgroundShade = classes.backgroundLight;
  let backgroundShadeObject = classes.backgroundLightObject;
  let backgroundShadeCard = classes.backgroundDark;
  let backgroundShadeCardObject = classes.backgroundDarkObject;

  function getTotalPrice(): number {
    return props.hypotheses.price;
  }

  return (
    <>
      <ModalInterested
        opened={opened}
        close={close}
        title={t('transactionInterested.title')}
        propertyDetails={props.propertyDetails}
        propertyType={t('transactionInterested.propertyForSale')} />

      <div className={classnames(classes.descriptionCard, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.descriptionCardHeader}>
          <div className={classes.descriptionCardPrice}>
            {Transaction.formatNumber(getTotalPrice(), t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
          </div>
          <div>{t('achat.description.inTotal')}</div>
          <div className={classes.descriptionCardLikeContainer}>
            <FavoriteButton propertyId={property.id} />
          </div>
        </div>

        <hr className={classes.descriptionCardSeparator} />

        <div className={classes.descriptionList}>
          <div className={classes.descriptionField}>
            <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
            <div>{t('achat.commun.price')}*</div>

            <Group position="center">
              <HoverCard withArrow={true} width="280px" shadow="md">
                <HoverCard.Target>
                  <div>
                    <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown className={classes.questionDropdown}>
                  <Text size="sm">{t('achat.commun.priceExplanation')}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <div className={classes.descriptionFieldSeparator} />
            <div className={classes.descriptionFieldPrice}>
              {Transaction.formatNumber(getTotalPrice(), t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
            </div>
          </div>
        </div>

        <div className={classes.descriptionList}>
          <div className={classes.descriptionField}>
            <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
            <div>{t('achat.commun.profitability')}*</div>

            <Group position="center">
              <HoverCard withArrow={true} width="280px" shadow="md">
                <HoverCard.Target>
                  <div>
                    <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
                  </div>
                </HoverCard.Target>

                <HoverCard.Dropdown className={classes.questionDropdown}>
                  <Text size="sm">{t('achat.commun.profitabilityExplanation')}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <div className={classes.descriptionFieldSeparator} />
            <div className={classes.descriptionFieldPrice}>
              {Transaction.formatNumber(SlideInvestorData.getProfitability(props.hypotheses, property), t('transaction.formatNumber'))} %
            </div>
          </div>
        </div>

        <div className={classes.descriptionList}>
          <div className={classes.descriptionField}>
            <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
            <div>{t('achat.description.finances')}*</div>

            <Group position="center">
              <HoverCard withArrow={true} width="280px" shadow="md">
                <HoverCard.Target>
                  <div>
                    <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
                  </div>
                </HoverCard.Target>

                <HoverCard.Dropdown className={classes.questionDropdown}>
                  <Text size="sm">{t('achat.description.financesExplanation')}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <div className={classes.descriptionFieldSeparator} />
            <div className={classes.descriptionFieldPrice}>
              {Transaction.formatNumber(SlideInvestorData.getMonthlyTreasury(props.hypotheses, property), t('transaction.formatNumber'))}{' '}
              {Transaction.currency(property.priceAskingPriceCurrency)}
            </div>
          </div>
        </div>

        <div className={classes.descriptionList}>
          <div className={classes.descriptionField}>
            <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
            <div>{t('achatDescription.returnInvestedCapital')}*</div>

            <Group position="center">
              <HoverCard withArrow={true} width="280px" shadow="md">
                <HoverCard.Target>
                  <div>
                    <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
                  </div>
                </HoverCard.Target>

                <HoverCard.Dropdown className={classes.questionDropdown}>
                  <Text size="sm">{t('achat.description.returnInvestedCapitalExplanation')}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>

            <div className={classes.descriptionFieldSeparator} />
            <div className={classes.descriptionFieldPrice}>
              {Transaction.formatNumber(SlideInvestorData.getProfitabilityOfInvestedCapitals(props.hypotheses, property) ?? 0, t('transaction.formatNumber'))} %
            </div>
          </div>
        </div>

        <div className={classes.descriptionMayChange}>
          {t('achat.description.mayChange')}
        </div>

        <button className={classnames(classes.descriptionCardButton, backgroundShadeObject, backgroundShade)} onClick={open}>
          {t('locationDescription.interested')}
        </button>
      </div>
    </>
  );
}

const useStyles = createStyles(theme => ({
  descriptionCard: {
    overflow: 'hidden',
    borderRadius: '1rem',
    width: '400px',
    maxWidth: '100%',
    margin: '1rem',
    padding: '1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  descriptionCardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionCardLikeContainer: {
    marginLeft: 'auto',
  },
  descriptionCardLike: {
    color: theme.colors.red[6],
    display: 'block',
    width: '18px',
    height: '18px',
  },
  descriptionCardSeparator: {
    borderWidth: '0.1px',
    margin: '1rem 0',
  },
  descriptionCardPrice: {
    marginRight: '1rem',
    fontWeight: 600,
    fontSize: '1.75rem',
  },
  descriptionList: {
    marginBottom: '2rem',
  },
  descriptionField: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.25rem',
  },
  descriptionFieldCircle: {
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
    marginRight: '1rem',
  },
  descriptionFieldSeparator: {
    borderTop: '4px dotted #d3d3d3',
    flex: '1 0 0',
    margin: 'auto 1rem',
  },
  descriptionFieldPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  descriptionMayChange: {
    fontStyle: 'italic',
    fontSize: '.9rem',
  },
  descriptionCardButton: {
    cursor: 'pointer',
    border: '0',
    borderRadius: '5rem',
    outline: '0',
    display: 'block',
    width: '50%',
    margin: 'auto',
    padding: '.75rem',
    fontSize: '1.15rem',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
  questionIcon: {
    marginLeft: '.6rem',
    cursor: 'pointer',
  },
  questionDropdown: {
    position: 'absolute',
    transform: 'translate(4px, -4px)',
    color: theme.black,
  },
}));
