import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {MantineProvider} from '@mantine/core';
import {initReactI18next} from 'react-i18next';
import TranslationFR from 'src/translations/fr.json';
import {Notifications} from '@mantine/notifications';
import RebootTheme from 'src/app/theme';
import ModelBase from 'src/app/components/model/base';
import Accueil from 'src/app/screens/accueil/Accueil';
import Location from 'src/app/screens/biens-location/Location';
import Estimation from 'src/app/screens/estimation/Estimation';
import Equipe from 'src/app/screens/equipe/Equipe';
import Vente from 'src/app/screens/vente/Vente';
import Achat from 'src/app/screens/biens-vente/Achat';
import LocationDetails from 'src/app/screens/biens-location/LocationDetails';
import AchatDetails from 'src/app/screens/biens-vente/AchatDetails';
import Honoraires from 'src/app/screens/honoraires/Honoraires';
import LegalNotice from 'src/app/screens/legal-notice/LegalNotice';
import Privacy from 'src/app/screens/privacy/Privacy';
import ScrollToTop from 'src/app/components/scroll-to-top/ScrollToTop';
import Gestion from 'src/app/screens/gestion/Gestion';
import {InvestorContextProvider} from './app/components/recherche-biens/InvestorView';
import {GoogleTagManagerId} from './AppData';

window.tarteaucitronForceLanguage = navigator.language.toLowerCase().split("-")[0];

tarteaucitron.init({
  "privacyUrl": "", /* Privacy policy url */
  "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

  "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
  "cookieName": "tarteaucitron", /* Cookie name */

  "orientation": "middle", /* Banner position (top - bottom) */

  "groupServices": false, /* Group services by category */
  "serviceDefaultState": "wait", /* Default state (true - wait - false) */

  "showAlertSmall": false, /* Show the small banner on bottom right */
  "cookieslist": false, /* Show the cookie list */

  "closePopup": false, /* Show a close X on the banner */

  "showIcon": true, /* Show cookie icon to manage cookies */
  //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
  "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */

  "adblocker": false, /* Show a Warning if an adblocker is detected */

  "DenyAllCta" : true, /* Show the deny all button */
  "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
  "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */

  "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

  "removeCredit": false, /* Remove credit link */
  "moreInfoLink": true, /* Show more info link */

  "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
  "useExternalJs": true, /* If false, the tarteaucitron.js file will be loaded */

  //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

  "readmoreLink": "", /* Change the default readmore link */

  "mandatory": true, /* Show a message about mandatory cookies */
  "mandatoryCta": true, /* Show the disabled accept button when mandatory on */
});

tarteaucitron.job = ['googletagmanager'];
tarteaucitron.user.googletagmanagerId = GoogleTagManagerId;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['navigator'],
    },
    resources: {
      // Add translations here.
      fr: {translation: TranslationFR},
    },
    fallbackLng: 'fr',
    debug: process.env.REACT_APP_ENV_NAME === 'development',
    returnNull: false,
  });

// Freeze language to french in development.
if (process.env.REACT_APP_ENV_NAME === "development")
  i18n.changeLanguage('fr');

function AppWindow(): JSX.Element {
  return (
    <>
      <Notifications />
      <ScrollToTop />
      <ModelBase>
        <Routes>
          <Route index element={<Accueil />} />
          <Route path="location" element={<Location />} />
          <Route path="location/details/:id" element={<LocationDetails />} />
          <Route path="location/details/:id/:slide" element={<LocationDetails />} />
          <Route path="vendre" element={<Vente />} />
          <Route path="achat" element={<Achat />} />
          <Route path="achat/details/:id" element={<AchatDetails />} />
          <Route path="achat/details/:id/:slide" element={<AchatDetails />} />
          <Route path="estimation" element={<Estimation />} />
          <Route path="gestion" element={<Gestion />} />
          <Route path="equipe" element={<Equipe />} />
          <Route path="honoraires" element={<Honoraires />} />
          <Route path="mentions-legales" element={<LegalNotice />} />
          <Route path="politique-de-confidentialite" element={<Privacy />} />
        </Routes>
      </ModelBase>
    </>
  );
}

function App(): JSX.Element {
  return (
    <MantineProvider theme={RebootTheme}>
      <InvestorContextProvider>
        <BrowserRouter>
          <AppWindow />
        </BrowserRouter>
      </InvestorContextProvider>
    </MantineProvider>
  );
}

export default App;
