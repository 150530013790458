import classnames from 'classnames';
import {Button, createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {OptionalHypothesesFields, HypothesesFields, PropertyDetailsProps} from 'src/types/locationDetails';
import {useEffect, useState} from 'react';
import Hypotheses from '../hypotheses/Hypotheses';
import HypothesesSupply from '../hypotheses/HypothesesSupply';
import Transaction from 'src/services/transaction';
import HypothesesEvolution from '../hypotheses/HypothesesEvolution';
import HypothesesData from '../hypotheses/HypothesesData';
import * as SlideInvestorData from './SlideInvestorData';

type SlideHypothesesProps = {
  propertyDetails: PropertyDetailsProps;
  updateHypotheses: (value: OptionalHypothesesFields) => void;
  initHypotheses: HypothesesFields;
  hypotheses: HypothesesFields;
};

export default function SlideHypotheses(props: SlideHypothesesProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = props.propertyDetails.property;
  const isBackgroundLight = true;

  const [changeWhenReset, setChangeWhenReset] = useState(false);

  function reset(): void {
    setChangeWhenReset(!changeWhenReset);
    props.updateHypotheses({
      price: props.initHypotheses.price,
      workFurniture: props.initHypotheses.workFurniture,
      monthlyRent: props.initHypotheses.monthlyRent,
      supply: props.initHypotheses.supply,
      loanDuration: props.initHypotheses.loanDuration,
      interestRate: props.initHypotheses.interestRate,
      evolutionYear: props.initHypotheses.evolutionYear,
      evolutionRate: props.initHypotheses.evolutionRate,
      resaleYear: props.initHypotheses.resaleYear,
    });
  }

  let evolutionYearList = HypothesesData.evolutionYearList;
  let evolutionRateList = HypothesesData.evolutionRateList;
  let evolutionRateBorder = HypothesesData.evolutionRateBorder;

  const [evolutionMap, setEvolutionMap] = useState<Map<number, number>>(new Map());

  useEffect(() => {
    evolutionYearList.forEach((key: number, index: number) => setEvolutionMap(evolutionMap.set(key, evolutionRateList[index])));
  }, []);


  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;
  let backgroundShadeCard = isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;
  let backgroundShadeCardObject = isBackgroundLight ? classes.backgroundDarkObject : classes.backgroundLightObject;

  return (
    <div className={classnames(classes.hypothesesPage, backgroundShade)}>
      <div className={classnames(classes.hypothesesCard, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.hypothesesCardHeader}>
          <div className={classes.hypothesesTitle}>{t('achat.hypothesesTitle')}</div>
          <Button className={classnames(classes.buttonReset, isBackgroundLight ? classes.buttonResetBorder : null)} onClick={reset}>
            {t('achat.hypotheses.reset')}
          </Button>
        </div>
        <hr className={classes.hypothesesCardSeparator} />
        <div>
          <div className={classes.hypothesesSectionTitle}>{t('achat.hypotheses.purchase')}</div>
          <Hypotheses
            title={t('achat.commun.price')}
            explanation={t('achat.commun.priceExplanation')}
            value={props.hypotheses.price}
            onChange={value => props.updateHypotheses({price: value})}
            unit={property.priceAskingPriceCurrency ? Transaction.currency(property.priceAskingPriceCurrency) : '€'}
            numberFormatLanguage={t('transaction.formatNumber')}
            sliderProps={{
              min: (property.priceAskingPriceAmount * (100 - HypothesesData.minimalPricePercentage)) / 100,
              max: property.priceAskingPriceAmount,
              marks: [{value: property.priceAskingPriceAmount, label: t('achat.hypotheses.displayedPrice')}],
            }}
            isLarge
            isBackgroundLight={isBackgroundLight}
          />
          <Hypotheses
            title={t('achat.commun.workFurniture')}
            explanation={t('achat.commun.workFurnitureExplanation')}
            value={props.hypotheses.workFurniture}
            onChange={value => props.updateHypotheses({workFurniture: value})}
            unit={property.priceAskingPriceCurrency ? Transaction.currency(property.priceAskingPriceCurrency) : '€'}
            numberFormatLanguage={t('transaction.formatNumber')}
            sliderProps={{
              min: 0,
              max: HypothesesData.maximalWorkFurniture,
              marks: [{value: property.workFurniture || 0, label: t('achat.hypotheses.estimation')}],
            }}
            isLarge
            isBackgroundLight={isBackgroundLight}
          />
          <hr className={classes.hypothesesCardDottedSeparator} />
          <div className={classes.hypothesesSectionTitle}>{t('achat.hypotheses.location')}</div>
          <Hypotheses
            title={t('achat.commun.monthlyRent')}
            explanation={t('achat.commun.monthlyRentExplanation')}
            value={props.hypotheses.monthlyRent}
            onChange={value => props.updateHypotheses({monthlyRent: value})}
            unit={property.priceAskingPriceCurrency ? Transaction.currency(property.priceAskingPriceCurrency) : '€'}
            numberFormatLanguage={t('transaction.formatNumber')}
            sliderProps={{
              min: HypothesesData.monthlyRentBorder[0],
              max: HypothesesData.monthlyRentBorder[1],
              marks: [{value: property.monthlyRent || 0, label: t('achat.hypotheses.estimatedRent')}],
            }}
            isLarge
            isBackgroundLight={isBackgroundLight}
          />
        </div>
      </div>
      <div className={classnames(classes.hypothesesCard, backgroundShadeCard, backgroundShadeCardObject)}>
        <div>
          <div className={classes.hypothesesSectionTitle}>{t('achat.hypotheses.financing')}</div>
          <HypothesesSupply
            title={t('achat.hypotheses.supply')}
            explanation={t('achat.hypotheses.supplyExplanation')}
            value={props.hypotheses.supply}
            onChange={value => props.updateHypotheses({supply: value})}
            currency={property.priceAskingPriceCurrency}
            numberFormatLanguage={t('transaction.formatNumber')}
            hypotheses={props.hypotheses}
            property={property}
            sliderProps={{
              min: 0,
              max: SlideInvestorData.getTotalBudget(props.hypotheses, property),
            }}
            isBackgroundLight={isBackgroundLight}
          />
          <Hypotheses
            title={t('achat.hypotheses.loanDuration')}
            explanation={t('achat.hypotheses.loanDurationExplanation')}
            value={props.hypotheses.loanDuration}
            onChange={value => props.updateHypotheses({loanDuration: value})}
            unit={t('achat.hypotheses.years')}
            numberFormatLanguage={t('transaction.formatNumber')}
            sliderProps={{
              min: HypothesesData.loanDurationBorder[0],
              max: HypothesesData.loanDurationBorder[1],
            }}
            isLarge
            isBackgroundLight={isBackgroundLight}
          />
          <Hypotheses
            title={t('achat.hypotheses.interestRate')}
            explanation={t('achat.hypotheses.interestRateExplanation')}
            value={props.hypotheses.interestRate}
            onChange={value => props.updateHypotheses({interestRate: value})}
            numberFormatLanguage={t('transaction.formatNumber')}
            step={0.1}
            unit="%"
            precision={1}
            sliderProps={{
              min: HypothesesData.interestRateBorder[0],
              max: HypothesesData.interestRateBorder[1],
            }}
            isBackgroundLight={isBackgroundLight}
            decimalSeparator={t('achat.hypotheses.decimalSeparator')}
          />
          <hr className={classes.hypothesesCardDottedSeparator} />
          <div className={classes.hypothesesSectionTitle}>{t('achat.hypotheses.resale')}</div>
          <HypothesesEvolution
            title={t('achat.hypotheses.annualEvolutionPrice')}
            explanation={t('achat.hypotheses.annualEvolutionPriceExplanation')}
            valueYears={props.hypotheses.evolutionYear}
            onChangeYears={value => props.updateHypotheses({evolutionYear: value})}
            valueRate={props.hypotheses.evolutionRate}
            onChangeRate={value => props.updateHypotheses({evolutionRate: value})}
            numberFormatLanguage={t('transaction.formatNumber')}
            step={0.1}
            precision={1}
            list={evolutionYearList}
            map={evolutionMap}
            sliderProps={{
              min: evolutionRateBorder[0],
              max: evolutionRateBorder[1],
            }}
            changeWhenReset={changeWhenReset}
            isBackgroundLight={isBackgroundLight}
            decimalSeparator={t('achat.hypotheses.decimalSeparator')}
          />
          <Hypotheses
            title={t('achat.hypotheses.resaleYear')}
            explanation={t('achat.hypotheses.resaleYearExplanation')}
            value={props.hypotheses.resaleYear}
            onChange={value => props.updateHypotheses({resaleYear: value})}
            unit={''}
            numberFormatLanguage={t('transaction.formatNumber')}
            sliderProps={{
              min: Transaction.getYear(),
              max: Transaction.getYear()+HypothesesData.resaleYearMaxDuration,
            }}
            isLarge
            isBackgroundLight={isBackgroundLight}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  hypothesesPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    columnGap: '2rem',
  },
  hypothesesCardSeparator: {
    borderWidth: '0.1px',
    margin: '1rem 0',
  },
  hypothesesCardDottedSeparator: {
    border: 'dashed 0.1px grey',
    margin: '1.5rem 0 .5rem',
  },
  hypothesesCard: {
    overflow: 'hidden',
    borderRadius: '1rem',
    flex: '0 0 430px',
    padding: '1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  hypothesesCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
  hypothesesTitle: {
    fontWeight: 700,
    fontSize: '1.8rem',
  },
  buttonReset: {
    backgroundColor: theme.colors.black_pearl[0],
    '&:hover': {backgroundColor: `${theme.colors.gray[5]}`},
    borderRadius: '1rem',
    fontSize: '1.1rem',
  },
  buttonResetBorder: {
    borderColor: 'white',
  },
  hypothesesSectionTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
}));
