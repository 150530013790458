import {Button, createStyles, Group, Textarea, TextInput} from '@mantine/core';
import {showNotification} from '@mantine/notifications';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import ContactController from 'src/services/contact';
import {PropertyDetailsProps} from 'src/types/locationDetails';
import {ContactFull} from 'src/types/contact';
import ModalTemplate from '../modals/ModalTemplate';

type ModalInterestedProps = {
  opened: boolean;
  title: string;
  propertyDetails?: PropertyDetailsProps;
  close: () => void;
  propertyType: string;
};

export default function ModalInterested(props: ModalInterestedProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  const schema = Yup.object().shape({
    firstname: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    email: Yup.string().email(t('estimationInfoContact.emailError')).required('Required'),
    phoneNumber: Yup.string().required('Required'),
  });

  const form = useFormik({
    initialValues: {
      firstname: '',
      name: '',
      email: '',
      phoneNumber: '',
      comments: undefined,
    },
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: (values) => {
      // The next line allow to have the new line in the message that is sent to Reboot Immo in the back
      contact.comments = contact.comments?.replace(/\n/g, '<br>');
      ContactController.postSendContact(contact, {
        success: () => {
          showNotification({
            message: t('transactionInterested.sendSuccess'),
            autoClose: false,
          });
        },
        error: () => {
          showNotification({
            title: t('transactionInterested.sendFails'),
            message: t('transactionInterested.tryAgainLater'),
            autoClose: false,
          });
        },
      });
      props.close();
    },
  });

  const contact: ContactFull = {
    firstname: form.values.firstname,
    name: form.values.name,
    email: form.values.email,
    phoneNumber: form.values.phoneNumber,
    propertyTitle: props.propertyDetails?.property.descriptionTitle || "",
    propertyCity: props.propertyDetails?.property.locationCity || "",
    propertyType: props.propertyType,
    comments: form.values.comments,
  };

  return (
    <ModalTemplate opened={props.opened} onClose={props.close} title={t('transactionInterested.title')}>
      <form onSubmit={form.handleSubmit}>
        <Group className={classes.names}>
          <TextInput
            size="md"
            radius="lg"
            name="firstname"
            required
            label={t('transactionInterested.firstname')}
            placeholder={t('transactionInterested.firstname')}
            onChange={form.handleChange}
            value={form.values.firstname}
            type="string"
            error={form.errors.firstname && form.values.firstname.length ? t('transactionInterested.requiredField') : false}
            className={classes.nameInput}
          />
          <TextInput
            size="md"
            type="string"
            radius="lg"
            name="name"
            required
            placeholder={t('transactionInterested.name')}
            label={t('transactionInterested.name')}
            onChange={form.handleChange}
            value={form.values.name}
            error={form.errors.name && form.values.name.length ? t('transactionInterested.requiredField') : false}
            className={classes.nameInput}
          />
        </Group>

        <TextInput
          size="md"
          name="phoneNumber"
          required
          radius="lg"
          label={t('estimationInfoContact.phoneNumberLabel')}
          placeholder={t('estimationInfoContact.phoneNumberLabel')}
          onChange={form.handleChange}
          value={form.values.phoneNumber}
          type="tel"
          error={form.errors.phoneNumber && form.values.phoneNumber.length ? t('estimationInfoContact.phoneNumberError') : false}
        />

        <TextInput
          size="md"
          radius="lg"
          name="email"
          type="email"
          required
          label={t('estimationInfoContact.emailLabel')}
          placeholder={t('estimationInfoContact.emailLabel')}
          onChange={form.handleChange}
          value={form.values.email}
          error={form.errors.email && form.values.email.length ? t('estimationInfoContact.emailError') : false}
        />

        <Textarea
          name="comments"
          label={t('transactionInterested.commentsLabel')}
          placeholder={t('transactionInterested.commentsPlaceholder')}
          size="md"
          onChange={form.handleChange}
          value={form.values.comments}
        />

        <Button size="xl" radius="xl" type="submit" className={classes.button} disabled={form.isValid == false}>
          {t('transactionInterested.send')}
        </Button>
      </form>
    </ModalTemplate>
  );
}

const useStyles = createStyles(theme => ({
  button: {
    background: theme.colors.black_pearl[0],
    marginTop: '1rem',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  names: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameInput: {
    flexGrow: 1,
  },
}));
