import FilterData from 'src/app/components/filter/FilterData';
import RechercheBiens from 'src/app/components/recherche-biens/RechercheBiens';
import {RangeValue} from 'src/types/filter';
import LocationController from 'src/services/location';
import { useTranslation } from 'react-i18next';

export default function Location(): JSX.Element {
  const {t} = useTranslation();

  return (
    <RechercheBiens
      transactionType="location"
      postFilteredPropertyList={LocationController.postFilteredPropertyList}
      areaBounds={FilterData.areaSlider.range as RangeValue}
      budgetBounds={FilterData.budgetSlider.rangeLocation as RangeValue}
      budgetStep={FilterData.budgetSlider.stepLocation}
      introTitle="location.intro.title"
      introDescription={t("location.intro.description")}
    />
  );
}
