import classnames from 'classnames';
import {useEffect} from 'react';
import {MapContainer, TileLayer, Marker, Popup, Circle} from 'react-leaflet';
import {createStyles} from '@mantine/core';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {amenity, printedStation, transport} from 'src/types/locationDetails';
import {MdDirectionsBus, MdDirectionsRailway, MdDirectionsSubway, MdTram} from 'react-icons/md';
import {useTranslation} from 'react-i18next';
import pinMarket from '../../../assets/icons/pin_market.png';
import pinHome from '../../../assets/icons/pin_home.png';
import pinBus from '../../../assets/icons/pin_bus.png';
import pinTram from '../../../assets/icons/pin_tram.png';
import pinSubway from '../../../assets/icons/pin_subway.png';
import pinTrain from '../../../assets/icons/pin_train.png';

type MapProps = {
  coordinatesArray: L.LatLngExpression;
  className?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  dragging?: boolean;
  doubleClickZoom?: boolean;
  displayPin?: boolean;
  displayCircle?: boolean;
  radius?: number;
  popupText?: string;
  markets?: amenity[];
  stations?: printedStation[];
};

export default function LeafletMap({
  coordinatesArray,
  className,
  borderRadius,
  height,
  width,
  dragging,
  doubleClickZoom,
  displayPin,
  displayCircle,
  radius,
  popupText,
  markets,
  stations,
}: MapProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  //icon created on : https://api.geoapify.com/v1/icon/?type=material&color=%23d60e0e&icon=shopping-cart&iconType=awesome&noWhiteCircle&apiKey=${apiKey}
  const marketMarker = L.icon({
    iconUrl: pinMarket,
    iconSize: [31, 46], // size of the icon
    iconAnchor: [15.5, 42], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -45], // point from which the popup should open relative to the iconAnchor
  });

  const homeMarker = L.icon({
    iconUrl: pinHome,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  });

  const busMarker = L.icon({
    iconUrl: pinBus,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  });

  const tramMarker = L.icon({
    iconUrl: pinTram,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  });

  const subwayMarker = L.icon({
    iconUrl: pinSubway,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  });

  const trainMarker = L.icon({
    iconUrl: pinTrain,
    iconSize: [31, 46],
    iconAnchor: [15.5, 42],
    popupAnchor: [0, -45],
  });

  function DisplayPin(): JSX.Element | null {
    return displayPin ? (
      <Marker position={coordinatesArray} icon={homeMarker} zIndexOffset={1000}>
        {popupText ? <Popup>{popupText}</Popup> : null}
      </Marker>
    ) : null;
  }

  function DisplayCircle(): JSX.Element | null {
    return displayCircle ? (
      <Circle center={coordinatesArray} radius={radius}>
        {popupText ? <Popup>{popupText}</Popup> : null}
      </Circle>
    ) : null;
  }

  function DisplayMarket(latitude: number, longitude: number, title: string): JSX.Element | null {
    return (
      <Marker position={[latitude, longitude]} key={title} icon={marketMarker}>
        <Popup>{title}</Popup>
      </Marker>
    );
  }

  function CreateLineIcon(transport: transport): JSX.Element {
    return (
      <div
        className={transport.name.length < 3 ? classnames(classes.transportLineIcon, classes.transportLineIconStandard) : classes.transportLineIcon}
        style={{backgroundColor: transport.color, color: transport.textColor}}
        key={transport.mode + transport.name}>
        {transport.name}
      </div>
    );
  }

  function DisplayTransportStation(printedStation: printedStation): JSX.Element | null {
    return (
      <Marker
        position={[printedStation.station.latitude, printedStation.station.longitude]}
        key={printedStation.station.id}
        icon={
          printedStation.transportsMode.train.length
            ? trainMarker
            : printedStation.transportsMode.subway.length
            ? subwayMarker
            : printedStation.transportsMode.lightRail.length
            ? tramMarker
            : busMarker
        }>
        <Popup>
          <div className={classes.popup}>
            {printedStation.station.title}
            {printedStation.transportsMode.subway.length ? (
              <div className={classes.transportLines}>
                <MdDirectionsSubway className={classes.transportSmallIcon} /> {t('transactionTransport.subway')} :
                {printedStation.transportsMode.subway.map((transport: transport) => (
                  <div key={transport.mode + transport.name}>{CreateLineIcon(transport)}</div>
                ))}
              </div>
            ) : null}
            {printedStation.transportsMode.lightRail.length ? (
              <div className={classes.transportLines}>
                <MdTram className={classes.transportSmallIcon} /> {t('transactionTransport.lightRail')} :
                {printedStation.transportsMode.lightRail.map((transport: transport) => (
                  <div key={transport.mode + transport.name}>{CreateLineIcon(transport)}</div>
                ))}
              </div>
            ) : null}
            {printedStation.transportsMode.bus.length ? (
              <div className={classes.transportLines}>
                <MdDirectionsBus className={classes.transportSmallIcon} />
                {t('transactionTransport.bus')} :
                {printedStation.transportsMode.bus.map((transport: transport) => (
                  <div key={transport.mode + transport.name}>{CreateLineIcon(transport)}</div>
                ))}
              </div>
            ) : null}
            {printedStation.transportsMode.train.length ? (
              <div className={classes.transportLines}>
                <MdDirectionsRailway className={classes.transportSmallIcon} /> {t('transactionTransport.train')} :
                {printedStation.transportsMode.train.map((transport: transport) => (
                  <div key={transport.mode + transport.name}>{CreateLineIcon(transport)}</div>
                ))}
              </div>
            ) : null}
          </div>
        </Popup>
      </Marker>
    );
  }

  useEffect(() => {
    delete (L.Icon.Default.prototype as any)._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
  }, []);

  return (
    <div className={classnames('head', classes.container, className)}>
      <MapContainer
        key={coordinatesArray.toString()}
        center={coordinatesArray}
        zoom={13}
        style={{height: height, width: width, borderRadius: borderRadius}}
        scrollWheelZoom="center"
        dragging={dragging || false}
        doubleClickZoom={doubleClickZoom || false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {DisplayPin()}
        {DisplayCircle()}
        {markets?.map(market => DisplayMarket(market.latitude, market.longitude, market.title))}
        {stations?.map(station => DisplayTransportStation(station))}
      </MapContainer>
    </div>
  );
}

const useStyles = createStyles(() => ({
  container: {
    position: 'relative',
    [`@media (max-width: 1020px)`]: {
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
  },
  popup: {
    display: 'flex',
    flexDirection: 'column',
  },
  transportLineIcon: {
    borderRadius: '.2rem',
    margin: '.2rem',
    height: '1.5rem',
  },
  transportLineIconStandard: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transportLines: {
    display: 'flex',
    marginLeft: '.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transportSmallIcon: {
    marginTop: '-.1rem',
  },
}));
