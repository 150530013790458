import {createStyles, HoverCard, NumberInput, Slider, Text} from '@mantine/core';
import {BsQuestionCircle} from 'react-icons/bs';
import {SliderProps} from 'src/types/hypotheses';
import Transaction from 'src/services/transaction';
import classnames from 'classnames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HypothesesFields, PropertyDetails} from 'src/types/locationDetails';
import * as SlideInvestorData from 'src/app/components/slides/SlideInvestorData';



type HypothesesSupplyProps = {
  title: string;
  explanation: string;
  value: number;
  onChange: (value: number) => void;
  sliderProps: SliderProps;
  currency?: string;
  numberFormatLanguage: string;
  step?: number;
  precision?: number;
  hypotheses: HypothesesFields;
  property: PropertyDetails;
  isBackgroundLight: boolean;
};

export default function HypothesesSupply(props: HypothesesSupplyProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  const [localValue, setLocalValue] = useState(props.value);
  let backgroundShadeCard = props.isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;


  function getSupplyPercentage():number {
    return (localValue / (SlideInvestorData.getTotalBudget(props.hypotheses, props.property))) * 100;
  }

  // if reset
  useEffect(() => {
    if (props.value !== undefined) {
      setLocalValue(props.value);
    }
  }, [props.value]);

  return (
    <div>
      <div className={classes.line}>
        <div className={classes.lineTitle}>
          {props.title}
          <HoverCard withArrow={true} width="280px" shadow="md">
            <HoverCard.Target>
              <div>
                <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown className={classes.questionDropdown}>
              <Text size="sm">{props.explanation}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </div>
        <div className={classes.inputAndCurrency}>
          {Transaction.formatNumber(getSupplyPercentage(), props.numberFormatLanguage, 3, 3)}%<div>{t('achat.hypotheses.supplyOr')}</div>
          <NumberInput
            value={localValue}
            size="md"
            step={props.step}
            precision={props.precision}
            onChange={val => {
              if (val !== '' && !isNaN(val)) {
                setLocalValue(val);
                props.onChange(val);
              }
            }}
            variant="unstyled"
            hideControls
            classNames={{wrapper: classnames(classes.numberInput, classes.largeInput), input: backgroundShadeCard}}
            parser={value => value?.replace(/\s?|(,*)/g, '')}
            formatter={value => {
              return !Number.isNaN(parseFloat(value as string)) ? Transaction.formatNumber(parseFloat(value as string), props.numberFormatLanguage) : '';
            }}
          />
          {props.currency ? Transaction.currency(props.currency) : '%'}
        </div>
      </div>
      <Slider
        min={props.sliderProps.min}
        max={props.sliderProps.max}
        size="xs"
        value={localValue}
        onChange={setLocalValue}
        onChangeEnd={props.onChange}
        label={null}
        showLabelOnHover={false}
        color="red"
        step={props.step}
        marks={props.sliderProps.marks}
        className={classes.slider}
      />
    </div>
  );
}

const useStyles = createStyles(theme => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lineTitle: {
    display: 'flex',
    gap: '1rem',
  },
  questionIcon: {
    cursor: 'pointer',
  },
  questionDropdown: {
    position: 'absolute',
    transform: 'translate(4px, -4px)',
    color: theme.black,
  },
  inputAndCurrency: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  slider: {
    margin: '1rem',
  },
  numberInput: {
    borderBottom: 'solid 1px',
    height: '2.5rem',
    textAlign: 'center',
  },
  largeInput: {
    width: '4rem',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
}));
