import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {PropertyDetails} from 'src/types/locationDetails';

type DescriptionComponentProps = {
  className?: string;
  property: PropertyDetails;
  showChargesIncluded?: boolean;
};

export function DescriptionComponent(props: DescriptionComponentProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={props.className}>
      <div className={classes.descriptionTitle}>
        {props.property.descriptionTitle}
      </div>

      <div className={classes.descriptionCity}>
        {props.property.locationCity} ({props.property.locationPostalCode})
      </div>

      <div className={classes.descriptionDescription}>
        {props.property.descriptionDescription}

        {props.showChargesIncluded && (
          <div className={classes.descriptionCharges}>
            {t('locationDescription.rent_charges')}
          </div>
        )}
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  descriptionTitle: {
    fontSize: '2rem',
    textAlign:'center',
    overflow: 'hidden',
  },
  descriptionCity: {
    fontSize: '1.5rem',
    fontWeight: 300,
    [`@media (max-width: 1020px)`]: {
      marginTop: "16px",
    },
  },
  descriptionDescription: {
    marginTop: '1rem',
    fontSize: '1.25rem',
    fontWeight: 300,
    textAlign: 'justify',
    whiteSpace: 'pre-wrap',
  },
  descriptionContentRoot: {
    outline: '0',
    display: 'flex',
    gap: '1rem',
  },
  descriptionContent: {
    maxHeight: '200px',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    paddingRight: '1rem',
    whiteSpace: 'break-spaces',
    maxWidth: 'max-content',
  },
  descriptionCharges: {
    marginTop: '1rem',
    fontWeight: 500,
  },
}));
