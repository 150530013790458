import {createStyles, SegmentedControl, NumberInput, HoverCard, Text} from '@mantine/core';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import GestionData from './GestionData';
import {IconInfo} from 'src/assets/icons/IconInfo';
import IllustratedPage from 'src/app/components/illustrated/IllustratedPage';

export default function Gestion(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const [pack, setPack] = useState('champagne');
  const [GLI, setGLI] = useState('no');
  const [GLIRate, setGLIRate] = useState('2.6');
  const [rent, setRent] = useState<number>(500);

  const NumberFormat = new Intl.NumberFormat('fr-FR', {
    style     : 'currency',
    currency  : 'EUR',
  });

  const SegmentedControlClassnames = {
    root: classes.segmentedControlRoot,
    control:  classes.segmentedControlControl,
    controlActive: classes.segmentedControlActive,
    label: classes.segmentedControlLabel,
    indicator: classes.segmentedControlIndicator,
  };

  const SegmentedControlPackData = [
    {
      label: t('gestion.cremant'),
      value: 'cremant',
    },
    {
      label: t('gestion.champagne'),
      value: 'champagne',
    },
  ];

  const SegmentedControlGLIData = [
    {
      label: t('gestion.yes'),
      value: 'yes',
    },
    {
      label: t('gestion.no'),
      value: 'no',
    },
  ];

  const SegmentedControlGLIRateData = [
    {
      label: t('gestion.rate26'),
      value: '2.6',
    },
    {
      label: t('gestion.rate29'),
      value: '2.9',
    },
  ];

  function getPercentageAccordingToPack(): number {
    let annualRent = rent * 12;

    for (let index in GestionData.tranches)
      if (annualRent < GestionData.tranches[index])
          return pack === 'champagne'
            ? GestionData.champagne[index]
            : GestionData.cremant[index];

    // Return the highest if annualRent is bigger.
    return pack === 'champagne'
      ? GestionData.champagne.at(-1) as number
      : GestionData.cremant.at(-1) as number;
  }

  function getPercentageAccordingToGLI(): number {
    return GLI === 'yes'
      ? GLIRate === '2.6'
        ? 2.6
        : 2.9
      : 0;
  }

  function getFinalRent(): number {
    return rent * ((100 - getPercentageAccordingToPack() - getPercentageAccordingToGLI()) / 100);
  }

  return (
    <IllustratedPage titleKey='gestion.intro.title' subtitleKey='gestion.intro.description'>
      <div className={classes.card}>
        <div className={classes.cardColumn}>
          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.packQuestion')}

              <HoverCard>
                <HoverCard.Target>
                  <IconInfo />
                </HoverCard.Target>

                <HoverCard.Dropdown>
                  <Text>
                    <Link to="/honoraires">
                      {t('gestion.details')}
                    </Link>
                  </Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </div>

            <SegmentedControl radius="lg" classNames={SegmentedControlClassnames} value={pack} onChange={setPack} data={SegmentedControlPackData} />
          </div>

          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.GLIQuestion')}
            </div>

            <SegmentedControl radius="lg" classNames={SegmentedControlClassnames} value={GLI} onChange={setGLI} data={SegmentedControlGLIData} />
            <SegmentedControl radius="lg" classNames={SegmentedControlClassnames} value={GLIRate} onChange={setGLIRate} data={SegmentedControlGLIRateData} disabled={GLI === "no"} />
          </div>

          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.rent')}
            </div>

            <NumberInput value={rent} onChange={(value) => setRent(value || 0)} hideControls />
          </div>
        </div>

        <div className={classes.cardColumn}>
          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.rentEstimated')}
            </div>

            <div className={classes.cardResult}>
              {NumberFormat.format(getFinalRent())}
            </div>
          </div>

          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.costReboot')}
            </div>

            <div className={classes.cardResult}>
              {NumberFormat.format((rent * getPercentageAccordingToPack()) / 100)}
            </div>
          </div>

          <div className={classes.cardGroup}>
            <div className={classes.cardGroupTitle}>
              {t('gestion.costGLI')}
            </div>

            <div className={classes.cardResult}>
              {NumberFormat.format((rent * getPercentageAccordingToGLI()) / 100)}
            </div>
          </div>
        </div>
      </div>
    </IllustratedPage>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    pointerEvents: 'all',
    transform: 'scale(0.8) translateY(-40px)',
    background: theme.colors.local_grey[1],
    boxShadow: '0 .25rem 2rem rgba(0, 0, 0, .25)',
    borderRadius: '2rem',
    display: 'flex',
    alignItems: 'center',
    width: '700px',
    gap: '2.5rem',
    padding: '2.5rem',
    margin: '0 auto',
    marginBottom: '-1rem',
    [`@media (max-width: 960px)`]: {
      boxSizing: 'border-box',
      boxShadow: 'unset',
      width: '100%',
      transform: 'unset',
      background: 'unset',
      flexDirection: 'column',
      alignItems: 'unset',
      margin: '1rem 0',
      padding: 0,
    },
  },
  cardColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    [`@media (max-width: 960px)`]: {
      ':last-of-type': {
        background: '#e9ecef',
        borderRadius: '2rem',
        alignItems: 'center',
        padding: '1.5rem 0',
        textAlign: 'center',
      },
    },
    [`@media (min-width: 960px)`]: {
      ':first-of-type': {
        flex: '0 0 350px',
      },
      ':last-of-type': {
        flex: '0 0 250px',
        marginLeft: 'auto',
      },
    },
  },
  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
  cardGroupTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    fontSize: '1.05rem',
    fontWeight: 600,
    svg: {
      color: theme.colors.local_red[0],
      width: '18px',
      height: '18px',
    },
    a: {
      color: theme.colors.local_red[0] + ' !important',
    },
  },
  cardResult: {
    color: theme.colors.local_red[1],
    fontSize: '2rem',
    fontWeight: 900,
  },
  segmentedControlRoot: {
    height: '32px',
    background: theme.colors.gray[2],
  },
  segmentedControlLabel: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 0',
    height: '100%',
  },
  segmentedControlControl: {
    paddingBottom: '1px',
  },
  segmentedControlActive: {
    'label[data-active], &:hover label[data-active]': {
      color: theme.white,
    },
    'label[data-disabled]': {
      color: 'rgba(255, 255, 255, .5) !important',
    },
  },
  segmentedControlIndicator: {
    background: theme.colors.local_red[0],
    color: theme.white,
    height: '32px !important',
  },
}));
