import classnames from 'classnames';
import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {HypothesesFields, PropertyDetailsProps} from 'src/types/locationDetails';
import {Chart as ChartJS, ArcElement, Tooltip} from 'chart.js';
import Transaction from 'src/services/transaction';
import {Doughnut} from 'react-chartjs-2';
import BudgetLegendLine from '../budget/BudgetLegendLine';
import * as SlideInvestorData from './SlideInvestorData';

type SlideBudgetProps = {
  propertyDetails: PropertyDetailsProps;
  hypotheses: HypothesesFields;
};

// Le texte ne revient pas automatiquement à la ligne donc il faut soit le couper en une liste, soit faire un tooltip personnalisé (plus compliqué et pas nécessaire)
export function splitStringInArray(text: string): string[] {
  let res: string[] = [];
  let tempFin = 0;
  let trimmedString = '';
  while (text.length > 45) {
    trimmedString = text.substring(0, 45);
    tempFin = Math.min(trimmedString.length, trimmedString.lastIndexOf(' '));
    trimmedString = trimmedString.substring(0, tempFin);
    text = text.substring(tempFin + 1); //on enlève l'espace qui vient
    res.push(trimmedString);
  }
  res.push(text);
  return res;
}

export default function SlideBudget(props: SlideBudgetProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = props.propertyDetails.property;
  const isBackgroundLight = 1;

  ChartJS.register(ArcElement, Tooltip);

  // Attention à respecter l'ordre des élements : workAndFurniture, lawyerCost, rebootCost, price
  const ExplanationLabel = [
    splitStringInArray(t('achat.commun.workFurnitureExplanation')),
    splitStringInArray(t('achat.budget.lawyerCostExplanation')),
    splitStringInArray(t('achat.budget.rebootCostExplanation')),
    splitStringInArray(t('achat.commun.priceExplanation')),
  ];

  let colors = new Map();
  colors.set('price', '#F94A46');
  colors.set('workAndFurniture', '#FFB70D');
  colors.set('lawyerCost', '#92B620');
  colors.set('rebootCost', '#70B5E8');

  const data = {
    labels: [t('achat.commun.workFurniture'), t('achat.budget.lawyerCost'), t('achat.budget.rebootCost'), t('achat.commun.price')],
    datasets: [
      {
        data: [props.hypotheses.workFurniture, SlideInvestorData.getLawyerCost(props.hypotheses.price), property.rebootCost, props.hypotheses.price],
        backgroundColor: [colors.get('workAndFurniture'), colors.get('lawyerCost'), colors.get('rebootCost'), colors.get('price')],
        borderWidth: 0,
      },
    ],
  };

  let options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#FFF',
        titleColor: '#000',
        titleFont: {size: 16},
        bodyColor: '#000',
        bodyFont: {size: 14},
        displayColors: false,
        callbacks: {
          title: (context: any) => {
            return `${context[0].label}: ${context[0].formattedValue} ${Transaction.currency(property.priceAskingPriceCurrency)}`;
          },
          label: (context: any) => {
            return ExplanationLabel[context.dataIndex];
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    cutout: 105,
  };

  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;
  let backgroundShadeCard = isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;
  let backgroundShadeCardObject = isBackgroundLight ? classes.backgroundDarkObject : classes.backgroundLightObject;

  return (
    <div className={classnames(classes.budgetPage, backgroundShade)}>
      <div className={classnames(classes.budgetCard, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.columnLeft}>
          <Doughnut data={data} options={options} className={classes.doughnut} />
          <div className={classes.textInDoughnut}>
            <div className={classes.totalAmount}>
              {Transaction.formatNumber(SlideInvestorData.getTotalBudget(props.hypotheses, property), t('transaction.formatNumber'))}
              {Transaction.currency(property.priceAskingPriceCurrency)}
            </div>
            <div className={classes.totalText}>{t('achat.budget.totalBudget')}</div>
          </div>
        </div>
        <div className={classes.columnRight}>
          <div className={classes.budgetCardHeader}>
            <div className={classes.budgetTitle}>{t('achat.budget.totalBudget')}</div>
          </div>
          <hr className={classes.budgetCardSeparator} />
          <div className={classes.legend}>
            <BudgetLegendLine
              name={t('achat.commun.price')}
              description={t('achat.commun.priceExplanation')}
              amount={Transaction.formatNumber(props.hypotheses.price, t('transaction.formatNumber'))}
              color={colors.get('price')}
              currency={Transaction.currency(props.propertyDetails.property.priceAskingPriceCurrency)}
            />
            <BudgetLegendLine
              name={t('achat.commun.workFurniture')}
              description={t('achat.commun.workFurnitureExplanation')}
              amount={Transaction.formatNumber(props.hypotheses.workFurniture, t('transaction.formatNumber'))}
              color={colors.get('workAndFurniture')}
              currency={Transaction.currency(props.propertyDetails.property.priceAskingPriceCurrency)}
            />
            <BudgetLegendLine
              name={`${t('achat.budget.lawyerCost')}*`}
              description={t('achat.budget.lawyerCostExplanation')}
              amount={Transaction.formatNumber(SlideInvestorData.getLawyerCost(props.hypotheses.price), t('transaction.formatNumber'))}
              color={colors.get('lawyerCost')}
              currency={Transaction.currency(props.propertyDetails.property.priceAskingPriceCurrency)}
            />
            <BudgetLegendLine
              name={t('achat.budget.rebootCost')}
              description={t('achat.budget.rebootCostExplanation')}
              amount={Transaction.formatNumber(property.rebootCost, t('transaction.formatNumber'))}
              color={colors.get('rebootCost')}
              currency={Transaction.currency(props.propertyDetails.property.priceAskingPriceCurrency)}
            />
          </div>
          <div className={classes.estimation}>{t('achat.budget.estimation')}</div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  budgetPage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
  },
  budgetCard: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '1rem',
    padding: '1rem',
  },
  columnLeft: {
    margin: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginRight: '2rem',
  },
  budgetCardSeparator: {
    borderWidth: 'solid 0.1px grey',
    margin: '1rem 0',
  },
  budgetCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
  budgetTitle: {
    fontWeight: 700,
    fontSize: '1.8rem',
  },
  legend: {
    fontWeight: 500,
  },
  legendLine: {
    display: 'flex',
    gap: '5rem',
    justifyContent: 'space-between',
    margin: '1.5rem 0',
  },
  legendLineTitle: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  legendColorCircle: {
    borderRadius: '50%',
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: 'red',
  },
  textInDoughnut: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '15%',
    zIndex: 0,
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '2.3rem',
  },
  totalText: {
    fontWeight: 400,
  },
  doughnut: {
    zIndex: 1000,
  },
  questionDropdown: {
    color: theme.black,
  },
  estimation: {
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: 400,
  }
}));
