import {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Divider, createStyles} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import LocationController from 'src/services/location';
import Slide from 'src/app/components/slides/Slide';
import SlideDetails from 'src/app/components/slides/SlideDetails';
import SlideDescriptionLocation from 'src/app/components/slides/SlideDescriptionLocation';
import SlideDPE from 'src/app/components/slides/SlideDPE';
import SlideTransport from 'src/app/components/slides/SlideTransport';
import SlideContact from 'src/app/components/slides/SlideContact';
import {PropertyDetails} from 'src/types/locationDetails';
import {AiOutlineEuro} from 'react-icons/ai';
import {MdOutlineTram} from 'react-icons/md';
import {BsFillTelephoneFill, BsCameraFill} from 'react-icons/bs';
import {useTranslation} from 'react-i18next';
import ModalLoading from 'src/app/components/loading/Loading';
import { divIcon } from 'leaflet';
import SlideMobile from 'src/app/components/slides/SlideMobile';
import classNames from 'classnames';

export default function LocationDetails(): JSX.Element {
  const [property, setProperty] = useState<PropertyDetails>();
  const [nbSlides, setNbSlides] = useState(0);
  const [error, setError] = useState(false);
  const {t} = useTranslation();
  let {id, slide} = useParams();
  const {classes} = useStyles();
  let navigate = useNavigate();
  let isMobileVersion = useMediaQuery('(max-width: 1020px)');

  const descriptions = [
    t('transaction.detailsTitle'),
    t('transaction.descriptionTitle'),
    t('transaction.transportTitle'),
    {
      long: t('transaction.dpeTitle'),
      short: t('transaction.dpeTitleShort'),
    },
    t('transaction.contactTitle'),
  ];

  const icons = [
    /* eslint-disable react/jsx-key */
    <BsCameraFill className={classes.scrollPagesItemIcon} />,
    <AiOutlineEuro className={classes.scrollPagesItemIcon} />,
    <MdOutlineTram className={classes.scrollPagesItemIcon} />,
    <div className={classes.scrollPagesItemText}>{t('transaction.dpeTitleShort')}</div>,
    <BsFillTelephoneFill className={classes.scrollPagesItemIcon} />,
    /* eslint-enable react/jsx-key */
  ];

  function onChange(index: number): void {
    if(document.documentElement.clientWidth > 1020){
      navigate(`/location/details/${id}/${index}`, {replace: true});
    } else {
      document.getElementById(`${index}`)?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }

  useEffect(() => {
    LocationController.getPropertyDetails(id as string, {
      error: (err) => {
        console.error(err);
        setError(true);
      },
      success: (prop) => {
        setProperty({...prop, id: id});
    }});
  }, []);

  if (property && isMobileVersion)
  // render mobile version
  return (
  <div className={classNames(classes.wrapper)}>
    <SlideMobile  icons={icons} descriptions={descriptions}>
      <SlideDetails propertyDetails={{property}} />
      <SlideDescriptionLocation propertyDetails={{property}} />
      <SlideTransport propertyDetails={{property}} />
      <SlideDPE propertyDetails={{property}} />
      <SlideContact />
    </SlideMobile>
    </div>
  );

  // render desktop version
  if (property)
    return (
    <Slide className={classes.wrapper} onChange={onChange} position={parseInt(slide as string)} icons={icons} descriptions={descriptions} setNbSlides={setNbSlides} investor={false}>
      <SlideDetails propertyDetails={{property}} />
      <SlideDescriptionLocation propertyDetails={{property}} />
      <SlideTransport propertyDetails={{property}} />
      <SlideDPE propertyDetails={{property}} />
      <SlideContact />
    </Slide>
    );

  else
    return <ModalLoading showError={error} />;
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
  },

  textBackSearch: {
    fontSize: '1.5rem',
  },
  scrollPagesItemIcon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  scrollPagesItemText: {
    width: '18px',
    height: '18px',
    margin: '1.6rem',
    [`@media (max-width: 1020px)`]: {
      width: 'auto',
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  loadingPage: {
    flex: '1 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  loadingIllustration: {
    position: 'relative',
    marginBottom: '-.5rem',
    '[data-error]': {
      filter: 'grayscale(.75)',
    },
    '> svg': {
      display: 'block',
      width: '256px',
      height: '256px',
      marginTop: '-3.5rem',
    },
  },
}));
