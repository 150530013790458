export default {
  colors_dpe: ['#3fc00c', '#61f127', '#cafa02', '#f9de15', '#fdad03', '#fc7230', '#da4654'],
  colors_ghe: ['#fdeafe', '#ffdefd', '#fabefd', '#fb7eff', '#f652fd', '#e428f4', '#b416b9'],
  colors_ghe_2021: ['#90E2FA', '#76B6D1', '#618FB0', '#4C6989', '#364969', '#262B4B', '#1A162F'],
  letters: [
    ['A', '<= 50', '<= 5'],
    ['B', '51 à 90', '6 à 10'],
    ['C', '91 à 150', '11 à 20'],
    ['D', '151 à 230', '21 à 35'],
    ['E', '231 à 330', '36 à 55'],
    ['F', '331 à 450', '56 à 80'],
    ['G', '>= 450', '>= 80'],
  ],
  letterToIndex(letter: string): number {
    return (/[A-Z]/.exec(letter)?.[0] ?? 'A').charCodeAt(0) - 'A'.charCodeAt(0);
  },
  valueToLetterDpe(value: number): string {
    if (value <= 50) return 'A';
    if (value <= 90) return 'B';
    if (value <= 150) return 'C';
    if (value <= 230) return 'D';
    if (value <= 330) return 'E';
    if (value <= 450) return 'F';
    return 'G';
  },
  valueToLetterGhe(value: number): string {
    if (value <= 5) return 'A';
    if (value <= 10) return 'B';
    if (value <= 20) return 'C';
    if (value <= 35) return 'D';
    if (value <= 55) return 'E';
    if (value <= 80) return 'F';
    return 'G';
  },
  valueToLetterDpe2021(valueDpe: number, valueGhe: number): string {
    if (valueDpe <= 70 && valueGhe <= 6) return 'A';
    if (valueDpe <= 110 && valueGhe <= 11) return 'B';
    if (valueDpe <= 180 && valueGhe <= 30) return 'C';
    if (valueDpe <= 250 && valueGhe <= 50) return 'D';
    if (valueDpe <= 330 && valueGhe <= 70) return 'E';
    if (valueDpe <= 420 && valueGhe <= 101) return 'F';
    return 'G';
  },
  valueToLetterGhe2021(value: number): string {
    if (value <= 6) return 'A';
    if (value <= 11) return 'B';
    if (value <= 30) return 'C';
    if (value <= 50) return 'D';
    if (value <= 70) return 'E';
    if (value <= 100) return 'F';
    return 'G';
  },
};
