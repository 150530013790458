import * as Yup from 'yup';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, createStyles, Text, RingProgress} from '@mantine/core';
import UploadVideo from 'src/app/components/estimation/UploadVideo';
import {Contact} from 'src/types/contact';
import {FormikProps, useFormik} from 'formik';
import InfoContact from 'src/app/components/estimation/InfoContact';
import EstimationController from 'src/services/estimation';
import {notifications} from '@mantine/notifications';
import ReactWhatsapp from 'react-whatsapp';
import {BsWhatsapp} from 'react-icons/bs';
import EstimationData from 'src/app/components/estimation/EstimationData';
import {AxiosProgressEvent} from 'axios';
import ModalTemplate from 'src/app/components/modals/ModalTemplate';
import IllustratedPage from 'src/app/components/illustrated/IllustratedPage';

export default function Estimation(): JSX.Element {
  const {classes} = useStyles();
  const [videoList, setVideoList] = useState<File[]>([]);
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [percentage, setPercentage] = useState(0);
  const [email] = useState(location.state ? (location.state as Contact).email : '');
  const [phoneNumber] = useState(location.state ? (location.state as Contact).phoneNumber : '');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email(t('estimationInfoContact.emailError')).required('Required'),
    phoneNumber: Yup.string().required('Required'),
  });

  const form: FormikProps<Contact> = useFormik({
    initialValues: {
      email: email,
      phoneNumber: phoneNumber,
    },
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: onSubmit,
  });

  function updateVideoList(videos: File[]): void {
    setVideoList([...videoList, ...videos]);
  }

  function deleteVideoFromList(key: number): void {
    setVideoList(videoList.filter((nameFile, index) => key !== index));
  }

  async function onSubmit(): Promise<void> {
    setButtonClicked(true);
    let percent = 0;
    const formData = new FormData();
    for (let i = 0; i < videoList.length; i++) {
      formData.append('files', videoList[i]);
    }
    formData.append('email', form.values.email);
    formData.append('phoneNumber', form.values.phoneNumber);

    function onUploadProgress(progressEvent: AxiosProgressEvent): void {
      // The type indicates that "total" can be undefined but this is only true for download when HTTP responses do not contain a "Content-Length" header.
      // For upload progress "total" will always be a finite number.
      percent = Math.ceil((progressEvent.loaded * 100) / (progressEvent.total as number));
      setPercentage(percent);
      if (percent > 99) {
        setTimeout(() => {
          setShowModal(true);
        }, 500);
      }
    }

    EstimationController.postSendVideo(formData, onUploadProgress, {
      success: () => {},
      error: () => {
        notifications.show({
          title: t('estimation.uploadFails'),
          message: t('estimation.tryAgainLater'),
          autoClose: false,
        });
      },
    });
  }

  function renderModal(): JSX.Element | undefined {
    if (buttonClicked) {
      if (showModal) {
        return (
          <ModalTemplate
            opened={true}
            onClose={() => navigate('/')}
            title={t('estimation.sendingTitle')}>
            {t('estimation.sendingMessage')}
          </ModalTemplate>
        );

      } else {
        return (
          <ModalTemplate
            opened={true}
            className={classes.modalProgress}
            withCloseButton={false}
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => {}}
            title={t('estimationUpload.loadVideo')}>
              <RingProgress
                size={250}
                style={{ margin: 'auto' }}
                sections={[{value: percentage, color: 'blue'}]}
                label={
                  <Text color="blue" weight={700} align="center" className={classes.textRingProgress}>
                    {percentage}%
                  </Text>
                }/>
          </ModalTemplate>
        );
      }
    }
  }

  return (
    <IllustratedPage hideIntro={buttonClicked} titleKey='estimation.intro.title' subtitleKey='estimation.intro.description'>
      {renderModal()}

      {buttonClicked || (
        <form onSubmit={form.handleSubmit} className={classes.form}>
          <InfoContact form={form} location={location} className={classes.infoContact} />
          <UploadVideo updateVideoList={updateVideoList} deleteVideoFromList={deleteVideoFromList} />

          <div className={classes.buttonBar}>
            <ReactWhatsapp
              className={classes.buttonWhatsApp}
              number={EstimationData.phone_number}
              message={`${t('sale.messageWhatsapp')}\n${form.values.phoneNumber.length ? t('estimationInfoContact.phoneNumberLabel') + ' : ' + form.values.phoneNumber : ''} \n${form.values.phoneNumber.length ? t('estimationInfoContact.emailLabel') + ' : ' + form.values.email : ''}`}
              element="div">
                <BsWhatsapp className={classes.iconWhatsApp} />
                {t('estimationUpload.sendByWhatsapp')}
            </ReactWhatsapp>

            <Button type="submit" radius="xl" className={classes.button} disabled={form.isValid == false}>
              {t('estimation.sendData')}
            </Button>
          </div>
        </form>
      )}
    </IllustratedPage>
  );
}

const useStyles = createStyles((theme) => ({
  form: {
    transform: 'scale(0.8) translateY(-50px)',
    background: theme.colors.local_grey[1],
    boxShadow: '0 .25rem 2rem rgba(0, 0, 0, .25)',
    borderRadius: '2rem',
    padding: '1rem 3rem',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
    marginBottom: '-1rem',
    [`@media (max-width: 960px)`]: {
      boxSizing: 'border-box',
      boxShadow: 'unset',
      width: '100%',
      transform: 'unset',
      background: 'unset',
      padding: 0,
    },
  },
  ringProgressBox: {
    background: theme.white,
    borderRadius: '2rem',
    padding: '1rem 3rem',
    minHeight: '60%',
    width: '30%',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  button: {
    background: theme.colors.black_pearl[0],
    flex: '1 0 0',
    height: '48px',
    padding: '0 1rem',
    fontSize: '1.15rem',
    [`@media (max-width: 960px)`]: {
      flex: 'unset',
    },
  },
  buttonBar: {
    display: 'flex',
    gap: '1.25rem',
    margin: '1rem 0',
    [`@media (max-width: 960px)`]: {
      flexDirection: 'column-reverse',
      gap: '1rem',
    },
  },
  textRingProgress: {
    fontSize: '3rem',
  },
  loadText: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  iconWhatsApp: {
    width: '2rem',
    height: '2rem',
  },
  buttonWhatsApp: {
    transition: 'background ease .15s',
    cursor: 'pointer',
    WebkitTapHighlightColor: 'transparent',
    background: '#01e675',
    color: theme.white,
    borderRadius: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 0',
    gap: '1rem',
    height: '48px',
    padding: '0 1rem',
    fontSize: '1.15rem',
    '&:hover': {
      background: theme.fn.darken('#01e675', 0.25),
    },
    [`@media (max-width: 960px)`]: {
      flex: 'unset',
    },
  },
  infoContact: {
    marginBottom: '1rem',
  },
  modalProgress: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
}));
