import * as Yup from 'yup';
import {Button, Group, createStyles, Text, Modal, Stack, getStylesRef} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Contact} from 'src/types/contact';
import EstimationController from 'src/services/estimation';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import InfoContact from 'src/app/components/estimation/InfoContact';
import {useState} from 'react';
import {notifications} from '@mantine/notifications';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai';
import {BsWhatsapp} from 'react-icons/bs';
import ReactWhatsapp from 'react-whatsapp';
import SlideContactData from 'src/app/components/slides/SlideContactData';

export default function Accueil(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string().email(t('estimationInfoContact.emailError')).required('Required'),
    phoneNumber: Yup.string().required('Required'),
  });

  const form = useFormik({
    initialValues: {
      email: '',
      phoneNumber: '',
    },
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: values => {
      EstimationController.postSendContact(contact, {
        success: () => {
          setShowModal(true);
        },
        error: () => {
          notifications.show({
            title: t('estimation.uploadFails'),
            message: t('estimation.tryAgainLater'),
            autoClose: false,
          });
        },
      });
    },
  });

  const contact: Contact = {
    email: form.values.email,
    phoneNumber: form.values.phoneNumber,
  };

  return (
    <>
      <Group>
        <div className={classes.text}>
          <div className={classes.textExplicationRed}>{t('sale.textExplication1')}</div>
          <div>{t('sale.textExplication2')}</div>
          <div>{t('sale.textExplication3')}</div>
          <div className={classes.textExplicationRed}>{t('sale.textExplication4')}</div>
        </div>
        <form onSubmit={form.handleSubmit} className={classes.box}>
          <InfoContact form={form} />
          <Button size="xl" radius="xl" type="submit" className={classes.button} disabled={form.isValid == false}>
            {t('estimation.sendData')}
          </Button>
        </form>
      </Group>

      <Modal
        opened={showModal}
        centered
        title={t('estimation.sendingTitle')}
        radius="lg"
        size="lg"
        classNames={{title: classes.modalTitle, inner: classes.modal}}
        onClose={() => {
          navigate('/estimation', {state: {email: contact.email, phoneNumber: contact.phoneNumber}});
        }}>
        <Text inline={true} className={classes.textSendingMessage}>
          {t('estimation.sendingMessage')}
        </Text>
        <Text inline={true}>{t('sale.videosExplanation')}</Text>
        <Group className={classes.modalGroup}>
          <Stack
            spacing={0}
            align="center"
            className={classes.buttonModal}
            onClick={() => {
              navigate('/');
            }}>
            <AiOutlineArrowLeft size="2rem" />
            <Text className={classes.textNavigate}>{t('sale.returnToHome')}</Text>
          </Stack>
          <ReactWhatsapp
            number={SlideContactData.phone}
            message={`${t('sale.messageWhatsapp')}\n${form.values.phoneNumber.length ? t('estimationInfoContact.phoneNumberLabel') + ' : ' + form.values.phoneNumber : ''} \n${
              form.values.phoneNumber.length ? t('estimationInfoContact.emailLabel') + ' : ' + form.values.email : ''
            }`}
            element="div">
            <Stack
              spacing={0}
              align="center"
              className={classes.buttonModalWhatsapp}
              onClick={() => {
                navigate('/');
              }}>
              <BsWhatsapp className={classes.iconWhatsapp} />
              {t('sale.continueOnWhatsapp')}
            </Stack>
          </ReactWhatsapp>

          <Stack
            spacing={0}
            align="center"
            className={classes.buttonModal}
            onClick={() => navigate('/estimation', {state: {email: contact.email, phoneNumber: contact.phoneNumber}})}>
            <AiOutlineArrowRight size="2rem" />
            <Text className={classes.textNavigate}>{t('sale.continueOnWebsite')}</Text>
          </Stack>
        </Group>
      </Modal>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  box: {
    display: 'flex',
    border: 'solid grey',
    borderRadius: '2rem',
    width: '70%',
    height: '8rem',
    alignSelf: 'end',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '2rem',
    justifyContent: 'space-around',
  },
  button: {
    background: theme.colors.black_pearl[0],
    width: '30%',
  },
  text: {
    fontSize: '2.8rem',
    margin: 'auto',
    padding: '5rem 10rem 1rem 8rem',
    lineHeight: '4rem',
  },
  textExplicationRed: {
    color: theme.colors.red,
    fontWeight: 700,
  },
  modalTitle: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  modal: {
    userSelect: 'none',
  },
  textSendingMessage: {
    marginBottom: '1rem',
  },
  buttonModal: {
    width: '15%',
    cursor: 'pointer',
    fontSize: '1rem',
    '&:hover': {color: `${theme.colors.gray[5]}`},
  },
  buttonModalWhatsapp: {
    width: '100%',
    cursor: 'pointer',
    fontSize: '1rem',
    '&:hover': {
      color: `${theme.colors.gray[5]}`,
      [`& .${getStylesRef('iconWhatsapp')}`]: {
        color: `${theme.colors.gray[5]}`,
      },
    },
  },
  iconWhatsapp: {
    ref: getStylesRef('iconWhatsapp'),
    cursor: 'pointer',
    width: '2.5rem',
    height: '2.5rem',
    color: '#00ff11',
    marginBottom: '.5rem',
  },
  modalGroup: {
    marginTop: '1rem',
    height: 'auto',
    fontSize: '0.8rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  textNavigate: {
    fontSize: 'inherit',
    fontStyle: 'inherit',
    width: '100%',
    textAlign: 'center',
  },
}));
