import {useEffect, useRef, useState} from 'react';
import PropertyCard from 'src/app/components/property-card/PropertyCard';
import Filter, {FilterRef} from 'src/app/components/filter/Filter';
import {Button, createStyles, Group, MediaQuery} from '@mantine/core';
import {propertyCard} from 'src/types/property';
import {Filters, HeatingFilter, HeatingFilterKey, InfiniteScrollFilterPayload, RangeValue} from 'src/types/filter';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {HttpMutators} from 'src/services/http';
import {InvestorViewSwitch} from './InvestorView';
import {IconDivertissement} from 'src/assets/icons/IconDivertissement';
import {BsArrowDown} from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';

type Options = {
  elementsPerPage: number;
};

type RechercheBiensProps = {
  transactionType: string;
  postFilteredPropertyList: (data: InfiniteScrollFilterPayload, mutators: HttpMutators) => Promise<any>;
  areaBounds: RangeValue;
  budgetBounds: RangeValue;
  budgetStep: number;
  introTitle: string;
  introDescription: string;
  showInvestorView?: boolean;
};

const parseURLFilterValue = (urlValue: string | null, defaultValue: Array<string> | RangeValue) => {
  let value = urlValue === null ? defaultValue : urlValue.split(",");
  if (typeof defaultValue[0] === "number" && urlValue !== null) { //defaultValue is of type RangeValue, and so must be the returned value
    return [
      parseInt(value[0] as string),
      parseInt(value[1] as string)
    ];
  } else return value;
}

const getFiltersFromSeachParams = (
  searchParams: URLSearchParams, 
  areaBounds: RangeValue, 
  budgetBounds:RangeValue
):Filters => {
  const areaRangeFilter        = searchParams.get('areaRange');
  const budgetRangeFilter      = searchParams.get('budgetRange');
  const propertyTypeFilter     = searchParams.get('propertyTypeFilter');
  const numberOfRoomsFilter    = searchParams.get('numberOfRoomsFilter');
  const numberOfBedroomsFilter = searchParams.get('numberOfBedroomsFilter');
  const commoditiesFilter      = searchParams.get('commoditiesFilter');
  const heatingFilter          = searchParams.get('heatingFilter');

  return {
    areaRange: parseURLFilterValue(areaRangeFilter, areaBounds) as RangeValue,
    budgetRange: parseURLFilterValue(budgetRangeFilter, budgetBounds) as RangeValue,
    regionFilter: searchParams.get('regionFilter') ?? '',
    propertyTypeFilter: parseURLFilterValue(propertyTypeFilter, []) as string[],
    numberOfRoomsFilter: parseURLFilterValue(numberOfRoomsFilter, []) as string[],
    numberOfBedroomsFilter: parseURLFilterValue(numberOfBedroomsFilter, []) as string[],
    commoditiesFilter: parseURLFilterValue(commoditiesFilter, []) as string[],
    heatingFilter: parseURLFilterValue(heatingFilter, []) as (keyof HeatingFilter)[],
  }
}

export default function RechercheBiens(props: RechercheBiensProps): JSX.Element {
  const {classes} = useStyles();
  const scrollNode = useRef<HTMLDivElement>(null);
  const filterNode = useRef<FilterRef>(null);
  const {t} = useTranslation();

  const [propertyList, setPropertyList] = useState([] as propertyCard[]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [filters, setFilters] = useState<Filters>(getFiltersFromSeachParams(
    searchParams,
    props.areaBounds,
    props.budgetBounds
  ));

  useEffect(() => {
    for (const filter of Object.keys(filters)) {

      // we need this conversion in order to access the filters by keys later on
      const _filters = filters as {[key: string]: string | Array<string | number>};
      
      const filterValue = _filters[filter];
      if (!Array.isArray(filterValue)) {
        if (filterValue.length !== 0) {
          searchParams.set(filter, filterValue);
          setSearchParams(prev => {
            prev.set(filter, filterValue)
            return prev;
          });
        } else {
          if (searchParams.get(filter) !== null) {
            setSearchParams(prev => {
              prev.delete(filter);
              return prev;
            })
          }
        }
      } else { // the filter is of type array
        if (filterValue.length !== 0) {
          let stringifiedFilterValue = filterValue.join(",");
          setSearchParams(prev => {
            prev.set(filter, stringifiedFilterValue);
            return prev;
          });
        } else {
          if (searchParams.get(filter) !== null) {
            setSearchParams(prev => {
              prev.delete(filter);
              return prev;
            })
          }
        }
      }
    }
  }, [filters]);

  const options: Options = {
    elementsPerPage: 10,
  };

  function setHeatingFilter(heatingFilterData: HeatingFilterKey[]): HeatingFilter {
    return heatingFilterData.reduce((res, elem) => ({...res, [elem]: true}), {central: false, electricity: false, fuel: false, gas: false, individual: false});
  }

  function renderCards(cb: (property: propertyCard, i: number) => JSX.Element): JSX.Element[] {
    let result = [];
    let i = 0;

    for (let property of propertyList) result.push(cb(property, i++));

    if (loading)
      for (let j = 0; j < options.elementsPerPage; j++)
        result.push(
          cb(
            {
              descriptionTitle: 'Titre en chargement!',
              id: 'loading',
              image: '',
              locationCity: 'Une très belle ville!',
              structureRoomsBedrooms: 1,
              structureRooms: 0,
              loading: true,
              priceAskingPriceAmount: 0,
              priceAskingPriceCurrency: 'euro',
              structureAreasLiveableSize: 0,
              structureAreasLiveableUnits: 'm2',
            },
            i++,
          ),
        );

    return result;
  }

  function reset(): void {
    setFilters({
      areaRange: props.areaBounds,
      budgetRange: props.budgetBounds,
      regionFilter: '',
      propertyTypeFilter: [],
      numberOfRoomsFilter: [],
      numberOfBedroomsFilter: [],
      commoditiesFilter: [],
      heatingFilter: [],
    });
  }

  useEffect(() => {
    // un seul objet qui stocke toutes les valeurs de filtre : recherche-"transactionType"
    try {
      let filtersLocalStorage = localStorage.getItem(`recherche-${props.transactionType}`);

      if (filtersLocalStorage !== null) setFilters(JSON.parse(filtersLocalStorage));
    } catch (err) {
      console.error(err);
    }

    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading) {
      let data: InfiniteScrollFilterPayload = {
        pageIndex: page,
        pageSize: options.elementsPerPage,
        areaRange: filters.areaRange,
        budgetRange: filters.budgetRange,
        regionFilter: filters.regionFilter,
        propertyTypeFilter: filters.propertyTypeFilter,
        numberOfRoomsFilter: filters.numberOfRoomsFilter,
        numberOfBedroomsFilter: filters.numberOfBedroomsFilter,
        commoditiesFilter: filters.commoditiesFilter,
        heatingFilter: setHeatingFilter(filters.heatingFilter),
      };
      let mutators: HttpMutators = {
        success: res => {
          setPropertyList([...propertyList, ...res.properties]);
          setHasMoreItems(res.hasMoreItems);
          setLoading(false);
        },
      };
      props.postFilteredPropertyList(data, mutators);
    }
  }, [loading]);

  useEffect(() => {
    if (scrollNode.current) scrollNode.current.scrollTop = 0;
    setPropertyList([]);
    setPage(1);
    window.localStorage.setItem(`recherche-${props.transactionType}`, JSON.stringify(filters));
    setLoading(true);
  }, [filters]);

  function next(): void {
    setPage(page + 1);
    setLoading(true);
  }

  return (
    <>
      <Filter filters={filters} setFilters={setFilters} budgetBounds={props.budgetBounds} budgetStep={props.budgetStep} reset={reset} ref={filterNode} />

      <div className={classes.content}>
        <div className={classes.contentIntro}>
          <div className={classes.textWrapper}>
            {props.showInvestorView && 
              <MediaQuery query="(max-width: 1020px)" styles={{ display: 'none' }}>
                <div className={classes.bufferDiv}></div>
              </MediaQuery>
            }
            <h1 className={classes.contentTitle}>
              <Trans i18nKey={props.introTitle} components={{lines: <span className={classes.lines} />}} />
            </h1>
            {props.showInvestorView && (
              <MediaQuery query="(max-width: 1020px)" styles={{ display: 'none' }}>
                <InvestorViewSwitch className={''} />
              </MediaQuery>
            )}
          </div> 

          <p className={classes.contentDescription}>{props.introDescription}</p>
        </div>

        <MediaQuery query="(min-width: 1020px)" styles={{ display: 'none' }}>
          <Button className={classes.filterIcon} onClick={() => filterNode.current?.toggle()}>
            <IconDivertissement fill="#FFF" />
            {t('transactionFilter.openMobile')}
          </Button>
        </MediaQuery>

        <InfiniteScroll className={classes.scrollList} next={next} hasMoreItems={hasMoreItems && !loading} ref={scrollNode}>
          {renderCards((property: propertyCard, i) => (
            <PropertyCard
              className={classes.cardStyle}
              key={i}
              id={property.id}
              image={property.image}
              descriptionTitle={property.descriptionTitle}
              locationCity={property.locationCity}
              priceAskingPriceAmount={property.priceAskingPriceAmount}
              priceAskingPriceCurrency={property.priceAskingPriceCurrency}
              structureAreasLiveableSize={property.structureAreasLiveableSize}
              structureAreasLiveableUnits={property.structureAreasLiveableUnits}
              numberOfRooms={property.structureRooms}
              propertyId={property.id}
              loading={property.loading}
              transactionType={props.transactionType}
            />
          ))}
        </InfiniteScroll>
        <div className={classes.seeMoreDiv}>
          {hasMoreItems && (
            <div>
              <Button
                className={classes.seeMoreButton}
                onClick={() => {
                  next();
                }}>
                {t('transaction.seeMoreProperties')}
              </Button>
              <BsArrowDown className={classes.seeMoreArrow} size={22}/>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

type InfiniteScrollProps = React.PropsWithChildren & {
  className?: string;
  next: () => void;
  hasMoreItems: boolean;
};

const InfiniteScroll = React.forwardRef<HTMLDivElement, InfiniteScrollProps>(function InfiniteScroll(props, ref): JSX.Element {
  useEffect(() => {
    let node = (ref as React.RefObject<HTMLDivElement>).current as HTMLDivElement;
    node.onscroll = () => {
      if (props.hasMoreItems && node.scrollTop / (node.scrollHeight - node.offsetHeight) > 0.8) {
        props.next();
      }
    };
  }, [props]);
  return (
    <div className={props.className} ref={ref}>
      {props.children}
    </div>
  );
});

const useStyles = createStyles(theme => ({
  lines: {
    position: 'relative',
    display: 'inline-block',
    '::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'url(/assets/trait.svg)',
      backgroundRepeat: 'no-repeat',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 64%)',
      width: '160px',
      height: '135px',
      backgroundSize: '100% 100%',
      zIndex: -1,
    },
  },
  centerColumn: {
    overflow: 'auto',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1rem',
    gap: '1rem', 
    [`@media (max-width: 1020px)`]: {
      justifyContent: 'center',
    },
  },
  bufferDiv: {
    height: '50px', 
    width: '150px'
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flex: '1 0 0',
    flexWrap: 'wrap',
    gap: theme.spacing.xl,
    padding: '0 7rem',
    paddingBottom: '4rem',
    [`@media (max-width: 1020px)`]: {
      padding: '2rem 1.5rem',
    },
  },
  contentIntro: {
    position: 'relative',
    marginBottom: '2rem',
  },
  contentTitle: {
    flexGrow: 1,
    margin: 0,
    marginBottom: '1rem',
    textAlign: 'center',
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
      fontSize: '1.75rem',
    },
  },
  contentDescription: {
    margin: 0,
    lineHeight: '1.5rem',
    textAlign: 'justify',
  },
  scrollList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.spacing.xl,
  },
  cardStyle: {
    boxShadow: '1rem 1rem 1rem rgba(0, 0, 0, .175)',
    color: theme.white,
    textAlign: 'center',
    width: '18.125rem',
    borderRadius: '1rem',
    [`@media (max-width: 1020px)`]: {
      boxShadow: '0 0 1rem rgba(0, 0, 0, .125)',
      width: '100%',
    },
  },
  filterIcon: {
    width: '100%',
    marginBottom: '1rem',
  },
  seeMoreButton: {
    position: 'relative',
    textAlign: 'center',
    '&:hover': {
      background: 'inherit',
    },
    background: 'inherit',
    color: theme.colors.gray[6] + ' !important',
    border: 'none',
    fontWeight: 600,
    fontSize: '1.5rem',
    cursor: 'pointer',
    outline: 'inherit',
    height: '2.5rem',
    [`@media (max-width: 1230px)`]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  seeMoreDiv: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
    [`@media (max-width: 1230px)`]: {
      paddingTop: '1rem',
    },

  },
  seeMoreArrow: {
    color: theme.colors.gray[6] + ' !important',
  },
}));
