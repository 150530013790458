import FilterData from 'src/app/components/filter/FilterData';
import RechercheBiens from 'src/app/components/recherche-biens/RechercheBiens';
import {RangeValue} from 'src/types/filter';
import AchatController from 'src/services/achat';
import { useTranslation } from 'react-i18next';

export default function Achat(): JSX.Element {
  const {t} = useTranslation();

  return (
    <RechercheBiens
      transactionType="achat"
      postFilteredPropertyList={AchatController.postFilteredPropertyList}
      areaBounds={FilterData.areaSlider.range as RangeValue}
      budgetBounds={FilterData.budgetSlider.rangeAchat as RangeValue}
      budgetStep={FilterData.budgetSlider.stepAchat}
      introTitle="achat.intro.title"
      introDescription={t("achat.intro.description")}
      showInvestorView={true}
    />
  );
}
