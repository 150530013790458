import {IconProps} from 'src/types/icon';

export const IconQuestion = (props: IconProps): JSX.Element => (
  <svg className={props.className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M259 .6c-28.8 3.6-49.2 9.4-70.2 20C168.5 30.8 152.7 42.2 137 58c-20.1 20.2-33.9 41.2-44.1 67.3-8.1 20.8-11.2 36.4-13.4 66.2-.8 11.5-2.1 22.4-3 25.5-1 3-9.3 22.7-18.6 43.7-19.2 43.2-19.8 45.4-15.9 55.7 2.6 6.7 7.7 12.9 12.9 15.5 2 1 8.2 3.3 13.8 5.2l10.1 3.3.4 34.5c.4 32.4.6 35 2.6 40.5 6 16.2 19 27.5 35.1 30.6 3 .5 19.8 1 37.5 1h32.1l6.4 22.4c6.9 24.6 9.9 31 16.4 35.7 6.7 4.9 10.5 6.2 18.7 6.3 6.9 0 14.7-2 104.3-27.7l100.6-29.6c4.9-2.6 11.8-10.2 14.3-15.9 4.2-9.4 3.5-13.7-11.1-64.2L423 327.1c0-.8 2.4-4.4 5.3-8 32.6-40.5 48.4-94.5 42.2-144.2-4.7-38.1-19-72.4-41.8-100.9-30.5-38-75.5-64.1-123.5-71.5C295.5 1 265.7-.2 259 .6zm44 27.8c42.2 7 78.9 28.7 106.6 63.1 24.3 30.1 38 72.4 36.1 111.5-2 40.1-15.8 74.7-42.1 105.4-5 5.9-7.8 10-8.2 12.3-.5 2.7 2.5 14.4 14 54.8 15.3 53.6 15.5 54.5 10.9 55.9L323.5 459c-77.3 22.1-95.3 26.9-97.2 26.1-1.5-.7-2.7-2.7-3.8-6.3l-7.8-27.5c-3.5-12.2-7.2-23.4-8.1-24.8-3.5-5.3-5.3-5.5-46.8-5.5-42.9 0-43.2-.1-49.5-7.3-4.8-5.5-5.3-9.9-5.3-49.8 0-40.2 0-40.4-6.8-43.8-2-1-9.5-3.7-16.7-6.1-7.1-2.3-13.6-4.8-14.4-5.6-1.2-1.1.9-6.6 14.7-37.6l18.2-42.2c3-8.8 4.8-20.6 5.5-35.6 1.7-34.1 9.4-60.1 25.7-86.4 28.3-45.6 76.2-74.9 130.8-80 8-.7 32.2.3 41 1.8zm-40.5 80.2l-7.5 1.5c-25.9 5.2-46.6 30.3-47.2 57-.2 9.9 4.2 15.1 12.7 15.1 8.2 0 11.7-4.2 13.3-16 1.3-8.9 4.4-15.4 10.2-21.2 17.2-17.4 46.3-12.1 56.7 10.4 2.4 5.2 2.8 7.3 2.8 14.6-.1 7.2-.5 9.4-2.8 14.2-3.6 7.4-8.5 12.3-17.7 17.7-13.3 7.9-22 18.8-25.5 32.2-.9 3.4-1.5 10.4-1.5 18.7 0 12 .2 13.7 2.1 16.3 4.1 5.8 11.8 7.6 17.2 4 5.4-3.5 6.1-5.5 6.7-20.1.8-18.5 2.8-22.2 16.5-30 22.7-13 35.5-40.9 30.1-65.4-4.2-18.6-16.3-34.4-32.7-42.3-4.6-2.3-10.4-4.6-12.9-5.1-5.4-1.3-17.7-2.2-20.5-1.6zm.6 192.1c-4.9 2.4-7.1 6.6-7.1 13.8 0 9.5 5.1 15.5 13.1 15.5 13.4 0 17.8-20.9 6-28.7-4-2.7-7.4-2.9-12-.6z"
      fill={props.fill}
      fillRule="evenodd"
    />
  </svg>
);