export default {
  minimalPricePercentage: 10,
  maximalWorkFurniture: 400000,
  monthlyRentBorder: [0, 5000],
  loanDurationInitialValue: 20,
  loanDurationBorder: [0,30],
  interestRate: 0.1,
  interestRateBorder: [0,15],
  evolutionYear: 1,
  evolutionYearList: [1, 2, 10, 15],
  evolutionRate: 1,
  evolutionRateList: [1, 1.4, 1, 1.2],
  evolutionRateBorder: [0, 3],
  resaleYearMaxDuration: 40,
};
