import React from 'react';
import classnames from 'classnames';
import {createStyles, Group} from '@mantine/core';
import {useEffect} from 'react';

type SlideDescriptionDetails = {
    long: string;
    short: string;
};

type SlideProps = {
    children: JSX.Element[];
    icons: JSX.Element[];
    descriptions: (string | SlideDescriptionDetails)[];
};

enum DescriptionPurpose {
  Title,
  Arrow,
};

export default function SlideMobile(props: SlideProps): JSX.Element {
  const {classes} = useStyles();

  // It is used to know which slide is displayed on the screen
  const [actualSlideId, setActualSlideId] = React.useState(0);

  // It is used in order to disable scroll event when the user click on a slide icon
  const [isClicking, setIsClicking] = React.useState(false);
  
  // Create refs with the appropriate type
  const childRefs: React.RefObject<HTMLDivElement>[] = props.children.map(() => React.createRef());

  // Show the description of the current slide
  function showDescriptionSlide(slideIdx: number, purpose: DescriptionPurpose, longDescription : boolean = false): string {
    let description = props.descriptions[slideIdx];

    if (typeof description === "string")
      return description;

    return (longDescription || (purpose === DescriptionPurpose.Title && document.documentElement.clientWidth > 500))
      ? description.long
      : description.short;
  }

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = (): void => {

      // disable scroll event when the user click on a slide icon
      if(isClicking)
        return;

      // Calculate the current scroll position
      const scrollPosition = window.scrollY;
      const vh = window.innerHeight;

      // Find the child element currently in view
      for (let i = 0; i < childRefs.length; i++) {
        const childRef = childRefs[i].current;
        if (!childRef) continue;

        const rect = childRef.getBoundingClientRect();

        // Check if the child element is in the viewport
        // @TODO: improve this condition
        if (rect.top <= 250 && rect.bottom >= 250) {
          setActualSlideId(i);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [childRefs]);

  // display the title corresponding to the child and the child itself
  const children = React.Children.map(props.children, (child: JSX.Element, i : number) => {
    return (
      <>
        <div className={classnames(classes.sectionTitle)}>{showDescriptionSlide(i, DescriptionPurpose.Title, true)}</div>
        <div ref={childRefs[i]} className={classnames(classes.childDiv)}>{child}</div>
      </>
    )
  });

  // function to handle when the user click on a slide icon
  function handleClick(slideId : number) : void  {

    // disable scroll event when the user click on a slide icon
    setIsClicking(true);

    // set the actual slide id
    setActualSlideId(slideId);

    // get the corresponding element
    const targetElement = childRefs[slideId]?.current;
  
    if (targetElement) {
      // if we don't do that, the top of targeted div is hidden by the navbar
      const targetPosition = targetElement.offsetTop - 160;
      
      // scroll to the target position
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });

      // enable scroll event
      // we add a timer to wait for the scrollTo to end
      // @TODO: do this without a setTimeout
      setTimeout(() => {
        setIsClicking(false);
      }, 650);
    }
  }

  return (
    <div>
      <div className={classnames(classes.wrapper)}>
        <div className={classes.scrollPages}>
          {children.map((_, i) => (
            // render slides icons
            <div className={classes.scrollPagesItem} key={i} onClick={() => handleClick(i)} aria-hidden="true">
              {props.icons[i]}
            </div>
          ))}
        </div>

        {/* render the description of the current slide */}
        <div className={classes.scrollPagesDescription}>
          {showDescriptionSlide(actualSlideId, DescriptionPurpose.Title)}
        </div>
      </div>
      
      {/* render children components */}
      <div className={classnames(classes.slideDiv)}>
        <div>{children}</div>
      </div>
    </div>
  )
}

const useStyles = createStyles(theme => ({
  slideDiv : {
    width: '100%',
    paddingTop: '7rem',
    zIndex: 1,
  },
  childDiv : {
    overflow: 'visible',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  wrapper: {
    display: 'flex',
    position: 'fixed', // fixed position for the header
    overflow: 'visible',
    width: '100%',
    zIndex: 99,
    paddingBottom: '7rem',
    flexDirection: 'column',
    [`@media (max-width: 1020px)`]: {
      height: '100px',
     },
  },
  scrollPages: {
    boxSizing: 'border-box',
    userSelect: 'none',
    height: '1.15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    gap: '1rem',
    zIndex: 10,
    backgroundColor: theme.colors.local_red[0],
  },  
  scrollPagesItem: {
    color: theme.white,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background 0.25s cubic-bezier(0.075, 0.82, 0.165, 1)',
    background: 'rgba(255, 255, 255, 0.25)',
    borderRadius: '0.5rem',
    width: '5rem',
    height: '2rem',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.75)',
    },
  },  
  scrollPagesDescription: {
    position: 'absolute',
    zIndex: 9,
    transform: 'translateX(-50%)',
    left: '50%',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1.2rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '5rem',
    width: '60%',
    borderRadius: '50%',
    background: theme.colors.local_red[0],
    color: theme.white,
    paddingTop: '.25rem',
    paddingBottom: '.75rem',
    [`@media (max-width: 1020px)`]: {
      width: '100%',
    },
  },
  sectionTitle: {
    backgroundColor: theme.colors.local_red[0],
    color: theme.white,
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem',
    height: '60px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1.25rem',
  },
}));