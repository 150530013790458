import {createStyles, Group} from '@mantine/core';
import SocialMedia from 'src/app/components/social-media/SocialMedia';
import Navbar from 'src/app/components/navbar/navbar';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

export default function ModelBase(props: React.PropsWithChildren): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.baseContainer}>
      <Navbar />

      <div className={classes.middleColumn}>
        {props.children}
      </div>

      <div className={classes.bottomColumn}>
        <SocialMedia />

        <Group className={classes.legalPages}>
          <Link to="/mentions-legales" className={classes.link}>{t('home.legal')}</Link>
          <Link to="/politique-de-confidentialite" className={classes.link}>{t('home.privacy')}</Link>
          <Link to="/honoraires" className={classes.link}>{t('home.fees')}</Link>
        </Group>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  baseContainer: {
    userSelect: 'none',
    minHeight: CSS.supports("height", "100svh") ? "100svh" : '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  middleColumn: {
    position: 'relative',
    display: 'flex',
    flex: '1 0 0',
  },
  bottomColumn: {
    position: 'relative',
    display: 'flex',
    padding: '1rem 3.75rem',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.local_red[0],
    color: theme.white,
    gap: '3rem',
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem',
      padding: '1.5rem',
    },
  },
  link: {
    transition: 'border ease-in-out .15s',
    borderBottom: '2px solid transparent',
    textDecoration: 'none',
    color: 'inherit',
    zIndex: 100,
    paddingBottom: '1px',
    ':hover': {
      borderBottom: '2px solid ' + theme.white,
    },
  },
  legalPages: {
    zIndex: 0,
    [`@media (min-width: 1020px)`]: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      gap: '1.5rem',
    },
    [`@media (max-width: 1020px)`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));
