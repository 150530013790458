import {Grid, NumberInput, RangeSlider, createStyles} from '@mantine/core';
import {useState, KeyboardEvent, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {RangeValue} from 'src/types/filter';

type NumberSliderProps = {
  min: number;
  max: number;
  onChange(value: RangeValue): void;
  units?: string;
  sliderColor?: string;
  step?: number;
  value?: RangeValue;
};

export default function NumberSlider({min, max, onChange, units, sliderColor, step, value}: NumberSliderProps): JSX.Element {
  const [initialMinValue, setInitialMinValue] = useState<boolean>(false);
  const [initialMaxValue, setInitialMaxValue] = useState<boolean>(false);
  const [range, setRange] = useState<RangeValue>([min, max]);

  const {classes} = useStyles();
  const {t} = useTranslation();

  function pressEnter(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Enter') {
      onChange(range);
    }
  }

  // pour l'initialisation
  useEffect(() => {
    if (value !== undefined) {
      setRange(value);
    }
  }, [value]);

  return (
    <div>
      <RangeSlider
        className={classes.rangeSlider}
        min={min}
        max={max}
        value={range}
        onChangeEnd={sliderRange => {
          onChange(sliderRange);
          setInitialMinValue(false);
          setInitialMaxValue(false);
        }}
        onChange={setRange}
        label={null}
        showLabelOnHover={false}
        color={sliderColor}
        step={step}
      />
      <Grid columns={6}>
        <Grid.Col span={3}>
          <NumberInput
            classNames={{input: classes.inputNumber}}
            placeholder={t('transactionFilter.minimum')}
            onChange={(num: number) => {
              if (num < range[1]) {
                setRange([num, range[1]]);
              }
              setInitialMinValue(false);
            }}
            value={initialMinValue ? undefined : range[0]}
            max={range[1] - 1}
            min={min}
            icon={<div>{units}</div>}
            hideControls={true}
            onBlur={event => onChange(range)}
            onKeyPress={event => pressEnter(event)}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <NumberInput
            classNames={{input: classes.inputNumber}}
            placeholder={t('transactionFilter.maximum')}
            onChange={(num: number) => {
              if (num > range[0]) {
                setRange([range[0], num]);
              }
              setInitialMaxValue(false);
            }}
            value={initialMaxValue ? undefined : range[1]}
            max={max}
            min={range[0] + 1}
            icon={<div>{units}</div>}
            hideControls={true}
            onBlur={event => onChange(range)}
            onKeyPress={event => pressEnter(event)}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  rangeSlider: {
    marginBottom: '1em',
  },
  inputNumber: {
    backgroundColor: '#515766',
    border: '1px solid #515766',
    color: theme.white,
  },
}));
