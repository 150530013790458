import {createStyles} from '@mantine/core';
import {Link} from 'react-router-dom';
import logoReboot from 'src/assets/images/logo/logo-reboot-color-black-red.png'

type RebootLogoProps = {
  to?: string;
  className?: string;
};

export default function RebootLogo(props: RebootLogoProps): JSX.Element {
  const {classes,cx} = useStyles();

  return (
    <Link className={cx(classes.rebootLogoButton, props.className)} to={props.to ?? '/'}>
      <img src={logoReboot} alt="Logo Site" className={classes.logo} />
    </Link>
  );
}

const useStyles = createStyles(theme => ({
  rebootLogoButton: {
    userSelect: 'none',
    display: 'block',
    width: '256px',
    marginTop: '-.5rem',
    fill: theme.white,
    '&:hover': {
      fill: theme.colors.gray[5],
    },
  },
  logo: {
    width: '100%',
  }
}));
