import classnames from 'classnames';
import {useState} from 'react';
import {createStyles, ActionIcon} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import {useTranslation} from 'react-i18next';
import PhoneIcon from 'src/assets/icons/contact/iconPhone-white.png';
import ModalInterested from '../modal-interested/ModalInterested';

type ContactIconProps = {
  className?: string;
};

export default function ContactIcon(props: ContactIconProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const [overPhone, setOverPhone] = useState(false);
  const [opened, {open, close}] = useDisclosure(false);

  return (
    <>
      <ModalInterested
        opened={opened}
        close={close}
        title={t('transactionInterested.title')}
        propertyType={t('transactionInterested.propertyForRent')} />

      <ActionIcon
        className={classnames(props.className, classes.contactIconButton)}
        variant="filled"
        size="xl"
        onClick={open}
        onMouseOver={() => setOverPhone(true)}
        onMouseOut={() => setOverPhone(false)}>
          <img src={PhoneIcon} alt="Téléphone" />
      </ActionIcon>
    </>
  );
}

const useStyles = createStyles(theme => ({
  contactIconButton: {
    background: theme.colors.local_red[0],
    borderRadius: '50%',
    outline: '0 !important',
    width: '52px',
    height: '52px',
    padding: '.75rem',
    img: {
      width: '100%',
      height: '100%',
    },
    '&:hover': {
      background: theme.colors.gray[5],
    },
    [`@media (max-width: 1020px)`]: {
      width: '60px',
      height: '60px',
    },
  },
}));
