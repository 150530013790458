import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import Chip from 'src/app/components/chip/Chip';

type ChipWrapperProps = {
  field: string;
  options: {value: string; label: string}[];
  onChange: (field: string, value: string[]) => void;
  selectedFilters: string[];
};

export default function ChipWrapper({field, options, onChange, selectedFilters}: ChipWrapperProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  function addElement(elt: string): void {
    onChange(field, [...selectedFilters, elt]);
  }

  function removeElement(elt: string): void {
    onChange(
      field,
      selectedFilters.filter(item => item !== elt),
    );
  }

  return (
    <div className={classes.chipWrapper}>
      {options.map(elt => {
        return (
          <div key={elt.value}>
            <Chip value={elt.value} text={t(elt.label)} addElement={addElement} removeElement={removeElement} isSelected={selectedFilters.includes(elt.value)} />
          </div>
        );
      })}
    </div>
  );
}

const useStyles = createStyles(() => ({
  chipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '-.25rem',
  },
}));
