import {createStyles, TextInput} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {FormikProps} from 'formik';
import {Contact} from 'src/types/contact';
import classNames from 'classnames';

type InfoContactProps = {
  className?: string;
  form: FormikProps<Contact>;
  location?: any;
};

export default function InfoContact(props: InfoContactProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.inputContainer, props.className)}>
      <TextInput
        classNames={{root: classes.input, error: classes.errorInput}}
        size="xl"
        name="phoneNumber"
        required
        radius="xl"
        placeholder={t('estimationInfoContact.phoneNumberLabel')}
        onChange={props.form.handleChange}
        value={props.form.values.phoneNumber}
        type="tel"
        error={props.form.errors.phoneNumber && props.form.values.phoneNumber.length ? t('estimationInfoContact.phoneNumberError') : false}
      />

      <TextInput
        classNames={{root: classes.input, error: classes.errorInput}}
        size="xl"
        radius="xl"
        name="email"
        type="email"
        required
        placeholder={t('estimationInfoContact.emailLabel')}
        onChange={props.form.handleChange}
        value={props.form.values.email}
        error={props.form.errors.email && props.form.values.email.length ? t('estimationInfoContact.emailError') : false}
      />
    </div>
  );
}

const useStyles = createStyles(theme => ({
  input: {
    flex: '1 0 0',
    fontSize: '1.5',
  },
  inputContainer: {
    display: 'flex',
    gap: '1.25rem',
    [`@media (max-width: 960px)`]: {
      flexDirection: 'column',
      gap: '1rem',
    },
  },
  errorInput: {
    textAlign: 'center',
    fontSize: '15px',
  },
}));
