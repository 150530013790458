export default {
  honorairesVente: [
    { rangeMin: 0, rangeMax: 50_001, rangeValue: 5_000, rangeType: "fixed" },
    { rangeMin: 50_001, rangeMax: 100_000, rangeValue: 9, rangeType: "percentage" },
    { rangeMin: 100_001, rangeMax: 150_000, rangeValue: 7.5, rangeType: "percentage" },
    { rangeMin: 150_001, rangeMax: 200_000, rangeValue: 7, rangeType: "percentage" },
    { rangeMin: 200_001, rangeMax: 300_000, rangeValue: 6, rangeType: "percentage" },
    { rangeMin: 300_001, rangeMax: 600_000, rangeValue: 5.5, rangeType: "percentage" },
    { rangeMin: 600_000, rangeMax: 0, rangeValue: 5, rangeType: "percentage" },
  ],
  honorairesLocation: [
    {service: 'honoraires.location.service1', priceHighZone: '', priceMediumZone: 'honoraires.location.included', priceLowZone: ''},
    {
      service: 'honoraires.location.service2',
      priceHighZone: '12€ TTC/m²',
      priceMediumZone: '10€ TTC/m²',
      priceLowZone: '8€ TTC/m²',
    },
    {service: 'honoraires.location.service3', priceHighZone: '3€ TTC/m²', priceMediumZone: '3€ TTC/m²', priceLowZone: '3€ TTC/m²'},
  ],
  honorairesGestion: [
    {range: 'honoraires.gestion.tableFees.range1', cremant: '7,5%', champagne: '12,0%'},
    {range: 'honoraires.gestion.tableFees.range2', cremant: '6,5%', champagne: '11,0%'},
    {range: 'honoraires.gestion.tableFees.range3', cremant: '5,5%', champagne: '10,0%'},
    {range: 'honoraires.gestion.tableFees.range4', cremant: '5,0%', champagne: '9,0%'},
    {range: 'honoraires.gestion.tableFees.range5', cremant: '4,0%', champagne: '8,0%'},
    {range: 'honoraires.gestion.tableFees.range6', cremant: '3,0%', champagne: '7,0%'},
  ],
  gestionDetails: {
    administrative: [
      {title: 'honoraires.gestion.table.administrative.rentRevision', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.declaration', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.dispute', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.insurance', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.customerAccess', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.representative', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.accountingMonitoring', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.administrative.caution', cremant: true, champagne: true},
    ],
    financial: [
      {title: 'honoraires.gestion.table.financial.rentCashing', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.financial.collectiveOwnership', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.financial.trashTax', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.financial.annualFees', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.financial.outstandingDebt', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.financial.landTax', cremant: false, champagne: true},
      {title: 'honoraires.gestion.table.financial.helpToTaxDeclaration', cremant: false, champagne: true},
    ],
    technical: [
      {title: 'honoraires.gestion.table.technical.estimation', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.technical.accomodationLife', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.technical.equipmentMaintenance', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.technical.work', cremant: '1 000€ TTC', champagne: '10 000€ TTC'},
      {title: 'honoraires.gestion.table.technical.damageManagement', cremant: true, champagne: true},
      {title: 'honoraires.gestion.table.technical.quotation', cremant: true, champagne: true},
    ],
    others: [
      {title: 'honoraires.gestion.table.others.rental', both: 'honoraires.gestion.table.others.inAddition'},
      {title: 'honoraires.gestion.table.others.inventory', both: 'honoraires.gestion.table.others.inAddition'},
      {title: 'honoraires.gestion.table.others.technicalAudit', both: 'honoraires.gestion.table.others.inAddition'},
      {title: 'honoraires.gestion.table.others.GLI', both: 'honoraires.gestion.table.others.inAdditionGLI'},
      {title: 'honoraires.gestion.table.others.patrimonialStudy', both: 'honoraires.gestion.table.others.inAddition'},
    ],
  },
};
