export default {
  region: [
    {
      value: '',
      label: 'transactionFilter.all',
    },
    {
      value: 'Grand Est',
      label: 'transactionFilter.grandEst',
    },
    {
      value: 'Nancy',
      label: 'transactionFilter.nancy',
    },
    {
      value: 'Paris',
      label: 'transactionFilter.paris',
    },
  ],
  propertyType: [
    {
      value: 'Apartment',
      label: 'transactionFilter.apartment',
    },
    {
      value: 'Building',
      label: 'transactionFilter.building',
    },
    {
      value: 'House',
      label: 'transactionFilter.house',
    },
    {
      value: 'Parking',
      label: 'transactionFilter.parking',
    },
    /*{
      value: 'Office',
      label: 'transactionFilter.office',
    },*/
    /**
     * HIDING THE OFFICE FILTER BECAUSE OF THE FOLLOWING UNRESOLVED BUG: #5182
     * The problem seems to come from the SweepBright / Zapier.
     */
  ],
  areaSlider: {
    units: 'm²',
    range: [0, 1000],
    step: 1,
  },
  numberOfRooms: [
    {
      value: '1',
      label: 'transactionFilter.studio',
    },
    {
      value: '2',
      label: 'transactionFilter.two',
    },
    {
      value: '3',
      label: 'transactionFilter.three',
    },
    {
      value: '4',
      label: 'transactionFilter.four',
    },
    {
      value: '5',
      label: 'transactionFilter.five',
    },
  ],
  numberOfBedrooms: [
    {
      value: '1',
      label: 'transactionFilter.one',
    },
    {
      value: '2',
      label: 'transactionFilter.two',
    },
    {
      value: '3',
      label: 'transactionFilter.three',
    },
    {
      value: '4',
      label: 'transactionFilter.four',
    },
    {
      value: '5',
      label: 'transactionFilter.five',
    },
  ],
  budgetSlider: {
    units: '€',
    rangeLocation: [0, 5000],
    rangeAchat: [0, 1000000],
    stepLocation: 10,
    stepAchat: 100,
  },
  commodities: [
    {
      value: 'elevator',
      label: 'transactionFilter.elevator',
    },
    {
      value: 'balcony',
      label: 'transactionFilter.balcony',
    },
    {
      value: 'basement',
      label: 'transactionFilter.basement',
    },
    {
      value: 'garage',
      label: 'transactionFilter.garage',
    },
    {
      value: 'caretaker',
      label: 'transactionFilter.caretaker',
    },
    {
      value: 'garden',
      label: 'transactionFilter.garden',
    },
    {
      value: 'parking',
      label: 'transactionFilter.parking',
    },
    {
      value: 'pool',
      label: 'transactionFilter.pool',
    },
    {
      value: 'terrace',
      label: 'transactionFilter.terrace',
    },
  ],
  heating: [
    {
      value: 'Central',
      label: 'transactionFilter.community',
    },
    {
      value: 'Electricity',
      label: 'transactionFilter.electric',
    },
    {
      value: 'Fuel',
      label: 'transactionFilter.fuel',
    },
    {
      value: 'Gas',
      label: 'transactionFilter.gas',
    },
    {
      value: 'Individual',
      label: 'transactionFilter.individual',
    },
  ],
};
