import {createStyles, Stack, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import HonorairesData from './HonorairesData';
import classNames from 'classnames';

export default function HonorairesLocation(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  return (
    <div className={classes.card}>
      <div className={classes.title}>{t('honoraires.location.title')}</div>
      <Stack className={classes.stack}>
        <Group className={classes.group}>
          <div className={classes.empty}>{''}</div>
          <div className={classNames(classes.header, classes.cellResponsive)}>{t('honoraires.location.highZone')}</div>
          <div className={classNames(classes.header, classes.cellResponsive)}>{t('honoraires.location.mediumZone')}</div>
          <div className={classNames(classes.header, classes.cellResponsive)}>{t('honoraires.location.lowZone')}</div>
        </Group>

        {HonorairesData.honorairesLocation.map(element => {
          if (t(element.service) == t("honoraires.location.service1")) {
            return (
              <Group key={element.service} className={classes.group}>
                <div className={classNames(classes.service, classes.cell, classes.cellResponsive)}>{t(element.service)}</div>
                <div className={classNames(classes.included, classes.cell, classes.cellResponsive)}>{t(element.priceMediumZone)}</div>
              </Group>
            );

          } else {
            return (
              <Group key={element.service} className={classes.group}>
                <div className={classNames(classes.service, classes.cell, classes.cellResponsive)}>
                  <Stack className={classes.stackService}>
                    {t(element.service)
                      .split('\n')
                      .map((line) => (
                        <div key={line}>{line}</div>
                      ))}
                  </Stack>
                </div>
                <div className={classNames(classes.value, classes.valueColumn, classes.cell, classes.cellResponsive)}>{t(element.priceHighZone)}</div>
                <div className={classNames(classes.value, classes.valueColumn, classes.cell, classes.cellResponsive)}>{t(element.priceMediumZone)}</div>
                <div className={classNames(classes.value, classes.valueColumn, classes.cell, classes.cellResponsive)}>{t(element.priceLowZone)}</div>
                </Group>
            );
          }
        })}
        <p>
          {t('honoraires.location.note')}<br/>
          <a href="https://www.service-public.fr/particuliers/vosdroits/F375">{t('honoraires.location.noteSource')}</a>
        </p>
      </Stack>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  card: {
    boxSizing: 'border-box',
    borderRadius: '2.5rem',
    padding: '3rem',
    maxWidth: '100%',
    width: '1200px',
    textAlign: 'center',
    boxShadow: '0 0 2.5rem rgba(0, 0, 0, .175)',
    margin: '2rem 0',
    marginBottom: '3rem',
    [`@media (max-width: 1020px)`]: {
      boxShadow: 'unset',
      padding: 0,
    },
    [`@media (max-width: 530px)`]: {
      maxWidth: '110%',
    },
  },
  title: {
    fontSize: '1.5rem',
    padding: '2rem',
    paddingTop: '0',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
    },
  },
  stack: {
    [`@media (max-width: 530px)`]: {
      gap: '0.25rem',
    },
  },
  stackService: {
    gap: '0',
  },
  group: {
    justifyContent: 'center',
    [`@media (max-width: 530px)`]: {
      gap: '0.2rem',
    },
  },
  header: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    padding: '1.1rem 0',
    borderRadius: '.625rem',
    width: '20%',
  },
  service: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    width: '30%',
  },
  empty: {
    width: '30%',
  },
  value: {
    backgroundColor: theme.colors.local_grey[2],
  },
  valueColumn: {
    width: '20%',
  },
  included: {
    width: 'calc(60% + 2rem)',
    backgroundColor: theme.colors.local_grey[2],
    [`@media (max-width: 530px)`]: {
      width: 'calc(60% + 0.4rem)',
    },
  },
  cell: {
    height: '4.5rem',
    borderRadius: '.625rem',
    padding: '.3rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellResponsive : {
    [`@media (max-width: 820px)`]: {
      fontSize: '0.9rem',
    },
    [`@media (max-width: 710px)`]: {
      height: '6rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.1rem'
    },
    [`@media (max-width: 570px)`]: {
      fontSize: '0.85rem',
    },
    [`@media (max-width: 530px)`]: {
      lineHeight: '1',
    },
    [`@media (max-width: 350px)`]: {
      height: '7rem',
    },
    [`@media (max-width: 300px)`]: {
      fontSize: '0.80rem',
    }
  }
}));