import {MantineThemeOverride} from '@mantine/core';

export default {
  colors: {
    black_pearl: ['#0F1D2E'],
    dark_red: ['#8B0000'],
    local_red: ['#E8505B', '#F22F3C', '#F2878F'],
    local_grey: ['#D9D9D9', '#F6F6F6', '#D0DAE3'],
    light_pink: ['#F0D9DB'],
    local_blue: ['#5192D5', '#87A9D6'],
  },
  cursorType: 'pointer',
} as MantineThemeOverride;
