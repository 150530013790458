import {IconProps} from 'src/types/icon';

export const IconYes = (props: IconProps): JSX.Element => (
  <svg className={props.className} viewBox="0 0 45 41" width={props.width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.8087 0.83254C41.0516 0.859262 40.3569 1.26004 39.9562 1.90129L18.5099 34.7119L4.61617 22.7063C4.01945 22.0873 3.12438 21.8557 2.30055 22.114C1.48117 22.3679 0.871089 23.0625 0.728589 23.9086C0.586089 24.7592 0.928979 25.6142 1.62367 26.1263L17.5124 39.8775C18.0112 40.3006 18.6702 40.4876 19.3159 40.3941C19.9661 40.2961 20.5405 39.9265 20.8968 39.3788L43.8037 4.39505C44.2935 3.67809 44.3336 2.75184 43.9105 1.99482C43.4875 1.24223 42.6726 0.792458 41.8087 0.83254Z"
      fill={props.fill}
    />
  </svg>
);
