import {createStyles} from '@mantine/core';
import {Trans, useTranslation} from 'react-i18next';
import '@fontsource/righteous';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

export default function Accueil(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.page}>
      <h1 className={classes.title}>
        <Trans i18nKey='home.title' components={{
          la: <span className={classes.la} />,
          lines: <span className={classes.lines} />,
        }}/>
      </h1>

      <div className={classes.wrapper}>
        <div className={classes.grid}>
          <Link to="/achat" className={classes.square}>
            {t('navbar.buy')}
            <img src="/assets/acheter.svg" alt={t('navbar.buy')} />
          </Link>

          <Link to="/location" className={classes.square}>
            {t('navbar.rent')}
            <img src="/assets/louer.svg" alt={t('navbar.rent')} />
          </Link>

          <Link to="/estimation" className={classes.square}>
            {t('navbar.estimate')}
            <img src="/assets/estimer.svg" alt={t('navbar.estimate')} />
          </Link>

          <Link to="/gestion" className={classes.square}>
            {t('navbar.manage')}
            <img src="/assets/donner_en_gestion.svg" alt={t('navbar.manage')} />
          </Link>
        </div>

        <Link to="/equipe" className={classNames(classes.square, classes.map)}>
          <img src="/assets/carte.svg" alt="Carte" />
        </Link>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  page: {
    width: '1200px',
    maxWidth: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: 1020px)`]: {
      padding: '2rem 0',
    },
  },
  title: {
    margin: 0,
    marginBottom: '1.5rem',
    textAlign: 'center',
    [`@media (max-width: 1020px)`]: {
      margin: 0,
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
      fontSize: '1.75rem',
    },
  },
  la: {
    color: theme.colors.local_red[1],
    padding: 0,
    display: 'inline',
  },
  lines: {
    position: 'relative',
    display: 'inline-block',
    '::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'url(/assets/trait.svg)',
      backgroundRepeat: 'no-repeat',
      left: 0,
      bottom: 0,
      transform: 'translateY(64%)',
      width: '160px',
      height: '135px',
      backgroundSize: '100% 100%',
      zIndex: -1,
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1.5rem',
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
      gap: '1rem',
      padding: '1.5rem',
    },
  },
  grid: {
    display: 'grid',
    gap: '1rem',
    color: theme.colors.local_red[1],
    fontWeight: 700,
    fontSize: '1.25rem',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
    width: '400px',
    height: '400px',
    square: {
      width: '200px',
      height: '200px',
    },
    [`@media (max-width: 480px)`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(4, 1fr)',
      width: '100%',
      height: '100%', 
      square: {
        width: '100%',
        height: '100%'
      }
    },
  },
  square: {
    overflow: 'hidden',
    cursor: 'pointer',
    color: 'inherit',
    boxSizing: 'border-box',
    boxShadow: '0 0 2.5rem rgba(0, 0, 0, .2)',
    borderRadius: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    width: '100%',
    aspectRatio: '1/1',
    textDecoration: 'none',
    'img': {
      margin: 'auto',
      width: '100%',
    },
    'a&': {
      transition: "all ease-in-out .25s",
      ':hover': {
        color: theme.colors.local_grey[1],
      },
      ':hover:nth-of-type(2), :hover:nth-of-type(3)': {
        background: theme.colors.local_red[2],
      },
      ':hover:nth-of-type(1), :hover:nth-of-type(4)': {
        background: theme.colors.local_blue[1],
      },
    }
  },
  map: {
    overflow: 'hidden',
    padding: '2rem',
    maxWidth: '100%',
    'img': {
      transition: "transform ease-in-out .25s",
    },
    ':hover': {
      background: 'unset !important',
      'img': {
        transform: 'rotate(5deg) scale(1.1)',
      },
    },
    [`@media (min-width: 1020px)`]: {
      width: '400px',
      height: '400px',
    },
    [`@media (max-width: 480px)`]: {
      width: '100%',
      aspectRatio: '1/1',
    },
  },
}));
