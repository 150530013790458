import {createStyles, Stack, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import HonorairesData from './HonorairesData';
import classNames from 'classnames';
import HonorairesGestionDescription from 'src/app/components/gestion/HonorairesGestionDescription';
import HonorairesGestionDetailsTable from 'src/app/components/gestion/HonorairesGestionDetailsTable';


function extractPrices(inputString : string) : Array<string> {
  // use a regex to find price in the string
  const priceRegex = /\d{1,3}(?:\s\d{3})*(?:,\d{2})?€/g;
  const prices = inputString.match(priceRegex) || [];

  // Replace prices with a placeholder
  let stringWithPlaceholders = inputString;
  for (let i = 0; i < prices.length; i++) {
    stringWithPlaceholders = stringWithPlaceholders.replace(prices[i], `#PRICE${i}#`);
  }

  // split the string into segments
  const segments = stringWithPlaceholders.split('#').filter(Boolean);

  // Reconstruct the segments with actual prices
  const result = segments.map(segment => {
    if (segment.startsWith('PRICE')) {
      return prices[Number(segment.slice(5))];
    }
    return segment;
  });

  return result;
}

function stylePriceText(value: string, classes: any): JSX.Element {
  // get the words from the result, filtering out empty strings
  const words = extractPrices(value);

  // return the result with prices styled in order to have them in a single line
  return (
    <div>
      {words.map((word, index) => {
        if (word.includes('€')) {
          return (<span key={index} className={classes.priceNoWrap}>{word}</span>);
        }
        return (<span key={index}>{word}</span>);
      })}
    </div>
  );
}

export default function HonorairesGestion(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.page}>
      <div className={classes.title}>{t('honoraires.gestion.title')}</div>

      <HonorairesGestionDescription />

      {/* card with details of the packs */}
      <HonorairesGestionDetailsTable />

      {/* card with price Honoraires gestion */}
      <div className={classes.card}>
        <div className={classes.titleTableFees}>{t('honoraires.gestion.tableFees.title')}</div>
        <Stack className={classes.stack}>
          <Group className={classes.group}>
            <div className={classNames(classes.header, classes.rangeColumn, classes.cell)}>{t('honoraires.gestion.tableFees.range')}</div>
            <div className={classNames(classes.header, classes.rateColumn, classes.cell)}>{t('honoraires.gestion.tableFees.cremant')}</div>
            <div className={classNames(classes.header, classes.rateColumn, classes.cell)}>{t('honoraires.gestion.tableFees.champagne')}</div>
          </Group>

          {HonorairesData.honorairesGestion.map(element => {
            return (
              <Group key={element.range} className={classes.group}>
                <div className={classNames(classes.range, classes.rangeColumn, classes.cell)}>{stylePriceText(t(element.range), classes)}</div>
                <div className={classNames(classes.value, classes.rateColumn, classes.cell)}>{t(element.cremant)}</div>
                <div className={classNames(classes.value, classes.rateColumn, classes.cell)}>{t(element.champagne)}</div>
              </Group>
            );
          })}
        </Stack>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  page: {
    maxWidth: '100%',
    width: '1200px',
  },
  card: {
    borderRadius: '2.5rem',
    padding: '3rem',
    margin: '3rem auto',
    textAlign: 'center',
    boxShadow: '0 0 2.5rem rgba(0, 0, 0, .175)',
    [`@media (max-width: 1020px)`]: {
      boxShadow: 'unset',
      padding: 0,
    },
  },
  titleTableFees: {
    fontSize: '1.5rem',
    padding: '2rem',
    paddingTop: '0',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
    },
  },
  stack: {
    gap: '.5rem',
  },
  group: {
    [`@media (min-width: 1020px)`]: {
      justifyContent: 'center',
      alignItems: 'stretch',
    },
    [`@media (max-width: 530px)`]: {
      gap: '0.2rem',
    },
  },
  header: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    padding: '1.1rem 0',
    fontWeight: 700,
    fontSize: '1.2rem',
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    [`@media (min-width: 1020px)`]: {
      justifyContent: 'center',
    },
    [`@media (max-width: 1020px)`]: {
      fontSize: '1rem',
    },
    [`@media (max-width: 710px)`]: {
      padding: '0.1rem',
      lineHeight: '1',
      fontSize: '0.75rem',
    },
    [`@media (max-width: 350px)`]: {
      fontSize: '0.65rem',
    },
  },
  range: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    [`@media (max-width: 1140px)`]: {
      fontSize: '0.85rem',
    },
  },
  value: {
    color: theme.black,
    backgroundColor: theme.colors.local_grey[2]
  },
  rangeColumn: {
    width: '55%',
    
  },
  rateColumn: {
    width: '15%',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 700,
    padding: '1.5rem 0',
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
      fontSize: '1.5rem',
      textAlign: 'center',
    },
  },
  cell: {
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1.5rem',
    height: '60px',
    [`@media (max-width: 880px)`]: {
      height: '4.5rem',
    },
    [`@media (max-width: 710px)`]: {
      height: '6rem',
      lineHeight: '1',
    },
    [`@media (max-width: 350px)`]: {
      height: '7rem',
    },
  },
  priceNoWrap: {
    whiteSpace: 'nowrap',
  },
}));
