import classnames from 'classnames';
import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import SlideDPEData from 'src/app/components/slides/SlideDPEData';
import {SlideProps} from 'src/types/locationDetails';
import Modal from './Modal';
import ContactIcon from '../contact/ContactIcon';

export default function SlideDPE({propertyDetails: { property }}: SlideProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  let dpeLetter = property.legalDpe || "?";
  let emissionsLetter = property.legalGreenhouseEmissions || "?";
  let dpeIndex = SlideDPEData.letterToIndex(dpeLetter);
  let emissionsIndex = SlideDPEData.letterToIndex(emissionsLetter);
  let isAvailable = !!(property.legalDpe && property.legalEpcValue && property.legalGreenhouseEmissions);

  return (
    <>
      <div id='3' className={classes.dpePage}>
 

        <div className={classes.dpeContent}>
        <div className={classes.dpeRows}>
          <div className={classes.dpeColumnLeft}>
            <div className={classes.dpeFontBold}>{t('transactionDpe.energy_consumption')}</div>

            <div className={classes.dpeColumnTitle}>
              <div className={classes.dpeColumnBadge} style={{ background: SlideDPEData.colors_dpe[dpeIndex] }}>
                {dpeLetter}
              </div>

              <div className={classnames(classes.dpeMarginLeft1, classes.dpeFontLight)}>
                <span className={classes.dpeFontBold}>{property.legalEpcValue}</span> kWh/m².{t('transactionDpe.year')}
              </div>
            </div>
          </div>

          <div className={classes.dpeColumnRight}>
            <div className={classes.dpeFontBold}>{t('transactionDpe.greenhouse_emissions')}</div>

            {property.legalGreenhouseEmissions === emissionsLetter ? (
              <div className={classnames(classes.dpeColumnTitle, classes.dpeJustifyContentRight)}>
                <div className={classes.dpeColumnBadge} style={{ background: SlideDPEData.colors_ghe[emissionsIndex] }}>
                  {emissionsLetter}
                </div>
              </div>
            ) : (
              <div className={classes.dpeColumnTitle}>
                <div className={classnames(classes.dpeMarginLeftAuto, classes.dpeFontLight)}>
                  <span className={classes.dpeFontBold}>{property.legalGreenhouseEmissions}</span> kgeqCO2/m².{t('transactionDpe.year')}
                </div>

                <div className={classnames(classes.dpeColumnBadge, classes.dpeMarginLeft1)} style={{ background: SlideDPEData.colors_ghe[emissionsIndex] }}>
                  {emissionsLetter}
                </div>
              </div>
            )}
          </div>

          <div className={classes.dpeAlignRight}>
            {SlideDPEData.letters.map((letter, i) => {
              const customStyle = {
                background: SlideDPEData.colors_dpe[i],
                width: `${120 + 15 * i}px`,
                border: letter[0] === dpeLetter ? 'solid black' : '',
              };

              return (
                <div className={classnames(classes.dpeColumnBox, classes.dpeMarginLeftAuto)} style={customStyle} key={i}>
                  <div>{letter[0]}</div>
                  <div className={classes.dpeMarginLeftAuto}>{letter[1]}</div>
                </div>
              );
            })}

            <div className={classnames(classes.dpeFontLight, classes.dpeAlignTextRight)}>
              {t('transactionDpe.in')} kWhEP/m².an
            </div>
          </div>

          <div className={classes.dpeColumnSeparator} />

          <div>
            {SlideDPEData.letters.map((letter, i) => {
              let customStyle = {
                background: SlideDPEData.colors_ghe[i],
                width: `${120 + 15 * i}px`,
                border: letter[0] === emissionsLetter ? 'solid black' : '',
              };

              return (
                <div className={classes.dpeColumnBox} style={customStyle} key={i}>
                  <div>{letter[2]}</div>
                  <div className={classes.dpeMarginLeftAuto}>{letter[0]}</div>
                </div>
              );
            })}

            <div className={classes.dpeFontLight}>
              {t('transactionDpe.in')} kgeqCO2/m².an
            </div>
          </div>
        </div>
      </div>

      {!isAvailable || document.documentElement.offsetWidth < 1020 ? (
        <div className={classnames(classes.modal)}>
          <div>
            {t('transactionDpe.unavailableInfos')}
          </div>
          <ContactIcon />
        </div>
      ) : (
        <Modal text={t('transactionDpe.unavailableInfos')} isBackgroundLight={true} />
      )}
      </div>
    </>
  );
}

const useStyles = createStyles(theme => ({
  modal: {
    position:'absolute',
    boxSizing: 'border-box',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '2rem',
    width: '900px',
    height: '350px',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '1rem',
    margin: 'auto',
    padding: '5rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    backgroundColor: theme.white,
    color: theme.colors.black_pearl[0],
    
    [`@media (max-width: 1020px)`]: {
      opacity: 0.9,
      width: '100%',
      height: '400px',
      padding: 0,
    }

  },
  dpePage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.black,
  },
  dpeTitle: {
    textAlign: 'center',
    marginBottom: '.5rem',
    fontSize: '1.75rem',
  },
  dpeRows: {
    position: 'relative',
    display: 'flex',
    fontSize: '1.25rem',
    alignItems: 'center',

    [`@media (max-width: 1020px)`]: {
      position: 'relative',
      height:'100%',
    }
    
  },
  dpeColumnTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.5rem',
  },
  dpeColumnBadge: {
    background: 'red',
    borderRadius: '.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '48px',
    fontWeight: 900,
  },
  dpeColumnLeft: {
    position: 'absolute',
    left: '-15rem',
    top: '1rem',

    width: 'auto'
  },
  dpeColumnSeparator: {
    background: 'rgba(255, 255, 255, .25)',
    width: '1px',
    margin: '0 3rem',

    [`@media (max-width: 1020px)`]: {
      margin: '0 0.25rem'
    }
    
  },
  dpeColumnRight: {
    position: 'absolute',
    right: '-15rem',
    top: '1rem',
  },
  dpeColumnBox: {
    borderRadius: '.75rem',
    display: 'flex',
    margin: '.5rem 0',
    padding: '.65rem 1.25rem',
    color: theme.white,
  },
  dpeAlignTextRight: {
    textAlign: 'right',
  },
  dpeAlignRight: {
    alignItems: 'right',
  },
  dpeMarginLeftAuto: {
    marginLeft: 'auto !important',
  },
  dpeMarginLeft1: {
    marginLeft: '.5rem',
  },
  dpeJustifyContentRight: {
    justifyContent: 'flex-end ',
  },
  dpeFontBold: {
    fontWeight: 500,
  },
  dpeFontLight: {
    fontWeight: 300,
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  dpeContent : {
    [`@media (max-width: 1020px)`]: {
      position: 'relative',
    }
  }
}));
