import {createStyles, Stack, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import HonorairesData from '../honoraires/HonorairesData';
import {IconYes} from 'src/assets/icons/IconYes';
import {IconNo} from 'src/assets/icons/IconNo';

type data = {
  title: string;
  cremant: boolean | string;
  champagne: boolean | string;
}[];

type SectionProps = {
  titleSection: string;
  data: data;
};

type convertBoolToIconProps = {
  value: boolean | string;
  detailsIconClass: string;
  classes: any;
};

function HonorairesGestionDetailsTableSection(props: SectionProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div>
      <Stack className={classes.stack}>
        <Group className={classes.group}>
          <div className={classNames(classes.cell, classes.sectionTitle)}>{props.titleSection}</div>
        </Group>

        {props.data.map(element => {
          return (
            <Group key={element.title} className={classes.group}>
              <div className={classNames(classes.rowTitle, classes.titleColumn, classes.cellResponsive)}>{t(element.title)}</div>
              <div className={classNames(classes.value, classes.cell, classes.valueColumn, classes.cellResponsive)}>
                {convertBoolToIcon({classes: classes, value: element.cremant, detailsIconClass: classes.detailsIcon})}
              </div>
              <div className={classNames(classes.value, classes.cell, classes.valueColumn, classes.cellResponsive)}>
                {convertBoolToIcon({classes: classes, value: element.champagne, detailsIconClass: classes.detailsIcon})}
              </div>
            </Group>
          );
        })}
      </Stack>
    </div>
  );
}

function convertBoolToIcon(props: convertBoolToIconProps): JSX.Element {
  if (typeof props.value == 'boolean') {
    if (props.value) {
      return <IconYes fill="#000" className={props.detailsIconClass} />;

    } else {
      return <IconNo fill="#E8505B" className={props.detailsIconClass} />;
    }

  } else {
    // This part ensures that prices are displayed on a single line
    if (props.value.includes("€")) {
      const words = props.value.split("€ ");
      if(words.length == 2){
        // render the price and text separately
        return (<div>
          <div><span className={classNames(props.classes.priceNoWrap)}>{words[0] + '€'}</span>
          {" " + words[1]}</div>
        </div>);
      }
    }
    // If none of the special conditions are met, render the original 'value' prop
    return <div>{props.value}</div>;
  }
}

export default function HonorairesGestionDetailsTable(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.card}>
      <Stack className={classes.stack}>
      <Group className={classes.group}>
          <div className={classNames(classes.header, classes.titleColumn, classes.cellResponsive)}>{t('honoraires.gestion.table.title')}</div>
          <div className={classNames(classes.header, classes.valueColumn, classes.cellResponsive)}>{t('honoraires.gestion.table.cremantPack')}</div>
          <div className={classNames(classes.header, classes.valueColumn, classes.cellResponsive)}>{t('honoraires.gestion.table.champagnePack')}</div>
        </Group>

        {/* administrative */}
        <HonorairesGestionDetailsTableSection titleSection={t('honoraires.gestion.table.administrativeManagement')} data={HonorairesData.gestionDetails.administrative} />

        {/* financial */}
        <HonorairesGestionDetailsTableSection titleSection={t('honoraires.gestion.table.financialManagement')} data={HonorairesData.gestionDetails.financial} />

        {/* technical */}
        <HonorairesGestionDetailsTableSection titleSection={t('honoraires.gestion.table.technicalManagement')} data={HonorairesData.gestionDetails.technical} />

        {/* other services */}
        <Group className={classes.group}>
          <div className={classNames(classes.cell, classes.sectionTitle)}>{t('honoraires.gestion.table.otherServices')}</div>
        </Group>

        {HonorairesData.gestionDetails.others.map(element => {
          return (
            <Group key={element.title} className={classes.group}>
              <div className={classNames(classes.rowTitle, classes.titleColumn, classes.cellResponsive)}>{t(element.title)}</div>
              <div className={classNames(classes.value, classes.cell, classes.otherServicesColumn, classes.cellResponsive)}>{t(element.both)}</div>
            </Group>
          );
        })}
      </Stack>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  card: {
    boxSizing: 'border-box',
    borderRadius: '2.5rem',
    padding: '3rem',
    maxWidth: '100%',
    width: '1200px',
    textAlign: 'center',
    boxShadow: '0 0 2.5rem rgba(0, 0, 0, .175)',
    margin: '3rem 0',
    [`@media (max-width: 1020px)`]: {
      boxShadow: 'unset',
      padding: 0,
    },
  },
  stack: {
    gap: '.5rem',
  },
  group: {
    [`@media (min-width: 1020px)`]: {
      justifyContent: 'center',
      alignItems: 'stretch', // if multiple rows, all have the same height
    },
    [`@media (max-width: 530px)`]: {
      gap: '0.2rem',
    },
  },
  titleColumn: {
    padding: '0.25rem',
    width: '55%',
    [`@media (max-width: 1140px)`]: {
      fontSize: '0.85rem',
    },
    [`@media (max-width: 880px)`]: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [`@media (max-width: 710px)`]: {
      gap: '0',
      lineHeight: '1',
    },
  },
  valueColumn: {
    width: '15%',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  header: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    fontWeight: 700,
    fontSize: '1.2rem',
    borderRadius: '.625rem',
    [`@media (min-width: 1020px)`]: {
      justifyContent: 'center',
    },
    [`@media (max-width: 1020px)`]: {
      fontSize: '1rem',
    },    
    [`@media (max-width: 710px)`]: {
      padding: '0.1rem',
      lineHeight: '1',
      fontSize: '0.75rem',
    },
    [`@media (max-width: 350px)`]: {
      fontSize: '0.65rem',
    },

  },
  sectionTitle: {
    backgroundColor: '#E0E0E0',
    color: theme.black,
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 1.5rem',
    height: '60px',
    width: 'calc(85% + 2rem)',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  cell: {
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 1.5rem',
    height: '60px',
  },
  value: {
    color: theme.black,
    backgroundColor: theme.colors.local_grey[2],
  },
  rowTitle: {
    borderRadius: '.625rem',
    backgroundColor: theme.colors.local_red[1],
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    [`@media (min-width: 880px)`]: {
      height: '60px', 
      textAlign: 'left',
      padding: '0 1.5rem',
    },
  },
  otherServicesColumn: {
    width: 'calc(30% + 1rem)',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  detailsIcon: {
    display: 'block',
    width: '20px',
    height: '20px',
    margin: 'auto',
  },
  cellResponsive : {
    [`@media (max-width: 880px)`]: {
      height: '4.5rem',
    },
    [`@media (max-width: 710px)`]: {
      height: '6rem',
    },
    [`@media (max-width: 350px)`]: {
      height: '7rem',
    }
  },
  priceNoWrap: {
    whiteSpace: 'nowrap',
  }
}));