import classnames from 'classnames';
import {createStyles, HoverCard} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {HypothesesFields, PropertyDetailsProps} from 'src/types/locationDetails';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, TooltipItem, ScriptableScaleContext, ChartMeta, BarProps, ChartType, ChartTypeRegistry} from 'chart.js';
import Transaction from 'src/services/transaction';
import ChartDataLabels, {Context} from 'chartjs-plugin-datalabels';
import {splitStringInArray} from './SlideBudget';
import {DeepPartial} from 'chart.js/types/utils';
import * as SlideInvestorData from './SlideInvestorData';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, ChartDataLabels);

type SlideIncomeProps = {
  propertyDetails: PropertyDetailsProps;
  hypotheses: HypothesesFields;
};

function getWidth(context: Context): number {
  let chart = context.chart;
  let area = chart.chartArea;
  let meta: ChartMeta = chart.getDatasetMeta(context.datasetIndex);
  let model: DeepPartial<{[key in ChartType]: ChartTypeRegistry[key]['metaExtensions']}['bar']> = meta.data[context.dataIndex];
  let left = Math.max((model as BarProps).base, area.left);
  let width = (model as BarProps).x - left;
  return width;
}

export default function SlideIncome(props: SlideIncomeProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = props.propertyDetails.property;
  const isBackgroundLight = true;
  const color = isBackgroundLight ? 'white' : 'black';

  let monthlyLoanPayment = SlideInvestorData.getMonthlyLoanPayment(props.hypotheses, property);
  let monthlyTreasury = SlideInvestorData.getMonthlyTreasury(props.hypotheses, property);
  let amount = [props.hypotheses.monthlyRent, -property.charges, -property.propertyTax, -monthlyLoanPayment, monthlyTreasury];

  const data = {
    labels: [t('achat.commun.monthlyRent'), t('achat.income.charges'), t('achat.income.propertyTax'), t('achat.income.monthlyLoanPayment'), t('achat.income.monthlyTreasury')],
    datasets: [
      {
        data: [
          props.hypotheses.monthlyRent,
          [props.hypotheses.monthlyRent - property.charges, props.hypotheses.monthlyRent],
          [props.hypotheses.monthlyRent - property.charges, props.hypotheses.monthlyRent - property.charges - property.propertyTax],
          [props.hypotheses.monthlyRent - property.charges - property.propertyTax, props.hypotheses.monthlyRent - property.charges - property.propertyTax - monthlyLoanPayment],
          monthlyTreasury,
        ],
        backgroundColor: ['#70B5E8', '#FFB70D', '#92B620', '#F94A46', monthlyTreasury >= 0 ? 'green' : 'red'],
      },
    ],
  };

  const ExplanationLabel = [
    splitStringInArray(t('achat.commun.monthlyRentExplanation')),
    splitStringInArray(t('achat.income.chargesExplanation')),
    splitStringInArray(t('achat.income.propertyTaxExplanation')),
    splitStringInArray(t('achat.income.monthlyLoanPaymentExplanation')),
    splitStringInArray(t('achat.income.monthlyTreasuryExplanation')),
  ];

  let options = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#FFF',
        titleColor: '#000',
        titleFont: {size: 16},
        bodyColor: '#000',
        bodyFont: {size: 14},
        displayColors: false,
        callbacks: {
          title: (context: TooltipItem<'bar'>[]) => {
            return `${context[0].label} : ${amount[context[0].dataIndex]}${Transaction.currency(property.priceAskingPriceCurrency)}`;
          },
          label: (context: TooltipItem<'bar'>) => {
            return ExplanationLabel[context.dataIndex];
          },
        },
      },
      datalabels: {
        color: function (context: Context) {
          return isBackgroundLight ? 'white' : Math.abs(getWidth(context)) < 40 ? 'black' : 'white';
        },
        formatter: function (value: number | number[], context: Context) {
          return `${amount[context.dataIndex]} ${Transaction.currency(property.priceAskingPriceCurrency)}`;
        },
        align: function (context: Context) {
          return Math.abs(getWidth(context)) < 40 ? (context.dataIndex === 4 && (context.dataset.data[4] ?? 1) as number > 0 ? 'end' : 'start') : 'center';
        },
        offset: function (context: Context) {
          return Math.abs(getWidth(context)) < 40 ? 15 : 0;
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: false,
          color: function (context: ScriptableScaleContext) {
            return context.tick.value ? '' : color;
          },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        ticks: {
          color: color,
        },
      },
    },
  };

  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;
  let backgroundShadeCard = isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;
  let backgroundShadeCardObject = isBackgroundLight ? classes.backgroundDarkObject : classes.backgroundLightObject;
  let backgroundShadeCardObjectInside = isBackgroundLight ? classes.backgroundLightObject : classes.backgroundDarkObject;

  return (
    <div className={classnames(classes.incomePage, backgroundShade)}>
      <div className={classnames(classes.incomeCardLeft, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.incomeTitle}> {t('achat.income.rentalIncome')}</div>
        <hr className={classes.incomeSeparator} />
        <Bar data={data} options={options} />
      </div>
      <div className={classnames(classes.incomeCardRight, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.summaryValues}>
          <div className={classes.summaryValuesSection}>
            {Transaction.formatNumber(SlideInvestorData.getProfitability(props.hypotheses, property), t('transaction.formatNumber'))}%
            <HoverCard withArrow={true} width="280px" shadow="md">
              <HoverCard.Target>
                <div className={classnames(classes.buttonStyle, backgroundShade, backgroundShadeCardObjectInside)}>{t('achat.commun.profitability')}</div>
              </HoverCard.Target>
              <HoverCard.Dropdown className={classnames(classes.questionDropdown)}>
                <div>{t('achat.commun.profitabilityExplanation')}</div>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
          <div className={classes.summaryValuesSection}>
            {props.hypotheses.monthlyRent - property.charges - property.propertyTax} {Transaction.currency(property.priceAskingPriceCurrency)}
            <HoverCard withArrow={true} width="280px" shadow="md" position="top">
              <HoverCard.Target>
                <div className={classnames(classes.buttonStyle, backgroundShade, backgroundShadeCardObjectInside)}>{t('achat.income.rent')}</div>
              </HoverCard.Target>
              <HoverCard.Dropdown className={classnames(classes.questionDropdown)}>
                <div>{t('achat.income.rentExplanation')}</div>
                <div className={classes.rentDetails}>
                  <div className={classes.rentDetailsLine}>
                    <div>{t('achat.commun.monthlyRent')}</div>
                    {props.hypotheses.monthlyRent}
                    {Transaction.currency(property.priceAskingPriceCurrency)}
                  </div>
                  <div className={classes.rentDetailsLine}>
                    <div>{t('achat.income.charges')}</div>
                    {property.charges}
                    {Transaction.currency(property.priceAskingPriceCurrency)}
                  </div>
                  <div className={classes.rentDetailsLine}>
                    <div>{t('achat.income.propertyTax')}</div>
                    {property.propertyTax}
                    {Transaction.currency(property.priceAskingPriceCurrency)}
                  </div>
                </div>
                <div className={classnames(classes.rentDetailsTotal, classes.rentDetailsLine)}>
                  <div>{t('achat.income.rentalIncome')}</div> {props.hypotheses.monthlyRent - property.charges - property.propertyTax}
                  {Transaction.currency(property.priceAskingPriceCurrency)}
                </div>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  incomePage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    columnGap: '2rem',
  },
  incomeCardLeft: {
    overflow: 'hidden',
    borderRadius: '1rem',
    flex: '0 0 650px',
    padding: '1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  incomeCardRight: {
    overflow: 'hidden',
    borderRadius: '1rem',
    flex: '0 0 200px',
    padding: '1rem',
  },
  incomeTitle: {
    fontWeight: 700,
    fontSize: '1.7rem',
  },
  incomeSeparator: {
    borderColor: 'solid 0.1px grey',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
  buttonStyle: {
    display: 'flex',
    borderRadius: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 2rem',
    height: '5rem',
    width: '100%',
    fontSize: '1.4rem',
    fontWeight: 400,
    boxSizing: 'border-box',
  },
  summaryValues: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    fontSize: '2.5rem',
    fontWeight: 700,
    justifyContent: 'center',
  },
  summaryValuesSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  questionDropdown: {
    fontWeight: 400,
    fontSize: '1rem',
    color: theme.black,
  },
  rentDetails: {
    fontSize: '1rem',
  },
  rentDetailsLine: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '.5rem 0',
  },
  rentDetailsTotal: {
    fontWeight: 600,
  },
}));
