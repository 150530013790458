import {FaFacebookF, FaLinkedinIn, FaPinterestP} from 'react-icons/fa';
import {AiFillInstagram, AiFillTwitterCircle} from 'react-icons/ai';
import {ActionIcon, createStyles, MantineNumberSize} from '@mantine/core';
import {IconType} from 'react-icons';

type Options = {
  variant: string;
  size: MantineNumberSize;
  radius: MantineNumberSize;
  iconSize: number;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  pinterestLink: string;
  linkedinLink: string;
};

export default function SocialMedia(): JSX.Element {
  const {classes} = useStyles();

  const options: Options = {
    variant: 'filled',
    size: 'lg',
    radius: 'xl',
    iconSize: 25,
    facebookLink: 'https://www.facebook.com/rebootimmobilier/',
    twitterLink: 'https://twitter.com/rebootimmobili1',
    instagramLink: 'https://www.instagram.com/reboot.immobilier/',
    pinterestLink: '',
    linkedinLink: 'https://www.linkedin.com/company/reboot-immobilier/',
  };

  function renderIcon(Icon: IconType, link: string): JSX.Element | undefined {
    if (link)
      return (
        <ActionIcon className={classes.socialIconButton} component="a" href={link} target="_blank" variant={options.variant} size={options.size} radius={options.radius}>
          <Icon size={options.iconSize} />
        </ActionIcon>
      );
  }

  return (
    <div className={classes.socialMedia}>
      {renderIcon(FaFacebookF, options.facebookLink)}
      {renderIcon(AiFillTwitterCircle, options.twitterLink)}
      {renderIcon(AiFillInstagram, options.instagramLink)}
      {renderIcon(FaPinterestP, options.pinterestLink)}
      {renderIcon(FaLinkedinIn, options.linkedinLink)}
    </div>
  );
}

const useStyles = createStyles(theme => ({
  socialMedia: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing.xs,
    alignItems: 'center'
  },
  socialIconButton: {
    color: theme.colors.local_red[0],
    '&:hover': {backgroundColor: `${theme.colors.local_grey[0]} !important`},
    backgroundColor: 'white!important',
  },
}));
