import {Button, createStyles} from '@mantine/core';

type ChipProps = {
  value: string;
  text: string;
  addElement: (elt: string) => void;
  removeElement: (elt: string) => void;
  isSelected: boolean;
};

export default function Chip(props: ChipProps): JSX.Element {
  const {classes} = useStyles();

  return (
    <Button
      compact
      onClick={() => {
        if (props.isSelected) {
          props.removeElement(props.value);
        } else {
          props.addElement(props.value);
        }
      }}
      style={{backgroundColor: props.isSelected ? '#FA5252' : '#515766'}}
      className={classes.button}>
      {props.text}
    </Button>
  );
}

const useStyles = createStyles(() => ({
  button: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '6.75rem',
    margin: '.25rem',
    fontWeight: 400,
  },
}));
