import {useEffect, useState} from 'react';
import {AiOutlineHeart, AiFillHeart} from 'react-icons/ai';
import {ActionIcon, createStyles} from '@mantine/core';

type FavoriteButtonProps = {
  propertyId: string;
};

export default function FavoriteButton({propertyId}: FavoriteButtonProps): JSX.Element {
  const [selected, setSelected] = useState(false);
  const {classes} = useStyles();

  function pressFavoriteIcon(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();
    setSelected(!selected);
    localStorage.setItem(propertyId, String(!selected)); //use this to write localStorage in browser
  }

  function showHeart(): JSX.Element {
    return selected ? <AiFillHeart size={18} className={classes.like} /> : <AiOutlineHeart size={18} className={classes.like} />;
  }

  useEffect(() => {
    setSelected(localStorage[propertyId] === 'true');
  }, [propertyId]);

  return (
    <ActionIcon variant="default" radius="md" size={36} onClick={pressFavoriteIcon}>
      {showHeart()}
    </ActionIcon>
  );
}

const useStyles = createStyles(theme => ({
  like: {
    color: theme.colors.red[6],
    marginBottom: '-2px',
  },
}));
