import {createStyles, getStylesRef, MediaQuery} from '@mantine/core';
import classNames from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

type IllustratedPageProps = React.PropsWithChildren & {
  className?: string;
  titleKey: string;
  subtitleKey: string;
  hideIntro?: boolean;
};

export default function IllustratedPage(props: IllustratedPageProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <>
      <MediaQuery query='(max-width: 960px)' styles={{ display: 'none '}}>
        <div className={classes.illustrations}>
          <img className={classes.illustrationImmeubleBleu2} src="/assets/immeuble_bleu_2.svg" alt="Immeuble" />
          <img className={classes.illustrationVelo} src="/assets/velo.svg" alt="Vélo" />
          <img className={classes.illustrationPlante2} src="/assets/plante_2.svg" alt="Plante" />
          <img className={classes.illustrationPlante1} src="/assets/plante_1.svg" alt="Plante" />
          <img className={classes.illustrationImmeubleRouge1} src="/assets/immeuble_rouge_1.svg" alt="Immeuble" />
          <img className={classes.easterEgg} src="/assets/easter_egg.gif" alt="Easter Egg" />
        </div>
      </MediaQuery>

      <div className={classNames(classes.wrapper, props.className)}>
        {props.hideIntro || (
          <div className={classes.intro}>
            <h1>
              <Trans i18nKey={props.titleKey} components={{lines: <span className={classes.lines} />}}/>
            </h1>

            <p>
              {t(props.subtitleKey)}
            </p>
          </div>
        )}

        {props.children}
      </div>
    </>
  );
}

const useStyles = createStyles(() => ({
  // @TODO: Ask Alex to re-export illustrations (fit to screen).
  illustrations: {
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: .65,
  },
  illustrationImmeubleBleu2: {
    position: 'absolute',
    bottom: '-3px',
    left: '3%',
    width: '300px',
    height: '300px',
  },
  illustrationVelo: {
    transform: 'translateY(50%)',
    position: 'absolute',
    bottom: '31px',
    left: '13%',
    width: '470px',
    height: '470px',
  },
  illustrationPlante2: {
    transform: 'translate(-50%, 50%)',
    position: 'absolute',
    bottom: '57px',
    left: '50%',
    width: '700px',
    height: '700px',
  },
  illustrationPlante1: {
    transform: 'translateY(50%)',
    position: 'absolute',
    bottom: '56px',
    right: '26%',
    width: '750px',
    height: '750px',
  },
  illustrationImmeubleRouge1: {
    cursor: 'pointer',
    transform: 'scaleX(-1)',
    position: 'absolute',
    bottom: '-2px',
    right: '3%',
    width: '300px',
    height: '300px',
    [`&:hover ~ .${getStylesRef('easterEgg')}`]: {
      opacity: 1,
    },
  },
  easterEgg: {
    ref: getStylesRef('easterEgg'),
    pointerEvents: 'none',
    transition: 'opacity ease-in-out .25s',
    opacity: 0,
    position: 'absolute',
    bottom: '81px',
    right: 'calc(3% + 124px)',
    width: '32px',
    height: '32px',
    objectFit: 'contain',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '860px',
    maxWidth: '100%',
    [`@media (max-width: 1020px)`]: {
      padding: '2rem 1rem',
    },
  },
  intro: {
    overflow: 'hidden',
    marginBottom: '1rem',
    textAlign: 'center',
    h1: {
      margin: 0,
    },
    p: {
      lineHeight: '1.5rem',
    },
    [`@media (max-width: 960px)`]: {
      h1: {
        fontSize: '1.75rem',
      },
      p: {
        textAlign: 'justify',
      },
    },
  },
  lines: {
    position: 'relative',
    display: 'inline-block',
    '::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: 'url(/assets/trait.svg)',
      backgroundRepeat: 'no-repeat',
      left: '50%',
      bottom: 0,
      transform: 'translate(-50%, 64%)',
      width: '160px',
      height: '135px',
      backgroundSize: '100% 100%',
      zIndex: -1,
    },
  },
}));
