import {HypothesesFields, PropertyDetails} from 'src/types/locationDetails';
import Transaction from 'src/services/transaction';

export function getTotalPrice(hypotheses: HypothesesFields): number {
  return hypotheses.price;
}

export function getTotalBudget(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return hypotheses.price + hypotheses.workFurniture + getLawyerCost(hypotheses.price) + property.rebootCost;
}

export function getProfitability(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return Math.round((((hypotheses.monthlyRent - property.charges) * 12 - property.propertyTax) / getTotalBudget(hypotheses, property)) * 1000) / 10;
}

export function getMonthlyLoanPayment(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return Math.round(
    ((getTotalBudget(hypotheses, property) - hypotheses.supply) * (hypotheses.interestRate / 100 / 12)) /
      (1 - (1 + hypotheses.interestRate / 100 / 12) ** (-hypotheses.loanDuration * 12)),
  );
}

export function getMonthlyTreasury(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return hypotheses.monthlyRent - getMonthlyLoanPayment(hypotheses, property) - property.charges - property.propertyTax;
}

export function getCurrentYear(): number {
  return Transaction.getYear();
}

export function getNumberYear(hypotheses: HypothesesFields): number {
  return hypotheses.resaleYear - getCurrentYear();
}

export function getNumberMonths(hypotheses: HypothesesFields): number {
  return (hypotheses.resaleYear - getCurrentYear()) * 12;
}

export function getResalePrice(hypotheses: HypothesesFields): number {
  return hypotheses.price * (1 + hypotheses.evolutionRate / 100) ** (hypotheses.resaleYear - Transaction.getYear());
}

export function getCapitalToRepay(hypotheses: HypothesesFields, property: PropertyDetails): number {
  //https://formulecredit.com/capital-restant-a-rembourser.php
  if (getNumberMonths(hypotheses) < hypotheses.loanDuration * 12) {
    let cp =
      (getMonthlyLoanPayment(hypotheses, property) * (1 - (1 + hypotheses.interestRate / 100 / 12) ** -(hypotheses.loanDuration * 12 - getNumberMonths(hypotheses)))) /
      (hypotheses.interestRate / 100 / 12);
    return -cp;
  }
  return 0;
}

export function getTax(hypotheses: HypothesesFields, property: PropertyDetails): number {
  //https://www.cogedis.com/nos-conseils/fiches-conseils-gestion/remboursement-anticipe-pret/
  //exonération si le prix de vente<15000 ou si le bien est détenu depuis plus de 30 ans
  if (getResalePrice(hypotheses) > 15000 && getNumberYear(hypotheses) < 30) {
    let prixAcquisition = hypotheses.price + getLawyerCost(hypotheses.price);
    //les travaux ne sont pas pris en compte car que si construction, agrandissement etc
    let plusValue = getResalePrice(hypotheses) - prixAcquisition;
    // la moins-value n'est pas imposable
    if (plusValue <= 0) {
      return 0;
    }

    //calcul abattement impot sur le revenu
    let tauxAbattementImpotSurRevenu = 0;
    //si on possède le bien depuis moins de 6 ans : pas d'abattement
    if (getNumberYear(hypotheses) > 6 && getNumberYear(hypotheses) <= 21) {
      // 6% par an d'abattement sur impot sur le revenu entre la 6eme et la 21eme année
      tauxAbattementImpotSurRevenu = (getNumberYear(hypotheses) - 6 + 1) * 6;
    }
    if (getNumberYear(hypotheses) > 21) {
      tauxAbattementImpotSurRevenu = 1;
    }

    //calcul abattement prélèvements sociaux
    let tauxAbattementPrelevementsSociaux = 0;
    // si moins de 6 ans : pas d'abattement
    if (getNumberYear(hypotheses) > 6) {
      // entre 6 et 21 : 1.65% par annee
      if (getNumberYear(hypotheses) <= 21) {
        tauxAbattementPrelevementsSociaux = (getNumberYear(hypotheses) - 5) * 1.65;
      } else {
        // 22eme année : 1.6%
        if (getNumberYear(hypotheses) === 22) {
          tauxAbattementPrelevementsSociaux = 16 * 1.65 + 1.6;
        }
        //entre 22 30 : 9%
        else {
          tauxAbattementPrelevementsSociaux = 16 * 1.65 + 1.6 + (getNumberYear(hypotheses) - 22) * 9;
        }
        // plus que 30 ans : exoneré mais ne rentre pas dans le 1er if de la méthode
      }
    }
    return -(plusValue * (1 - tauxAbattementImpotSurRevenu / 100) * 0.19 + plusValue * (1 - tauxAbattementPrelevementsSociaux / 100) * 0.172);
  }
  return 0;
}

export function getInterestToRepay(hypotheses: HypothesesFields, property: PropertyDetails): number {
  if (getNumberMonths(hypotheses) < hypotheses.loanDuration * 12) {
    //calcul des interets pour les 6 prochains mois
    //https://www.solutis.fr/tableau-amortissement.html#:~:text=Voici%20les%20formules%20de%20calcul,(1%20%2B%20TAEG%2F12)&text=Int%C3%A9r%C3%AAts%20%3D%20(capital%20emprunt%C3%A9%20x%20TAEG,Capital%20amorti%20%3D%20mensualit%C3%A9s%20%2D%20int%C3%A9r%C3%AAts
    let mensualite = getMonthlyLoanPayment(hypotheses, property);
    let resteDu = -getCapitalToRepay(hypotheses, property);
    let interest;
    let interestSum = 0;
    let capitalAmorti;
    for (let i = 0; i < 6; i++) {
      interest = (resteDu * hypotheses.interestRate) / 12 / 100;
      interestSum += interest;
      capitalAmorti = mensualite - interest;
      resteDu -= capitalAmorti;
    }
    //https://www.cogedis.com/nos-conseils/fiches-conseils-gestion/remboursement-anticipe-pret/
    //3% du capital à rembourser ou la somme des 6 prochains mois d'intérêt
    let res = Math.min(-getCapitalToRepay(hypotheses, property) * 0.03, interestSum);
    return -res;
  }
  return 0;
}

export function getInitialSupply(hypotheses: HypothesesFields): number {
  return -hypotheses.supply;
}

export function getTreasury(hypotheses: HypothesesFields, property: PropertyDetails): number {
  let monthlyLoanPayment = getMonthlyLoanPayment(hypotheses, property);

  if (getNumberMonths(hypotheses) < hypotheses.loanDuration * 12) {
    return (getRent(hypotheses, property) - monthlyLoanPayment) * getNumberMonths(hypotheses);

  } else {
    return getRent(hypotheses, property) * getNumberMonths(hypotheses) - monthlyLoanPayment * hypotheses.loanDuration * 12;
  }
}

export function getRent(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return hypotheses.monthlyRent - property.charges - property.propertyTax;
}

export function getProfit(hypotheses: HypothesesFields, property: PropertyDetails): number {
  return (
    getResalePrice(hypotheses) +
    getTreasury(hypotheses, property) +
    getCapitalToRepay(hypotheses, property) +
    getInitialSupply(hypotheses) +
    getInterestToRepay(hypotheses, property) +
    getTax(hypotheses, property)
  );
}

export function getProfitabilityOfInvestedCapitals(hypotheses: HypothesesFields, property: PropertyDetails): number {
  //TODO : la formule est à vérifier avec Yaniv ou Dan
  return Math.round(((getRent(hypotheses, property) + getResalePrice(hypotheses)) / (getInitialSupply(hypotheses) + getTreasury(hypotheses, property))) * 10) / 10;
}

// https://www.economie.gouv.fr/particuliers/frais-notaire-achat-immobilier
// https://selectra.info/finance/guides/credit-immo/frais-notaire
export function getLawyerCost(price: number): number{
  // A verifier avec Yaniv ou Dan; on distingue ancien et neuf ?
  let emolument = 0;
  let debours = 1000;

  let publiciteFonciere = price * 4.5 / 100;
  let communale = price * 1.2 / 100;
  let nationale = publiciteFonciere * 2.37 / 100;
  let mutation = publiciteFonciere + communale + nationale;

  if (price < 6500){
    emolument = price * 3.87 / 100;

  } else {
    emolument = 6500 * 3.87 / 100;

    if (price < 17000) {
      emolument += (price - 6500) * 1.596 / 100;

    } else {
      emolument += (17000 - 6500) * 1.596 / 100;

      if (price < 60000) {
        emolument += (price - 17000) * 1.064 / 100;

      } else {
        emolument += (60000 - 17000) * 1.064 / 100;
        emolument += (price - 60000) * 0.799 / 100;
      }
    }
  }

  return Math.round(emolument + debours + mutation);
}
