import {createStyles, HoverCard, NumberInput, Slider, Text} from '@mantine/core';
import {BsQuestionCircle} from 'react-icons/bs';
import {SliderProps} from 'src/types/hypotheses';
import Transaction from 'src/services/transaction';
import classnames from 'classnames';

type HypothesesProps = {
  title: string;
  explanation: string;
  value: number;
  onChange: (value: number) => void;
  sliderProps: SliderProps;
  unit?: string;
  numberFormatLanguage: string;
  step?: number;
  precision?: number;
  isLarge?: boolean;
  isBackgroundLight: boolean;
  decimalSeparator?: string;
};

export default function Hypotheses(props: HypothesesProps): JSX.Element {
  const {classes} = useStyles();

  let numberInputWidth = props.isLarge ? classes.largeInput : classes.smallInput;

  let backgroundShadeCard = props.isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;

  return (
    <div>
      <div className={classes.line}>
        <div className={classes.lineTitle}>
          {props.title}
          <HoverCard withArrow={true} width="280px" shadow="md">
            <HoverCard.Target>
              <div>
                <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown className={classes.questionDropdown}>
              <Text size="sm">{props.explanation}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </div>
        <div className={classes.inputAndCurrency}>
          <NumberInput
            value={props.value}
            size="md"
            step={props.step}
            precision={props.precision}
            // onChange={val => {
            //   if (val !== undefined && !isNaN(val)) {
            //     props.onChange(val);
            //   }
            // }}
            onChange = {props.onChange}
            variant="unstyled"
            hideControls
            classNames={{wrapper: classnames(classes.numberInput, numberInputWidth), input: backgroundShadeCard}}
            decimalSeparator={props.decimalSeparator}
            parser={value => value?.replace(/\s?|(,*)/g, '')}
            formatter={value => {
              if (props.decimalSeparator) {
                return value ?? '';
              }
              return !Number.isNaN(parseFloat(value as string)) ? Transaction.formatNumber(parseFloat(value as string), props.numberFormatLanguage) : '';
            }}
          />
          {props.unit}
        </div>
      </div>
      <Slider
        min={props.sliderProps.min}
        max={props.sliderProps.max}
        size="xs"
        value={props.value}
        onChange={props.onChange}
        label={null}
        showLabelOnHover={false}
        color="red"
        step={props.step}
        marks={props.sliderProps.marks}
        className={classes.slider}
      />
    </div>
  );
}

const useStyles = createStyles(theme => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lineTitle: {
    display: 'flex',
    gap: '1rem',
  },
  questionIcon: {
    cursor: 'pointer',
  },
  questionDropdown: {
    position: 'absolute',
    transform: 'translate(4px, -4px)',
    color: 'black',
  },
  inputAndCurrency: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  nativeSelect: {
    borderBottom: 'solid 1px',
    width: '4.5rem',
    height: '2.5rem',
  },
  nativeSelectInput: {
    transform: 'translateY(4px)',
  },
  slider: {
    margin: '1rem',
  },
  numberInput: {
    borderBottom: 'solid 1px',
    height: '2.5rem',
  },
  largeInput: {
    width: '4rem',
  },
  smallInput: {
    width: '2.5rem',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
}));
