import {createStyles, HoverCard, NativeSelect, NumberInput, Slider, Text} from '@mantine/core';
import {BsChevronDown, BsQuestionCircle} from 'react-icons/bs';
import {SliderProps} from 'src/types/hypotheses';
import Transaction from 'src/services/transaction';
import classnames from 'classnames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type HypothesesEvolutionProps = {
  title: string;
  explanation: string;
  valueYears: number;
  onChangeYears: (value: number) => void;
  valueRate: number;
  onChangeRate: (value: number) => void;
  sliderProps: SliderProps;
  currency?: string;
  numberFormatLanguage: string;
  step?: number;
  precision?: number;
  list: number[];
  map: Map<number, number>;
  changeWhenReset: boolean;
  isBackgroundLight: boolean;
  decimalSeparator: string;
};

export default function HypothesesEvolution(props: HypothesesEvolutionProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  const [localValueYears, setLocalValueYears] = useState<string>(props.valueYears.toString());
  const [localValueRate, setLocalValueRate] = useState<number>(props.valueRate);

  let backgroundShadeCard = props.isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;
  let backgroundShadeCardObject = props.isBackgroundLight ? classes.backgroundDarkObject : classes.backgroundLightObject;

  // if reset
  useEffect(() => {
    if (props.valueYears !== undefined) {
      setLocalValueYears(props.valueYears.toString());
    }
    if (props.valueRate !== undefined) {
      setLocalValueRate(props.valueRate);
    }
  }, [props.changeWhenReset]);

  useEffect(() => {
    try {
      setLocalValueRate(props.map.get(parseInt(localValueYears)) ?? localValueRate); // si autre, on laisse pareil
    } catch {}
  }, [localValueYears]);

  useEffect(() => {
    let index = Array.from(props.map.values()).indexOf(localValueRate);
    try {
      setLocalValueYears(props.list[index].toString()); // si un autre valeur on met "autre"
    } catch {
      setLocalValueYears(t('achat.hypotheses.other'));
    }
  }, [localValueRate]);

  return (
    <div>
      <div className={classes.line}>
        <div className={classes.lineTitle}>
          {props.title}
          <HoverCard withArrow={true} width="280px" shadow="md">
            <HoverCard.Target>
              <div>
                <BsQuestionCircle size=".9rem" className={classes.questionIcon} />
              </div>
            </HoverCard.Target>
            <HoverCard.Dropdown className={classes.questionDropdown}>
              <Text size="sm">{props.explanation}</Text>
            </HoverCard.Dropdown>
          </HoverCard>
        </div>
        <div className={classes.inputAndCurrency}>
          <NativeSelect
            data={[
              ...props.list.map(value => {
                return {value: value.toString(), label: `${value} ${t('achat.hypotheses.years')}`};
              }),
              t('achat.hypotheses.other'),
            ]}
            rightSection={<BsChevronDown size={14} />}
            rightSectionWidth={30}
            variant="unstyled"
            classNames={{
              root: classes.nativeSelect,
              input: classnames(classes.nativeSelectInput, backgroundShadeCard, backgroundShadeCardObject),
              rightSection: classes.nativeSelectInput,
            }}
            onChange={event => {
              setLocalValueYears(event.currentTarget.value);
              props.onChangeYears(parseInt(event.currentTarget.value));
            }}
            value={localValueYears}
          />
          <NumberInput
            min={props.sliderProps.min}
            max={props.sliderProps.max}
            value={localValueRate}
            size="md"
            step={props.step}
            precision={props.precision}
            onChange={val => {
              if (val !== '' && !isNaN(val)) {
                setLocalValueRate(val);
                props.onChangeRate(val);
              }
            }}
            variant="unstyled"
            hideControls
            classNames={{wrapper: classnames(classes.numberInput, classes.smallInput), input: backgroundShadeCard}}
            decimalSeparator=","
          />
          {props.currency ? Transaction.currency(props.currency) : '%'}
        </div>
      </div>
      <Slider
        min={props.sliderProps.min}
        max={props.sliderProps.max}
        size="xs"
        value={localValueRate}
        onChange={setLocalValueRate}
        onChangeEnd={val => {
          setLocalValueRate(val);
          props.onChangeRate(val);
        }}
        label={null}
        showLabelOnHover={false}
        color="red"
        className={classes.slider}
        step={props.step}
      />
    </div>
  );
}

const useStyles = createStyles(theme => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lineTitle: {
    display: 'flex',
    gap: '1rem',
  },
  questionIcon: {
    cursor: 'pointer',
  },
  questionDropdown: {
    position: 'absolute',
    transform: 'translate(4px, -4px)',
    color: theme.black,
  },
  inputAndCurrency: {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
  nativeSelect: {
    borderBottom: 'solid 1px',
    width: '4.5rem',
    height: '2.5rem',
  },
  nativeSelectInput: {
    transform: 'translateY(4px)',
  },
  slider: {
    margin: '1rem',
  },
  numberInput: {
    borderBottom: 'solid 1px',
    height: '2.5rem',
  },
  smallInput: {
    width: '2.5rem',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
}));
