import { ContactFull } from 'src/types/contact';
import Http, { HttpMutators } from './http';

export default {
  postSendContact: (data: ContactFull, mutators: HttpMutators) =>
    Http.post(
      {
        url: '/contact/PostSendContact',
        data,
      },
      mutators,
    ),
};
