import classnames from 'classnames';
import {createStyles, HoverCard, NumberInput} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {OptionalHypothesesFields, HypothesesFields, PropertyDetailsProps} from 'src/types/locationDetails';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ChartMeta, ChartType, ChartTypeRegistry, BarProps, TooltipItem, ScriptableScaleContext} from 'chart.js';
import Transaction from 'src/services/transaction';
import ChartDataLabels, {Context} from 'chartjs-plugin-datalabels';
import {splitStringInArray} from './SlideBudget';
import {DeepPartial} from 'chart.js/types/utils';
import {FaPencilAlt} from 'react-icons/fa';
import * as SlideInvestorData from './SlideInvestorData';


ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, ChartDataLabels);

type SlideprofitProps = {
  propertyDetails: PropertyDetailsProps;
  hypotheses: HypothesesFields;
  updateHypotheses: (value: OptionalHypothesesFields) => void;
};

function getHeight(context: Context): number[] {
  try {
    let chart = context.chart;
    let meta: ChartMeta = chart.getDatasetMeta(context.datasetIndex);
    let model: DeepPartial<{[key in ChartType]: ChartTypeRegistry[key]['metaExtensions']}['bar']> = meta.data[context.dataIndex];
    let height = (model as BarProps).height;
    let y = (model as BarProps).y;
    let yTotal = chart.height;
    return [height, y, yTotal];

  } catch {
    return [0, 0, 0];
  }
}

export default function SlideProfit(props: SlideprofitProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = props.propertyDetails.property;
  const isBackgroundLight = 1;

  const color = isBackgroundLight ? 'white' : 'black';

  let getCurrentYear = SlideInvestorData.getCurrentYear;
  let getResalePrice = SlideInvestorData.getResalePrice;
  let getTax = SlideInvestorData.getTax;
  let getCapitalToRepay = SlideInvestorData.getCapitalToRepay;
  let getInterestToRepay = SlideInvestorData.getInterestToRepay;
  let getInitialSupply = SlideInvestorData.getInitialSupply;
  let getProfit = SlideInvestorData.getProfit;
  let getTreasury = SlideInvestorData.getTreasury;
  let getProfitabilityOfInvestedCapitals = SlideInvestorData.getProfitabilityOfInvestedCapitals;

  const data = {
    labels: [
      t('achat.profit.resalePrice'),
      t('achat.profit.tax'),
      t('achat.profit.capitalToRepay'),
      t('achat.profit.interestToRepay'),
      t('achat.profit.initialSupply'),
      t('achat.profit.treasury'),
      t('achat.profit.profit'),
    ],
    datasets: [
      {
        data: [
          getResalePrice(props.hypotheses),
          getTax(props.hypotheses, property) ? [getResalePrice(props.hypotheses) + getTax(props.hypotheses, property), getResalePrice(props.hypotheses)] : null,
          getCapitalToRepay(props.hypotheses, property)
            ? [
                getResalePrice(props.hypotheses) + getTax(props.hypotheses, property) + getCapitalToRepay(props.hypotheses, property),
                getResalePrice(props.hypotheses) + getTax(props.hypotheses, property),
              ]
            : null,
          getInterestToRepay(props.hypotheses, property)
            ? [
                getResalePrice(props.hypotheses) +
                  getTax(props.hypotheses, property) +
                  getCapitalToRepay(props.hypotheses, property) +
                  getInterestToRepay(props.hypotheses, property),
                getResalePrice(props.hypotheses) + getTax(props.hypotheses, property) + getCapitalToRepay(props.hypotheses, property),
              ]
            : null,
          getInitialSupply(props.hypotheses)
            ? [
                getResalePrice(props.hypotheses) +
                  getTax(props.hypotheses, property) +
                  getCapitalToRepay(props.hypotheses, property) +
                  getInterestToRepay(props.hypotheses, property) +
                  getInitialSupply(props.hypotheses),
                getResalePrice(props.hypotheses) +
                  getTax(props.hypotheses, property) +
                  getCapitalToRepay(props.hypotheses, property) +
                  getInterestToRepay(props.hypotheses, property),
              ]
            : null,
          [
            getResalePrice(props.hypotheses) +
              getTax(props.hypotheses, property) +
              getCapitalToRepay(props.hypotheses, property) +
              getInterestToRepay(props.hypotheses, property) +
              getInitialSupply(props.hypotheses) +
              getTreasury(props.hypotheses, property),
            getResalePrice(props.hypotheses) +
              getTax(props.hypotheses, property) +
              getCapitalToRepay(props.hypotheses, property) +
              getInterestToRepay(props.hypotheses, property) +
              getInitialSupply(props.hypotheses),
          ],
          getProfit(props.hypotheses, property),
        ],
        backgroundColor: ['#F94A46', 'grey', 'grey', 'grey', 'grey', getTreasury(props.hypotheses, property) > 0 ? 'green' : 'grey', '#F94A46'],
      },
    ],
  };

  const ExplanationLabel = [
    splitStringInArray(t('achat.profit.resalePriceExplanation')),
    splitStringInArray(t('achat.profit.taxExplanation')),
    splitStringInArray(t('achat.profit.capitalToRepayExplanation')),
    splitStringInArray(t('achat.profit.interestToRepayExplanation')),
    splitStringInArray(t('achat.profit.initialSupplyExplanation')),
    splitStringInArray(t('achat.profit.treasuryExplanation')),
    splitStringInArray(t('achat.profit.profitExplanation')),
  ];

  // clean null values : remove labels and colors associated with null values
  let labels = data.labels;
  let dataValues = data.datasets[0].data;
  let colors = data.datasets[0].backgroundColor;
  for (let i = 0; i <= dataValues.length; i++) {
    if (dataValues[i] === null) {
      dataValues.splice(i, 1);
      labels.splice(i, 1);
      colors.splice(i, 1);
      ExplanationLabel.splice(i, 1);
      i--;
    }
  }

  function getAmount(value: number | number[]): number {
    let amount = 0;
    if (value !== undefined && value !== null) {
      if (!(typeof value).localeCompare('number')) {
        amount = value as number;
      } else {
        amount = (value as number[])[0] - (value as number[])[1];
      }
    }
    return amount;
  }

  let options = {
    indexAxis: 'x' as const,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#FFF',
        titleColor: '#000',
        titleFont: {size: 16},
        bodyColor: '#000',
        bodyFont: {size: 14},
        displayColors: false,
        callbacks: {
          title: (context: TooltipItem<'bar'>[]) => {
            return `${context[0].label}  : ${Transaction.formatNumber(
              Math.round(getAmount(context[0].dataset.data[context[0].dataIndex])),
              t('transaction.formatNumber'),
            )}${Transaction.currency(property.priceAskingPriceCurrency)}`;
          },
          label: (context: TooltipItem<'bar'>) => {
            return ExplanationLabel[context.dataIndex];
          },
        },
      },
      datalabels: {
        color: function (context: Context) {
          return color;
        },
        formatter: function (value: number | number[], context: Context) {
          return `${Transaction.formatNumber(Math.round(getAmount(value)), t('transaction.formatNumber'))} ${Transaction.currency(property.priceAskingPriceCurrency)}`;
        },
        align: function (context: Context) {
          return Math.abs(getHeight(context)[0]) < 40 ? (getHeight(context)[1] > getHeight(context)[2] / 2 ? 'end' : 'start') : 'center';
        },
        offset: function (context: Context) {
          return Math.abs(getHeight(context)[0]) < 40 ? 20 : 0;
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: color,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false,
          color: function (context: ScriptableScaleContext) {
            if (context.tick ? context.tick.value : true) {
              return '';
            } else {
              return color;
            }
          },
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;
  let backgroundShadeCard = isBackgroundLight ? classes.backgroundDark : classes.backgroundLight;
  let backgroundShadeCardObject = isBackgroundLight ? classes.backgroundDarkObject : classes.backgroundLightObject;
  let backgroundShadeCardObjectInside = isBackgroundLight ? classes.backgroundLightObject : classes.backgroundDarkObject;

  return (
    <div className={classnames(classes.profitPage, backgroundShade)}>
      <div className={classnames(classes.profitCardLeft, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.profitTitle}>
          {t('achat.profit.slideTitle')}
          <NumberInput
            value={props.hypotheses.resaleYear}
            size="md"
            onChange={val => {
              if (val) {
                props.updateHypotheses({resaleYear: val});
              }
            }}
            min={getCurrentYear() + 1}
            variant="unstyled"
            hideControls
            classNames={{wrapper: classes.numberInput, input: classnames(classes.numberInputInput, backgroundShadeCard)}}
            parser={value => value?.replace(/\s?|(,*)/g, '')}
            formatter={value => {
              return !Number.isNaN(parseFloat(value as string)) ? Transaction.formatNumber(parseFloat(value as string), t('transaction.formatNumber')) : '';
            }}
          />
          <FaPencilAlt />
        </div>
        <hr className={classes.profitSeparator} />
        <Bar data={data} options={options} />
      </div>
      <div className={classnames(classes.profitCardRight, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.summaryValues}>
          <div className={classes.summaryValuesSection}>
            {Transaction.formatNumber(Math.round(getProfit(props.hypotheses, property)), t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
            <HoverCard withArrow={true} width="280px" shadow="md">
              <HoverCard.Target>
                <div className={classnames(classes.buttonStyle, backgroundShade, backgroundShadeCardObjectInside)}>{t('achat.profit.profit')}</div>
              </HoverCard.Target>
              <HoverCard.Dropdown className={classnames(classes.questionDropdown)}>
                <div>{t('achat.profit.profitExplanation')}</div>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
          <div className={classes.summaryValuesSection}>
            {Transaction.formatNumber(getProfitabilityOfInvestedCapitals(props.hypotheses, property), t('transaction.formatNumber'))}%
            <HoverCard withArrow={true} width="280px" shadow="md">
              <HoverCard.Target>
                <div className={classnames(classes.buttonStyle, backgroundShade, backgroundShadeCardObjectInside)}>{t('achat.profit.profitabilityOfInvestedCapitals')}</div>
              </HoverCard.Target>
              <HoverCard.Dropdown className={classnames(classes.questionDropdown)}>
                <div>{t('achat.profit.profitabilityOfInvestedCapitalsExplanation')}</div>
              </HoverCard.Dropdown>
            </HoverCard>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  profitPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    columnGap: '2rem',
  },
  profitCardLeft: {
    overflow: 'hidden',
    borderRadius: '1rem',
    flex: '0 0 650px',
    padding: '1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
  },
  profitCardRight: {
    overflow: 'hidden',
    borderRadius: '1rem',
    flex: '0 0 200px',
    padding: '1rem',
  },
  profitTitle: {
    fontWeight: 700,
    fontSize: '1.7rem',
    display: 'flex',
    gap: '.8rem',
  },
  profitSeparator: {
    borderColor: 'solid 0.1px grey',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  },
  buttonStyle: {
    display: 'flex',
    borderRadius: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 2.5rem',
    width: '100%',
    height: '5rem',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  summaryValues: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
    fontSize: '2.5rem',
    fontWeight: 700,
    justifyContent: 'center',
    marginBottom: '1rem',
    boxSizing: 'border-box',
  },
  summaryValuesSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
  },
  questionDropdown: {
    fontWeight: 400,
    fontSize: '1rem',
    color: theme.black,
  },
  numberInput: {
    borderBottom: 'solid 1px',
    height: '2.2rem',
    width: '4.5rem',
    transform: 'translateY(-6px)',
  },
  numberInputInput: {
    fontWeight: 700,
    fontSize: '1.5rem',
  },
}));
