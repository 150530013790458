import {IconProps} from 'src/types/icon';

export const Immeuble = (props: IconProps): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 767 581" fill="none" {...props}>
    <g clipPath="url(#clip0_34_118)">
      <path
        d="M687.974 322.819C704.756 326.074 718.718 340.658 721.247 357.579C722.487 365.927 721.214 374.726 724.081 382.671C727.109 391.051 734.259 397.158 739.38 404.458C749.043 418.269 751.008 437.14 744.405 452.643C761.879 458.476 770.736 480.312 765.502 497.991C760.268 515.669 743.858 528.465 726.062 533.267C708.266 538.069 689.311 535.926 671.403 531.543"
        fill="#EBEBEB"
      />
      <path
        d="M704.949 579.969C704.723 579.969 704.53 552.009 704.53 517.506C704.53 483.004 704.723 455.044 704.949 455.044C705.174 455.044 705.367 483.004 705.367 517.506C705.367 552.009 705.174 579.969 704.949 579.969Z"
        fill="#E0E0E0"
      />
      <path
        d="M723.55 465.599C723.727 465.76 719.862 470.402 714.918 475.994C709.973 481.586 705.834 485.985 705.657 485.824C705.48 485.663 709.345 481.022 714.289 475.43C719.234 469.838 723.372 465.438 723.55 465.599Z"
        fill="#E0E0E0"
      />
      <path
        d="M439.093 144.344H550.618C550.618 144.344 540.762 126.391 512.047 129.002C484.637 131.5 483.236 120.171 469.289 118.769C455.343 117.367 443.731 128.067 439.093 144.344Z"
        fill="#F5F5F5"
      />
      <path
        d="M632.349 131.5H743.874C743.874 131.5 734.018 113.547 705.303 116.158C677.893 118.656 676.492 107.327 662.545 105.925C648.599 104.523 636.987 115.223 632.349 131.5Z"
        fill="#F5F5F5"
      />
      <path
        d="M40.5193 166.035H126.502C126.502 166.035 118.901 152.192 96.7728 154.206C75.6435 156.124 74.5645 147.389 63.8066 146.326C53.0648 145.246 44.1106 153.497 40.5193 166.035Z"
        fill="#EBEBEB"
      />
      <path
        d="M121.88 112.355H220.972C220.972 112.355 212.211 96.4009 186.701 98.7215C162.335 100.945 161.111 90.8734 148.71 89.6325C136.326 88.3917 126.003 97.8996 121.88 112.355Z"
        fill="#F5F5F5"
      />
      <path
        d="M93.5519 285.271C73.566 282.869 53.0326 297.873 49.2158 317.646C46.945 329.458 49.8278 342.528 43.9657 353.019C40.6964 358.869 35.1403 363.01 30.5183 367.861C16.4106 382.703 11.4182 405.57 18.0211 424.957C8.32608 428.132 1.67486 437.849 0.289863 447.953C-1.07903 458.074 2.17411 468.371 7.42422 477.122C17.7795 494.381 36.2193 506.548 56.1569 509.271C76.0944 511.995 97.111 505.21 111.702 491.351"
        fill="#E0E0E0"
      />
      <path
        d="M61.2782 580.275C61.0527 580.275 60.8594 541.824 60.8594 494.397C60.8594 446.97 61.0527 408.503 61.2782 408.503C61.5036 408.503 61.6969 446.954 61.6969 494.397C61.6969 541.84 61.5036 580.275 61.2782 580.275Z"
        fill="#EBEBEB"
      />
      <path
        d="M61.2137 471.53C61.0205 471.659 57.0909 466.131 52.4528 459.185C47.7986 452.24 44.1911 446.503 44.3844 446.374C44.5776 446.245 48.5072 451.772 53.1453 458.718C57.7995 465.664 61.407 471.401 61.2137 471.53Z"
        fill="#EBEBEB"
      />
      <path
        d="M352.611 80.6564H493.301C493.301 80.6564 480.869 57.9985 444.649 61.3021C410.057 64.4445 408.301 50.1504 390.715 48.3938C373.129 46.6373 358.473 60.1257 352.611 80.6564Z"
        fill="#EBEBEB"
      />
      <path
        d="M746.917 579.969C746.917 580.194 582.022 580.388 378.652 580.388C175.283 580.388 10.3553 580.194 10.3553 579.969C10.3553 579.743 175.219 579.55 378.652 579.55C582.086 579.55 746.917 579.743 746.917 579.969Z"
        fill="#263238"
      />
      <path d="M299.24 100.929H210.568V136.753H299.24V100.929Z" fill="#E8505B" />
      <g opacity="0.7">
        <path d="M299.24 100.929H210.568V136.753H299.24V100.929Z" fill="black" />
      </g>
      <path d="M331.015 100.929H242.343V136.753H331.015V100.929Z" fill="#E8505B" />
      <g opacity="0.4">
        <path d="M331.015 100.929H242.343V136.753H331.015V100.929Z" fill="black" />
      </g>
      <path d="M302.252 135.077H140.883V580.275H302.252V135.077Z" fill="#E8505B" />
      <g opacity="0.7">
        <path d="M201.968 135.077H140.883V580.275H201.968V135.077Z" fill="black" />
      </g>
      <path d="M406.208 135.077H244.839V580.275H406.208V135.077Z" fill="#E8505B" />
      <g opacity="0.4">
        <path d="M406.208 135.077H201.968V580.275H406.208V135.077Z" fill="black" />
      </g>
      <path
        d="M338.359 135.19C338.359 135.416 309.644 135.609 274.23 135.609C238.816 135.609 210.085 135.416 210.085 135.19C210.085 134.964 238.8 134.771 274.23 134.771C309.66 134.771 338.359 134.964 338.359 135.19Z"
        fill="#263238"
      />
      <path d="M285.584 166.115H231.247V292.571H285.584V166.115Z" fill="#FAFAFA" />
      <path
        d="M285.584 292.571C285.584 292.571 285.568 292.345 285.552 291.926C285.552 291.491 285.552 290.879 285.519 290.089C285.519 288.461 285.487 286.092 285.471 283.047C285.439 276.891 285.407 267.963 285.358 256.747C285.31 234.314 285.246 202.761 285.165 166.099L285.568 166.502C268.674 166.502 250.298 166.502 231.231 166.518L231.649 166.099C231.601 215.363 231.553 260.034 231.537 292.555L231.231 292.248C247.963 292.313 261.507 292.377 270.896 292.41C275.583 292.442 279.222 292.474 281.735 292.49C282.959 292.49 283.909 292.522 284.569 292.522C285.213 292.522 285.552 292.555 285.552 292.555C285.552 292.555 285.246 292.571 284.617 292.587C283.957 292.587 283.039 292.603 281.831 292.619C279.335 292.635 275.712 292.667 271.057 292.7C261.62 292.732 248.012 292.796 231.198 292.861H230.892V292.555C230.86 260.018 230.812 215.363 230.78 166.099V165.68H231.198C250.266 165.68 268.642 165.68 285.535 165.696H285.938V166.099C285.858 202.874 285.793 234.524 285.745 257.021C285.696 268.189 285.664 277.084 285.632 283.224C285.616 286.238 285.6 288.574 285.584 290.186C285.584 290.943 285.568 291.539 285.552 291.975C285.552 292.377 285.519 292.571 285.519 292.571H285.584Z"
        fill="#263238"
      />
      <path
        d="M286.453 191.98C286.453 192.206 273.924 192.399 258.48 192.399C243.035 192.399 230.49 192.206 230.49 191.98C230.49 191.754 243.019 191.561 258.48 191.561C273.94 191.561 286.453 191.754 286.453 191.98Z"
        fill="#263238"
      />
      <path
        d="M258.995 291.604C258.77 291.604 258.576 269.381 258.576 241.969C258.576 214.557 258.77 192.334 258.995 192.334C259.22 192.334 259.414 214.557 259.414 241.969C259.414 269.381 259.22 291.604 258.995 291.604Z"
        fill="#263238"
      />
      <path d="M383.597 166.115H329.26V292.571H383.597V166.115Z" fill="#FAFAFA" />
      <path
        d="M383.613 292.571C383.613 292.571 383.597 292.345 383.58 291.926C383.58 291.491 383.581 290.879 383.548 290.089C383.548 288.461 383.516 286.092 383.5 283.047C383.468 276.891 383.436 267.963 383.387 256.747C383.339 234.314 383.275 202.761 383.194 166.099L383.597 166.502C366.703 166.502 348.327 166.502 329.26 166.518L329.678 166.099C329.63 215.363 329.582 260.034 329.566 292.555L329.26 292.248C345.992 292.313 359.536 292.377 368.925 292.41C373.612 292.442 377.251 292.474 379.764 292.49C380.988 292.49 381.938 292.522 382.598 292.522C383.242 292.522 383.58 292.555 383.58 292.555C383.58 292.555 383.275 292.571 382.646 292.587C381.986 292.587 381.068 292.603 379.86 292.619C377.364 292.635 373.741 292.667 369.086 292.7C359.649 292.732 346.041 292.796 329.227 292.861H328.921V292.555C328.889 260.018 328.841 215.363 328.809 166.099V165.68H329.227C348.295 165.68 366.671 165.68 383.564 165.696H383.967V166.099C383.886 202.874 383.822 234.524 383.774 257.021C383.725 268.189 383.693 277.084 383.661 283.224C383.645 286.238 383.629 288.574 383.613 290.186C383.613 290.943 383.597 291.539 383.58 291.975C383.58 292.377 383.548 292.571 383.548 292.571H383.613Z"
        fill="#263238"
      />
      <path
        d="M384.466 191.98C384.466 192.206 371.937 192.399 356.493 192.399C341.048 192.399 328.503 192.206 328.503 191.98C328.503 191.754 341.032 191.561 356.493 191.561C371.953 191.561 384.466 191.754 384.466 191.98Z"
        fill="#263238"
      />
      <path
        d="M357.008 291.604C356.782 291.604 356.589 269.381 356.589 241.969C356.589 214.557 356.782 192.334 357.008 192.334C357.233 192.334 357.427 214.557 357.427 241.969C357.427 269.381 357.233 291.604 357.008 291.604Z"
        fill="#263238"
      />
      <path d="M285.584 320.079H231.247V446.535H285.584V320.079Z" fill="#FAFAFA" />
      <path
        d="M285.584 446.535C285.584 446.535 285.568 446.309 285.552 445.89C285.552 445.455 285.552 444.843 285.519 444.053C285.519 442.426 285.487 440.057 285.471 437.011C285.439 430.855 285.407 421.927 285.358 410.711C285.31 388.279 285.246 356.725 285.165 320.063L285.568 320.466C268.674 320.466 250.298 320.466 231.231 320.482L231.649 320.063C231.601 369.327 231.553 413.999 231.537 446.519L231.231 446.213C247.963 446.277 261.507 446.342 270.896 446.374C275.583 446.406 279.222 446.438 281.735 446.454C282.959 446.454 283.909 446.487 284.569 446.487C285.213 446.487 285.552 446.519 285.552 446.519C285.552 446.519 285.246 446.535 284.617 446.551C283.957 446.551 283.039 446.567 281.831 446.583C279.335 446.6 275.712 446.632 271.057 446.664C261.62 446.696 248.012 446.761 231.198 446.825H230.892V446.519C230.86 413.982 230.812 369.327 230.78 320.063V319.644H231.198C250.266 319.644 268.642 319.644 285.535 319.66H285.938V320.063C285.858 356.838 285.793 388.488 285.745 410.985C285.696 422.153 285.664 431.048 285.632 437.188C285.616 440.202 285.6 442.538 285.584 444.15C285.584 444.907 285.568 445.504 285.552 445.939C285.552 446.342 285.519 446.535 285.519 446.535H285.584Z"
        fill="#263238"
      />
      <path
        d="M286.453 345.944C286.453 346.17 273.924 346.363 258.48 346.363C243.035 346.363 230.49 346.17 230.49 345.944C230.49 345.719 243.019 345.525 258.48 345.525C273.94 345.525 286.453 345.719 286.453 345.944Z"
        fill="#263238"
      />
      <path
        d="M258.995 445.568C258.77 445.568 258.576 423.345 258.576 395.933C258.576 368.522 258.77 346.299 258.995 346.299C259.22 346.299 259.414 368.522 259.414 395.933C259.414 423.345 259.22 445.568 258.995 445.568Z"
        fill="#263238"
      />
      <path d="M383.597 320.079H329.26V446.535H383.597V320.079Z" fill="#FAFAFA" />
      <path
        d="M383.613 446.535C383.613 446.535 383.597 446.309 383.58 445.89C383.58 445.455 383.581 444.843 383.548 444.053C383.548 442.426 383.516 440.057 383.5 437.011C383.468 430.855 383.436 421.927 383.387 410.711C383.339 388.279 383.275 356.725 383.194 320.063L383.597 320.466C366.703 320.466 348.327 320.466 329.26 320.482L329.678 320.063C329.63 369.327 329.582 413.999 329.566 446.519L329.26 446.213C345.992 446.277 359.536 446.342 368.925 446.374C373.612 446.406 377.251 446.438 379.764 446.454C380.988 446.454 381.938 446.487 382.598 446.487C383.242 446.487 383.58 446.519 383.58 446.519C383.58 446.519 383.275 446.535 382.646 446.551C381.986 446.551 381.068 446.567 379.86 446.583C377.364 446.6 373.741 446.632 369.086 446.664C359.649 446.696 346.041 446.761 329.227 446.825H328.921V446.519C328.889 413.982 328.841 369.327 328.809 320.063V319.644H329.227C348.295 319.644 366.671 319.644 383.564 319.66H383.967V320.063C383.886 356.838 383.822 388.488 383.774 410.985C383.725 422.153 383.693 431.048 383.661 437.188C383.645 440.202 383.629 442.538 383.613 444.15C383.613 444.907 383.597 445.504 383.58 445.939C383.58 446.342 383.548 446.535 383.548 446.535H383.613Z"
        fill="#263238"
      />
      <path
        d="M384.466 345.944C384.466 346.17 371.937 346.363 356.493 346.363C341.048 346.363 328.503 346.17 328.503 345.944C328.503 345.719 341.032 345.525 356.493 345.525C371.953 345.525 384.466 345.719 384.466 345.944Z"
        fill="#263238"
      />
      <path
        d="M357.008 445.568C356.782 445.568 356.589 423.345 356.589 395.933C356.589 368.522 356.782 346.299 357.008 346.299C357.233 346.299 357.427 368.522 357.427 395.933C357.427 423.345 357.233 445.568 357.008 445.568Z"
        fill="#263238"
      />
      <path
        d="M308.903 580.275C308.903 580.275 308.903 580.098 308.887 579.759C308.887 579.405 308.887 578.921 308.871 578.293C308.871 576.971 308.839 575.102 308.823 572.685C308.791 567.769 308.758 560.679 308.71 551.751C308.662 533.879 308.597 508.724 308.517 479.394L308.919 479.797C293.974 479.797 277.644 479.797 260.654 479.813C260.541 479.893 261.443 479.039 261.072 479.41V481.215V483.036C261.072 484.245 261.072 485.453 261.072 486.662C261.072 489.079 261.072 491.464 261.072 493.849C261.072 498.603 261.072 503.309 261.072 507.918C261.072 517.152 261.072 526.08 261.056 534.604C261.024 551.654 260.992 567.109 260.976 580.259L260.67 579.953C275.502 580.017 287.532 580.081 295.891 580.114C300.046 580.146 303.283 580.178 305.537 580.194C306.616 580.194 307.454 580.226 308.066 580.226C308.646 580.226 308.952 580.259 308.952 580.259C308.952 580.259 308.678 580.275 308.13 580.291C307.534 580.291 306.713 580.307 305.666 580.323C303.444 580.339 300.223 580.372 296.084 580.404C287.677 580.436 275.583 580.5 260.686 580.565H260.38V580.259C260.364 567.109 260.332 551.654 260.299 534.604C260.299 526.08 260.299 517.152 260.283 507.918C260.283 503.293 260.283 498.603 260.283 493.849C260.283 491.464 260.283 489.079 260.283 486.662C260.283 485.453 260.283 484.245 260.283 483.036V481.215V480.296V479.41C259.913 479.781 260.783 478.878 260.702 478.975C277.692 478.975 294.023 478.975 308.968 478.991H309.37V479.394C309.29 508.82 309.225 534.073 309.177 552.009C309.129 560.888 309.097 567.963 309.064 572.862C309.048 575.247 309.032 577.1 309.016 578.389C309.016 578.986 309 579.453 309 579.808C309 580.13 309 580.275 308.984 580.275H308.903Z"
        fill="#263238"
      />
      <path
        d="M357.169 580.275C357.169 580.275 357.169 580.098 357.153 579.759C357.153 579.405 357.153 578.921 357.137 578.293C357.137 576.971 357.104 575.102 357.088 572.685C357.056 567.769 357.024 560.679 356.976 551.751C356.927 533.879 356.863 508.724 356.782 479.394L357.185 479.797C342.24 479.797 325.91 479.797 308.919 479.813C308.807 479.893 309.709 479.039 309.338 479.41V483.036C309.338 484.245 309.338 485.453 309.338 486.662C309.338 489.079 309.338 491.464 309.338 493.849C309.338 498.603 309.338 503.309 309.338 507.918C309.338 517.152 309.338 526.08 309.322 534.604C309.29 551.654 309.258 567.109 309.241 580.259L308.935 579.953C323.768 580.017 335.798 580.081 344.156 580.114C348.311 580.146 351.548 580.178 353.803 580.194C354.882 580.194 355.719 580.226 356.331 580.226C356.911 580.226 357.217 580.259 357.217 580.259C357.217 580.259 356.943 580.275 356.396 580.291C355.8 580.291 354.979 580.307 353.932 580.323C351.709 580.339 348.489 580.372 344.35 580.404C335.943 580.436 323.864 580.5 308.952 580.565H308.646V580.259C308.629 567.109 308.597 551.654 308.565 534.604C308.565 526.08 308.565 517.152 308.549 507.918C308.549 503.293 308.549 498.603 308.549 493.849C308.549 491.464 308.549 489.079 308.549 486.662C308.549 485.453 308.549 484.245 308.549 483.036V481.215V480.296V479.41C308.179 479.781 309.048 478.878 308.968 478.975C325.958 478.975 342.288 478.975 357.233 478.991H357.636V479.394C357.555 508.82 357.491 534.073 357.443 552.009C357.394 560.888 357.362 567.963 357.33 572.862C357.314 575.247 357.298 577.1 357.282 578.389C357.282 578.986 357.266 579.453 357.266 579.808C357.266 580.13 357.266 580.275 357.233 580.275H357.169Z"
        fill="#263238"
      />
      <path
        d="M315.232 528.158C315.152 528.094 315.86 527.514 317.036 528.013C317.584 528.239 318.212 528.867 318.244 529.754C318.292 530.592 317.825 531.591 316.827 531.962C315.796 532.284 314.814 531.768 314.363 531.043C313.864 530.286 314.121 529.351 314.524 528.916C314.926 528.432 315.426 528.255 315.764 528.255C316.102 528.255 316.263 528.352 316.247 528.4C316.247 528.513 315.506 528.465 315.007 529.27C314.765 529.657 314.685 530.189 315.007 530.624C315.313 531.059 315.989 531.365 316.553 531.172C317.117 530.979 317.471 530.334 317.471 529.786C317.487 529.238 317.149 528.787 316.762 528.545C315.957 528.046 315.265 528.303 315.249 528.174L315.232 528.158Z"
        fill="#263238"
      />
      <path
        d="M298.194 528.158C298.113 528.094 298.822 527.514 299.997 528.013C300.545 528.239 301.173 528.867 301.205 529.754C301.254 530.592 300.787 531.591 299.788 531.962C298.757 532.284 297.775 531.768 297.324 531.043C296.825 530.286 297.082 529.351 297.485 528.916C297.888 528.432 298.387 528.255 298.725 528.255C299.063 528.255 299.224 528.352 299.208 528.4C299.208 528.513 298.467 528.465 297.968 529.27C297.727 529.657 297.646 530.189 297.968 530.624C298.274 531.059 298.951 531.365 299.514 531.172C300.078 530.979 300.432 530.334 300.432 529.786C300.448 529.238 300.11 528.787 299.724 528.545C298.918 528.046 298.226 528.303 298.21 528.174L298.194 528.158Z"
        fill="#263238"
      />
      <path d="M644.25 82.558H526.638V580.243H644.25V82.558Z" fill="#E8505B" />
      <path d="M584.309 117.447H548.557V186.372H584.309V117.447Z" fill="#E0E0E0" />
      <path
        d="M567.447 186.372C567.222 186.372 567.029 170.934 567.029 151.902C567.029 132.87 567.222 117.431 567.447 117.431C567.673 117.431 567.866 132.853 567.866 151.902C567.866 170.95 567.673 186.372 567.447 186.372Z"
        fill="#E8505B"
      />
      <path
        d="M584.309 141.394C584.309 141.62 576.305 141.813 566.433 141.813C556.561 141.813 548.557 141.62 548.557 141.394C548.557 141.169 556.561 140.975 566.433 140.975C576.305 140.975 584.309 141.169 584.309 141.394Z"
        fill="#E8505B"
      />
      <path
        d="M584.502 166.035C584.502 166.26 576.434 166.454 566.481 166.454C556.528 166.454 548.46 166.26 548.46 166.035C548.46 165.809 556.528 165.616 566.481 165.616C576.434 165.616 584.502 165.809 584.502 166.035Z"
        fill="#E8505B"
      />
      <path d="M694.271 82.558H608.579V580.243H694.271V82.558Z" fill="#E8505B" />
      <path opacity="0.1" d="M694.271 580.243L608.273 579.969V83.8633H693.965L694.271 580.243Z" fill="black" />
      <path d="M668.794 117.447H633.041V186.372H668.794V117.447Z" fill="#E0E0E0" />
      <path
        d="M651.916 186.372C651.691 186.372 651.497 170.934 651.497 151.902C651.497 132.87 651.691 117.431 651.916 117.431C652.142 117.431 652.335 132.853 652.335 151.902C652.335 170.95 652.142 186.372 651.916 186.372Z"
        fill="#E8505B"
      />
      <path
        d="M668.794 141.394C668.794 141.62 660.79 141.813 650.918 141.813C641.046 141.813 633.041 141.62 633.041 141.394C633.041 141.169 641.046 140.975 650.918 140.975C660.79 140.975 668.794 141.169 668.794 141.394Z"
        fill="#E8505B"
      />
      <path
        d="M668.971 166.035C668.971 166.26 660.903 166.454 650.95 166.454C640.997 166.454 632.929 166.26 632.929 166.035C632.929 165.809 640.997 165.616 650.95 165.616C660.903 165.616 668.971 165.809 668.971 166.035Z"
        fill="#E8505B"
      />
      <path d="M668.794 202.245H633.041V271.17H668.794V202.245Z" fill="#E0E0E0" />
      <path
        d="M651.916 271.17C651.691 271.17 651.497 255.732 651.497 236.7C651.497 217.668 651.691 202.229 651.916 202.229C652.142 202.229 652.335 217.651 652.335 236.7C652.335 255.748 652.142 271.17 651.916 271.17Z"
        fill="#E8505B"
      />
      <path
        d="M668.794 226.192C668.794 226.418 660.79 226.611 650.918 226.611C641.046 226.611 633.041 226.418 633.041 226.192C633.041 225.967 641.046 225.773 650.918 225.773C660.79 225.773 668.794 225.967 668.794 226.192Z"
        fill="#E8505B"
      />
      <path
        d="M668.971 250.849C668.971 251.074 660.903 251.268 650.95 251.268C640.997 251.268 632.929 251.074 632.929 250.849C632.929 250.623 640.997 250.43 650.95 250.43C660.903 250.43 668.971 250.623 668.971 250.849Z"
        fill="#E8505B"
      />
      <path opacity="0.3" d="M607.918 82.558H526.638V580.243H607.918V82.558Z" fill="black" />
      <path
        d="M481.223 215.701C481.867 216.765 481.674 218.119 481.368 219.311C481.062 220.504 480.675 221.777 481.014 222.969C481.239 223.743 481.738 224.404 481.964 225.177C482.399 226.66 481.561 228.4 480.144 228.996C477.986 228.03 475.828 227.063 473.67 226.096C475.313 223.227 476.375 220.036 476.778 216.749"
        fill="#E8505B"
      />
      <g opacity="0.5">
        <path
          d="M481.223 215.701C481.867 216.765 481.674 218.119 481.368 219.311C481.062 220.504 480.675 221.777 481.014 222.969C481.239 223.743 481.738 224.404 481.964 225.177C482.399 226.66 481.561 228.4 480.144 228.996C477.986 228.03 475.828 227.063 473.67 226.096C475.313 223.227 476.375 220.036 476.778 216.749"
          fill="black"
        />
      </g>
      <path
        d="M464.361 214.203C466.278 214.654 463.089 217.893 463.234 219.843C463.395 221.809 463.669 223.872 462.88 225.677C459.031 225.725 455.166 224.855 451.703 223.147C452.605 219.311 453.491 215.476 454.393 211.657C454.393 211.657 462.654 213.816 464.361 214.219V214.203Z"
        fill="#E8505B"
      />
      <path d="M567.447 182.327H508.456V267.641H567.447V182.327Z" fill="#263238" />
      <path d="M620.512 182.327H561.521V267.641H620.512V182.327Z" fill="#455A64" />
      <path
        d="M606.404 250.414C606.404 250.414 606.372 250.075 606.356 249.447C606.356 248.77 606.324 247.867 606.308 246.739C606.276 244.322 606.243 240.922 606.195 236.651C606.147 228.013 606.082 215.927 606.002 201.81L606.388 202.197C598.127 202.197 589.076 202.213 579.622 202.229C579.526 202.326 580.412 201.44 580.041 201.826V203.551V205.275C580.041 206.419 580.041 207.563 580.041 208.707C580.041 210.98 580.041 213.22 580.041 215.444C580.041 219.859 580.041 224.146 580.025 228.255C579.993 236.458 579.977 243.951 579.944 250.43L579.622 250.107C587.755 250.172 594.406 250.236 599.077 250.285C601.348 250.317 603.151 250.349 604.456 250.365C605.035 250.365 605.503 250.397 605.873 250.397C606.195 250.397 606.372 250.414 606.388 250.43C606.388 250.43 606.243 250.446 605.953 250.462C605.599 250.462 605.148 250.478 604.585 250.494C603.296 250.51 601.525 250.542 599.27 250.575C594.535 250.623 587.836 250.671 579.606 250.752H579.284V250.43C579.268 243.951 579.236 236.458 579.204 228.255C579.204 224.146 579.204 219.875 579.188 215.444C579.188 213.236 579.188 210.996 579.188 208.707C579.188 207.563 579.188 206.419 579.188 205.275V203.551V202.68V201.842C578.817 202.197 579.703 201.311 579.606 201.391C589.06 201.391 598.111 201.407 606.372 201.423H606.759V201.81C606.678 216.04 606.614 228.223 606.565 236.925C606.517 241.147 606.485 244.531 606.453 246.917C606.437 248.012 606.421 248.883 606.404 249.543C606.404 250.14 606.372 250.43 606.356 250.414H606.404Z"
        fill="#263238"
      />
      <path
        d="M601.058 228.739C601.106 228.851 600.736 229.061 600.655 229.593C600.542 230.141 600.945 230.801 601.686 230.527C602.443 230.27 602.33 229.512 601.895 229.158C601.492 228.787 601.09 228.851 601.058 228.739C600.977 228.658 601.493 228.255 602.233 228.658C602.572 228.851 602.958 229.238 603.006 229.851C603.071 230.479 602.572 231.108 601.96 231.301C601.364 231.527 600.575 231.349 600.22 230.817C599.866 230.302 599.93 229.754 600.075 229.399C600.413 228.61 601.058 228.61 601.042 228.723L601.058 228.739Z"
        fill="#263238"
      />
      <path
        d="M681.243 252.573C681.243 252.573 681.211 252.202 681.194 251.461C681.194 250.687 681.162 249.592 681.146 248.174C681.13 245.224 681.098 240.922 681.05 235.346L681.243 235.539C656.232 235.668 603.876 235.765 543.451 235.765C483.027 235.765 427.756 235.668 403.405 235.523L403.582 235.346C403.55 240.197 403.518 243.951 403.502 246.514C403.486 247.755 403.47 248.705 403.454 249.382C403.454 250.027 403.405 250.349 403.405 250.349C403.405 250.349 403.373 250.027 403.357 249.382C403.357 248.705 403.325 247.755 403.309 246.514C403.293 243.951 403.26 240.197 403.228 235.346V235.169H403.405C427.756 235.024 481.32 234.927 543.451 234.927C605.583 234.927 656.248 235.024 681.243 235.152H681.436V235.346C681.404 240.938 681.372 245.241 681.339 248.174C681.323 249.592 681.307 250.687 681.291 251.461C681.275 252.202 681.243 252.573 681.243 252.573Z"
        fill="#263238"
      />
      <path
        d="M412.311 252.073C412.086 252.073 411.892 248.399 411.892 243.871C411.892 239.342 412.086 235.668 412.311 235.668C412.537 235.668 412.73 239.342 412.73 243.871C412.73 248.399 412.537 252.073 412.311 252.073Z"
        fill="#263238"
      />
      <path
        d="M422.956 252.073C422.731 252.073 422.538 248.399 422.538 243.871C422.538 239.342 422.731 235.668 422.956 235.668C423.182 235.668 423.375 239.342 423.375 243.871C423.375 248.399 423.182 252.073 422.956 252.073Z"
        fill="#263238"
      />
      <path
        d="M444.231 252.073C444.231 252.073 444.182 251.719 444.15 251.042C444.134 250.285 444.102 249.285 444.07 248.061C444.037 245.369 443.989 241.631 443.941 237.022C443.909 227.578 443.86 214.59 443.812 200.021L444.231 200.44C442.54 200.44 440.752 200.457 438.948 200.473C437.112 200.473 435.293 200.489 433.585 200.505L434.004 200.086C433.956 214.638 433.908 227.611 433.875 237.038C433.827 241.647 433.779 245.386 433.746 248.061C433.714 249.285 433.698 250.285 433.666 251.042C433.634 251.719 433.618 252.073 433.585 252.073C433.553 252.073 433.537 251.719 433.505 251.042C433.489 250.285 433.457 249.285 433.424 248.061C433.392 245.369 433.344 241.631 433.296 237.038C433.263 227.611 433.215 214.638 433.167 200.086V199.667H433.585C435.293 199.667 437.112 199.651 438.948 199.635C440.752 199.635 442.54 199.619 444.231 199.602H444.649V200.021C444.601 214.59 444.553 227.578 444.52 237.022C444.472 241.631 444.424 245.369 444.392 248.061C444.359 249.302 444.343 250.285 444.311 251.042C444.279 251.719 444.263 252.073 444.231 252.073Z"
        fill="#263238"
      />
      <path
        d="M454.876 252.073C454.65 252.073 454.457 248.399 454.457 243.871C454.457 239.342 454.65 235.668 454.876 235.668C455.101 235.668 455.295 239.342 455.295 243.871C455.295 248.399 455.101 252.073 454.876 252.073Z"
        fill="#263238"
      />
      <path
        d="M465.505 252.073C465.279 252.073 465.086 248.399 465.086 243.871C465.086 239.342 465.279 235.668 465.505 235.668C465.73 235.668 465.924 239.342 465.924 243.871C465.924 248.399 465.73 252.073 465.505 252.073Z"
        fill="#263238"
      />
      <path
        d="M476.15 252.073C475.925 252.073 475.731 248.399 475.731 243.871C475.731 239.342 475.925 235.668 476.15 235.668C476.376 235.668 476.569 239.342 476.569 243.871C476.569 248.399 476.376 252.073 476.15 252.073Z"
        fill="#263238"
      />
      <path
        d="M486.779 252.073C486.554 252.073 486.36 248.399 486.36 243.871C486.36 239.342 486.554 235.668 486.779 235.668C487.005 235.668 487.198 239.342 487.198 243.871C487.198 248.399 487.005 252.073 486.779 252.073Z"
        fill="#263238"
      />
      <path
        d="M497.424 252.073C497.199 252.073 497.006 248.399 497.006 243.871C497.006 239.342 497.199 235.668 497.424 235.668C497.65 235.668 497.843 239.342 497.843 243.871C497.843 248.399 497.65 252.073 497.424 252.073Z"
        fill="#263238"
      />
      <path
        d="M508.069 252.073C507.844 252.073 507.651 248.399 507.651 243.871C507.651 239.342 507.844 235.668 508.069 235.668C508.295 235.668 508.488 239.342 508.488 243.871C508.488 248.399 508.295 252.073 508.069 252.073Z"
        fill="#263238"
      />
      <path
        d="M518.699 252.073C518.473 252.073 518.28 248.399 518.28 243.871C518.28 239.342 518.473 235.668 518.699 235.668C518.924 235.668 519.117 239.342 519.117 243.871C519.117 248.399 518.924 252.073 518.699 252.073Z"
        fill="#263238"
      />
      <path
        d="M529.344 252.073C529.118 252.073 528.925 248.399 528.925 243.871C528.925 239.342 529.118 235.668 529.344 235.668C529.569 235.668 529.762 239.342 529.762 243.871C529.762 248.399 529.569 252.073 529.344 252.073Z"
        fill="#263238"
      />
      <path
        d="M539.989 252.073C539.763 252.073 539.57 248.399 539.57 243.871C539.57 239.342 539.763 235.668 539.989 235.668C540.214 235.668 540.408 239.342 540.408 243.871C540.408 248.399 540.214 252.073 539.989 252.073Z"
        fill="#263238"
      />
      <path
        d="M550.618 252.073C550.392 252.073 550.199 248.399 550.199 243.871C550.199 239.342 550.392 235.668 550.618 235.668C550.843 235.668 551.037 239.342 551.037 243.871C551.037 248.399 550.843 252.073 550.618 252.073Z"
        fill="#263238"
      />
      <path
        d="M561.263 252.073C561.038 252.073 560.844 248.399 560.844 243.871C560.844 239.342 561.038 235.668 561.263 235.668C561.489 235.668 561.682 239.342 561.682 243.871C561.682 248.399 561.489 252.073 561.263 252.073Z"
        fill="#263238"
      />
      <path
        d="M571.892 252.073C571.667 252.073 571.473 248.399 571.473 243.871C571.473 239.342 571.667 235.668 571.892 235.668C572.118 235.668 572.311 239.342 572.311 243.871C572.311 248.399 572.118 252.073 571.892 252.073Z"
        fill="#263238"
      />
      <path
        d="M582.537 252.073C582.312 252.073 582.119 248.399 582.119 243.871C582.119 239.342 582.312 235.668 582.537 235.668C582.763 235.668 582.956 239.342 582.956 243.871C582.956 248.399 582.763 252.073 582.537 252.073Z"
        fill="#263238"
      />
      <path
        d="M593.182 252.073C592.957 252.073 592.764 248.399 592.764 243.871C592.764 239.342 592.957 235.668 593.182 235.668C593.408 235.668 593.601 239.342 593.601 243.871C593.601 248.399 593.408 252.073 593.182 252.073Z"
        fill="#263238"
      />
      <path
        d="M603.812 252.073C603.586 252.073 603.393 248.399 603.393 243.871C603.393 239.342 603.586 235.668 603.812 235.668C604.037 235.668 604.23 239.342 604.23 243.871C604.23 248.399 604.037 252.073 603.812 252.073Z"
        fill="#263238"
      />
      <path
        d="M614.457 252.073C614.231 252.073 614.038 248.399 614.038 243.871C614.038 239.342 614.231 235.668 614.457 235.668C614.682 235.668 614.875 239.342 614.875 243.871C614.875 248.399 614.682 252.073 614.457 252.073Z"
        fill="#263238"
      />
      <path
        d="M625.102 252.073C624.876 252.073 624.683 248.399 624.683 243.871C624.683 239.342 624.876 235.668 625.102 235.668C625.327 235.668 625.521 239.342 625.521 243.871C625.521 248.399 625.327 252.073 625.102 252.073Z"
        fill="#263238"
      />
      <path
        d="M635.731 252.073C635.506 252.073 635.312 248.399 635.312 243.871C635.312 239.342 635.506 235.668 635.731 235.668C635.956 235.668 636.15 239.342 636.15 243.871C636.15 248.399 635.956 252.073 635.731 252.073Z"
        fill="#263238"
      />
      <path
        d="M646.376 252.073C646.151 252.073 645.957 248.399 645.957 243.871C645.957 239.342 646.151 235.668 646.376 235.668C646.602 235.668 646.795 239.342 646.795 243.871C646.795 248.399 646.602 252.073 646.376 252.073Z"
        fill="#263238"
      />
      <path
        d="M657.005 252.073C656.78 252.073 656.587 248.399 656.587 243.871C656.587 239.342 656.78 235.668 657.005 235.668C657.231 235.668 657.424 239.342 657.424 243.871C657.424 248.399 657.231 252.073 657.005 252.073Z"
        fill="#263238"
      />
      <path
        d="M667.65 252.073C667.425 252.073 667.232 248.399 667.232 243.871C667.232 239.342 667.425 235.668 667.65 235.668C667.876 235.668 668.069 239.342 668.069 243.871C668.069 248.399 667.876 252.073 667.65 252.073Z"
        fill="#263238"
      />
      <path
        d="M678.296 252.073C678.07 252.073 677.877 248.399 677.877 243.871C677.877 239.342 678.07 235.668 678.296 235.668C678.521 235.668 678.714 239.342 678.714 243.871C678.714 248.399 678.521 252.073 678.296 252.073Z"
        fill="#263238"
      />
      <path
        d="M681.243 250.414C681.243 250.639 648.405 250.833 607.918 250.833C567.431 250.833 534.594 250.639 534.594 250.414C534.594 250.188 567.415 249.995 607.918 249.995C648.421 249.995 681.243 250.188 681.243 250.414Z"
        fill="#263238"
      />
      <path d="M681.243 250.414H436.645V580.307H681.243V250.414Z" fill="#455A64" />
      <path d="M534.594 250.414H403.405V580.307H534.594V250.414Z" fill="#263238" />
      <path d="M663.286 429.405H618.547V474.173H663.286V429.405Z" fill="#EBEBEB" />
      <path
        d="M642.64 478.669C642.414 478.669 642.221 467.098 642.221 452.836C642.221 438.574 642.414 427.003 642.64 427.003C642.865 427.003 643.059 438.574 643.059 452.836C643.059 467.098 642.865 478.669 642.64 478.669Z"
        fill="#455A64"
      />
      <path
        d="M671.693 444.424C671.693 444.65 658.68 444.843 642.64 444.843C626.6 444.843 613.587 444.65 613.587 444.424C613.587 444.198 626.6 444.005 642.64 444.005C658.68 444.005 671.693 444.198 671.693 444.424Z"
        fill="#455A64"
      />
      <path d="M599.479 429.405H554.741V474.173H599.479V429.405Z" fill="#EBEBEB" />
      <path
        d="M578.833 478.669C578.608 478.669 578.415 467.098 578.415 452.836C578.415 438.574 578.608 427.003 578.833 427.003C579.059 427.003 579.252 438.574 579.252 452.836C579.252 467.098 579.059 478.669 578.833 478.669Z"
        fill="#455A64"
      />
      <path
        d="M607.886 444.424C607.886 444.65 594.874 444.843 578.833 444.843C562.793 444.843 549.78 444.65 549.78 444.424C549.78 444.198 562.793 444.005 578.833 444.005C594.874 444.005 607.886 444.198 607.886 444.424Z"
        fill="#455A64"
      />
      <path d="M663.286 358.691H618.547V403.459H663.286V358.691Z" fill="#EBEBEB" />
      <path
        d="M642.64 407.939C642.414 407.939 642.221 396.369 642.221 382.107C642.221 367.845 642.414 356.274 642.64 356.274C642.865 356.274 643.059 367.845 643.059 382.107C643.059 396.369 642.865 407.939 642.64 407.939Z"
        fill="#455A64"
      />
      <path
        d="M671.693 373.695C671.693 373.92 658.68 374.114 642.64 374.114C626.6 374.114 613.587 373.92 613.587 373.695C613.587 373.469 626.6 373.276 642.64 373.276C658.68 373.276 671.693 373.469 671.693 373.695Z"
        fill="#455A64"
      />
      <path d="M599.479 358.691H554.741V403.459H599.479V358.691Z" fill="#EBEBEB" />
      <path
        d="M578.833 407.939C578.608 407.939 578.415 396.369 578.415 382.107C578.415 367.845 578.608 356.274 578.833 356.274C579.059 356.274 579.252 367.845 579.252 382.107C579.252 396.369 579.059 407.939 578.833 407.939Z"
        fill="#455A64"
      />
      <path
        d="M607.886 373.695C607.886 373.92 594.874 374.114 578.833 374.114C562.793 374.114 549.78 373.92 549.78 373.695C549.78 373.469 562.793 373.276 578.833 373.276C594.874 373.276 607.886 373.469 607.886 373.695Z"
        fill="#455A64"
      />
      <path d="M663.286 287.801H618.547V332.569H663.286V287.801Z" fill="#EBEBEB" />
      <path
        d="M642.64 337.065C642.414 337.065 642.221 325.494 642.221 311.232C642.221 296.97 642.414 285.4 642.64 285.4C642.865 285.4 643.059 296.97 643.059 311.232C643.059 325.494 642.865 337.065 642.64 337.065Z"
        fill="#455A64"
      />
      <path
        d="M671.693 302.804C671.693 303.03 658.68 303.223 642.64 303.223C626.6 303.223 613.587 303.03 613.587 302.804C613.587 302.578 626.6 302.385 642.64 302.385C658.68 302.385 671.693 302.578 671.693 302.804Z"
        fill="#455A64"
      />
      <path d="M599.479 287.801H554.741V332.569H599.479V287.801Z" fill="#EBEBEB" />
      <path
        d="M578.833 337.065C578.608 337.065 578.415 325.494 578.415 311.232C578.415 296.97 578.608 285.4 578.833 285.4C579.059 285.4 579.252 296.97 579.252 311.232C579.252 325.494 579.059 337.065 578.833 337.065Z"
        fill="#455A64"
      />
      <path
        d="M607.886 302.804C607.886 303.03 594.874 303.223 578.833 303.223C562.793 303.223 549.78 303.03 549.78 302.804C549.78 302.578 562.793 302.385 578.833 302.385C594.874 302.385 607.886 302.578 607.886 302.804Z"
        fill="#455A64"
      />
      <g opacity="0.7">
        <path d="M489.646 287.801H444.907V332.569H489.646V287.801Z" fill="white" />
      </g>
      <path
        d="M467.292 337.065C467.067 337.065 466.874 325.494 466.874 311.232C466.874 296.97 467.067 285.4 467.292 285.4C467.518 285.4 467.711 296.97 467.711 311.232C467.711 325.494 467.518 337.065 467.292 337.065Z"
        fill="#263238"
      />
      <path
        d="M498.052 302.804C498.052 303.03 485.04 303.223 469 303.223C452.959 303.223 439.947 303.03 439.947 302.804C439.947 302.578 452.959 302.385 469 302.385C485.04 302.385 498.052 302.578 498.052 302.804Z"
        fill="#263238"
      />
      <g opacity="0.7">
        <path d="M489.646 359.384H444.907V404.152H489.646V359.384Z" fill="white" />
      </g>
      <path
        d="M467.292 408.648C467.067 408.648 466.874 397.078 466.874 382.816C466.874 368.554 467.067 356.983 467.292 356.983C467.518 356.983 467.711 368.554 467.711 382.816C467.711 397.078 467.518 408.648 467.292 408.648Z"
        fill="#263238"
      />
      <path
        d="M498.052 374.388C498.052 374.613 485.04 374.806 469 374.806C452.959 374.806 439.947 374.613 439.947 374.388C439.947 374.162 452.959 373.968 469 373.968C485.04 373.968 498.052 374.162 498.052 374.388Z"
        fill="#263238"
      />
      <g opacity="0.7">
        <path d="M489.646 430.968H444.907V475.736H489.646V430.968Z" fill="white" />
      </g>
      <path
        d="M467.292 480.232C467.067 480.232 466.874 468.661 466.874 454.399C466.874 440.137 467.067 428.567 467.292 428.567C467.518 428.567 467.711 440.137 467.711 454.399C467.711 468.661 467.518 480.232 467.292 480.232Z"
        fill="#263238"
      />
      <path
        d="M498.052 445.971C498.052 446.197 485.04 446.39 469 446.39C452.959 446.39 439.947 446.197 439.947 445.971C439.947 445.745 452.959 445.552 469 445.552C485.04 445.552 498.052 445.745 498.052 445.971Z"
        fill="#263238"
      />
      <path
        d="M629.949 580.243C629.949 580.243 629.917 580.114 629.917 579.84C629.917 579.534 629.901 579.147 629.885 578.663C629.869 577.567 629.853 576.037 629.821 574.087C629.788 570.042 629.756 564.256 629.692 557.053C629.66 542.485 629.611 522.164 629.547 498.893L629.949 499.296C622.509 499.296 614.682 499.296 606.646 499.296C598.884 499.296 591.314 499.296 584.1 499.296L584.502 498.893C584.438 522.357 584.389 542.872 584.357 557.569C584.309 564.853 584.261 570.702 584.228 574.78C584.196 576.746 584.18 578.293 584.164 579.405C584.164 579.904 584.132 580.291 584.132 580.597C584.132 580.871 584.1 581 584.1 581C584.1 581 584.067 580.855 584.067 580.597C584.067 580.291 584.051 579.888 584.035 579.405C584.019 578.293 584.003 576.762 583.971 574.78C583.938 570.702 583.906 564.853 583.842 557.569C583.81 542.872 583.761 522.357 583.697 498.893V498.49H584.1C591.33 498.49 598.884 498.49 606.646 498.49C614.682 498.49 622.509 498.49 629.949 498.49H630.352V498.893C630.288 522.18 630.239 542.501 630.207 557.053C630.159 564.272 630.11 570.058 630.078 574.087C630.046 576.037 630.03 577.567 630.014 578.663C630.014 579.147 629.982 579.534 629.982 579.84C629.982 580.098 629.949 580.243 629.949 580.243Z"
        fill="#263238"
      />
      <path d="M605.486 503.712H589.865V541.147H605.486V503.712Z" fill="#E0E0E0" />
      <path d="M605.535 541.179H589.833V503.663H605.535V541.179ZM589.913 541.099H605.454V503.744H589.913V541.099Z" fill="#263238" />
      <path d="M624.168 503.712H608.546V541.147H624.168V503.712Z" fill="#E0E0E0" />
      <path d="M624.216 541.179H608.514V503.663H624.216V541.179ZM608.595 541.099H624.136V503.744H608.595V541.099Z" fill="#263238" />
      <path
        d="M624.49 545.015C624.49 545.015 624.651 544.886 624.989 544.918C625.327 544.95 625.827 545.208 626.149 545.74C626.439 546.272 626.68 547.029 626.294 547.867C625.923 548.641 625.005 549.269 623.942 549.092C622.896 548.866 622.284 547.932 622.203 547.094C622.106 546.207 622.638 545.482 623.153 545.176C624.248 544.515 625.054 544.983 624.973 545.063C624.973 545.192 624.232 545.031 623.508 545.659C623.153 545.966 622.879 546.449 622.976 547.013C623.057 547.561 623.508 548.157 624.103 548.286C624.683 548.415 625.343 548.012 625.601 547.545C625.875 547.094 625.778 546.449 625.601 546.046C625.231 545.16 624.442 545.176 624.474 545.031L624.49 545.015Z"
        fill="#263238"
      />
      <path
        d="M672.595 498.877C672.595 498.877 672.595 498.684 672.578 498.313C672.578 497.862 672.578 497.346 672.546 496.718C672.546 495.203 672.514 493.237 672.482 490.836L672.595 490.948C666.233 490.948 656.925 490.981 646.489 491.013C646.569 490.932 646.312 491.174 646.65 490.852V492.931C646.65 493.624 646.65 494.3 646.65 494.961C646.65 496.299 646.65 497.62 646.65 498.893L646.489 498.732C653.865 498.764 660.323 498.78 665.106 498.813C667.328 498.829 669.164 498.845 670.581 498.861C671.145 498.861 671.644 498.861 672.063 498.877C672.401 498.877 672.595 498.893 672.595 498.893C672.595 498.893 672.433 498.893 672.111 498.909C671.693 498.909 671.226 498.909 670.662 498.925C669.261 498.925 667.441 498.958 665.219 498.974C660.403 498.99 653.913 499.022 646.489 499.054H646.328V498.893C646.328 497.62 646.328 496.299 646.328 494.961C646.328 494.284 646.328 493.607 646.328 492.931V491.899V491.384V490.9V490.868C646.666 490.529 646.424 490.771 646.505 490.691C656.941 490.723 666.249 490.739 672.611 490.755H672.723V490.868C672.707 493.301 672.675 495.3 672.659 496.814C672.659 497.427 672.643 497.942 672.627 498.378C672.627 498.732 672.611 498.909 672.611 498.909L672.595 498.877Z"
        fill="#263238"
      />
      <path
        d="M571.039 482.214C571.039 482.214 571.039 482.021 571.023 481.65C571.023 481.199 571.023 480.683 570.99 480.055C570.99 478.54 570.958 476.574 570.926 474.173L571.039 474.285C564.677 474.285 555.369 474.318 544.933 474.35C545.014 474.269 544.756 474.511 545.094 474.189V476.268C545.094 476.961 545.094 477.637 545.094 478.298C545.094 479.636 545.094 480.957 545.094 482.23L544.933 482.069C552.309 482.101 558.767 482.117 563.55 482.15C565.772 482.166 567.608 482.182 569.026 482.198C569.589 482.198 570.088 482.198 570.507 482.214C570.845 482.214 571.039 482.23 571.039 482.23C571.039 482.23 570.878 482.23 570.555 482.246C570.137 482.246 569.67 482.246 569.106 482.262C567.705 482.262 565.885 482.295 563.663 482.311C558.847 482.327 552.357 482.359 544.933 482.391H544.772V482.23C544.772 480.957 544.772 479.636 544.772 478.298C544.772 477.621 544.772 476.944 544.772 476.268V475.236V474.72V474.237V474.205C545.11 473.866 544.869 474.108 544.949 474.028C555.385 474.044 564.693 474.076 571.055 474.092H571.167V474.205C571.151 476.638 571.119 478.636 571.103 480.151C571.103 480.764 571.087 481.279 571.071 481.714C571.071 482.069 571.055 482.246 571.055 482.246L571.039 482.214Z"
        fill="#263238"
      />
      <path
        d="M554.741 416.754C554.741 416.754 554.741 416.561 554.725 416.19C554.725 415.739 554.725 415.223 554.692 414.595C554.692 413.08 554.66 411.114 554.628 408.713L554.741 408.826C548.379 408.826 539.071 408.858 528.635 408.89C528.716 408.809 528.458 409.051 528.796 408.729V410.808C528.796 411.501 528.796 412.178 528.796 412.838C528.796 414.176 528.796 415.497 528.796 416.77L528.635 416.609C536.011 416.641 542.469 416.658 547.252 416.69C549.474 416.706 551.31 416.722 552.728 416.738C553.291 416.738 553.791 416.738 554.209 416.754C554.547 416.754 554.741 416.77 554.741 416.77C554.741 416.77 554.58 416.77 554.258 416.786C553.839 416.786 553.372 416.787 552.808 416.803C551.407 416.803 549.587 416.835 547.365 416.851C542.55 416.867 536.059 416.899 528.635 416.932H528.474V416.77C528.474 415.497 528.474 414.176 528.474 412.838C528.474 412.161 528.474 411.485 528.474 410.808V409.776V409.261V408.777V408.745C528.812 408.407 528.571 408.648 528.651 408.568C539.087 408.584 548.395 408.616 554.757 408.632H554.87V408.745C554.853 411.178 554.821 413.177 554.805 414.691C554.805 415.304 554.789 415.82 554.773 416.255C554.773 416.609 554.757 416.786 554.757 416.786L554.741 416.754Z"
        fill="#263238"
      />
      <path
        d="M648.599 346.299C648.599 346.299 648.599 346.105 648.583 345.735C648.583 345.283 648.582 344.768 648.55 344.139C648.55 342.625 648.518 340.658 648.486 338.257L648.599 338.37C642.237 338.37 632.929 338.402 622.493 338.435C622.574 338.354 622.316 338.596 622.654 338.273V340.352C622.654 341.045 622.654 341.722 622.654 342.383C622.654 343.72 622.654 345.042 622.654 346.315L622.493 346.154C629.869 346.186 636.327 346.202 641.11 346.234C643.332 346.25 645.168 346.267 646.586 346.283C647.149 346.283 647.648 346.283 648.067 346.299C648.405 346.299 648.599 346.315 648.599 346.315C648.599 346.315 648.438 346.315 648.115 346.331C647.697 346.331 647.23 346.331 646.666 346.347C645.265 346.347 643.445 346.379 641.223 346.395C636.407 346.412 629.917 346.444 622.493 346.476H622.332V346.315C622.332 345.042 622.332 343.72 622.332 342.383C622.332 341.706 622.332 341.029 622.332 340.352V339.321V338.805V338.322V338.29C622.67 337.951 622.429 338.193 622.509 338.112C632.945 338.128 642.253 338.161 648.615 338.177H648.727V338.29C648.695 340.723 648.679 342.721 648.663 344.236C648.663 344.848 648.647 345.364 648.631 345.799C648.631 346.154 648.615 346.331 648.615 346.331L648.599 346.299Z"
        fill="#263238"
      />
      <path
        d="M574.308 271.105C574.308 271.105 574.308 270.912 574.292 270.541C574.292 270.09 574.276 269.574 574.26 268.946C574.26 267.431 574.227 265.465 574.195 263.064L574.308 263.177C567.947 263.177 558.638 263.209 548.202 263.241C548.283 263.161 548.025 263.402 548.363 263.08V265.159C548.363 265.852 548.363 266.529 548.363 267.189C548.363 268.527 548.363 269.848 548.363 271.122L548.202 270.96C555.578 270.993 562.036 271.009 566.819 271.041C569.042 271.057 570.878 271.073 572.295 271.089C572.858 271.089 573.358 271.089 573.776 271.105C574.115 271.105 574.308 271.122 574.308 271.122C574.308 271.122 574.147 271.122 573.825 271.138C573.406 271.138 572.939 271.138 572.375 271.154C570.974 271.154 569.154 271.186 566.932 271.202C562.117 271.218 555.626 271.25 548.202 271.283H548.041V271.122C548.041 269.848 548.041 268.527 548.041 267.189C548.041 266.513 548.041 265.836 548.041 265.159V264.128V263.612V263.128V263.096C548.379 262.758 548.138 262.999 548.218 262.919C558.654 262.935 567.963 262.967 574.324 262.983H574.437V263.096C574.421 265.53 574.388 267.528 574.372 269.043C574.372 269.655 574.356 270.171 574.34 270.606C574.34 270.96 574.324 271.138 574.324 271.138L574.308 271.105Z"
        fill="#263238"
      />
      <path
        d="M664.413 354.098C664.413 354.098 664.413 353.905 664.397 353.534C664.397 353.083 664.397 352.568 664.365 351.939C664.365 350.424 664.333 348.458 664.301 346.057L664.413 346.17C658.052 346.17 648.744 346.202 638.308 346.234C638.388 346.154 638.131 346.395 638.469 346.073V348.152C638.469 348.845 638.469 349.522 638.469 350.183C638.469 351.52 638.469 352.842 638.469 354.115L638.308 353.953C645.684 353.986 652.142 354.002 656.925 354.034C659.147 354.05 660.983 354.066 662.4 354.082C662.964 354.082 663.463 354.082 663.882 354.098C664.22 354.098 664.413 354.115 664.413 354.115C664.413 354.115 664.252 354.115 663.93 354.131C663.512 354.131 663.044 354.131 662.481 354.147C661.08 354.147 659.26 354.179 657.037 354.195C652.222 354.211 645.732 354.244 638.308 354.276H638.147V354.115C638.147 352.842 638.147 351.52 638.147 350.183C638.147 349.506 638.147 348.829 638.147 348.152V347.121V346.605V346.121V346.089C638.485 345.751 638.243 345.993 638.324 345.912C648.76 345.944 658.068 345.96 664.429 345.976H664.542V346.089C664.526 348.523 664.494 350.521 664.478 352.036C664.478 352.648 664.462 353.164 664.446 353.599C664.446 353.953 664.429 354.131 664.429 354.131L664.413 354.098Z"
        fill="#263238"
      />
      <path
        d="M543.548 424.635C543.548 424.635 543.548 424.441 543.532 424.071C543.532 423.619 543.532 423.104 543.5 422.475C543.5 420.96 543.467 418.994 543.435 416.593L543.548 416.706C537.187 416.706 527.878 416.738 517.442 416.77C517.523 416.69 517.281 416.932 517.603 416.609V417.641V418.672C517.603 419.365 517.603 420.042 517.603 420.702C517.603 422.04 517.603 423.361 517.603 424.635L517.442 424.473C524.818 424.506 531.276 424.522 536.059 424.554C538.282 424.57 540.118 424.586 541.535 424.602C542.099 424.602 542.598 424.602 543.017 424.618C543.355 424.618 543.548 424.635 543.548 424.635C543.548 424.635 543.387 424.635 543.065 424.651C542.646 424.651 542.179 424.651 541.615 424.667C540.214 424.667 538.394 424.699 536.172 424.715C531.357 424.731 524.867 424.764 517.442 424.796H517.281V424.635C517.281 423.361 517.281 422.04 517.281 420.702C517.281 420.026 517.281 419.349 517.281 418.672V417.641V417.125V416.641V416.609C517.62 416.271 517.378 416.513 517.458 416.432C527.894 416.448 537.203 416.48 543.564 416.496H543.677V416.609C543.661 419.043 543.628 421.041 543.612 422.556C543.612 423.168 543.596 423.684 543.58 424.119C543.58 424.473 543.564 424.651 543.564 424.651L543.548 424.635Z"
        fill="#263238"
      />
      <path
        d="M554.741 279.147C554.741 279.147 554.741 278.953 554.725 278.583C554.725 278.132 554.725 277.616 554.692 276.987C554.692 275.473 554.66 273.507 554.628 271.105L554.741 271.218C548.379 271.218 539.071 271.25 528.635 271.283C528.716 271.202 528.458 271.444 528.796 271.122V273.2C528.796 273.893 528.796 274.57 528.796 275.231C528.796 276.568 528.796 277.89 528.796 279.163L528.635 279.002C536.011 279.034 542.469 279.05 547.252 279.082C549.474 279.099 551.31 279.115 552.728 279.131C553.291 279.131 553.791 279.131 554.209 279.147C554.547 279.147 554.741 279.163 554.741 279.163C554.741 279.163 554.58 279.163 554.258 279.179C553.839 279.179 553.372 279.179 552.808 279.195C551.407 279.195 549.587 279.227 547.365 279.244C542.55 279.26 536.059 279.292 528.635 279.324H528.474V279.163C528.474 277.89 528.474 276.568 528.474 275.231C528.474 274.554 528.474 273.877 528.474 273.2V272.169V271.653V271.17V271.138C528.812 270.799 528.571 271.041 528.651 270.96C539.087 270.976 548.395 271.009 554.757 271.025H554.87V271.138C554.853 273.571 554.821 275.569 554.805 277.084C554.805 277.697 554.789 278.212 554.773 278.647C554.773 279.002 554.757 279.179 554.757 279.179L554.741 279.147Z"
        fill="#263238"
      />
      <path
        d="M681.05 506.919C681.05 506.919 681.049 506.725 681.033 506.355C681.033 505.903 681.033 505.388 681.001 504.759C681.001 503.244 680.969 501.278 680.937 498.877L681.05 498.99C674.688 498.99 665.38 499.022 654.944 499.054C655.024 498.974 654.783 499.216 655.105 498.893V499.925V500.956C655.105 501.649 655.105 502.326 655.105 502.987C655.105 504.324 655.105 505.646 655.105 506.919L654.944 506.757C662.32 506.79 668.778 506.806 673.561 506.838C675.783 506.854 677.619 506.87 679.036 506.886C679.6 506.886 680.099 506.886 680.518 506.902C680.856 506.902 681.05 506.919 681.05 506.919C681.05 506.919 680.888 506.919 680.566 506.935C680.148 506.935 679.681 506.935 679.117 506.951C677.716 506.951 675.896 506.983 673.674 506.999C668.858 507.015 662.368 507.048 654.944 507.08H654.783V506.919C654.783 505.646 654.783 504.324 654.783 502.987C654.783 502.31 654.783 501.633 654.783 500.956V499.925V499.409V498.926V498.893C655.121 498.555 654.879 498.797 654.96 498.716C665.396 498.732 674.704 498.764 681.066 498.78H681.178V498.893C681.162 501.327 681.13 503.325 681.114 504.84C681.114 505.452 681.098 505.968 681.082 506.403C681.082 506.757 681.066 506.935 681.066 506.935L681.05 506.919Z"
        fill="#263238"
      />
      <path
        d="M528.941 202.1C528.941 202.1 528.667 202.342 528.12 202.729C527.556 203.099 526.751 203.696 525.656 204.356C523.498 205.742 520.245 207.563 516.057 209.449C507.731 213.236 495.218 216.846 481.126 216.636C467.019 216.443 454.634 212.446 446.421 208.417C442.282 206.419 439.093 204.485 436.967 203.051C435.888 202.358 435.099 201.746 434.552 201.343C434.004 200.94 433.73 200.714 433.746 200.698C433.763 200.682 434.069 200.843 434.648 201.214C435.228 201.552 436.033 202.133 437.145 202.777C439.319 204.131 442.523 205.968 446.678 207.886C454.908 211.753 467.196 215.589 481.159 215.798C495.105 216.008 507.506 212.527 515.848 208.901C520.035 207.096 523.304 205.356 525.527 204.082C526.654 203.47 527.476 202.922 528.071 202.584C528.651 202.245 528.973 202.068 528.989 202.1H528.941Z"
        fill="#263238"
      />
      <path
        d="M525.607 199.602C525.607 199.602 525.382 199.796 524.883 200.102C524.303 200.457 523.594 200.876 522.741 201.407C522.258 201.697 521.742 202.004 521.179 202.342C520.599 202.664 519.939 202.97 519.246 203.309C517.845 203.97 516.283 204.824 514.447 205.501C513.529 205.871 512.579 206.242 511.596 206.645C510.598 207.032 509.535 207.322 508.44 207.692C506.266 208.466 503.866 208.998 501.354 209.61C496.297 210.657 490.628 211.399 484.653 211.479C478.678 211.479 472.994 210.835 467.921 209.852C465.392 209.272 462.993 208.772 460.802 208.031C459.707 207.676 458.628 207.402 457.63 207.032C456.631 206.645 455.681 206.274 454.763 205.936C452.911 205.291 451.349 204.453 449.932 203.808C449.239 203.47 448.579 203.18 447.983 202.858C447.419 202.535 446.888 202.229 446.405 201.955C445.535 201.44 444.81 201.021 444.247 200.682C443.764 200.376 443.506 200.215 443.522 200.183C443.522 200.15 443.812 200.279 444.327 200.537C444.923 200.843 445.664 201.214 446.566 201.665C447.065 201.923 447.597 202.197 448.176 202.503C448.772 202.793 449.432 203.067 450.141 203.373C451.558 203.986 453.137 204.759 454.972 205.372C455.89 205.71 456.841 206.048 457.839 206.419C458.838 206.774 459.917 207.031 460.996 207.37C463.17 208.079 465.569 208.546 468.066 209.11C473.106 210.045 478.727 210.674 484.637 210.657C490.548 210.577 496.168 209.884 501.193 208.869C503.689 208.272 506.072 207.773 508.23 207.032C509.31 206.677 510.389 206.403 511.371 206.032C512.353 205.662 513.303 205.291 514.221 204.953C516.057 204.324 517.62 203.502 519.037 202.89C519.729 202.568 520.406 202.278 520.985 201.987C521.565 201.681 522.097 201.391 522.58 201.133C523.482 200.666 524.206 200.279 524.802 199.973C525.318 199.715 525.591 199.586 525.607 199.619V199.602Z"
        fill="#263238"
      />
      <path
        d="M481.352 215.879C479.162 217.522 478.485 219.472 478.244 222.196C478.099 223.727 478.292 225.354 477.551 226.692C476.762 228.094 475.071 228.787 473.461 228.835C471.85 228.884 470.288 228.368 468.758 227.868C470.465 225.935 471.657 223.55 472.172 221.019C472.382 219.956 472.494 218.876 472.865 217.861C473.235 216.846 473.96 215.895 474.991 215.573L481.352 215.879Z"
        fill="#E8505B"
      />
      <path
        d="M492.851 214.928C491.449 215.444 490.596 216.894 490.306 218.36C490.032 219.827 490.209 221.326 490.274 222.808C490.338 224.291 490.274 225.854 489.517 227.143C492.931 227.723 496.539 226.998 499.453 225.145C498.278 221.277 498.6 217.442 500.371 213.8C500.371 213.8 500.468 213.574 499.985 213.542C499.502 213.51 492.834 214.912 492.834 214.912L492.851 214.928Z"
        fill="#E8505B"
      />
      <path d="M313.799 423.12H245.338V580.307H313.799V423.12Z" fill="#E8505B" />
      <g opacity="0.3">
        <path d="M313.799 423.12H245.338V580.307H313.799V423.12Z" fill="black" />
      </g>
      <path d="M229.314 422.475H398.22L429.785 377.643L462.864 423.748L501.74 423.12L459.836 366.668L281.235 367.41L229.314 422.475Z" fill="#E8505B" />
      <g opacity="0.3">
        <path d="M229.314 422.475H398.22L429.785 377.643L462.864 423.748L501.74 423.12L459.836 366.668L281.235 367.41L229.314 422.475Z" fill="black" />
      </g>
      <path d="M313.783 423.12V580.307H482.447V423.12H463.121L429.785 377.643L398.123 422.443L313.783 423.12Z" fill="#E8505B" />
      <path
        d="M377.058 580.307C377.058 580.307 377.026 580.194 377.026 579.969C377.026 579.711 377.01 579.372 376.994 578.986C376.978 578.051 376.962 576.778 376.929 575.15C376.897 571.75 376.865 566.915 376.8 560.888C376.768 548.673 376.72 531.655 376.656 512.156L377.058 512.559C370.761 512.559 364.142 512.559 357.346 512.559C350.534 512.559 343.915 512.559 337.618 512.559L338.02 512.156C337.956 531.655 337.908 548.673 337.876 560.888C337.827 566.915 337.779 571.75 337.747 575.15C337.714 576.778 337.698 578.051 337.682 578.986C337.682 579.388 337.65 579.711 337.65 579.969C337.65 580.194 337.618 580.307 337.618 580.307C337.618 580.307 337.586 580.194 337.586 579.969C337.586 579.711 337.57 579.372 337.553 578.986C337.537 578.051 337.521 576.778 337.489 575.15C337.457 571.75 337.425 566.915 337.36 560.888C337.328 548.673 337.28 531.655 337.215 512.156V511.753H337.618C343.915 511.753 350.534 511.753 357.346 511.753C364.158 511.753 370.777 511.753 377.058 511.753H377.461V512.156C377.396 531.655 377.348 548.673 377.316 560.888C377.267 566.915 377.219 571.75 377.187 575.15C377.155 576.778 377.139 578.051 377.123 578.986C377.123 579.388 377.09 579.711 377.09 579.969C377.09 580.194 377.058 580.307 377.058 580.307Z"
        fill="#263238"
      />
      <path d="M462.864 433.143H400.716V471.659H462.864V433.143Z" fill="white" />
      <path d="M372.951 433.498H333.753V470.627H372.951V433.498Z" fill="white" />
      <path
        d="M418.656 472.029C418.431 472.029 418.238 463.327 418.238 452.594C418.238 441.862 418.431 433.16 418.656 433.16C418.882 433.16 419.075 441.862 419.075 452.594C419.075 463.327 418.882 472.029 418.656 472.029Z"
        fill="#E8505B"
      />
      <path
        d="M444.504 472.029C444.279 472.029 444.086 463.327 444.086 452.594C444.086 441.862 444.279 433.16 444.504 433.16C444.73 433.16 444.923 441.862 444.923 452.594C444.923 463.327 444.73 472.029 444.504 472.029Z"
        fill="#E8505B"
      />
      <path
        d="M444.504 453.755C444.504 453.98 438.723 454.174 431.588 454.174C424.454 454.174 418.673 453.98 418.673 453.755C418.673 453.529 424.454 453.336 431.588 453.336C438.723 453.336 444.504 453.529 444.504 453.755Z"
        fill="#E8505B"
      />
      <path
        d="M353.352 470.627C353.127 470.627 352.933 462.312 352.933 452.063C352.933 441.813 353.127 433.498 353.352 433.498C353.578 433.498 353.771 441.813 353.771 452.063C353.771 462.312 353.578 470.627 353.352 470.627Z"
        fill="#E8505B"
      />
      <path
        d="M372.951 452.965C372.951 453.191 364.174 453.384 353.352 453.384C342.53 453.384 333.753 453.191 333.753 452.965C333.753 452.739 342.53 452.546 353.352 452.546C364.174 452.546 372.951 452.739 372.951 452.965Z"
        fill="#E8505B"
      />
      <path
        d="M437.998 407.649C437.998 412.242 434.278 415.965 429.688 415.965C425.098 415.965 421.378 412.242 421.378 407.649C421.378 403.056 425.098 399.334 429.688 399.334C434.278 399.334 437.998 403.056 437.998 407.649Z"
        fill="white"
      />
      <path d="M370.407 397.448L380.891 384.508V353.373H358.924V397.69L370.407 397.448Z" fill="#E8505B" />
      <g opacity="0.3">
        <path d="M370.407 397.448V353.373H380.891V384.508L370.407 397.448Z" fill="black" />
      </g>
      <path d="M382.389 348.78H356.492V353.357H382.389V348.78Z" fill="#E8505B" />
      <path d="M229.314 422.475V427.036H398.123V422.443L229.314 422.475Z" fill="#E8505B" />
      <path d="M398.123 427.036L429.688 382.687L429.785 377.643L398.22 422.475L398.123 427.036Z" fill="#E8505B" />
      <path d="M500.935 423.12H463.121V426.053H500.935V423.12Z" fill="#E8505B" />
      <path d="M363.611 501.713H349.664V507.015H363.611V501.713Z" fill="#263238" />
      <path
        d="M373.886 546.546C373.757 546.578 373.789 545.659 372.839 544.999C372.388 544.693 371.695 544.531 371.035 544.805C370.391 545.047 369.843 545.74 369.843 546.546C369.843 547.352 370.391 548.045 371.035 548.286C371.695 548.544 372.388 548.399 372.839 548.093C373.773 547.432 373.757 546.514 373.886 546.546C373.95 546.481 374.272 547.513 373.177 548.528C372.645 548.995 371.711 549.285 370.777 548.963C369.859 548.673 369.038 547.69 369.038 546.53C369.038 545.369 369.843 544.386 370.777 544.096C371.711 543.774 372.645 544.064 373.177 544.531C374.272 545.547 373.95 546.594 373.886 546.514V546.546Z"
        fill="#263238"
      />
      <path
        d="M342.24 486.275C342.24 486.275 342.24 486.098 342.224 485.792C342.224 485.389 342.208 484.938 342.192 484.422C342.192 483.1 342.159 481.408 342.127 479.362L342.24 479.474C336.716 479.474 328.728 479.507 319.774 479.539C319.854 479.458 319.613 479.7 319.935 479.378V480.264V481.15C319.935 481.747 319.935 482.327 319.935 482.907C319.935 484.067 319.935 485.195 319.935 486.291L319.774 486.13C326.103 486.162 331.627 486.178 335.782 486.211C337.666 486.227 339.244 486.243 340.501 486.259C340.984 486.259 341.402 486.259 341.773 486.275C342.063 486.275 342.224 486.275 342.224 486.291C342.224 486.291 342.095 486.291 341.805 486.307C341.435 486.307 341.032 486.307 340.565 486.323C339.325 486.323 337.763 486.356 335.895 486.372C331.707 486.388 326.135 486.42 319.774 486.452H319.613V486.291C319.613 485.195 319.613 484.067 319.613 482.907C319.613 482.327 319.613 481.747 319.613 481.15V480.264V479.813V479.394V479.362C319.951 479.023 319.709 479.265 319.774 479.184C328.728 479.201 336.7 479.233 342.24 479.249H342.353V479.362C342.337 481.441 342.304 483.149 342.288 484.486C342.288 485.002 342.272 485.437 342.256 485.824C342.256 486.13 342.256 486.275 342.24 486.275Z"
        fill="#263238"
      />
      <path
        d="M267.788 464.697C267.788 464.697 267.788 464.52 267.772 464.213C267.772 463.811 267.756 463.359 267.74 462.844C267.74 461.522 267.708 459.83 267.675 457.783L267.788 457.896C262.264 457.896 254.276 457.929 245.322 457.961C245.403 457.88 245.161 458.122 245.483 457.8V459.588C245.483 460.185 245.483 460.765 245.483 461.345C245.483 462.505 245.483 463.633 245.483 464.729L245.322 464.568C251.651 464.6 257.175 464.616 261.33 464.649C263.214 464.665 264.793 464.681 266.049 464.697C266.532 464.697 266.951 464.697 267.321 464.713C267.611 464.713 267.772 464.713 267.772 464.729C267.772 464.729 267.643 464.729 267.353 464.745C266.983 464.745 266.58 464.745 266.113 464.761C264.873 464.761 263.311 464.794 261.443 464.81C257.256 464.826 251.683 464.858 245.322 464.89H245.161V464.729C245.161 463.633 245.161 462.505 245.161 461.345C245.161 460.765 245.161 460.185 245.161 459.588V458.702V458.251V457.832V457.8C245.499 457.461 245.258 457.703 245.322 457.622C254.276 457.638 262.248 457.671 267.788 457.687H267.901V457.8C267.885 459.878 267.852 461.587 267.836 462.924C267.836 463.44 267.82 463.875 267.804 464.262C267.804 464.568 267.804 464.713 267.788 464.713V464.697Z"
        fill="#263238"
      />
      <path
        d="M281.235 457.767C281.235 457.767 281.235 457.59 281.219 457.284C281.219 456.881 281.203 456.43 281.187 455.914C281.187 454.593 281.155 452.901 281.123 450.854L281.235 450.967C275.712 450.967 267.724 450.999 258.769 451.031C258.85 450.951 258.608 451.192 258.931 450.87V452.659C258.931 453.255 258.931 453.835 258.931 454.415C258.931 455.576 258.931 456.704 258.931 457.8L258.769 457.638C265.099 457.671 270.623 457.687 274.778 457.719C276.662 457.735 278.24 457.751 279.496 457.767C279.979 457.767 280.398 457.767 280.768 457.783C281.058 457.783 281.219 457.783 281.219 457.8C281.219 457.816 281.091 457.8 280.801 457.816C280.43 457.816 280.028 457.816 279.561 457.832C278.321 457.832 276.758 457.864 274.89 457.88C270.703 457.896 265.131 457.928 258.769 457.961H258.608V457.8C258.608 456.704 258.608 455.576 258.608 454.415C258.608 453.835 258.608 453.255 258.608 452.659V451.773V451.321V450.902V450.87C258.947 450.532 258.705 450.773 258.769 450.693C267.724 450.709 275.695 450.741 281.235 450.757H281.348V450.87C281.332 452.949 281.3 454.657 281.284 455.995C281.284 456.51 281.268 456.945 281.252 457.332C281.252 457.638 281.252 457.783 281.235 457.783V457.767Z"
        fill="#263238"
      />
      <path
        d="M349.535 493.205C349.535 493.205 349.535 493.027 349.519 492.721C349.519 492.318 349.503 491.867 349.487 491.351C349.487 490.03 349.455 488.338 349.423 486.291L349.535 486.404C344.011 486.404 336.023 486.436 327.069 486.468C327.15 486.388 326.908 486.63 327.23 486.307V488.096C327.23 488.692 327.23 489.273 327.23 489.853C327.23 491.013 327.23 492.141 327.23 493.237L327.069 493.076C333.398 493.108 338.922 493.124 343.077 493.156C344.962 493.172 346.54 493.188 347.796 493.205C348.279 493.205 348.698 493.205 349.068 493.221C349.358 493.221 349.519 493.221 349.519 493.237C349.519 493.237 349.39 493.237 349.1 493.253C348.73 493.253 348.327 493.253 347.86 493.269C346.62 493.269 345.058 493.301 343.19 493.317C339.003 493.334 333.431 493.366 327.069 493.398H326.908V493.237C326.908 492.141 326.908 491.013 326.908 489.853C326.908 489.273 326.908 488.692 326.908 488.096V487.21V486.759V486.34V486.307C327.246 485.969 327.005 486.211 327.069 486.13C336.023 486.146 343.995 486.178 349.535 486.195H349.648V486.307C349.632 488.386 349.6 490.094 349.584 491.432C349.584 491.948 349.568 492.383 349.551 492.77C349.551 493.076 349.551 493.221 349.535 493.221V493.205Z"
        fill="#263238"
      />
      <path
        d="M482.447 505.323C482.447 505.323 482.447 505.146 482.431 504.84C482.431 504.437 482.415 503.986 482.399 503.47C482.399 502.149 482.366 500.456 482.334 498.41L482.447 498.523C476.923 498.523 468.935 498.555 459.981 498.587C460.061 498.506 459.82 498.748 460.142 498.426V500.215C460.142 500.811 460.142 501.391 460.142 501.971C460.142 503.132 460.142 504.26 460.142 505.355L459.981 505.194C466.31 505.226 471.834 505.243 475.989 505.275C477.873 505.291 479.451 505.307 480.708 505.323C481.191 505.323 481.61 505.323 481.98 505.339C482.27 505.339 482.431 505.339 482.431 505.355C482.431 505.355 482.302 505.355 482.012 505.372C481.642 505.372 481.239 505.372 480.772 505.388C479.532 505.388 477.97 505.42 476.102 505.436C471.915 505.452 466.342 505.484 459.981 505.517H459.82V505.355C459.82 504.26 459.82 503.132 459.82 501.971C459.82 501.391 459.82 500.811 459.82 500.215V499.328V498.877V498.458V498.426C460.158 498.087 459.917 498.329 459.997 498.249C468.951 498.265 476.923 498.297 482.463 498.313H482.576V498.426C482.56 500.505 482.527 502.213 482.511 503.551C482.511 504.066 482.495 504.501 482.479 504.888C482.479 505.194 482.479 505.339 482.463 505.339L482.447 505.323Z"
        fill="#263238"
      />
      <path
        d="M482.479 512.156C482.479 512.156 482.463 511.963 482.447 511.64C482.447 511.205 482.415 510.738 482.399 510.238C482.366 508.804 482.334 507.16 482.286 505.307L482.431 505.452C479.935 505.452 477.116 505.468 474.089 505.484C474.056 505.517 474.411 505.162 474.25 505.323V505.372V505.436V505.549V505.774V506.226V507.128C474.25 507.724 474.25 508.321 474.266 508.885C474.266 510.013 474.266 511.109 474.266 512.14L474.121 511.995C476.392 512.027 478.389 512.043 480.112 512.075C480.724 512.075 481.304 512.108 481.835 512.108C482.238 512.108 482.463 512.124 482.479 512.14C482.479 512.14 482.286 512.156 481.899 512.172C481.368 512.172 480.82 512.204 480.208 512.204C478.469 512.22 476.424 512.253 474.121 512.285H473.976V512.14C473.976 511.109 473.944 510.013 473.944 508.885C473.944 508.305 473.944 507.724 473.928 507.128V506.226V505.774V505.549V505.436V505.372V505.339V505.323C473.783 505.468 474.137 505.114 474.089 505.146C477.116 505.146 479.935 505.162 482.431 505.178H482.576V505.323C482.576 507.209 482.56 508.885 482.544 510.335C482.544 510.818 482.527 511.27 482.511 511.705C482.511 512.011 482.495 512.172 482.479 512.172V512.156Z"
        fill="#263238"
      />
      <path
        d="M426.709 493.205C426.709 493.205 426.709 493.027 426.693 492.721C426.693 492.318 426.677 491.867 426.66 491.351C426.66 490.03 426.628 488.338 426.596 486.291L426.709 486.404C421.185 486.404 413.197 486.436 404.243 486.468C404.323 486.388 404.082 486.63 404.404 486.307V486.759V487.21V488.096C404.404 488.692 404.404 489.273 404.404 489.853C404.404 491.013 404.404 492.141 404.404 493.237L404.243 493.076C410.572 493.108 416.096 493.124 420.251 493.156C422.135 493.172 423.713 493.188 424.969 493.205C425.453 493.205 425.871 493.205 426.242 493.221C426.532 493.221 426.693 493.221 426.693 493.237C426.693 493.237 426.564 493.237 426.274 493.253C425.904 493.253 425.501 493.253 425.034 493.269C423.794 493.269 422.232 493.301 420.364 493.317C416.176 493.334 410.604 493.366 404.243 493.398H404.082V493.237C404.082 492.141 404.082 491.013 404.082 489.853C404.082 489.273 404.082 488.692 404.082 488.096V487.21V486.759V486.34V486.307C404.42 485.969 404.178 486.211 404.243 486.13C413.197 486.162 421.169 486.178 426.709 486.195H426.821V486.307C426.805 488.386 426.773 490.094 426.757 491.432C426.757 491.948 426.741 492.383 426.725 492.77C426.725 493.076 426.725 493.221 426.709 493.221V493.205Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path d="M437.95 479.362H415.484V486.275H437.95V479.362Z" fill="white" />
      </g>
      <g opacity="0.2">
        <path d="M483.123 446.374H470.449V453.287H483.123V446.374Z" fill="white" />
      </g>
      <path
        d="M318.647 534.234C318.647 534.234 318.647 534.057 318.63 533.75C318.63 533.347 318.614 532.896 318.598 532.381C318.598 531.059 318.566 529.367 318.534 527.32L318.647 527.433C313.123 527.433 305.135 527.465 296.181 527.498C296.261 527.417 296.02 527.659 296.342 527.337V529.125C296.342 529.722 296.342 530.302 296.342 530.882C296.342 532.042 296.342 533.17 296.342 534.266L296.181 534.105C302.51 534.137 308.034 534.153 312.189 534.185C314.073 534.202 315.651 534.218 316.907 534.234C317.39 534.234 317.809 534.234 318.18 534.25C318.469 534.25 318.63 534.25 318.63 534.266C318.63 534.266 318.502 534.266 318.212 534.282C317.841 534.282 317.439 534.282 316.972 534.298C315.732 534.298 314.169 534.33 312.301 534.347C308.114 534.363 302.542 534.395 296.181 534.427H296.02V534.266C296.02 533.17 296.02 532.042 296.02 530.882C296.02 530.302 296.02 529.722 296.02 529.125V528.239V527.788V527.369V527.337C296.358 526.998 296.116 527.24 296.181 527.159C305.135 527.175 313.107 527.208 318.647 527.224H318.759V527.337C318.743 529.415 318.711 531.124 318.695 532.461C318.695 532.977 318.679 533.412 318.663 533.799C318.663 534.105 318.663 534.25 318.647 534.25V534.234Z"
        fill="#263238"
      />
      <path
        d="M308.839 541.405C308.839 541.405 308.839 541.228 308.823 540.922C308.823 540.519 308.807 540.067 308.791 539.552C308.791 538.23 308.758 536.538 308.726 534.492L308.839 534.604C303.315 534.604 295.327 534.637 286.373 534.669C286.453 534.588 286.212 534.83 286.534 534.508V535.394V536.28C286.534 536.877 286.534 537.457 286.534 538.037C286.534 539.197 286.534 540.325 286.534 541.421L286.373 541.26C292.702 541.292 298.226 541.308 302.381 541.341C304.265 541.357 305.843 541.373 307.1 541.389C307.583 541.389 308.001 541.389 308.372 541.405C308.662 541.405 308.823 541.405 308.823 541.421C308.823 541.421 308.694 541.421 308.404 541.437C308.034 541.437 307.631 541.437 307.164 541.453C305.924 541.453 304.362 541.486 302.494 541.502C298.306 541.518 292.734 541.55 286.373 541.582H286.212V541.421C286.212 540.325 286.212 539.197 286.212 538.037C286.212 537.457 286.212 536.877 286.212 536.28V535.394V534.943V534.524V534.492C286.55 534.153 286.308 534.395 286.373 534.314C295.327 534.33 303.299 534.363 308.839 534.379H308.952V534.492C308.935 536.571 308.903 538.279 308.887 539.616C308.887 540.132 308.871 540.567 308.855 540.954C308.855 541.26 308.855 541.405 308.839 541.405Z"
        fill="#263238"
      />
      <g opacity="0.2">
        <path d="M337.36 561.146H326.602V568.06H337.36V561.146Z" fill="white" />
      </g>
      <path
        d="M377.058 526.692C377.058 526.692 377.3 526.66 377.735 526.644C378.33 526.644 378.942 526.611 379.603 526.595C381.503 526.579 383.645 526.563 386.012 526.531L385.851 526.692C385.851 525.596 385.851 524.436 385.851 523.227C385.851 522.019 385.851 520.858 385.851 519.762L386.012 519.924C383.645 519.907 381.503 519.875 379.603 519.859C378.942 519.843 378.33 519.827 377.735 519.811C377.3 519.795 377.058 519.779 377.058 519.762C377.058 519.746 377.3 519.73 377.735 519.714C378.33 519.714 378.942 519.682 379.603 519.666C381.503 519.65 383.645 519.633 386.012 519.601H386.173V519.762C386.173 520.858 386.173 522.019 386.173 523.227C386.173 524.436 386.173 525.596 386.173 526.692V526.853H386.012C383.645 526.837 381.503 526.805 379.603 526.789C378.942 526.773 378.33 526.756 377.735 526.74C377.3 526.724 377.058 526.708 377.058 526.692Z"
        fill="#263238"
      />
      <path
        d="M372.597 452.385C372.597 452.385 372.839 452.353 373.274 452.337C373.869 452.337 374.481 452.304 375.142 452.288C377.042 452.272 379.184 452.256 381.551 452.224L381.39 452.385C381.39 451.289 381.39 450.129 381.39 448.92C381.39 447.711 381.39 446.551 381.39 445.455L381.551 445.616C379.184 445.6 377.042 445.568 375.142 445.552C374.481 445.536 373.869 445.52 373.274 445.504C372.839 445.488 372.597 445.471 372.597 445.455C372.597 445.439 372.839 445.423 373.274 445.407C373.869 445.407 374.481 445.375 375.142 445.359C377.042 445.343 379.184 445.326 381.551 445.294H381.712V445.455C381.712 446.551 381.712 447.711 381.712 448.92C381.712 450.129 381.712 451.289 381.712 452.385V452.546H381.551C379.184 452.53 377.042 452.498 375.142 452.482C374.481 452.465 373.869 452.449 373.274 452.433C372.839 452.417 372.597 452.401 372.597 452.385Z"
        fill="#263238"
      />
      <path d="M462.864 510.383H400.716V548.899H462.864V510.383Z" fill="white" />
      <path
        d="M418.656 549.269C418.431 549.269 418.238 540.567 418.238 529.834C418.238 519.102 418.431 510.399 418.656 510.399C418.882 510.399 419.075 519.102 419.075 529.834C419.075 540.567 418.882 549.269 418.656 549.269Z"
        fill="#E8505B"
      />
      <path
        d="M444.504 549.269C444.279 549.269 444.086 540.567 444.086 529.834C444.086 519.102 444.279 510.399 444.504 510.399C444.73 510.399 444.923 519.102 444.923 529.834C444.923 540.567 444.73 549.269 444.504 549.269Z"
        fill="#E8505B"
      />
      <path
        d="M444.504 530.995C444.504 531.22 438.723 531.414 431.588 531.414C424.454 531.414 418.673 531.22 418.673 530.995C418.673 530.769 424.454 530.576 431.588 530.576C438.723 530.576 444.504 530.769 444.504 530.995Z"
        fill="#E8505B"
      />
      <path d="M199.794 176.622H174.397L83.5187 264.627V580.307H142.897L199.794 176.622Z" fill="#263238" />
      <path
        d="M144.507 432.869C144.507 432.869 144.491 432.724 144.475 432.45C144.475 432.144 144.475 431.741 144.443 431.258C144.443 430.162 144.41 428.647 144.394 426.697C144.362 422.701 144.33 416.98 144.282 409.841C144.233 395.514 144.169 375.548 144.088 352.729L144.491 353.132C137.614 353.132 130.448 353.132 123.088 353.132C123.909 352.294 123.313 352.906 123.507 352.729V353.47V354.244V355.774C123.507 356.79 123.507 357.805 123.507 358.82C123.507 360.835 123.507 362.833 123.507 364.815C123.507 368.779 123.507 372.679 123.507 376.482C123.507 384.105 123.49 391.389 123.474 398.222C123.426 411.855 123.394 423.7 123.362 432.853L123.104 432.595C129.803 432.66 135.102 432.708 138.806 432.741C140.61 432.773 142.011 432.789 143.025 432.805C143.476 432.805 143.831 432.821 144.121 432.837C144.362 432.837 144.507 432.853 144.507 432.869C144.507 432.869 144.394 432.886 144.169 432.902C143.895 432.902 143.541 432.902 143.122 432.934C142.124 432.934 140.739 432.966 138.967 432.998C135.231 433.031 129.868 433.079 123.12 433.143H122.862V432.886C122.83 423.732 122.798 411.887 122.75 398.254C122.75 391.421 122.734 384.137 122.717 376.515C122.717 372.695 122.717 368.812 122.717 364.847C122.717 362.865 122.717 360.867 122.717 358.852C122.717 357.837 122.717 356.838 122.717 355.807V354.276V353.502V352.777C122.911 352.568 122.299 353.148 123.136 352.31C130.496 352.31 137.663 352.31 144.539 352.31H144.958V352.713C144.877 375.644 144.813 395.708 144.765 410.099C144.716 417.189 144.668 422.878 144.652 426.858C144.636 428.76 144.604 430.259 144.604 431.322C144.604 431.79 144.588 432.16 144.571 432.467C144.571 432.724 144.555 432.853 144.539 432.837L144.507 432.869Z"
        fill="#455A64"
      />
      <path
        d="M144.056 360.367C144.056 360.593 139.354 360.786 133.556 360.786C127.758 360.786 123.04 360.593 123.04 360.367C123.04 360.142 127.742 359.948 133.556 359.948C139.37 359.948 144.056 360.142 144.056 360.367Z"
        fill="#455A64"
      />
      <path
        d="M144.056 366.958C144.056 367.184 139.354 367.377 133.556 367.377C127.758 367.377 123.04 367.184 123.04 366.958C123.04 366.733 127.742 366.539 133.556 366.539C139.37 366.539 144.056 366.733 144.056 366.958Z"
        fill="#455A64"
      />
      <path
        d="M144.056 373.533C144.056 373.759 139.354 373.952 133.556 373.952C127.758 373.952 123.04 373.759 123.04 373.533C123.04 373.308 127.742 373.114 133.556 373.114C139.37 373.114 144.056 373.308 144.056 373.533Z"
        fill="#455A64"
      />
      <path
        d="M144.056 380.125C144.056 380.35 139.354 380.543 133.556 380.543C127.758 380.543 123.04 380.35 123.04 380.125C123.04 379.899 127.742 379.705 133.556 379.705C139.37 379.705 144.056 379.899 144.056 380.125Z"
        fill="#455A64"
      />
      <path
        d="M144.056 386.699C144.056 386.925 139.354 387.118 133.556 387.118C127.758 387.118 123.04 386.925 123.04 386.699C123.04 386.474 127.742 386.28 133.556 386.28C139.37 386.28 144.056 386.474 144.056 386.699Z"
        fill="#455A64"
      />
      <path
        d="M144.056 393.274C144.056 393.5 139.354 393.693 133.556 393.693C127.758 393.693 123.04 393.5 123.04 393.274C123.04 393.049 127.742 392.855 133.556 392.855C139.37 392.855 144.056 393.049 144.056 393.274Z"
        fill="#455A64"
      />
      <path
        d="M144.056 399.866C144.056 400.091 139.354 400.285 133.556 400.285C127.758 400.285 123.04 400.091 123.04 399.866C123.04 399.64 127.742 399.447 133.556 399.447C139.37 399.447 144.056 399.64 144.056 399.866Z"
        fill="#455A64"
      />
      <path
        d="M144.056 406.441C144.056 406.666 139.354 406.86 133.556 406.86C127.758 406.86 123.04 406.666 123.04 406.441C123.04 406.215 127.742 406.022 133.556 406.022C139.37 406.022 144.056 406.215 144.056 406.441Z"
        fill="#455A64"
      />
      <path
        d="M144.056 413.016C144.056 413.241 139.354 413.434 133.556 413.434C127.758 413.434 123.04 413.241 123.04 413.016C123.04 412.79 127.742 412.597 133.556 412.597C139.37 412.597 144.056 412.79 144.056 413.016Z"
        fill="#455A64"
      />
      <path
        d="M144.056 419.607C144.056 419.832 139.354 420.026 133.556 420.026C127.758 420.026 123.04 419.832 123.04 419.607C123.04 419.381 127.742 419.188 133.556 419.188C139.37 419.188 144.056 419.381 144.056 419.607Z"
        fill="#455A64"
      />
      <path
        d="M144.056 426.182C144.056 426.407 139.354 426.601 133.556 426.601C127.758 426.601 123.04 426.407 123.04 426.182C123.04 425.956 127.742 425.763 133.556 425.763C139.37 425.763 144.056 425.956 144.056 426.182Z"
        fill="#455A64"
      />
      <path
        d="M143.476 515.395C143.476 515.395 143.46 515.25 143.444 514.976C143.444 514.67 143.444 514.267 143.412 513.784C143.412 512.688 143.38 511.173 143.364 509.223C143.331 505.226 143.299 499.506 143.251 492.367C143.203 478.04 143.138 458.073 143.058 435.254L143.46 435.657C136.584 435.657 129.417 435.657 122.057 435.657C122.878 434.819 122.283 435.432 122.476 435.254V438.284C122.476 439.299 122.476 440.315 122.476 441.33C122.476 443.344 122.476 445.343 122.476 447.325C122.476 451.289 122.476 455.189 122.476 458.992C122.476 466.615 122.46 473.899 122.444 480.731C122.395 494.365 122.363 506.209 122.331 515.363L122.073 515.105C128.773 515.17 134.071 515.218 137.775 515.25C139.579 515.282 140.98 515.298 141.995 515.315C142.446 515.315 142.8 515.331 143.09 515.347C143.331 515.347 143.476 515.363 143.476 515.379C143.476 515.379 143.364 515.395 143.138 515.411C142.864 515.411 142.51 515.411 142.091 515.443C141.093 515.443 139.708 515.476 137.936 515.508C134.2 515.54 128.837 515.589 122.089 515.653H121.832V515.395C121.799 506.242 121.767 494.397 121.719 480.764C121.719 473.931 121.703 466.647 121.687 459.024C121.687 455.205 121.687 451.321 121.687 447.357C121.687 445.375 121.687 443.376 121.687 441.362C121.687 440.347 121.687 439.348 121.687 438.316V436.028V435.641V435.303C121.88 435.093 121.268 435.673 122.105 434.835C129.465 434.835 136.632 434.835 143.509 434.835H143.927V435.238C143.847 458.17 143.782 478.234 143.734 492.641C143.686 499.731 143.637 505.42 143.621 509.4C143.605 511.302 143.573 512.801 143.573 513.864C143.573 514.332 143.557 514.702 143.541 515.008C143.541 515.266 143.525 515.395 143.509 515.379L143.476 515.395Z"
        fill="#455A64"
      />
      <path
        d="M143.041 442.893C143.041 443.119 138.339 443.312 132.541 443.312C126.744 443.312 122.025 443.119 122.025 442.893C122.025 442.667 126.727 442.474 132.541 442.474C138.355 442.474 143.041 442.667 143.041 442.893Z"
        fill="#455A64"
      />
      <path
        d="M143.041 449.484C143.041 449.71 138.339 449.903 132.541 449.903C126.744 449.903 122.025 449.71 122.025 449.484C122.025 449.259 126.727 449.065 132.541 449.065C138.355 449.065 143.041 449.259 143.041 449.484Z"
        fill="#455A64"
      />
      <path
        d="M143.041 456.059C143.041 456.285 138.339 456.478 132.541 456.478C126.744 456.478 122.025 456.285 122.025 456.059C122.025 455.834 126.727 455.64 132.541 455.64C138.355 455.64 143.041 455.834 143.041 456.059Z"
        fill="#455A64"
      />
      <path
        d="M143.041 462.65C143.041 462.876 138.339 463.069 132.541 463.069C126.744 463.069 122.025 462.876 122.025 462.65C122.025 462.425 126.727 462.231 132.541 462.231C138.355 462.231 143.041 462.425 143.041 462.65Z"
        fill="#455A64"
      />
      <path
        d="M143.041 469.225C143.041 469.451 138.339 469.644 132.541 469.644C126.744 469.644 122.025 469.451 122.025 469.225C122.025 469 126.727 468.806 132.541 468.806C138.355 468.806 143.041 469 143.041 469.225Z"
        fill="#455A64"
      />
      <path
        d="M143.041 475.8C143.041 476.026 138.339 476.219 132.541 476.219C126.744 476.219 122.025 476.026 122.025 475.8C122.025 475.575 126.727 475.381 132.541 475.381C138.355 475.381 143.041 475.575 143.041 475.8Z"
        fill="#455A64"
      />
      <path
        d="M143.041 482.391C143.041 482.617 138.339 482.81 132.541 482.81C126.744 482.81 122.025 482.617 122.025 482.391C122.025 482.166 126.727 481.972 132.541 481.972C138.355 481.972 143.041 482.166 143.041 482.391Z"
        fill="#455A64"
      />
      <path
        d="M143.041 488.966C143.041 489.192 138.339 489.385 132.541 489.385C126.744 489.385 122.025 489.192 122.025 488.966C122.025 488.741 126.727 488.547 132.541 488.547C138.355 488.547 143.041 488.741 143.041 488.966Z"
        fill="#455A64"
      />
      <path
        d="M143.041 495.557C143.041 495.783 138.339 495.976 132.541 495.976C126.744 495.976 122.025 495.783 122.025 495.557C122.025 495.332 126.727 495.138 132.541 495.138C138.355 495.138 143.041 495.332 143.041 495.557Z"
        fill="#455A64"
      />
      <path
        d="M143.041 502.132C143.041 502.358 138.339 502.551 132.541 502.551C126.744 502.551 122.025 502.358 122.025 502.132C122.025 501.907 126.727 501.713 132.541 501.713C138.355 501.713 143.041 501.907 143.041 502.132Z"
        fill="#455A64"
      />
      <path
        d="M143.041 508.707C143.041 508.933 138.339 509.126 132.541 509.126C126.744 509.126 122.025 508.933 122.025 508.707C122.025 508.482 126.727 508.288 132.541 508.288C138.355 508.288 143.041 508.482 143.041 508.707Z"
        fill="#455A64"
      />
      <path d="M258.689 580.13V264.627L199.649 186.243L142.897 264.627V580.307H258.689" fill="#455A64" />
      <path
        d="M228.734 579.292C228.734 579.292 228.702 579.147 228.702 578.889C228.702 578.583 228.686 578.18 228.67 577.696C228.654 576.584 228.638 575.053 228.605 573.071C228.573 568.994 228.541 563.144 228.477 555.86C228.444 541.163 228.396 520.649 228.332 497.169L228.734 497.572C221.407 497.572 213.709 497.572 205.817 497.572C197.926 497.572 190.228 497.572 182.884 497.572L183.287 497.169C183.223 520.649 183.174 541.163 183.142 555.86C183.094 563.144 183.045 568.994 183.013 573.071C182.981 575.037 182.965 576.584 182.949 577.696C182.949 578.196 182.917 578.583 182.917 578.889C182.917 579.163 182.884 579.292 182.884 579.292C182.884 579.292 182.852 579.147 182.852 578.889C182.852 578.583 182.836 578.18 182.82 577.696C182.804 576.584 182.788 575.053 182.756 573.071C182.723 568.994 182.691 563.144 182.627 555.86C182.594 541.163 182.546 520.649 182.482 497.169V496.766H182.884C190.212 496.766 197.91 496.766 205.817 496.766C213.709 496.766 221.407 496.766 228.734 496.766H229.137V497.169C229.073 520.649 229.024 541.163 228.992 555.86C228.944 563.144 228.895 568.994 228.863 573.071C228.831 575.037 228.815 576.584 228.799 577.696C228.799 578.196 228.767 578.583 228.767 578.889C228.767 579.163 228.734 579.292 228.734 579.292Z"
        fill="#263238"
      />
      <path
        d="M222.405 539.681C222.486 539.777 222.067 540.132 222.115 540.857C222.148 541.196 222.292 541.615 222.615 541.856C222.921 542.098 223.404 542.098 223.839 541.856C224.257 541.598 224.499 541.163 224.434 540.793C224.386 540.406 224.08 540.067 223.79 539.89C223.178 539.503 222.663 539.697 222.615 539.584C222.518 539.52 223.082 538.988 224.048 539.358C224.483 539.536 225.014 539.939 225.191 540.648C225.385 541.373 224.95 542.195 224.273 542.581C223.613 542.984 222.679 542.984 222.131 542.469C221.584 541.985 221.487 541.324 221.536 540.857C221.664 539.826 222.405 539.584 222.405 539.697V539.681Z"
        fill="#263238"
      />
      <path d="M215.979 484.583H196.09V490.771H215.979V484.583Z" fill="#263238" />
      <path d="M197.991 389.294H162.238V458.219H197.991V389.294Z" fill="#E0E0E0" />
      <path
        d="M181.113 465.938C180.887 465.938 180.694 447.937 180.694 425.747C180.694 403.556 180.887 385.555 181.113 385.555C181.338 385.555 181.532 403.556 181.532 425.747C181.532 447.937 181.338 465.938 181.113 465.938Z"
        fill="#455A64"
      />
      <path
        d="M200.986 413.241C200.986 413.467 191.839 413.66 180.565 413.66C169.292 413.66 160.145 413.467 160.145 413.241C160.145 413.016 169.292 412.822 180.565 412.822C191.839 412.822 200.986 413.016 200.986 413.241Z"
        fill="#455A64"
      />
      <path
        d="M200.986 437.897C200.986 438.123 191.839 438.316 180.565 438.316C169.292 438.316 160.145 438.123 160.145 437.897C160.145 437.672 169.292 437.478 180.565 437.478C191.839 437.478 200.986 437.672 200.986 437.897Z"
        fill="#455A64"
      />
      <path d="M244.227 389.294H208.475V458.219H244.227V389.294Z" fill="#E0E0E0" />
      <path
        d="M227.349 465.938C227.124 465.938 226.931 447.937 226.931 425.747C226.931 403.556 227.124 385.555 227.349 385.555C227.575 385.555 227.768 403.556 227.768 425.747C227.768 447.937 227.575 465.938 227.349 465.938Z"
        fill="#455A64"
      />
      <path
        d="M247.239 413.241C247.239 413.467 238.091 413.66 226.818 413.66C215.545 413.66 206.397 413.467 206.397 413.241C206.397 413.016 215.545 412.822 226.818 412.822C238.091 412.822 247.239 413.016 247.239 413.241Z"
        fill="#455A64"
      />
      <path
        d="M247.239 437.897C247.239 438.123 238.091 438.316 226.818 438.316C215.545 438.316 206.397 438.123 206.397 437.897C206.397 437.672 215.545 437.478 226.818 437.478C238.091 437.478 247.239 437.672 247.239 437.897Z"
        fill="#455A64"
      />
      <path d="M197.991 291.153H162.238V360.077H197.991V291.153Z" fill="#E0E0E0" />
      <path
        d="M181.113 367.78C180.887 367.78 180.694 349.78 180.694 327.589C180.694 305.398 180.887 287.398 181.113 287.398C181.338 287.398 181.532 305.398 181.532 327.589C181.532 349.78 181.338 367.78 181.113 367.78Z"
        fill="#455A64"
      />
      <path
        d="M200.986 315.1C200.986 315.325 191.839 315.519 180.565 315.519C169.292 315.519 160.145 315.325 160.145 315.1C160.145 314.874 169.292 314.681 180.565 314.681C191.839 314.681 200.986 314.874 200.986 315.1Z"
        fill="#455A64"
      />
      <path
        d="M200.986 339.74C200.986 339.966 191.839 340.159 180.565 340.159C169.292 340.159 160.145 339.966 160.145 339.74C160.145 339.514 169.292 339.321 180.565 339.321C191.839 339.321 200.986 339.514 200.986 339.74Z"
        fill="#455A64"
      />
      <path d="M244.227 291.153H208.475V360.077H244.227V291.153Z" fill="#E0E0E0" />
      <path
        d="M227.349 367.78C227.124 367.78 226.931 349.78 226.931 327.589C226.931 305.398 227.124 287.398 227.349 287.398C227.575 287.398 227.768 305.398 227.768 327.589C227.768 349.78 227.575 367.78 227.349 367.78Z"
        fill="#455A64"
      />
      <path
        d="M247.239 315.1C247.239 315.325 238.091 315.519 226.818 315.519C215.545 315.519 206.397 315.325 206.397 315.1C206.397 314.874 215.545 314.681 226.818 314.681C238.091 314.681 247.239 314.874 247.239 315.1Z"
        fill="#455A64"
      />
      <path
        d="M247.239 339.74C247.239 339.966 238.091 340.159 226.818 340.159C215.545 340.159 206.397 339.966 206.397 339.74C206.397 339.514 215.545 339.321 226.818 339.321C238.091 339.321 247.239 339.514 247.239 339.74Z"
        fill="#455A64"
      />
      <path d="M142.88 431.258H114.713V462.537H142.88V431.258Z" fill="#455A64" />
      <path d="M142.88 330.893H114.713V362.172H142.88V330.893Z" fill="#455A64" />
      <path
        d="M216.339 261.152C223.171 253.285 222.336 241.366 214.474 234.53C206.613 227.694 194.701 228.529 187.87 236.396C181.038 244.263 181.873 256.182 189.735 263.018C197.596 269.854 209.508 269.019 216.339 261.152Z"
        fill="#E0E0E0"
      />
      <path
        d="M221.052 248.77C221.052 248.995 212.066 249.189 200.986 249.189C189.906 249.189 180.92 248.995 180.92 248.77C180.92 248.544 189.906 248.351 200.986 248.351C212.066 248.351 221.052 248.544 221.052 248.77Z"
        fill="#455A64"
      />
      <path
        d="M201.888 272.25C201.662 272.25 201.469 262.016 201.469 249.398C201.469 236.78 201.662 226.547 201.888 226.547C202.113 226.547 202.307 236.78 202.307 249.398C202.307 262.016 202.113 272.25 201.888 272.25Z"
        fill="#455A64"
      />
      <path d="M142.897 264.627H133.797L199.794 176.622L265.791 264.627H258.689L199.649 186.243L142.897 264.627Z" fill="#263238" />
      <path d="M114.713 330.893H70.2807V362.172H114.713V330.893Z" fill="#263238" />
      <path d="M114.713 431.258H70.2807V462.537H114.713V431.258Z" fill="#263238" />
      <path d="M114.713 264.627H70.2807L138.774 176.622H199.794L114.713 264.627Z" fill="#263238" />
      <path
        d="M117.065 462.989C117.065 463.214 106.355 463.408 93.1493 463.408C79.9435 463.408 69.2339 463.214 69.2339 462.989C69.2339 462.763 79.9435 462.57 93.1493 462.57C106.355 462.57 117.065 462.763 117.065 462.989Z"
        fill="#455A64"
      />
      <path
        d="M117.065 431.258C117.065 431.483 106.355 431.677 93.1493 431.677C79.9435 431.677 69.2339 431.483 69.2339 431.258C69.2339 431.032 79.9435 430.839 93.1493 430.839C106.355 430.839 117.065 431.032 117.065 431.258Z"
        fill="#455A64"
      />
      <path
        d="M117.065 362.865C117.065 363.091 106.355 363.284 93.1493 363.284C79.9435 363.284 69.2339 363.091 69.2339 362.865C69.2339 362.64 79.9435 362.446 93.1493 362.446C106.355 362.446 117.065 362.64 117.065 362.865Z"
        fill="#455A64"
      />
      <path
        d="M117.065 331.457C117.065 331.682 106.355 331.876 93.1493 331.876C79.9435 331.876 69.2339 331.682 69.2339 331.457C69.2339 331.231 79.9435 331.038 93.1493 331.038C106.355 331.038 117.065 331.231 117.065 331.457Z"
        fill="#455A64"
      />
      <path
        d="M177.489 487.113C177.489 487.113 177.489 486.92 177.473 486.549C177.473 486.098 177.473 485.582 177.441 484.954C177.441 483.439 177.409 481.473 177.377 479.072L177.489 479.184C171.128 479.184 161.82 479.217 151.384 479.249C151.464 479.168 151.223 479.41 151.545 479.088V480.119V481.15C151.545 481.843 151.545 482.52 151.545 483.181C151.545 484.518 151.545 485.84 151.545 487.113L151.384 486.952C158.76 486.984 165.218 487 170.001 487.032C172.223 487.049 174.059 487.065 175.476 487.081C176.04 487.081 176.539 487.081 176.958 487.097C177.296 487.097 177.489 487.113 177.489 487.113C177.489 487.113 177.328 487.113 177.006 487.129C176.587 487.129 176.12 487.129 175.557 487.145C174.156 487.145 172.336 487.178 170.113 487.194C165.298 487.21 158.808 487.242 151.384 487.274H151.223V487.113C151.223 485.84 151.223 484.518 151.223 483.181C151.223 482.504 151.223 481.827 151.223 481.15V480.119V479.603V479.12V479.088C151.561 478.749 151.319 478.991 151.4 478.91C161.836 478.943 171.144 478.959 177.505 478.975H177.618V479.088C177.602 481.521 177.57 483.519 177.554 485.034C177.554 485.647 177.538 486.162 177.522 486.597C177.522 486.952 177.505 487.129 177.505 487.129L177.489 487.113Z"
        fill="#263238"
      />
      <path
        d="M189.584 479.072C189.584 479.072 189.584 478.878 189.568 478.508C189.568 478.056 189.568 477.541 189.536 476.912C189.536 475.397 189.503 473.431 189.471 471.03L189.584 471.143C183.223 471.143 173.914 471.175 163.478 471.207C163.559 471.127 163.301 471.369 163.639 471.046V471.562V472.078V473.109C163.639 473.802 163.639 474.479 163.639 475.14C163.639 476.477 163.639 477.799 163.639 479.072L163.478 478.91C170.854 478.943 177.312 478.959 182.095 478.991C184.318 479.007 186.154 479.023 187.571 479.039C188.135 479.039 188.634 479.039 189.052 479.055C189.391 479.055 189.584 479.072 189.584 479.072C189.584 479.072 189.423 479.072 189.101 479.088C188.682 479.088 188.215 479.088 187.651 479.104C186.25 479.104 184.43 479.136 182.208 479.152C177.393 479.168 170.903 479.201 163.478 479.233H163.317V479.072C163.317 477.799 163.317 476.477 163.317 475.14C163.317 474.463 163.317 473.786 163.317 473.109V472.078V471.562V471.304V471.175V471.079V471.046C163.655 470.708 163.414 470.95 163.494 470.869C173.93 470.885 183.239 470.917 189.6 470.933H189.713V471.046C189.697 473.48 189.664 475.478 189.648 476.993C189.648 477.605 189.632 478.121 189.616 478.556C189.616 478.91 189.6 479.088 189.6 479.088L189.584 479.072Z"
        fill="#263238"
      />
      <path
        d="M180.131 389.294C180.131 389.294 180.131 389.101 180.114 388.73C180.114 388.279 180.114 387.763 180.082 387.135C180.082 385.62 180.05 383.654 180.018 381.253L180.131 381.365C173.769 381.365 164.461 381.398 154.025 381.43C154.105 381.349 153.864 381.591 154.186 381.269V382.3V383.331C154.186 384.024 154.186 384.701 154.186 385.362C154.186 386.699 154.186 388.021 154.186 389.294L154.025 389.133C161.401 389.165 167.859 389.181 172.642 389.213C174.864 389.23 176.7 389.246 178.117 389.262C178.681 389.262 179.18 389.262 179.599 389.278C179.937 389.278 180.131 389.294 180.131 389.294C180.131 389.294 179.969 389.294 179.647 389.31C179.229 389.31 178.762 389.31 178.198 389.326C176.797 389.326 174.977 389.358 172.755 389.375C167.939 389.391 161.449 389.423 154.025 389.455H153.864V389.294C153.864 388.021 153.864 386.699 153.864 385.362C153.864 384.685 153.864 384.008 153.864 383.331V382.3V381.784V381.301V381.269C154.202 380.93 153.96 381.172 154.041 381.091C164.477 381.108 173.785 381.14 180.147 381.156H180.259V381.269C180.243 383.702 180.211 385.7 180.195 387.215C180.195 387.828 180.179 388.343 180.163 388.778C180.163 389.133 180.147 389.31 180.147 389.31L180.131 389.294Z"
        fill="#263238"
      />
      <path
        d="M249.59 275.682C249.59 275.682 249.59 275.489 249.574 275.118C249.574 274.667 249.574 274.151 249.542 273.523C249.542 272.008 249.509 270.042 249.477 267.641L249.59 267.753C243.228 267.753 233.92 267.786 223.484 267.818C223.565 267.737 223.307 267.979 223.645 267.657V269.736C223.645 270.429 223.645 271.105 223.645 271.766C223.645 273.104 223.645 274.425 223.645 275.698L223.484 275.537C230.86 275.569 237.318 275.585 242.101 275.618C244.324 275.634 246.16 275.65 247.577 275.666C248.14 275.666 248.64 275.666 249.058 275.682C249.397 275.682 249.59 275.698 249.59 275.698C249.59 275.698 249.429 275.698 249.107 275.714C248.688 275.714 248.221 275.714 247.657 275.73C246.256 275.73 244.436 275.763 242.214 275.779C237.399 275.795 230.908 275.827 223.484 275.859H223.323V275.698C223.323 274.425 223.323 273.104 223.323 271.766C223.323 271.089 223.323 270.412 223.323 269.736V268.704V268.189V267.705V267.673C223.661 267.334 223.42 267.576 223.5 267.496C233.936 267.512 243.245 267.544 249.606 267.56H249.719V267.673C249.703 270.106 249.67 272.105 249.654 273.619C249.654 274.232 249.638 274.747 249.622 275.183C249.622 275.537 249.606 275.714 249.606 275.714L249.59 275.682Z"
        fill="#263238"
      />
      <path
        d="M185.236 230.366C185.236 230.366 185.236 230.173 185.22 229.802C185.22 229.351 185.22 228.835 185.187 228.207C185.187 226.692 185.155 224.726 185.123 222.325L185.236 222.438C178.874 222.438 169.566 222.47 159.13 222.502C159.211 222.422 158.969 222.663 159.291 222.341V224.42C159.291 225.113 159.291 225.79 159.291 226.45C159.291 227.788 159.291 229.109 159.291 230.382L159.13 230.221C166.506 230.253 172.964 230.27 177.747 230.302C179.969 230.318 181.805 230.334 183.223 230.35C183.786 230.35 184.286 230.35 184.704 230.366C185.042 230.366 185.236 230.382 185.236 230.382C185.236 230.382 185.075 230.382 184.753 230.399C184.334 230.399 183.867 230.398 183.303 230.415C181.902 230.415 180.082 230.447 177.86 230.463C173.044 230.479 166.554 230.511 159.13 230.544H158.969V230.382C158.969 229.109 158.969 227.788 158.969 226.45C158.969 225.773 158.969 225.097 158.969 224.42V223.388V222.873V222.389V222.357C159.307 222.019 159.066 222.26 159.146 222.18C169.582 222.196 178.89 222.228 185.252 222.244H185.365V222.357C185.348 224.79 185.316 226.789 185.3 228.304C185.3 228.916 185.284 229.432 185.268 229.867C185.268 230.221 185.252 230.399 185.252 230.399L185.236 230.366Z"
        fill="#263238"
      />
      <path
        d="M162.238 352.809C162.238 352.809 162.238 352.616 162.222 352.245C162.222 351.794 162.222 351.278 162.19 350.65C162.19 349.135 162.158 347.169 162.126 344.768L162.238 344.881C155.877 344.881 146.568 344.913 136.133 344.945C136.213 344.865 135.955 345.106 136.294 344.784V345.3V345.815V346.847C136.294 347.54 136.294 348.216 136.294 348.877C136.294 350.215 136.294 351.536 136.294 352.809L136.133 352.648C143.509 352.68 149.966 352.696 154.75 352.729C156.972 352.745 158.808 352.761 160.225 352.777C160.789 352.777 161.288 352.777 161.707 352.793C162.045 352.793 162.238 352.809 162.238 352.809C162.238 352.809 162.077 352.809 161.755 352.825C161.336 352.825 160.869 352.825 160.306 352.842C158.905 352.842 157.085 352.874 154.862 352.89C150.047 352.906 143.557 352.938 136.133 352.97H135.972V352.809C135.972 351.536 135.972 350.215 135.972 348.877C135.972 348.2 135.972 347.524 135.972 346.847V345.815V345.3V345.042V344.913V344.816V344.784C136.31 344.446 136.068 344.687 136.149 344.607C146.585 344.623 155.893 344.655 162.254 344.671H162.367V344.784C162.351 347.217 162.319 349.216 162.303 350.73C162.303 351.343 162.287 351.858 162.27 352.294C162.27 352.648 162.254 352.825 162.254 352.825L162.238 352.809Z"
        fill="#263238"
      />
      <path
        d="M151.818 344.639C151.818 344.639 151.819 344.445 151.802 344.075C151.802 343.624 151.802 343.108 151.77 342.479C151.77 340.965 151.738 338.999 151.706 336.597L151.818 336.71C145.457 336.71 136.149 336.742 125.713 336.775C125.793 336.694 125.552 336.936 125.874 336.614V337.645V338.676C125.874 339.369 125.874 340.046 125.874 340.707C125.874 342.044 125.874 343.366 125.874 344.639L125.713 344.478C133.089 344.51 139.547 344.526 144.33 344.558C146.552 344.574 148.388 344.591 149.805 344.607C150.369 344.607 150.868 344.607 151.287 344.623C151.625 344.623 151.818 344.639 151.818 344.639C151.818 344.639 151.657 344.639 151.335 344.655C150.917 344.655 150.45 344.655 149.886 344.671C148.485 344.671 146.665 344.703 144.443 344.719C139.627 344.736 133.137 344.768 125.713 344.8H125.552V344.639C125.552 343.366 125.552 342.044 125.552 340.707C125.552 340.03 125.552 339.353 125.552 338.676V337.645V337.129V336.646V336.614C125.89 336.275 125.648 336.517 125.729 336.436C136.165 336.452 145.473 336.485 151.835 336.501H151.947V336.614C151.931 339.047 151.899 341.045 151.883 342.56C151.883 343.172 151.867 343.688 151.851 344.123C151.851 344.478 151.835 344.655 151.835 344.655L151.818 344.639Z"
        fill="#263238"
      />
      <path
        d="M258.431 508.031C258.431 508.031 258.431 507.837 258.415 507.467C258.415 507.015 258.415 506.5 258.383 505.871C258.383 504.356 258.351 502.39 258.319 499.989L258.431 500.102C252.07 500.102 242.761 500.134 232.326 500.166C232.406 500.086 232.165 500.328 232.487 500.005V501.037V502.068C232.487 502.761 232.487 503.438 232.487 504.098C232.487 505.436 232.487 506.757 232.487 508.031L232.326 507.869C239.702 507.902 246.16 507.918 250.943 507.95C253.165 507.966 255.001 507.982 256.418 507.998C256.982 507.998 257.481 507.998 257.9 508.014C258.238 508.014 258.431 508.031 258.431 508.031C258.431 508.031 258.27 508.031 257.948 508.047C257.529 508.047 257.062 508.047 256.499 508.063C255.098 508.063 253.278 508.095 251.055 508.111C246.24 508.127 239.75 508.159 232.326 508.192H232.165V508.031C232.165 506.757 232.165 505.436 232.165 504.098C232.165 503.422 232.165 502.745 232.165 502.068V501.037V500.521V500.037V500.005C232.503 499.667 232.261 499.909 232.342 499.828C242.778 499.86 252.086 499.876 258.447 499.892H258.56V500.005C258.544 502.439 258.512 504.437 258.496 505.952C258.496 506.564 258.48 507.08 258.464 507.515C258.464 507.869 258.447 508.047 258.447 508.047L258.431 508.031Z"
        fill="#263238"
      />
      <path
        d="M252.456 374.984C252.456 374.984 252.456 374.79 252.44 374.42C252.44 373.968 252.44 373.453 252.408 372.824C252.408 371.309 252.376 369.343 252.344 366.942L252.456 367.055C246.095 367.055 236.787 367.087 226.351 367.12C226.431 367.039 226.174 367.281 226.512 366.958V369.037C226.512 369.73 226.512 370.407 226.512 371.068C226.512 372.405 226.512 373.727 226.512 375L226.351 374.839C233.727 374.871 240.185 374.887 244.968 374.919C247.19 374.935 249.026 374.952 250.443 374.968C251.007 374.968 251.506 374.968 251.925 374.984C252.263 374.984 252.456 375 252.456 375C252.456 375 252.295 375 251.973 375.016C251.555 375.016 251.088 375.016 250.524 375.032C249.123 375.032 247.303 375.064 245.081 375.08C240.265 375.097 233.775 375.129 226.351 375.161H226.19V375C226.19 373.727 226.19 372.405 226.19 371.068C226.19 370.391 226.19 369.714 226.19 369.037V368.006V367.49V367.007V366.975C226.528 366.636 226.286 366.878 226.367 366.797C236.803 366.813 246.111 366.846 252.473 366.862H252.585V366.975C252.569 369.408 252.537 371.406 252.521 372.921C252.521 373.533 252.505 374.049 252.489 374.484C252.489 374.839 252.473 375.016 252.473 375.016L252.456 374.984Z"
        fill="#263238"
      />
      <path
        d="M258.431 516.04C258.431 516.04 258.415 515.814 258.399 515.444C258.399 514.944 258.383 514.396 258.367 513.8C258.351 512.172 258.319 510.238 258.302 507.998L258.447 508.143C255.468 508.143 252.038 508.159 248.35 508.176C248.318 508.208 248.672 507.853 248.511 508.014V508.288V508.562V509.094C248.511 509.449 248.511 509.803 248.511 510.158C248.511 510.867 248.511 511.544 248.511 512.22C248.511 513.558 248.511 514.831 248.511 516.056L248.366 515.911C251.168 515.943 253.616 515.959 255.613 515.991C256.386 515.991 257.062 516.024 257.691 516.024C258.174 516.024 258.447 516.04 258.464 516.056C258.48 516.072 258.238 516.072 257.755 516.088C257.143 516.088 256.467 516.12 255.71 516.12C253.68 516.136 251.2 516.169 248.366 516.201H248.221V516.056C248.221 514.847 248.221 513.558 248.221 512.22C248.221 511.544 248.221 510.851 248.221 510.158C248.221 509.803 248.221 509.449 248.221 509.094V508.562V508.288V508.159V508.095V508.063V508.031C248.076 508.176 248.43 507.821 248.382 507.853C252.07 507.853 255.5 507.869 258.48 507.885H258.625V508.031C258.592 510.303 258.576 512.269 258.56 513.913C258.56 514.509 258.528 515.024 258.528 515.524C258.528 515.895 258.512 516.088 258.496 516.072L258.431 516.04Z"
        fill="#263238"
      />
      <path
        d="M258.431 383.025C258.431 383.025 258.415 382.8 258.399 382.429C258.399 381.929 258.383 381.381 258.367 380.785C258.351 379.158 258.319 377.224 258.302 374.984L258.447 375.129C255.468 375.129 252.038 375.145 248.35 375.161C248.318 375.193 248.672 374.839 248.511 375V375.274V375.548V376.08C248.511 376.434 248.511 376.789 248.511 377.143C248.511 377.852 248.511 378.529 248.511 379.206C248.511 380.543 248.511 381.817 248.511 383.041L248.366 382.896C251.168 382.929 253.616 382.945 255.613 382.977C256.386 382.977 257.062 383.009 257.691 383.009C258.174 383.009 258.447 383.025 258.464 383.041C258.48 383.057 258.238 383.057 257.755 383.074C257.143 383.074 256.467 383.106 255.71 383.106C253.68 383.122 251.2 383.154 248.366 383.186H248.221V383.041C248.221 381.833 248.221 380.543 248.221 379.206C248.221 378.529 248.221 377.836 248.221 377.143C248.221 376.789 248.221 376.434 248.221 376.08V375.548V375.274V375.145V375.08V375.048V375.016C248.076 375.161 248.43 374.806 248.382 374.839C252.07 374.839 255.5 374.855 258.48 374.871H258.625V375.016C258.592 377.288 258.576 379.254 258.56 380.898C258.56 381.494 258.528 382.026 258.528 382.51C258.528 382.88 258.512 383.074 258.496 383.057L258.431 383.025Z"
        fill="#263238"
      />
      <path d="M305.811 557.021H258.689V580.307H305.811V557.021Z" fill="#455A64" />
      <path
        d="M146.182 264.627C146.182 264.853 129.272 264.966 108.433 264.853C87.5771 264.74 70.6833 264.466 70.6833 264.24C70.6833 264.015 87.5771 263.902 108.433 264.015C129.272 264.128 146.182 264.401 146.182 264.627Z"
        fill="#455A64"
      />
      <path
        d="M108.449 579.969C98.0129 579.969 89.5096 571.476 89.5096 561.017C89.5096 550.558 97.9968 542.066 108.449 542.066C118.901 542.066 127.388 550.558 127.388 561.017C127.388 571.476 118.901 579.969 108.449 579.969ZM108.449 543.79C98.947 543.79 91.2328 551.525 91.2328 561.017C91.2328 570.509 98.9631 578.244 108.449 578.244C117.934 578.244 125.665 570.509 125.665 561.017C125.665 551.525 117.934 543.79 108.449 543.79Z"
        fill="#455A64"
      />
      <path d="M80.3944 563.821L107.515 560.921L107.853 563.193L80.3944 565.932V563.821Z" fill="#E8505B" />
      <path
        d="M53.242 579.969C42.8062 579.969 34.3029 571.476 34.3029 561.017C34.3029 550.558 42.7901 542.066 53.242 542.066C63.6939 542.066 72.1811 550.558 72.1811 561.017C72.1811 571.476 63.6939 579.969 53.242 579.969ZM53.242 543.79C43.7402 543.79 36.0261 551.525 36.0261 561.017C36.0261 570.509 43.7564 578.244 53.242 578.244C62.7276 578.244 70.4579 570.509 70.4579 561.017C70.4579 551.525 62.7276 543.79 53.242 543.79Z"
        fill="#455A64"
      />
      <path d="M95.5328 533.283L94.035 528.803L95.1785 527.046L102.12 525.161L102.57 526.305L96.3541 527.949L95.6777 529.657L96.7084 532.912L95.5328 533.283Z" fill="#E8505B" />
      <path
        d="M107.032 559.986L97.868 539.052L79.4121 561.984L77.7694 560.34L97.0466 537.763L96.3864 536.425L68.9601 542.227L68.3804 540.1L95.7583 534.814L95.1624 533.057L97.0466 532.461L108.449 558.922L107.032 559.986Z"
        fill="#E8505B"
      />
      <path d="M54.1277 561.984L71.2148 564.724L71.2953 562.887L54.6592 560.356L54.1277 561.984Z" fill="#E8505B" />
      <path d="M53.2581 559.744L66.029 542.581L68.3803 543.629L54.8524 560.921L53.2581 559.744Z" fill="#E8505B" />
      <path
        d="M75.6757 569.977C78.5486 569.977 80.8775 567.647 80.8775 564.772C80.8775 561.897 78.5486 559.567 75.6757 559.567C72.8029 559.567 70.4739 561.897 70.4739 564.772C70.4739 567.647 72.8029 569.977 75.6757 569.977Z"
        fill="#E8505B"
      />
      <path d="M68.3803 540.1L67.1081 535.733H64.5797L66.0291 542.581L68.3803 543.629L68.9601 542.227L68.3803 540.1Z" fill="#E8505B" />
      <path
        d="M61.0688 535.652C61.2621 536.264 62.1479 538.827 63.4362 538.923C64.7246 539.02 73.6305 537.634 74.9672 537.296C75.8207 537.086 76.3038 535.668 74.2908 535.668C71.3919 535.668 60.634 534.314 61.085 535.668L61.0688 535.652Z"
        fill="#E8505B"
      />
      <path
        d="M55.5288 561.098C55.5288 562.355 54.5142 563.37 53.2581 563.37C52.0019 563.37 50.9873 562.355 50.9873 561.098C50.9873 559.841 52.0019 558.826 53.2581 558.826C54.5142 558.826 55.5288 559.841 55.5288 561.098Z"
        fill="#E8505B"
      />
      <path
        d="M110.719 561.098C110.719 562.355 109.705 563.37 108.449 563.37C107.193 563.37 106.178 562.355 106.178 561.098C106.178 559.841 107.193 558.826 108.449 558.826C109.705 558.826 110.719 559.841 110.719 561.098Z"
        fill="#E8505B"
      />
      <path
        opacity="0.5"
        d="M95.5328 533.283L94.035 528.803L95.1785 527.046L102.12 525.161L102.57 526.305L96.3541 527.949L95.6777 529.657L96.7084 532.912L95.5328 533.283Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M75.6757 569.977C78.5486 569.977 80.8775 567.647 80.8775 564.772C80.8775 561.897 78.5486 559.567 75.6757 559.567C72.8029 559.567 70.4739 561.897 70.4739 564.772C70.4739 567.647 72.8029 569.977 75.6757 569.977Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M61.0688 535.652C61.2621 536.264 62.1479 538.827 63.4362 538.923C64.7246 539.02 73.6305 537.634 74.9672 537.296C75.8207 537.086 76.3038 535.668 74.2908 535.668C71.3919 535.668 60.634 534.314 61.085 535.668L61.0688 535.652Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M55.5288 561.098C55.5288 562.355 54.5142 563.37 53.2581 563.37C52.0019 563.37 50.9873 562.355 50.9873 561.098C50.9873 559.841 52.0019 558.826 53.2581 558.826C54.5142 558.826 55.5288 559.841 55.5288 561.098Z"
        fill="black"
      />
      <path
        opacity="0.5"
        d="M110.719 561.098C110.719 562.355 109.705 563.37 108.449 563.37C107.193 563.37 106.178 562.355 106.178 561.098C106.178 559.841 107.193 558.826 108.449 558.826C109.705 558.826 110.719 559.841 110.719 561.098Z"
        fill="black"
      />
      <path d="M75.0155 565.916V573.216H71.2953V575.053H77.1896V565.916H75.0155Z" fill="#E8505B" />
      <path d="M171.273 580.307H155.684L152.463 555.667H174.397L171.273 580.307Z" fill="#263238" />
      <path
        d="M166.844 548.335C168.406 547.255 170.371 546.868 172.271 546.933C173.286 546.965 174.381 547.126 175.154 547.787C175.927 548.447 176.233 549.753 175.541 550.494C175.122 550.945 174.494 551.09 173.882 551.155C172.416 551.332 170.774 551.284 169.727 552.315C169.131 552.911 168.793 553.798 168.036 554.152C167.295 554.491 166.361 554.152 165.846 553.508C165.346 552.863 165.218 551.993 165.298 551.171C165.298 551.171 165.266 549.414 166.844 548.318V548.335Z"
        fill="#E8505B"
      />
      <path
        d="M162.029 541.421C163.253 542.743 165.169 542.001 165.975 540.986C166.78 539.971 167.005 538.617 167.182 537.344C167.617 534.411 168.052 531.349 167.054 528.545C166.764 527.707 166.329 526.901 165.636 526.337C164.944 525.773 163.978 525.532 163.156 525.854C162.19 526.257 161.691 527.32 161.369 528.32C160.402 531.253 160.112 534.395 160.499 537.441C160.676 538.875 161.046 540.341 162.013 541.421"
        fill="#E8505B"
      />
      <path
        d="M159.484 543.903C160.418 542.436 160.37 540.486 159.661 538.891C158.953 537.296 157.648 536.039 156.199 535.056C154.508 533.911 152.592 533.09 150.595 532.671C149.805 532.51 148.984 532.397 148.211 532.59C147.422 532.767 146.681 533.283 146.407 534.04C146.005 535.152 146.697 536.361 147.39 537.312C148.63 539.004 149.999 540.583 151.496 542.066C152.688 543.242 154.041 544.402 155.684 544.789C157.326 545.176 158.937 544.886 159.613 543.71"
        fill="#E8505B"
      />
      <path
        d="M164.879 563.531C164.879 563.531 164.895 563.289 164.879 562.854C164.863 562.371 164.847 561.742 164.815 561.017C164.799 560.244 164.783 559.325 164.863 558.294C164.944 557.278 165.137 556.167 165.411 555.022C165.701 553.878 166.119 552.831 166.635 551.96C167.15 551.09 167.794 550.43 168.439 550.011C169.067 549.592 169.662 549.366 170.081 549.253C170.5 549.156 170.725 549.092 170.725 549.076C170.725 549.06 170.484 549.076 170.049 549.156C169.614 549.237 169.002 549.43 168.326 549.849C167.649 550.268 166.957 550.945 166.409 551.832C165.862 552.718 165.427 553.798 165.137 554.958C164.847 556.118 164.67 557.246 164.606 558.278C164.541 559.309 164.573 560.244 164.606 561.017C164.654 561.791 164.718 562.419 164.751 562.854C164.799 563.289 164.831 563.531 164.847 563.531H164.879Z"
        fill="#263238"
      />
      <path
        d="M164.815 562.113C164.815 562.113 164.815 562 164.799 561.807C164.767 561.581 164.734 561.275 164.686 560.921C164.59 560.147 164.428 559.051 164.3 557.665C164.01 554.926 163.849 551.944 163.752 547.722C163.672 543.758 163.639 539.133 163.865 536.103C163.913 535.41 163.961 534.798 164.01 534.25C164.058 533.702 164.09 533.235 164.139 532.848C164.171 532.493 164.203 532.187 164.235 531.962C164.251 531.752 164.251 531.655 164.235 531.655C164.235 531.655 164.203 531.752 164.171 531.962C164.139 532.187 164.09 532.477 164.026 532.848C163.961 533.235 163.897 533.702 163.849 534.25C163.784 534.798 163.72 535.426 163.655 536.103C163.414 538.859 163.269 542.694 163.349 546.916C163.446 551.139 163.736 554.958 164.09 557.714C164.251 559.083 164.428 560.195 164.573 560.953C164.638 561.307 164.702 561.597 164.751 561.823C164.799 562.016 164.815 562.129 164.831 562.129L164.815 562.113Z"
        fill="#263238"
      />
      <path
        d="M163.865 554.797C163.865 554.797 163.833 554.474 163.736 553.878C163.623 553.298 163.43 552.444 163.124 551.413C162.802 550.381 162.399 549.173 161.836 547.883C161.578 547.287 160.998 545.869 159.742 543.774C158.083 540.954 156.038 538.73 154.347 537.409C153.944 537.054 153.509 536.812 153.171 536.57C152.994 536.458 152.833 536.345 152.672 536.248C152.511 536.168 152.366 536.087 152.221 536.023C151.69 535.749 151.384 535.604 151.384 535.636C151.351 535.7 152.575 536.216 154.234 537.57C155.877 538.907 157.858 541.131 159.517 543.935C160.434 545.45 161.111 546.82 161.61 547.996C162.19 549.285 162.609 550.478 162.947 551.493C163.269 552.508 163.494 553.346 163.639 553.927C163.768 554.507 163.849 554.829 163.881 554.829L163.865 554.797Z"
        fill="#263238"
      />
      <path d="M174.864 555.667H151.384V558.39H174.864V555.667Z" fill="#263238" />
      <path
        d="M174.268 558.31C174.268 558.407 169.518 558.471 163.639 558.471C157.761 558.471 153.01 558.39 153.01 558.31C153.01 558.229 157.761 558.149 163.639 558.149C169.518 558.149 174.268 558.229 174.268 558.31Z"
        fill="#E0E0E0"
      />
      <path
        d="M172.867 567.802C172.867 567.802 172.642 567.995 172.207 568.269C171.756 568.527 171.112 568.946 170.194 569.204C169.292 569.51 168.052 569.478 166.925 568.865C165.781 568.301 164.799 567.35 163.575 566.674C162.367 565.948 160.982 565.836 159.919 566.432C158.84 566.964 158.019 567.737 157.197 568.205C156.376 568.688 155.587 568.833 155.056 568.785C154.524 568.736 154.266 568.559 154.266 568.559C154.266 568.527 154.54 568.656 155.056 568.656C155.555 568.656 156.296 568.495 157.085 568.011C157.874 567.56 158.679 566.754 159.79 566.174C160.354 565.884 161.03 565.691 161.723 565.739C162.431 565.787 163.108 566.045 163.752 566.4C165.024 567.109 166.007 568.059 167.086 568.607C168.149 569.204 169.276 569.252 170.146 568.994C171.901 568.462 172.851 567.737 172.883 567.818L172.867 567.802Z"
        fill="#E0E0E0"
      />
      <path
        d="M155.458 565.433C155.458 565.433 155.684 565.272 156.006 565.497C156.151 565.61 156.296 565.803 156.296 566.093C156.296 566.384 156.054 566.641 155.78 566.722C155.506 566.819 155.152 566.722 154.991 566.496C154.83 566.271 154.846 566.013 154.895 565.852C155.023 565.481 155.313 565.449 155.313 565.481C155.329 565.529 155.168 565.626 155.136 565.9C155.088 566.174 155.297 566.529 155.684 566.4C156.07 566.287 156.054 565.868 155.861 565.674C155.684 565.465 155.474 565.481 155.474 565.433H155.458Z"
        fill="#E0E0E0"
      />
      <path
        d="M161.417 567.898C161.417 567.898 161.256 567.995 161.159 568.205C161.046 568.414 161.143 568.753 161.481 568.736C161.819 568.736 161.884 568.398 161.771 568.188C161.658 567.979 161.497 567.947 161.497 567.898C161.481 567.866 161.739 567.786 161.964 568.06C162.061 568.188 162.158 568.398 162.093 568.64C162.029 568.898 161.755 569.075 161.481 569.075C161.208 569.075 160.934 568.93 160.853 568.672C160.773 568.43 160.853 568.221 160.95 568.092C161.159 567.802 161.433 567.866 161.417 567.915V567.898Z"
        fill="#E0E0E0"
      />
      <path
        d="M168.841 566.158C168.841 566.158 168.68 566.287 168.616 566.561C168.535 566.819 168.696 567.205 169.115 567.141C169.518 567.076 169.566 566.657 169.405 566.432C169.26 566.206 169.05 566.174 169.067 566.126C169.05 566.093 169.324 565.997 169.598 566.287C169.727 566.416 169.84 566.641 169.791 566.915C169.743 567.189 169.469 567.431 169.179 567.463C168.889 567.512 168.567 567.383 168.422 567.141C168.294 566.899 168.326 566.641 168.406 566.48C168.583 566.126 168.873 566.126 168.857 566.158H168.841Z"
        fill="#E0E0E0"
      />
      <path d="M465.763 559.277H395.16V580.243H465.763V559.277Z" fill="#455A64" />
      <path d="M468.355 558.036H392.229V562.065H468.355V558.036Z" fill="#455A64" />
      <path
        d="M465.473 562C465.473 562.226 449.738 562.419 430.348 562.419C410.958 562.419 395.224 562.226 395.224 562C395.224 561.775 410.942 561.581 430.348 561.581C449.755 561.581 465.473 561.775 465.473 562Z"
        fill="#263238"
      />
      <path
        d="M466.101 567.495C466.101 567.641 450.173 567.753 430.526 567.753C410.878 567.753 394.934 567.641 394.934 567.495C394.934 567.35 410.862 567.238 430.526 567.238C450.189 567.238 466.101 567.35 466.101 567.495Z"
        fill="#FAFAFA"
      />
      <path
        d="M464.442 558.036C463.492 554.636 461.978 553.008 458.596 552.412C458.886 546.224 454.215 540.164 448.16 538.875C442.105 537.586 435.373 541.196 433.118 546.965C429.35 543.79 423.907 542.743 419.22 544.274C414.534 545.804 410.765 549.866 409.573 554.652C404.597 552.315 398.735 553.717 396.706 558.036H464.442Z"
        fill="#263238"
      />
      <path
        d="M668.085 501.133C668.085 501.133 665.154 500.295 664.027 507.048C662.9 513.8 662.835 517.506 662.835 517.506C662.835 517.506 662.433 518.973 661.354 518.441C660.274 517.909 658.406 516.008 657.118 516.878C655.781 517.764 655.813 519.569 656.86 521.89C657.907 524.226 660.017 529.415 660.194 529.883C660.371 530.35 660.323 531.124 659.534 530.882C658.728 530.64 653.623 527.627 652.351 530.141C651.063 532.654 655.04 538.665 655.475 539.326C655.894 539.987 655.862 540.341 655.572 540.632C655.282 540.922 653.655 539.745 652.174 538.956C650.692 538.166 648.824 537.489 648.196 539.004C647.584 540.519 648.824 545.579 656.087 552.138L659.646 554.958L663.914 553.346C672.836 549.334 675.574 544.902 675.445 543.258C675.316 541.631 673.335 541.695 671.677 541.985C670.018 542.291 668.117 542.904 667.924 542.549C667.731 542.179 667.811 541.84 668.407 541.324C669.019 540.825 674.64 536.313 674.189 533.541C673.738 530.753 667.956 532.058 667.119 532.042C666.282 532.042 666.475 531.269 666.797 530.882C667.119 530.495 670.71 526.192 672.417 524.307C674.124 522.405 674.704 520.713 673.706 519.456C672.739 518.248 670.388 519.472 669.196 519.65C668.005 519.827 668.069 518.312 668.069 518.312C668.069 518.312 669.148 514.767 670.147 507.998C671.145 501.23 668.021 501.133 668.021 501.133"
        fill="#E8505B"
      />
      <path
        d="M667.071 507.176C667.071 507.176 667.071 507.225 667.071 507.321C667.071 507.418 667.038 507.563 667.022 507.74C666.974 508.111 666.893 508.659 666.797 509.336C666.604 510.722 666.298 512.736 665.927 515.218C665.17 520.181 664.075 527.046 662.835 534.604C661.595 542.179 660.484 549.027 659.695 553.991C659.308 556.473 658.986 558.471 658.777 559.873C658.664 560.566 658.583 561.098 658.519 561.468C658.487 561.646 658.471 561.791 658.455 561.887C658.439 561.984 658.422 562.032 658.422 562.032C658.422 562.032 658.422 561.984 658.422 561.887C658.422 561.791 658.455 561.646 658.471 561.468C658.519 561.098 658.6 560.55 658.696 559.873C658.889 558.487 659.195 556.473 659.566 553.991C660.323 549.027 661.418 542.162 662.658 534.588C663.898 527.014 665.009 520.165 665.798 515.202C666.185 512.72 666.507 510.722 666.716 509.32C666.829 508.627 666.91 508.095 666.974 507.724C667.006 507.547 667.022 507.402 667.038 507.305C667.055 507.209 667.071 507.16 667.071 507.16V507.176Z"
        fill="#263238"
      />
      <path
        d="M659.614 519.633C659.614 519.633 659.695 519.714 659.824 519.891C659.952 520.069 660.113 520.31 660.323 520.633C660.741 521.261 661.289 522.147 661.885 523.13C662.465 524.097 662.98 524.984 663.383 525.677C663.56 525.983 663.721 526.241 663.834 526.45C663.946 526.627 663.995 526.74 663.995 526.74C663.995 526.74 663.914 526.66 663.785 526.482C663.656 526.305 663.495 526.063 663.286 525.741C662.883 525.113 662.336 524.21 661.74 523.227C661.16 522.26 660.629 521.374 660.226 520.697C660.049 520.391 659.888 520.149 659.759 519.94C659.646 519.762 659.598 519.65 659.598 519.65L659.614 519.633Z"
        fill="#263238"
      />
      <path
        d="M664.413 526.676C664.413 526.676 666.169 525.419 668.375 523.952C670.581 522.486 672.401 521.326 672.417 521.358C672.434 521.39 670.662 522.615 668.456 524.081C666.249 525.548 664.429 526.708 664.413 526.676Z"
        fill="#263238"
      />
      <path
        d="M661.982 538.214C661.982 538.214 662.094 538.166 662.304 538.101C662.545 538.021 662.835 537.94 663.173 537.827C663.914 537.602 664.913 537.28 666.024 536.877C667.135 536.474 668.117 536.087 668.826 535.797C669.164 535.668 669.438 535.555 669.68 535.459C669.873 535.378 669.986 535.346 670.002 535.346C670.002 535.346 669.905 535.41 669.712 535.507C669.519 535.604 669.229 535.733 668.89 535.894C668.182 536.216 667.2 536.619 666.088 537.022C664.977 537.425 663.962 537.731 663.222 537.94C662.851 538.037 662.545 538.118 662.336 538.166C662.127 538.214 662.014 538.23 661.998 538.23L661.982 538.214Z"
        fill="#263238"
      />
      <path
        d="M656.232 532.8C656.232 532.8 656.329 532.864 656.474 532.993C656.619 533.122 656.844 533.315 657.118 533.541C657.649 534.008 658.39 534.669 659.179 535.41C659.985 536.151 660.693 536.812 661.209 537.312C661.466 537.554 661.676 537.763 661.821 537.908C661.965 538.053 662.03 538.134 662.03 538.134C662.03 538.134 661.933 538.069 661.788 537.94C661.643 537.811 661.418 537.618 661.144 537.392C660.613 536.925 659.872 536.264 659.083 535.523C658.278 534.782 657.569 534.121 657.054 533.621C656.796 533.38 656.586 533.17 656.442 533.025C656.297 532.88 656.232 532.8 656.232 532.8Z"
        fill="#263238"
      />
      <path
        d="M652.802 542.71C652.802 542.71 652.915 542.791 653.124 542.952C653.333 543.146 653.623 543.371 653.945 543.661C654.638 544.257 655.572 545.095 656.603 546.03C657.633 546.965 658.567 547.819 659.244 548.431C659.566 548.721 659.824 548.979 660.033 549.173C660.21 549.35 660.307 549.447 660.307 549.463C660.307 549.463 660.194 549.382 659.985 549.221C659.775 549.028 659.485 548.802 659.163 548.512C658.471 547.916 657.537 547.078 656.506 546.143C655.475 545.208 654.541 544.354 653.865 543.742C653.543 543.452 653.285 543.194 653.076 543C652.899 542.823 652.802 542.726 652.802 542.71Z"
        fill="#263238"
      />
      <path
        d="M660.162 549.253C660.162 549.253 660.307 549.189 660.548 549.092C660.838 548.995 661.192 548.866 661.643 548.705C662.561 548.383 663.834 547.916 665.219 547.384C666.604 546.836 667.86 546.32 668.746 545.933C669.18 545.756 669.535 545.595 669.808 545.482C670.066 545.369 670.195 545.321 670.211 545.337C670.211 545.337 670.082 545.418 669.841 545.547C669.599 545.676 669.245 545.837 668.794 546.046C667.908 546.449 666.668 546.997 665.267 547.529C663.882 548.077 662.594 548.512 661.676 548.818C661.209 548.963 660.838 549.092 660.564 549.156C660.307 549.237 660.162 549.269 660.146 549.253H660.162Z"
        fill="#263238"
      />
      <path d="M668.407 580.307H652.834L649.597 555.667H671.532L668.407 580.307Z" fill="#263238" />
      <path d="M672.015 555.667H648.534V558.39H672.015V555.667Z" fill="#263238" />
      <path
        d="M671.419 558.31C671.419 558.407 666.668 558.471 660.79 558.471C654.912 558.471 650.161 558.39 650.161 558.31C650.161 558.229 654.912 558.149 660.79 558.149C666.668 558.149 671.419 558.229 671.419 558.31Z"
        fill="#E0E0E0"
      />
      <path
        d="M670.002 567.802C670.002 567.802 669.776 567.995 669.341 568.269C668.89 568.527 668.246 568.946 667.328 569.204C666.426 569.51 665.186 569.478 664.059 568.865C662.916 568.301 661.933 567.35 660.709 566.674C659.501 565.948 658.116 565.836 657.054 566.432C655.975 566.964 655.153 567.737 654.332 568.205C653.511 568.688 652.721 568.833 652.19 568.785C651.659 568.736 651.401 568.559 651.401 568.559C651.401 568.527 651.675 568.656 652.19 568.656C652.689 568.656 653.43 568.495 654.219 568.011C655.008 567.56 655.814 566.754 656.925 566.174C657.488 565.884 658.165 565.691 658.857 565.739C659.566 565.787 660.242 566.045 660.886 566.4C662.159 567.109 663.141 568.059 664.22 568.607C665.283 569.204 666.41 569.252 667.28 568.994C669.035 568.462 669.986 567.737 670.018 567.818L670.002 567.802Z"
        fill="#E0E0E0"
      />
      <path
        d="M652.609 565.433C652.609 565.433 652.834 565.272 653.156 565.497C653.301 565.61 653.446 565.803 653.446 566.093C653.446 566.384 653.205 566.641 652.931 566.722C652.657 566.819 652.303 566.722 652.142 566.496C651.981 566.271 651.997 566.013 652.045 565.852C652.174 565.481 652.464 565.449 652.464 565.481C652.48 565.529 652.319 565.626 652.287 565.9C652.238 566.174 652.448 566.529 652.834 566.4C653.221 566.287 653.205 565.868 653.011 565.674C652.834 565.465 652.625 565.481 652.625 565.433H652.609Z"
        fill="#E0E0E0"
      />
      <path
        d="M658.567 567.898C658.567 567.898 658.406 567.995 658.31 568.205C658.197 568.414 658.294 568.753 658.632 568.736C658.97 568.736 659.034 568.398 658.922 568.188C658.809 567.979 658.648 567.947 658.648 567.898C658.632 567.866 658.89 567.786 659.115 568.06C659.212 568.188 659.308 568.398 659.244 568.64C659.179 568.898 658.906 569.075 658.632 569.075C658.358 569.075 658.084 568.93 658.004 568.672C657.923 568.43 658.004 568.221 658.1 568.092C658.31 567.802 658.584 567.866 658.567 567.915V567.898Z"
        fill="#E0E0E0"
      />
      <path
        d="M665.976 566.158C665.976 566.158 665.815 566.287 665.75 566.561C665.67 566.819 665.831 567.205 666.249 567.141C666.652 567.076 666.7 566.657 666.539 566.432C666.394 566.206 666.185 566.174 666.201 566.126C666.185 566.093 666.459 565.997 666.732 566.287C666.861 566.416 666.974 566.641 666.926 566.915C666.877 567.189 666.604 567.431 666.314 567.463C666.024 567.512 665.702 567.383 665.557 567.141C665.428 566.899 665.46 566.641 665.541 566.48C665.718 566.126 666.008 566.126 665.992 566.158H665.976Z"
        fill="#E0E0E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_34_118">
        <rect width="767" height="581" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
