import {createStyles, ScrollArea} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {amenity, printedStation, SlideProps, station, transport, transportMode} from 'src/types/locationDetails';
import LeafletMap from 'src/app/components/leaflet-map/LeafletMap';
import {IconTransport} from 'src/assets/icons/IconTransport';
import {IconCommerce} from 'src/assets/icons/IconCommerce';
import { useMediaQuery } from '@mantine/hooks';
import classnames from 'classnames';
import {useEffect, useState} from 'react';
import {MdDirectionsBus, MdDirectionsSubway, MdTram} from 'react-icons/md';
import { doc } from 'prettier';

type point = {
  latitude: number;
  longitude: number;
  distance?: number;
};

function distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6378137;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;

  return Math.round(R * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)));
}

export default function SlideTransport({propertyDetails}: SlideProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  let isMobileVersion = useMediaQuery('(max-width: 1020px)');
  const property = propertyDetails.property;

  const isBackgroundLight = 1;
  let backgroundShade = isBackgroundLight ? classes.backgroundLight : classes.backgroundDark;
  let backgroundShadeSeparator = isBackgroundLight ? classes.transportRowSeparatorDark : classes.transportRowSeparatorLight;
  let color = isBackgroundLight ? '#000' : '#fff';

  const [stationList, setStationList] = useState<printedStation[]>([]);
  const [marketList, setMarketList] = useState<amenity[]>([]);

  function dedupe(stations: station[]): Map<string, printedStation> {
    let result = new Map();

    orderByPoints(stations);
    for (let station of stations) {
      let obj: printedStation = result.get(station.title);

      if (obj == null) {
        obj = {
          __set: new Set(),
          station: station,
          transportsMode: {
            highSpeedTrain: [],
            intercityTrain: [],
            interRegionalTrain: [],
            regionalTrain: [],
            cityTrain: [],
            bus: [],
            ferry: [],
            subway: [],
            lightRail: [],
            privateBus: [],
            inclined: [],
            aerial: [],
            busRapid: [],
            monorail: [],
            flight: [],
            train: [],
          },
        };

        result.set(station.title, obj);
      }

      for (let transport of station.transports as transport[]) {
        let key = `${transport.mode}:${transport.name}`;

        if (obj.__set.has(key)) continue;

        obj.transportsMode[transport.mode as keyof transportMode].push(transport);
        obj.__set.add(key);
      }

      obj.transportsMode.train = [
        ...obj.transportsMode.highSpeedTrain,
        ...obj.transportsMode.interRegionalTrain,
        ...obj.transportsMode.intercityTrain,
        ...obj.transportsMode.regionalTrain,
      ];

      obj.transportsMode.subway = [...obj.transportsMode.subway, ...obj.transportsMode.monorail];
    }

    for (let station of result.values())
      delete station.__set;

    return result;
  }

  function orderByPoints<T extends point>(points: T[]): T[] {
    for (let point of points) {
      point.distance = distance(property.latitude, property.longitude, point.latitude, point.longitude);
    }

    return points.sort((a, b) => (a.distance || 0) - (b.distance || 0));
  }

  useEffect(() => {
    setStationList([...dedupe(property.stations).values()]);
    setMarketList([...orderByPoints<amenity>(property.markets)]);
  }, []);


  const transportListHtml = (
    <div>
    <div className={classes.transportFlex}>
      <div className={classes.transportIconRow}>
        <IconTransport className={classes.transportIcon} fill={color} />
        <div className={classes.transportLabel}>{t('transactionTransport.transports')}</div>
      </div>
    </div>

    <div className={classes.transportRowGroup}>
      {stationList.map((printedStation: printedStation) =>
        printedStation.transportsMode.bus.length +
        printedStation.transportsMode.lightRail.length +
        printedStation.transportsMode.subway.length +
        printedStation.transportsMode.train.length ? (
          <div className={classes.transportRow} key={printedStation.station.title}>
            <div>
              <div>{printedStation.station.title}</div>
              <div>
                {printedStation.transportsMode.subway.length ? (
                  <div className={classes.transportLines}>
                    <MdDirectionsSubway className={classes.transportSmallIcon} fill={color} /> {t('transactionTransport.subway')} :
                    {printedStation.transportsMode.subway.map((transport: transport) => (
                      <div
                        className={transport.name.length < 3 ? classnames(classes.transportLineIcon, classes.transportLineIconStandard) : classes.transportLineIcon}
                        style={{backgroundColor: transport.color, color: transport.textColor}}
                        key={transport.mode + transport.name}>
                        {transport.name}
                      </div>
                    ))}
                  </div>
                ) : null}
                {printedStation.transportsMode.lightRail.length ? (
                  <div className={classes.transportLines}>
                    <MdTram className={classes.transportSmallIcon} fill={color} /> {t('transactionTransport.lightRail')} :
                    {printedStation.transportsMode.lightRail.map((transport: transport) => (
                      <div
                        className={transport.name.length < 3 ? classnames(classes.transportLineIcon, classes.transportLineIconStandard) : classes.transportLineIcon}
                        style={{backgroundColor: transport.color, color: transport.textColor}}
                        key={transport.mode + transport.name}>
                        {transport.name}
                      </div>
                    ))}
                  </div>
                ) : null}
                {printedStation.transportsMode.bus.length ? (
                  <div className={classes.transportLines}>
                    <MdDirectionsBus className={classes.transportSmallIcon} fill={color} />
                    {t('transactionTransport.bus')} :
                    {printedStation.transportsMode.bus.map((transport: transport) => (
                      <div
                        className={transport.name.length < 3 ? classnames(classes.transportLineIcon, classes.transportLineIconStandard) : classes.transportLineIcon}
                        style={{backgroundColor: transport.color, color: transport.textColor}}
                        key={transport.mode + transport.name}>
                        {transport.name}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={classnames(classes.transportRowSeparator, backgroundShadeSeparator)} />
            <div className={classes.transportRowDistance}>{printedStation.station.distance} m</div>
          </div>
        ) : null,
      )}
      {property.stations.length === 0 ? <div className={classes.noElement}>{t('transactionTransport.noTransport')}</div> : null}
    </div>

    <div className={classes.transportFlex}>
      <div className={classes.transportIconRow}>
        <IconCommerce className={classes.transportIcon} fill={color} />
        <div className={classes.transportLabel}>{t('transactionTransport.commerce')}</div>
      </div>
    </div>

    <div className={classes.transportRowGroup}>
      {marketList.map((market: amenity) => (
        <div className={classes.transportRow} key={market.title}>
          <div>{market.title}</div>
          <div className={classnames(classes.transportRowSeparator, backgroundShadeSeparator)} />
          <div className={classes.transportRowDistance}>{market.distance} m</div>
        </div>
      ))}
      {property.markets.length === 0 ? <div className={classes.noElement}>{t('transactionTransport.noMarket')}</div> : null}
    </div>
  </div>
  );

  const leafletMapHtml = (
    <LeafletMap
        coordinatesArray={[property.latitude, property.longitude]}
        borderRadius="20px"
        height={document.body.clientWidth > 1020 ? '30em' : '20em'}
        width={document.body.clientWidth > 1020 ? '40em' : '100%'}
        dragging={true}
        doubleClickZoom={true}
        displayPin={true}
        markets={property.markets}
        stations={stationList}
      />
  );

  // mobile version
  if (isMobileVersion) {
    return (
      <div id='2' className={classnames(classes.transportPage, backgroundShade)}>
        <div className={classes.transportDetails}>
          {transportListHtml}
        </div>
  
        {leafletMapHtml}
      </div>
    );
  }

  // desktop version
  return (
    <div id='2' className={classnames(classes.transportPage, backgroundShade)}>
      <div className={classes.transportDetails}>

        <ScrollArea classNames={{root: classes.transportContentRoot, viewport: classes.transportDetailsScroll}} tabIndex={-1} offsetScrollbars>
        {transportListHtml}
        </ScrollArea>
      </div>

      {leafletMapHtml}
    </div>
  );
}

const useStyles = createStyles(theme => ({
  transportPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,

    // align divs vertically
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
      padding:'0 1rem 0 1rem',
    }
    
  },
  transportDetails: {
    flex: '0 0 65%',
    marginRight: '1rem',
    height: '100%',

    [`@media (max-width: 1020px)`]: {
      marginRight: '0',
      flex: '0',
      width: '100%',
      paddingBottom: '1rem',
    }
  },
  transportRowGroup: {
    margin: '2rem 0 3rem',
    fontSize: '1.25rem',
    fontWeight: 400,
    '&:last-child': {
      marginBottom: '0',
    },
  },
  transportRow: {
    display: 'flex',
    margin: '1rem 0',
    justifyContent: 'left',
    alignItems: 'center',
  },
  transportRowSeparator: {
    flex: '1 0 0px',
    margin: 'auto 1rem',
  },
  transportRowSeparatorDark: {
    borderTop: `4px dotted ${theme.colors.black_pearl[0]}`,
  },
  transportRowSeparatorLight: {
    borderTop: `4px dotted ${theme.white}`,
  },
  transportRowDistance: {
    marginLeft: 'auto',
  },
  transportIconRow: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '3rem',
    '&:last-child': {
      marginRight: '0',
    },
  },
  transportIcon: {
    width: '64px',
    height: '64px',
    marginRight: '.5rem',
  },
  transportLabel: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  transportFlex: {
    display: 'flex',
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  transportLineIcon: {
    borderRadius: '.2rem',
    margin: '.2rem',
    height: '1.5rem',
  },
  transportLineIconStandard: {
    width: '1.5rem',
    height: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transportLines: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  transportSmallIcon: {
    marginTop: '.1rem',
  },
  noElement: {
    textAlign: 'center',
    fontStyle: 'italic',
  },
  transportDetailsScroll: {
    maxHeight: '350px',
    whiteSpace: 'break-spaces',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '1rem',
  },
  transportContentRoot: {
    outline: '0',
    display: 'flex',
    gap: '1rem',
  },
}));
