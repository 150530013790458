export default {
  currency(currency: string): string {
    switch (currency) {
      case 'dollar':
        return '$';
      default:
        return '€';
    }
  },
  formatNumber(value: number, formatLanguage: string, minimumSignificantDigits?: number, maximumSignificantDigits?: number): string {
    return Intl.NumberFormat(formatLanguage, {minimumSignificantDigits: minimumSignificantDigits, maximumSignificantDigits: maximumSignificantDigits}).format(value);
  },
  getYear(): number {
    return new Date().getFullYear()
  },
  surfaceArea(structureAreasLiveableUnits: string): string {
    switch (structureAreasLiveableUnits) {
      case 'foot':
        return 'ft²';
      case 'meter':
        return 'm²';
      default:
        return 'm²';
    }
  },
};
