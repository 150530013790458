import { createStyles } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import ImagePlaceholder from "../../../assets/images/misc/image-placeholder.png";

import { PropertyDetails } from "src/types/locationDetails"

type PropertyPresentationComponentProps = {
    className?: string;
    property: PropertyDetails;
}

export default function PropertyPresentationComponent(props: PropertyPresentationComponentProps): JSX.Element {
    
    const property = props.property;
    const {classes} = useStyles();
    const [showImages, setShowImages] = useState(property.virtualTourUrl === null);
    const {t} = useTranslation()

    return (
        <div className={classes.propertyPresentationWrapper}>
          {
            property.virtualTourUrl !== null && 
                <button 
                    className={classes.swapPresentationModeButton} 
                    onClick={() => setShowImages(!showImages)}
                >
                    {showImages ? t("transactionDetails.showVirtualTour") : t("transactionDetails.showImages")}
                </button>
          }
          {
            (property.virtualTourUrl === null || showImages) ? 
              <div className={classes.imageGalleryWrapper}>
                <ImageGallery 
                  onErrorImageURL={ImagePlaceholder} 
                  items={property.publicImages.map((image) => ({
                    original: image.imageLarge,
                    thumbnail: image.imageThumbnail,
                    thumbnailClass: classes.thumbnail,
                  }))} 
                />
              </div> 
              :
              <iframe 
                allow="fullscreen"
                className={classes.virtualTour}
                title="Property virtual tour" 
                src={property.virtualTourUrl}
              />
          }
        </div>
    )
}

const useStyles = createStyles(theme => ({
    propertyPresentationWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },
    imageGalleryWrapper: {
        overflow: 'hidden',
        borderRadius: '.25rem',
        background: 'rgba(0, 0, 0, .025)',
        position: 'sticky',
        top: 'calc(50% - 200px)',
        [`@media (max-width: 1020px)`]: {
          width: '85vw',
          // imageGalleryWrapper is centered horizontally
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        width: '50vw',
        height: '400px',
        // This is not perfect but the lib is broken
        '.image-gallery, .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide': {
          height: '100%',
        },
        '.image-gallery-content': {
          display: 'flex',
          overflow: 'hidden',
          flexDirection: 'column',
          height: '100%',
        },
        '.image-gallery-slide-wrapper': {
          flex: '1 0 0',
          overflow: 'hidden',
        },
        '.image-gallery-icon:hover': {
          color: theme.colors.local_red[0],
        },
        '.image-gallery-thumbnail:hover, .image-gallery-thumbnail.active': {
          border: '4px solid ' + theme.colors.local_red[0],
        },
        '.image-gallery-thumbnail:focus': {
          border: '4px solid transparent',
        },
        'img': {
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        },
    },
    virtualTour: {
        width: "50vw",
        height: "400px",
        overflow: 'hidden',
        borderRadius: '.25rem',
        background: 'rgba(0, 0, 0, .025)',
        position: 'sticky',
        top: 'calc(50% - 200px)',
        [`@media (max-width: 1020px)`]: {
          width: '85vw',
          // virtuala tour iframe is centered horizontally
          margin: "auto",
        },
      },
    thumbnail: {
        width: '100px !important',
        aspectRatio: '150/113',
        'img': {
          display: 'block',
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        },
        'span': {
          display: 'block',
          height: '100%',
        }
    },
    swapPresentationModeButton: {
        background: "none",
        width: "fit-content",
        color: "black",
        fontSize: '0.9rem',
        padding: "5px 20px", 
        border: '2px solid black',
        borderRadius: '99px',
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
        }
    }
}));