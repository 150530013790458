import {createStyles, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import HonorairesVente from 'src/app/components/honoraires/HonorairesVente';
import HonorairesLocation from 'src/app/components/honoraires/HonorairesLocation';
import HonorairesGestion from 'src/app/components/honoraires/HonorairesGestion';

export default function Honoraires(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  const [currentPage, setCurrentPage] = useState('vente');

  const pages: any = {
    vente: (): JSX.Element => <HonorairesVente />,
    location: (): JSX.Element => <HonorairesLocation />,
    gestion: (): JSX.Element => <HonorairesGestion />,
  };

  function renderButton(id: string, label: string): JSX.Element {
    return (
      <button className={currentPage === id ? classes.selected : classes.notSelected} onClick={() => setCurrentPage(id)}>
        {label}
      </button>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.title}>{t('home.fees')}</div>

      <Group className={classes.buttons}>
        {renderButton('vente', t('honoraires.feesSell'))}
        {renderButton('location', t('honoraires.feesRent'))}
        {renderButton('gestion', t('honoraires.feesManagement'))}
      </Group>

      {pages[currentPage]?.()}
    </div>
  );
}

const useStyles = createStyles(theme => ({
  page: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '0 2rem',
    lineHeight: '1.5rem',
    [`@media (max-width: 1020px)`]: {
      padding: '0 1.5rem',
    },
  },
  title: {
    fontSize: '2rem',
    padding: '2rem',
    paddingTop: '0',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
      fontSize: '1.75rem',
    },
  },
  selected: {
    textDecoration: 'none',
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    padding: '1rem',
    borderRadius: '2rem',
    borderWidth: '0',
  },
  notSelected: {
    textDecoration: 'none',
    background: '#FFF',
    color: theme.colors.local_red[1],
    border: '2px solid #F22F3C',
    padding: '1rem',
    borderRadius: '2rem',
    cursor: 'pointer',
    fontWeight: 600,
  },
  buttons: {
    gap: '3rem',
    paddingBottom: '1rem',
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
      alignItems: 'initial',
      width: '100%',
      gap: '.5rem',
    },
  },
}));
