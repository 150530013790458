import {createStyles, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';

export default function Privacy(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.privacyPage}>
      <div className={classes.title}>{t('home.privacy')}</div>

      <div className={classes.titleSection}>{t('privacy.privacySectionAim.title')}</div>
      <div>{t('privacy.privacySectionAim.inform')}</div>
      <ul>
        <li>{t('privacy.privacySectionAim.personalData')}</li>
        <li>{t('privacy.privacySectionAim.dataUse')}</li>
        <li>{t('privacy.privacySectionAim.whoHasAccess')}</li>
        <li>{t('privacy.privacySectionAim.userRights')}</li>
        <li>{t('privacy.privacySectionAim.cookies')}</li>
        </ul>
      <div>{t('privacy.privacySectionAim.inParallel')}</div>


      <div className={classes.titleSection}>{t('privacy.applicableDuties.title')}</div>
      <div>{t('privacy.applicableDuties.inform')}</div>
      <ul>
        <li>{t('privacy.applicableDuties.treatment')}</li>
        <li>{t('privacy.applicableDuties.collect')}</li>
        <li>{t('privacy.applicableDuties.limited')}</li>
        <li>{t('privacy.applicableDuties.accurate')}</li>
        <li>{t('privacy.applicableDuties.kept')}</li>
        <li>{t('privacy.applicableDuties.safety')}</li>
      </ul>

      <div>{t('privacy.applicableDuties.legalConditions')}</div>
      <ul>
        <li>{t('privacy.applicableDuties.consent')}</li>
        <li>{t('privacy.applicableDuties.contract')}</li>
        <li>{t('privacy.applicableDuties.legalObligations')}</li>
        <li>{t('privacy.applicableDuties.vitalInterests')}</li>
        <li>{t('privacy.applicableDuties.publicBenefit')}</li>
        <li>{t('privacy.applicableDuties.legitimateInterest')}</li>
      </ul>
      <div>{t('privacy.applicableDuties.californiaResident')}</div>

      <div className={classes.titleSection}>{t('privacy.consent.title')}</div>
      <div>{t('privacy.consent.sentence')}</div>
      <ul>
        <li>{t('privacy.consent.privacy')}</li>
        <li>{t('privacy.consent.dataUse')}</li>
      </ul>
      <div className={classes.titleSection}>{t('privacy.personalData.title')}</div>
      <div className={classes.subtitleSection}>{t('privacy.personalData.automaticData')}</div>
      <div>{t('privacy.personalData.automaticDataSentence')}</div>
      <ul>
        <li>{t('privacy.personalData.links')}</li>
        <li>{t('privacy.personalData.content')}</li>
      </ul>
      <div className={classes.subtitleSection}>{t('privacy.personalData.nonAutomaticData')}</div>
      <div>{t('privacy.personalData.nonAutomaticDataSentence')}</div>
      <ul>
        <li>{t('privacy.personalData.name')}</li>
        <li>{t('privacy.personalData.email')}</li>
        <li>{t('privacy.personalData.phoneNumber')}</li>
        <li>{t('privacy.personalData.video')}</li>
      </ul>
      <div>{t('privacy.personalData.form')}</div>
      <div>{t('privacy.personalData.onlyNeeded')}</div>


      <div className={classes.titleSection}>{t('privacy.personalDataUse.title')}</div>
      <div>{t('privacy.personalDataUse.automaticData')}</div>
      <ul>
        <li>{t('privacy.personalDataUse.statistic')}</li>
        <li>{t('privacy.personalDataUse.websiteImprovement')}</li>
      </ul>
      <div>{t('privacy.personalDataUse.formData')}</div>
      <ul>
        <li>{t('privacy.personalDataUse.contact')}</li>
        <li>{t('privacy.personalDataUse.estimate')}</li>
      </ul>

      <div className={classes.titleSection}>{t('privacy.withWho.title')}</div>
      <div className={classes.subtitleSection}>{t('privacy.withWho.employees')}</div>
      <div className={classes.content}>{t('privacy.withWho.employeesContent')}</div>
      <div className={classes.subtitleSection}>{t('privacy.withWho.otherDisclosures')}</div>
      <div>{t('privacy.withWho.otherDisclosuresContent')}</div>
      <ul>
        <li>{t('privacy.withWho.law')}</li>
        <li>{t('privacy.withWho.legalProceedings')}</li>
        <li>{t('privacy.withWho.legalRights')}</li>
        <li>{t('privacy.withWho.potentialBuyers')}</li>
      </ul>
      <div>{t('privacy.withWho.links')}</div>

      <div className={classes.titleSection}>{t('privacy.storageTime.title')}</div>
      <div>{t('privacy.storageTime.content')}</div>
      <div className={classes.titleSection}>{t('privacy.protection.title')}</div>
      <div>{t('privacy.protection.safe')}</div>
      <div>{t('privacy.protection.risks')}</div>
      <div className={classes.titleSection}>{t('privacy.minors.title')}</div>
      <div>{t('privacy.minors.content')}</div>


      <div className={classes.titleSection}>{t('privacy.userRights.title')}</div>
      <div>{t('privacy.userRights.sentence')}</div>
      <ul>
        <li>{t('privacy.userRights.access')}</li>
        <li>{t('privacy.userRights.correction')}</li>
        <li>{t('privacy.userRights.deletion')}</li>
        <li>{t('privacy.userRights.restrictTreatement')}</li>
        <li>{t('privacy.userRights.portability')}</li>
        <li>{t('privacy.userRights.objection')}</li>
      </ul>
      <div>{t('privacy.userRights.more')}</div>

      <div className={classes.titleSection}>{t('privacy.howToChange.title')}</div>
      <div>{t('privacy.howToChange.content')}</div>
      <div className={classes.titleSection}>{t('privacy.privacyChange.title')}</div>
      <div>{t('privacy.privacyChange.content')}</div>
      <div className={classes.titleSection}>{t('privacy.contact.title')}</div>
      <div>{t('privacy.contact.questions')}</div>
      <div>{t('privacy.contact.phoneNumber')}</div>
      <div>{t('privacy.contact.email')}</div>
      <div>{t('privacy.contact.address')}</div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  privacyPage: {
    width: '1200px',
    maxWidth: '100%',
    margin: 'auto',
    padding: '2rem',
    paddingBottom: '4rem',
  },
  title: {
    fontSize: '2rem',
    padding: '0 2rem',
    marginBottom: '1rem',
    fontWeight: 700,
    justifyContent: 'center',
    display: 'flex',
  },
  titleSection: {
    fontSize: '1.7rem',
    paddingTop: '3rem',
    paddingBottom: '1rem',
    color: theme.colors.red,
    fontWeight: 600,
  },
  subtitleSection: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  content: {
    marginBottom: '1rem',
  },
}));
