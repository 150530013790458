import {Grid, ScrollArea, createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';
import {useLocation} from 'react-router-dom';
import {SlideProps} from 'src/types/locationDetails';
import {IconBlueprint} from 'src/assets/icons/IconBlueprint';
import {IconLayout} from 'src/assets/icons/IconLayout';
import {IconStairs} from 'src/assets/icons/IconStairs';
import {IconElevator} from 'src/assets/icons/IconElevator';
import {IconBedroom} from 'src/assets/icons/IconBedroom';
import {IconFurniture} from 'src/assets/icons/IconFurniture';
import {IconBathroom} from 'src/assets/icons/IconBathroom';
import Transaction from 'src/services/transaction';
import classnames from 'classnames';
import { DescriptionComponent } from './DescriptionComponent';
import PropertyPresentationComponent from './PropertyPresentationComponent';

export default function SlideDetails({propertyDetails}: SlideProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const location = useLocation();
  let isMobileVersion = useMediaQuery('(max-width: 1020px)');
  const property = propertyDetails.property;

  let color = '#000';
  let backgroundShade = classes.backgroundLight;

  function formatNumber(floor: number): string {
    return floor ? (floor == 1 ? floor + 'er' : floor + 'ème') : '?';
  }

  function formatRethoric(has: boolean): string {
    return has ? t('transactionDetails.yes') : t('transactionDetails.no');
  }

  function decodeHTMLEntities(str: string): string | undefined {
    if (str) {
      let div = document.createElement('div');

      div.innerHTML = str;
      return div.innerHTML;
    }
  }

  const detailsHtml = (
    <div className={classes.detailsPageInner}>
      <div className={classes.detailsLeftColumn}>
        <div className={classes.detailsIconWrapper}>
          <div id='0' className={classes.detailsPriceInfo}>
            {Transaction.formatNumber(property.priceAskingPriceAmount, t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
            <div className={classes.detailsPriceDescription}>{t('transaction.priceDescription')}</div>
          </div>
          <Grid>

              {property.structureAreasLiveableSize && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconBlueprint className={classes.detailsIcon} fill={color} />
                  {Math.floor(property.structureAreasLiveableSize) || '?'} {decodeHTMLEntities(property.structureAreasLiveableUnits) || '?'}
                </div>
              )}

              {property.structureRooms && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconLayout className={classes.detailsIcon} fill={color} />
                  {property.structureRooms ?? '?'} {t('transactionDetails.rooms')}
                </div>
              )}

              {property.locationFloor && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconStairs className={classes.detailsIcon} fill={color} />
                  {formatNumber(property.locationFloor)} {t('transactionDetails.floor')}
                </div>
              )}

              {property.structureAmenitiesLift && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconElevator className={classes.detailsIcon} fill={color} />
                  {formatRethoric(property.structureAmenitiesLift)}
                </div>
              )}

              {property.structureRoomsBedrooms && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconBedroom className={classes.detailsIcon} fill={color} />
                  {property.structureRoomsBedrooms ?? '?'} chs
                </div>
              )}

              {property.structureComfortFurnished && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconFurniture className={classes.detailsIcon} fill={color} />
                  {formatRethoric(property.structureComfortFurnished)}
                </div>
              )}

              {property.structureRoomsBathrooms && (
                <div className={classnames(classes.detailsIconContainer, backgroundShade)}>
                  <IconBathroom className={classes.detailsIcon} fill={color} />
                  {property.structureRoomsBathrooms ?? '?'} sdb
                </div>
              )}
          </Grid>
        </div>
        
        <PropertyPresentationComponent 
          property={property}
        />
        
      </div>

      <div className={classes.detailsSeparator} />

      <div className={classes.detailsRightColumn}>
        <DescriptionComponent property={property} showChargesIncluded={location.pathname.startsWith("/location")} />
      </div>
    </div>
  )

  // mobile version to avoid a scroll area on the description of the property
  if(isMobileVersion){
    return (
      <div className={classes.detailsPage}>
        {detailsHtml}
      </div>
    );
  }
  // desktop version
  return (
    <ScrollArea className={classes.detailsPage}>
      {detailsHtml}
    </ScrollArea>
  );
}

const useStyles = createStyles(theme => ({
  detailsPage: {
    height:'100%',
    [`@media (max-width: 1020px)`]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    
  },
  detailsPageInner: {
    display: 'flex',
    fontSize: '1.25rem',
    justifyContent: 'center',
    [`@media (min-width: 1020px)`]: {	
      padding: '1rem',
    },
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
    },
  },
  detailsPlaceholder: {
    objectFit: 'cover',
    borderRadius: '.5rem',
    width: '534px',
    height: '400px',
    marginRight: '1.75rem',
  },
  detailsSeparator: {
    opacity: 0.5,
    background: 'rgba(0, 0, 0, .25)',
    borderRadius: '5rem',
    position: 'sticky',
    top: 'calc(50% - (325px / 2))',
    width: '3px',
    height: '325px',
    margin: '2rem',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
  },
  detailsLeftColumn: {
  },
  detailsRightColumn: {
    flex: '0 0 25%',
  },
  detailsPriceInfo: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 700,
    [`@media (min-width: 1020px)`]: {
      marginRight: '.5rem',
    },
  },
  detailsPriceDescription: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    [`@media (max-width: 1020px)`]: {
      paddingBottom: '0.5rem',
    },
  },
  detailsIconWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 300,
    marginBottom: '.5rem',
    [`@media (max-width: 1020px)`]: { 
      flexDirection: 'column',
    }
  },
  detailsIconContainer: {
    boxSizing: 'border-box',
    padding: '1rem',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [`@media (max-width: 1020px)`]: {
      // icons are centered horizontally
      marginLeft:'auto',
      marginRight:'auto',
    },
  },
  detailsIcon: {
    display: 'block',
    width: '32px',
    height: '32px',
    margin: 'auto',
    marginBottom: '.25rem',
  },
  backgroundDarkObject: {
    backgroundColor: 'rgba(255, 255, 255, .25)',
  },
  backgroundLightObject: {
    backgroundColor: theme.colors.black_pearl[0],
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
}));
