import {InfiniteScrollPayload, InfiniteScrollFilterPayload} from 'src/types/filter';
import Http, {HttpMutators} from 'src/services/http';

export default {
  getPropertyList: (params: InfiniteScrollPayload, mutators: HttpMutators) =>
    Http.get(
      {
        url: '/location/GetPropertyByPage',
        params,
      },
      mutators,
    ),
  getPropertyDetails: (Id: string, mutators: HttpMutators) =>
    Http.get(
      {
        url: '/location/GetPropertyDetails',
        params: {Id},
      },
      mutators,
    ),
  postFilteredPropertyList: (data: InfiniteScrollFilterPayload, mutators: HttpMutators) =>
    Http.post(
      {
        url: '/location/PostFilteredPropertyByPage',
        data,
      },
      mutators,
    ),
};
