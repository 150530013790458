import classnames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, createStyles, MediaQuery} from '@mantine/core';
import RebootLogo from '../reboot-logo/RebootLogo';
import ContactIcon from '../contact/ContactIcon';
import {useEffect, useState} from 'react';

type NavbarProps = {
  className?: string;
};

export default function Navbar(props: NavbarProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const [opened, setOpen] = useState(false);
  const location = useLocation();

  function createLink(name: string, link: string, className?: string): JSX.Element {
    return (
      <div className={className}>
        <Link to={link}>
          {name}
        </Link>
      </div>
    );
  }

  function toggleSidebar(): void {
    setOpen(!opened);
  }

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={classnames(classes.fullNavbar, props.className)}>
      <MediaQuery query="(max-width: 1020px)" styles={{ display: 'none' }}>
        <RebootLogo className={classes.logo} />
      </MediaQuery>

      <MediaQuery query="(min-width: 1020px)" styles={{ display: 'none' }}>
        <RebootLogo className={classes.logo} />
      </MediaQuery>

      <MediaQuery query="(min-width: 1020px)" styles={{ display: 'none' }}>
        <Button className={classes.iWantMobile} onClick={toggleSidebar}>
          {t('menu')}
        </Button>
      </MediaQuery>

      <div className={classes.navbar} style={{ display: opened ? 'block' : '' }}>
        <div className={classes.iWant}>{t('navbar.iWant')}</div>
        {createLink(t('navbar.buy'), '/achat', classes.stylized)}
        {createLink(t('navbar.rent'), '/location', classes.stylized)}
        {createLink(t('navbar.estimate'), '/estimation', classes.stylized)}
        {createLink(t('navbar.manage'), '/gestion', classes.stylized)}
        {/* {createLink(t('navbar.beAdvised'), '/TODO')} */}
        {createLink(t('navbar.discoverUs'), '/equipe')}

        <div className={classes.contactIconButton}>
          <ContactIcon />
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme, _params) => ({
  fullNavbar: {
    boxSizing: 'border-box',
    backgroundColor: theme.white,
    zIndex: 100,
    position: 'sticky',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '110px',
    padding: '0 3.75rem',
    [`@media (max-width: 1020px)`]: {
      borderBottom: '2px solid #e9e9e9',
      display: 'flex',
      height: '80px',
      padding: '1rem',
    },
  },
  logo: {
    boxSizing: 'border-box',
    width: '200px',
    marginRight: '2rem',
    img: {
      width: '100%',
      height: '100%',
    },
    [`@media (max-width: 1020px)`]: {
      width: '150px',
      img: {
        width: '100%',
        height:'100%'
      },
      margin: 0,
      marginRight: 'auto',
      marginTop: '-8px',
    },
  },
  navbar: {
    position: 'relative',
    userSelect: 'none',
    color: theme.black,
    display: 'flex',
    border: '2px solid black',
    borderRadius: '5rem',
    margin: 'auto 0',
    padding: 0,
    paddingRight: '4rem',
    listStyle: 'none',
    fontSize: '1.25rem',
    fontWeight: 400,
    textAlign: 'center',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
      background: theme.white,
      border: 0,
      borderRadius: 0,
      position: 'fixed',
      top: '80px',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '1rem',
    },
    'a, > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '52px',
      color: 'inherit',
      textDecoration: 'none',
      padding: '0 .5rem',
      a: {
        width: '100%',
        '&:hover': {
          WebkitTextStroke: '1px',
        },
      },
      [`@media (max-width: 1020px)`]: {
        padding: 0,
      },
    },
  },
  iWant: {
    background: theme.colors.local_red[0],
    color: theme.white + ' !important',
    borderRadius: '5rem',
    padding: '0 2rem !important',
  },
  iWantMobile: {
    borderRadius: '1rem',
    background: theme.colors.local_red[0],
    color: theme.white,
    height: 'auto !important',
    padding: '.75rem 1.75rem',
    fontSize: '1.15rem',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
  stylized: {
    color: theme.colors.local_red[0] + ' !important',
    fontWeight: 600,
  },
  mainServices: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contactIconButton: {
    position: 'absolute',
    right: 0,
    padding: '0 !important',
    [`@media (max-width: 1020px)`]: {
      bottom: '2rem',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));
