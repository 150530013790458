import axios, {AxiosRequestConfig} from 'axios';

export type RequestParams = AxiosRequestConfig & {url: string};
export type RequestPayloadParams = RequestParams;

export type HttpMutators = {
  loading?: (value: boolean) => void;
  success?: (res: any) => void;
  error?: (errorMessage: any) => void;
};

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

const axiosRequestVideo = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  maxBodyLength: 125_000_000,
});

function request(params: AxiosRequestConfig, {loading, error, success}: HttpMutators): Promise<any> {
  return axiosRequest(params)
    .then(res => {
      success?.(res.data);
      loading?.(false);
    })
    .catch(err => {
      error?.(err.response?.data);
      loading?.(false);
    });
}

function requestVideo(params: AxiosRequestConfig, {loading, error, success}: HttpMutators): Promise<any> {
  return axiosRequestVideo(params)
    .then(res => {
      success?.(res.data);
      loading?.(false);
    })
    .catch(err => {
      error?.(err.response?.data);
      loading?.(false);
    });
}

export default {
  get: (params: RequestParams, mutators: HttpMutators) => {
    (params as any).method = 'GET';
    return request(params, mutators);
  },
  post: (params: RequestPayloadParams, mutators: HttpMutators) => {
    (params as any).method = 'POST';
    return request(params, mutators);
  },
  patch: (params: RequestPayloadParams, mutators: HttpMutators) => {
    (params as any).method = 'PATCH';
    return request(params, mutators);
  },
  delete: (params: RequestParams, mutators: HttpMutators) => {
    (params as any).method = 'DELETE';
    return request(params, mutators);
  },
  postVideo: (params: RequestPayloadParams, mutators: HttpMutators) => {
    (params as any).method = 'POST';
    return requestVideo(params, mutators);
  },
};
