import {createStyles} from '@mantine/core';
import {Trans, useTranslation} from 'react-i18next';
import classNames from 'classnames';

export default function HonorairesGestionDescription() : JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <>
      <div className={classNames(classes.elementDescription, classes.precision)}>
        <Trans i18nKey="honoraires.gestion.description.definition" components={{strong: <strong />}} />
      </div>

      <div className={classes.elementDescription}>
        {t('honoraires.gestion.description.threeParts')}
        <ul className={classes.list}>
          <li>{t('honoraires.gestion.description.administrative')}</li>
          <li>{t('honoraires.gestion.description.financial')}</li>
          <li>{t('honoraires.gestion.description.technical')}</li>
        </ul>
      </div>

      <div className={classes.elementDescription}>
        {t('honoraires.gestion.description.twoPacks')}

        <ul className={classes.list}>
          <li>
            <strong>{t('honoraires.gestion.description.packCremant')}</strong>
          </li>
          <li>
            <strong>{t('honoraires.gestion.description.packChampagne')}</strong>
          </li>
        </ul>
      </div>

      <div className={classes.elementDescription}>
        <Trans i18nKey="honoraires.gestion.description.otherServices" components={{strong: <strong />}} />
      </div>

      <div className={classNames(classes.elementDescription, classes.precision)}>
        <Trans i18nKey="honoraires.gestion.description.precision" components={{strong: <strong />}} />
      </div>
    </>
  );
}

const useStyles = createStyles(theme => ({
  elementDescription: {
    marginTop: '1.5rem',
  },
  list: {
    marginBlockStart: '0',
    marginBlockEnd: '0',
    '> li': {
      margin: '.25rem 0',
    },
  },
  precision: {
    color: '#585858',
    borderLeft: '4px solid #c9c9c9',
    padding: '.75rem 1rem',
    background: 'rgba(0, 0, 0, .05)',
    borderRadius: '0.25rem',
    fontStyle: 'italic',
    fontWeight: 400,
  },
}));
