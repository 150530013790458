import {createStyles, Stack, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import HonorairesData from './HonorairesData';
import classNames from 'classnames';

export default function HonorairesVente(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  function rangeTypeToChar(rangeType: string): string {
    return rangeType === "percentage" ? "%"  : "€";
  }

  return (
    <div className={classes.card}>
      <div className={classes.title}>{t('honoraires.vente.title')}</div>

      <Stack className={classes.stack}>
        <Group className={classes.group}>
          <div className={classNames(classes.header, classes.rangeColumn, classes.cellResponsive)}>{t('honoraires.vente.range')}</div>
          <div className={classNames(classes.header, classes.rateColumn, classes.cellResponsive)}>{t('honoraires.vente.rate')}</div>
        </Group>

        {HonorairesData.honorairesVente.map((element, i) => {
          if (element.rangeMin === 0) {
            return (
              <Group key={i} className={classes.group}>
                <div className={classNames(classes.range, classes.rangeColumn, classes.cellResponsive)}>&lt; {element.rangeMax} €</div>
                <div className={classNames(classes.value, classes.rateColumn, classes.cellResponsive)}>{element.rangeValue} {rangeTypeToChar(element.rangeType)}</div>
              </Group>
            );

          } else if (element.rangeMax === 0) {
            return (
              <Group key={i} className={classes.group}>
                <div className={classNames(classes.range, classes.rangeColumn, classes.cellResponsive)}>&gt; {element.rangeMin} €</div>
                <div className={classNames(classes.value, classes.rateColumn, classes.cellResponsive)}>{element.rangeValue} {rangeTypeToChar(element.rangeType)}</div>
              </Group>
            );

          } else {
            return (
              <Group key={i} className={classes.group}>
                <div className={classNames(classes.range, classes.rangeColumn, classes.cellResponsive)}>{t("honoraires.vente.rangeBetween", { min: element.rangeMin + " €", max: element.rangeMax + " €" })}</div>
                <div className={classNames(classes.value, classes.rateColumn, classes.cellResponsive)}>{element.rangeValue} {rangeTypeToChar(element.rangeType)}</div>
              </Group>
            );
          }
        })}
      </Stack>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  card: {
    boxSizing: 'border-box',
    borderRadius: '2.5rem',
    padding: '3rem',
    maxWidth: '100%',
    width: '1200px',
    textAlign: 'center',
    boxShadow: '0 0 2.5rem rgba(0, 0, 0, .175)',
    margin: '2rem 0',
    marginBottom: '3rem',
    [`@media (max-width: 1020px)`]: {
      boxShadow: 'unset',
      padding: 0,
    },
  },
  title: {
    fontSize: '1.5rem',
    padding: '2rem',
    paddingTop: '0',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
    },
  },
  stack: {
    gap: '.5rem',
  },
  group: {
    [`@media (min-width: 1020px)`]: {
      justifyContent: 'center',
    },
  },
  header: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    fontWeight: 700,
    fontSize: '1.2rem',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  range: {
    color: theme.white,
    backgroundColor: theme.colors.local_red[1],
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  value: {
    backgroundColor: theme.colors.local_grey[2],
    borderRadius: '.625rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  rangeColumn: {
    width: '40%',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  rateColumn: {
    width: '50%',
    [`@media (max-width: 1020px)`]: {
      flex: '1 0 0 !important',
    },
  },
  cellResponsive : {
    [`@media (max-width: 880px)`]: {
      height: '4rem',
    },
  },
}));
