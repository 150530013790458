import { createStyles } from '@mantine/core';

type progressDetailsProps = {
    progress: number,
};

export default function ProgressDetails({progress}: progressDetailsProps): JSX.Element{
    const {classes} = useStyles({progress});

    return <div className={classes.progressBarLight} />;
}

const useStyles = createStyles((theme, {progress}: progressDetailsProps) => ({
    progressBarLight: {
        background:  `linear-gradient(to right,  #FA5858 0%,#FA5858 ${progress}%,#9c9e9f ${progress}%,#9c9e9f 100%)`,
        height: '.25rem',
    },
}));
