import {useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {createStyles} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import AchatController from 'src/services/achat';
import Slide from 'src/app/components/slides/Slide';
import SlideDetails from 'src/app/components/slides/SlideDetails';
import SlideDescriptionAchatInvestor from 'src/app/components/slides/SlideDescriptionAchatInvestor';
import SlideDescriptionAchatClassic from 'src/app/components/slides/SlideDescriptionAchatClassic';
import SlideDPE from 'src/app/components/slides/SlideDPE';
import SlideTransport from 'src/app/components/slides/SlideTransport';
import SlideContact from 'src/app/components/slides/SlideContact';
import SlideHypotheses from 'src/app/components/slides/SlideHypotheses';
import SlideBudget from 'src/app/components/slides/SlideBudget';
import SlideIncomes from 'src/app/components/slides/SlideIncome';
import SlideProfit from 'src/app/components/slides/SlideProfit';
import {HypothesesFields, OptionalHypothesesFields, PropertyDetails} from 'src/types/locationDetails';
import {AiOutlineEuro} from 'react-icons/ai';
import {MdOutlineTram} from 'react-icons/md';
import {BsFillTelephoneFill, BsCameraFill, BsGraphUp} from 'react-icons/bs';
import {FaCoins} from 'react-icons/fa';
import {GiReceiveMoney} from 'react-icons/gi';
import {useTranslation} from 'react-i18next';
import {IconQuestion} from 'src/assets/icons/IconQuestion';
import Transaction from 'src/services/transaction';
import HypothesesData from 'src/app/components/hypotheses/HypothesesData';
import { InvestorViewSwitch, useInvestorContext } from 'src/app/components/recherche-biens/InvestorView';
import classNames from 'classnames';
import ModalLoading from 'src/app/components/loading/Loading';
import {MutableRefObject} from 'react';
import SlideMobile from 'src/app/components/slides/SlideMobile';

function useStateRef<S>(initialState: S): [S, (value: S) => void, MutableRefObject<S>] {
  let [state, setState] = useState(initialState);
  let ref = useRef(initialState);

  // We need to switch from useState to useRef to avoid referring to an old state in function handlers.
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
  function setStateAndRef(value: S): void {
    setState(value);
    ref.current = value;
  }

  return [state, setStateAndRef, ref];
}

export default function AchatDetails(): JSX.Element {
  const [property, setProperty] = useState<PropertyDetails>();
  const [nbSlides, setNbSlides] = useState(0);
  const [error, setError] = useState(false);
  const {t} = useTranslation();
  let {id, slide} = useParams();
  const {classes} = useStyles();
  let navigate = useNavigate();
  let {investorChecked} = useInvestorContext();
  let isMobileVersion = useMediaQuery('(max-width: 1020px)');

  const [hypotheses, setHypotheses, refHypotheses] = useStateRef<HypothesesFields>({
    price: 0,
    workFurniture: 0,
    monthlyRent: 0,
    supply: 0,
    loanDuration: 0,
    interestRate: 0,
    evolutionYear: 0,
    evolutionRate: 0,
    resaleYear: 0,
  });

  const initHypotheses: HypothesesFields = {
    price: property?.priceAskingPriceAmount ?? 0,
    workFurniture: property?.workFurniture ?? 0,
    monthlyRent: property?.monthlyRent ?? 0,
    supply: 0,
    loanDuration: HypothesesData.loanDurationInitialValue,
    interestRate: HypothesesData.interestRate,
    evolutionYear: HypothesesData.evolutionYear,
    evolutionRate: HypothesesData.evolutionRate,
    resaleYear: Transaction.getYear() + 5,
  };

  function updateHypotheses(value: OptionalHypothesesFields): void {
    setHypotheses({
      ...refHypotheses.current,
      ...value,
    });
  }

  // in order to have the good string of the slice, we need to store the index
  function onChange(index: number): void {
    if(document.documentElement.clientWidth > 1020){
      navigate(`/achat/details/${id}/${index}`, {replace: true});
    } else {
      document.getElementById(`${index}`)?.scrollIntoView({behavior: 'smooth', block: 'end'})
    }
  }

  const icons = [
    /* eslint-disable react/jsx-key */
    <BsCameraFill className={classes.scrollPagesItemIcon} />,
    <AiOutlineEuro className={classes.scrollPagesItemIcon} />,
    <MdOutlineTram className={classes.scrollPagesItemIcon} />,
    <div className={classes.scrollPagesItemText}>DPE</div>,
    <BsFillTelephoneFill className={classes.scrollPagesItemIcon} />,
    <IconQuestion className={classes.scrollPagesItemIcon} fill="#fff" />,
    <FaCoins className={classes.scrollPagesItemIcon} />,
    <GiReceiveMoney className={classes.scrollPagesItemIcon} />,
    <BsGraphUp className={classes.scrollPagesItemIcon} />,
    /* eslint-enable react/jsx-key */
  ];

  useEffect(() => {
    AchatController.getPropertyDetails(id as string, {
      error: (err) => {
        setError(true);
        console.error(err);
      },
      success: prop => {
        setProperty({...prop, id: id});

        // Calculer la taxe foncière mensuelle.
        if (property != null) {
          property.propertyTax = property.propertyTax / 12;
        }
      },
    });
  }, []);

  useEffect(() => {
    setHypotheses(initHypotheses);
  }, [property]);

  const descriptions = [
    t('transaction.detailsTitle'),
    t('transaction.descriptionTitle'),
    t('transaction.transportTitle'),
    {
      long: t('transaction.dpeTitle'),
      short: t('transaction.dpeTitleShort'),
    },
    t('transaction.contactTitle'),
    t('achat.hypothesesTitle'),
    t('achat.budgetTitle'),
    t('achat.incomeTitle'),
    t('achat.profitTitle'),
  ];

  // mobile version
  if(property && isMobileVersion) {
    return (
      <div className={classNames(classes.wrapper, classes.backgroundLight)}>
        <InvestorViewSwitch className={classes.switch} />
        <SlideMobile icons={icons} descriptions={descriptions}>
            <SlideDetails propertyDetails={{property}} />
            <SlideDescriptionAchatClassic propertyDetails={{property}} />
            <SlideTransport propertyDetails={{property}} />
            <SlideDPE propertyDetails={{property}} />
            <SlideContact />
        </SlideMobile>

      </div>
    );
  }

  // desktop version
  if (property)
    return (
      <div className={classNames(classes.wrapper, classes.backgroundLight)}>
        <InvestorViewSwitch className={classes.switch} />

        {investorChecked ? (
          <Slide className={classes.details} onChange={onChange} position={parseInt(slide as string)} icons={icons} descriptions={descriptions} setNbSlides={setNbSlides} investor={true}>
            <SlideDetails propertyDetails={{property}} />
            <SlideDescriptionAchatInvestor propertyDetails={{property}} hypotheses={hypotheses} />
            <SlideTransport propertyDetails={{property}} />
            <SlideDPE propertyDetails={{property}} />
            <SlideContact />
            <SlideHypotheses propertyDetails={{property}} updateHypotheses={updateHypotheses} initHypotheses={initHypotheses} hypotheses={hypotheses} />
            <SlideBudget propertyDetails={{property}} hypotheses={hypotheses} />
            <SlideIncomes propertyDetails={{property}} hypotheses={hypotheses} />
            <SlideProfit propertyDetails={{property}} hypotheses={hypotheses} updateHypotheses={updateHypotheses} />
          </Slide>
        ) : (
          <Slide className={classes.details} onChange={onChange} position={parseInt(slide as string)} icons={icons} descriptions={descriptions} setNbSlides={setNbSlides} investor={false}>
            <SlideDetails propertyDetails={{property}} />
            <SlideDescriptionAchatClassic propertyDetails={{property}} />
            <SlideTransport propertyDetails={{property}} />
            <SlideDPE propertyDetails={{property}} />
            <SlideContact />
          </Slide>
        )}
      </div>
    );

  else
    return <ModalLoading showError={error} />;
}

const useStyles = createStyles(theme => ({
  switch: {
    zIndex: 1,
    position: 'absolute',
    left: '1rem',
    top: '5rem',
    [`@media (max-width: 1020px)`]: {
      display: 'none',
    },
  },
  wrapper: {
    overflow: 'hidden',
    
  },
  details: {
    height: '100%',
  },
  backSearch: {
    display: 'flex',
    alignItems: 'center',
    color: theme.black,
    userSelect: 'none',
    textDecoration: 'none',
    fontSize: '1.5rem',
    zIndex: 200,
    cursor: 'pointer',
    height: '4rem',
    position: 'absolute',
    left: '2rem',
    top: '8.5rem',
    gap: '.75rem',
  },
  textBackSearch: {
    fontSize: '1.5rem',
  },
  scrollPagesItemIcon: {
    width: '18px',
    height: '18px',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  scrollPagesItemText: {
    width: '18px',
    height: '18px',
    margin: '1.6rem',
    [`@media (max-width: 1020px)`]: {
      width: 'auto',
      margin: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
    backgroundColor: theme.white,
    transition: 'background-color 1s linear',
  },
  backgroundDark: {
    color: 'rgba(255, 255, 255, 0.75)',
    transition: 'background-color 1s linear',
  },
}));
