import {IconProps} from 'src/types/icon';
import {Ref, forwardRef} from 'react';

const IconInfoBase = (props: IconProps, ref: Ref<SVGSVGElement>): JSX.Element => (
  <svg ref={ref} {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416.979 416.979" className={props.className} fill={props.fill || "currentColor"}>
    <path d="M356.004 61.156c-81.37-81.47-213.377-81.551-294.848-.182s-81.552 213.379-.181 294.85 213.378 81.551 294.849.181 81.551-213.379.18-294.849zM237.6 340.786c0 3.217-2.607 5.822-5.822 5.822h-46.576c-3.215 0-5.822-2.605-5.822-5.822V167.885c0-3.217 2.607-5.822 5.822-5.822h46.576a5.82 5.82 0 0 1 5.822 5.822v172.901zm-29.11-202.885c-18.618 0-33.766-15.146-33.766-33.765S189.871 70.37 208.49 70.37s33.766 15.148 33.766 33.766-15.149 33.765-33.766 33.765z" />
  </svg>
);

export const IconInfo = forwardRef(IconInfoBase);
