import { createStyles, Switch } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

type InvestorContextType = {
    investorChecked: boolean;
    setInvestorChecked: React.Dispatch<React.SetStateAction<boolean>>;
};

type InvestorViewSwitchProps = {
    className?: string;
};

const InvestorContext = createContext<InvestorContextType | null>(null);

export function InvestorContextProvider({ children }: React.PropsWithChildren): JSX.Element {
    const [investorChecked, setInvestorChecked] = useState(localStorage.getItem("investorView") === "true");

    useEffect(() => {
        localStorage.investorView = String(investorChecked);
    }, [investorChecked]);

    return (
        <InvestorContext.Provider value={{ investorChecked, setInvestorChecked }}>
            {children}
        </InvestorContext.Provider>
    );
};

export function InvestorViewSwitch(props: InvestorViewSwitchProps): JSX.Element {
    const {investorChecked, setInvestorChecked} = useInvestorContext();
    const {t} = useTranslation();
    const {classes} = useStyles();

    const isLargeScreen = useMediaQuery('(min-width: 1200px)');

    return (
        <div className={props.className}>
            <Switch 
                size={isLargeScreen ? 'sm' : 'xs'}
                checked={investorChecked} 
                onChange={(event) => setInvestorChecked(event.currentTarget.checked)} 
                label={t('achat.investor')} color="red" 
                classNames={{ labelWrapper: classes.input, body: classes.body }} 
            />
        </div>
    );
}

export function useInvestorContext(): InvestorContextType {
    const context = useContext(InvestorContext);

    if (!context)
        throw new Error('useInvestorContext must be used within an InvestorContextProvider');

    return context;
};

const useStyles = createStyles(theme => ({
    input: {
        cursor: 'pointer !important',
        textAlign: 'center'
    },
    body: {
        display: 'flex',
        alignItems: 'center',
        color: 'red'
    }
}));
