import {createStyles} from '@mantine/core';
import classnames from 'classnames';
import ContactIcon from '../contact/ContactIcon';

type ModalProps = {
  isBackgroundLight?: boolean;
  text: string;
};

export default function Modal(props: ModalProps): JSX.Element {
  const {classes} = useStyles();
  const backgroundShadeObject = props.isBackgroundLight
    ? classes.backgroundLightObject
    : classes.backgroundDarkObject;

  return (
    <div className={classes.modalBackground}>
      <div className={classnames(classes.modal, backgroundShadeObject)}>
        <div>
          {props.text}
        </div>

        <ContactIcon />
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  modalBackground: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100% + 2rem)',
    width: '100%',
    background: '#00000078',
    zIndex: 9999,
  },
  modal: {
    boxSizing: 'border-box',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '2rem',
    width: '900px',
    height: '350px',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '1rem',
    margin: 'auto',
    padding: '5rem',
    textAlign: 'center',
    fontSize: '1.5rem',
  },
  backgroundDarkObject: {
    backgroundColor: theme.colors.black_pearl[0],
    color: theme.white,
  },
  backgroundLightObject: {
    backgroundColor: theme.white,
    color: theme.colors.black_pearl[0],
  },
}));
