import classnames from 'classnames';
import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import FavoriteButton from 'src/app/components/favorite/Favorite';
import {SlideProps} from 'src/types/locationDetails';
import Transaction from 'src/services/transaction';
import {useDisclosure} from '@mantine/hooks';
import ModalInterested from '../modal-interested/ModalInterested';
import FilterData from '../filter/FilterData';

export default function SlideDescriptionAchatClassic({propertyDetails}: SlideProps): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();
  const property = propertyDetails.property;
  const [opened, {open, close}] = useDisclosure(false);

  let backgroundShade = classes.backgroundLight;
  let backgroundShadeObject = classes.backgroundLightObject;
  let backgroundShadeCard = classes.backgroundDark;
  let backgroundShadeCardObject = classes.backgroundDarkObject;

  function getTotalPrice(): number {
    return property.priceAskingPriceAmount;
  }

  const propertyType = new Map<string, string>();

  FilterData.propertyType.forEach(element => {
    propertyType.set(element.value, element.label);
  });

  return (
    <>
      <ModalInterested
        opened={opened}
        close={close}
        title={t('transactionInterested.title')}
        propertyDetails={propertyDetails}
        propertyType={t('transactionInterested.propertyForRent')} />

      <div id='1' className={classnames(classes.descriptionCard, backgroundShadeCard, backgroundShadeCardObject)}>
        <div className={classes.descriptionCardHeader}>
          <div className={classes.descriptionCardPrice}>
            {Transaction.formatNumber(getTotalPrice(), t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
          </div>
          <div>{t('achat.description.inTotal')}</div>
          <div className={classes.descriptionCardLikeContainer}>
            <FavoriteButton propertyId={property.id} />
          </div>
        </div>

        <hr className={classes.descriptionCardSeparator} />

        <div className={classes.descriptionField}>
          <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
          <div>{t('achat.commun.price')}</div>
          <div className={classes.descriptionFieldSeparator} />
          <div className={classes.descriptionFieldPrice}>
            {Transaction.formatNumber(property.priceAskingPriceAmount, t('transaction.formatNumber'))} {Transaction.currency(property.priceAskingPriceCurrency)}
          </div>
        </div>

        <div>
          <div className={classes.descriptionField}>
            <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
            <div>{t('achat.descriptionClassic.propertyType')}</div>
            <div className={classes.descriptionFieldSeparator} />
            <div className={classes.descriptionFieldPrice}>{t(propertyType.get(property.structurePropertyType) as string)}</div>
          </div>
        </div>

        <div className={classes.descriptionField}>
          <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
          <div>{t('achat.descriptionClassic.area')}</div>
          <div className={classes.descriptionFieldSeparator} />
          <div className={classes.descriptionFieldPrice}>
            {property.structureAreasLiveableSize} {Transaction.surfaceArea(property.structureAreasLiveableUnits)}
          </div>
        </div>

        <div className={classes.descriptionField}>
          <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
          <div>{t('achat.descriptionClassic.roomsNumber')}</div>
          <div className={classes.descriptionFieldSeparator} />
          <div className={classes.descriptionFieldPrice}>
            {property.structureRooms}
          </div>
        </div>

        <div className={classes.descriptionField}>
          <div className={classnames(classes.descriptionFieldCircle, backgroundShadeObject)} />
          <div>{t('achat.descriptionClassic.bedroomsNumber')}</div>
          <div className={classes.descriptionFieldSeparator} />
          <div className={classes.descriptionFieldPrice}>
            {property.structureRoomsBedrooms}
          </div>
        </div>

        <button className={classnames(classes.descriptionCardButton, backgroundShadeObject, backgroundShade)} onClick={open}>
          {t('locationDescription.interested')}
        </button>
      </div>
    </>
  );
}

const useStyles = createStyles(theme => ({
  descriptionCard: {
    overflow: 'hidden',
    borderRadius: '1rem',
    width: '400px',
    maxWidth: '100%',
    margin: '1rem',
    padding: '1rem',
    fontSize: '1.25rem',
    fontWeight: 400,
    
    [`@media (max-width: 1020px)`]: {
      width: 'auto',
      margin: '0rem 1rem 0rem 1rem',
    }
  },
  descriptionCardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionCardLikeContainer: {
    marginLeft: 'auto',
  },
  descriptionCardLike: {
    color: theme.colors.red[6],
    display: 'block',
    width: '18px',
    height: '18px',
  },
  descriptionCardSeparator: {
    borderWidth: '0.1px',
    margin: '1rem 0',
  },
  descriptionCardPrice: {
    marginRight: '1rem',
    fontWeight: 600,
    fontSize: '1.75rem',
  },
  descriptionList: {
    marginBottom: '2rem',
  },
  descriptionListIndent: {
    marginLeft: '3rem',
    marginBottom: '2rem',
  },
  descriptionField: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '.25rem',
    padding: '.5rem',
  },
  descriptionFieldCircle: {
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
    marginRight: '1rem',
  },
  descriptionFieldSeparator: {
    borderTop: '4px dotted #d3d3d3',
    flex: '1 0 0',
    margin: 'auto 1rem',
  },
  descriptionFieldPrice: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  descriptionCardButton: {
    cursor: 'pointer',
    border: '0',
    borderRadius: '5rem',
    outline: '0',
    display: 'block',
    width: '50%',
    margin: 'auto',
    padding: '.75rem',
    fontSize: '1.15rem',
    marginTop: '1rem'
  },
  backgroundLight: {
    color: theme.colors.black_pearl[0],
  },
  backgroundDark: {
    color: theme.white,
  },
  backgroundLightObject: {
    background: theme.white,
  },
  backgroundDarkObject: {
    background: theme.colors.black_pearl[0],
  }
}));
