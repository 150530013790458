import {createStyles} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import EquipeData from './EquipeData';
import {IconEmail} from 'src/assets/icons/IconEmail';
import {IconPhone} from 'src/assets/icons/IconPhone';

export default function Equipe(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  function phoneNumberToCallNumber(phoneNumber: string): string {
    return "+33" + phoneNumber.replaceAll(" ", "").substring(1);
  }

  return (
    <div className={classes.page}>
      <div className={classes.title}>{t('equipe.title')}</div>
      <div className={classes.sectionTitle}>{t('equipe.ourTeam')}</div>

      <div className={classes.teamMembers}>
        {EquipeData.map((item, i) => (
          <div className={classes.teamMember} key={i}>
            <div className={classes.teamMemberPhoto}>
              <img src={item.photo} alt={item.name} />
            </div>

            <div className={classes.teamMemberContent}>
              <div className={classes.teamMemberName}>{item.name}</div>
              <div className={classes.teamMemberRole}>{item.role}</div>
              <div className={classes.teamMemberDescription}>{item.description}</div>

              <div className={classes.teamMemberContactList}>
                <a href={`tel:${phoneNumberToCallNumber(item.phone)}`} className={classes.teamMemberContact}>
                  <IconPhone className={classes.teamMemberContactIcon} />
                  <div className={classes.teamMemberContactValue}>{item.phone}</div>
                </a>

                <a href={`mailto:${item.email}`} className={classes.teamMemberContact}>
                  <IconEmail className={classes.teamMemberContactIcon} />
                  <div className={classes.teamMemberContactValue}>{item.email}</div>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.sectionTitle}>{t('equipe.ourOffices')}</div>

      <div className={classes.agencePhotos}>
        <div className={classes.agencePhotosItem}>
          <img src="/assets/img/agence/photo_exterieur.jpg" alt="Extérieur" />
        </div>

        <div className={classes.agencePhotosItem}>
          <img src="/assets/img/agence/photo_bureau.jpg" alt="Bureau" />
        </div>

        <div className={classes.agencePhotosItem}>
          <img src="/assets/img/agence/photo_interieur.jpg" alt="Intérieur" />
        </div>
      </div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  teamMembers: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4rem 0',
    gap: '4rem',
  },
  teamMember: {
    display: 'flex',
    [`@media (max-width: 1020px)`]: {
      flexDirection: 'column',
    },
  },
  teamMemberPhoto: {
    background: theme.colors.local_red[0],
    width: '200px',
    flex: '0 0 200px',
    aspectRatio: '3/4',
    marginTop: '1rem',
    marginRight: '3rem',
    img: {
      objectFit: 'cover',
      display: 'block',
      width: '100%',
      height: '100%',
      marginLeft: '.75rem',
      marginTop: '-.75rem',
    },
    [`@media (max-width: 1020px)`]: {
      borderRadius: '.5rem',
      margin: 'auto',
      marginTop: '1rem',
      marginBottom: '1.75rem',
      img: {
        borderRadius: '.5rem',
      },
    },
  },
  teamMemberContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  teamMemberName: {
    color: theme.colors.local_red[0],
    fontSize: '1.35rem',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      textAlign: 'center',
    },
  },
  teamMemberRole: {
    fontSize: '1.15rem',
    fontWeight: 700,
    [`@media (max-width: 1020px)`]: {
      marginTop: '-1rem',
      textAlign: 'center',
    },
  },
  teamMemberDescription: {
    width: '750px',
    maxWidth: '100%',
    [`@media (max-width: 1020px)`]: {
      textAlign: 'justify',
    },
  },
  teamMemberContactList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
  teamMemberContact: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    color: 'inherit',
  },
  teamMemberContactIcon: {
    boxSizing: 'border-box',
    borderRadius: '50%',
    background: theme.colors.local_red[0],
    color: theme.white,
    width: '32px',
    height: '32px',
    padding: '.4rem',
  },
  teamMemberContactValue: {
    fontSize: '1.15rem',
    fontWeight: 700,
    color: 'inherit',
  },
  page: {
    overflow: 'hidden',
    maxWidth: '100%',
    width: '1200px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: '2rem',
    [`@media (max-width: 1020px)`]: {
      padding: '1.5rem',
    },
  },
  title: {
    fontSize: '2rem',
    padding: '2rem',
    paddingTop: '0',
    fontWeight: 700,
    textAlign: 'center',
    [`@media (max-width: 1020px)`]: {
      margin: '0',
      marginBottom: '.5rem',
      padding: '1rem 1.5rem',
      fontSize: '1.75rem',
    },
  },
  sectionTitle: {
    fontSize: '1.35rem',
    fontWeight: 900,
    [`@media (max-width: 1020px)`]: {
      textAlign: 'center',
    },
  },
  agencePhotos: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '.5rem',
    height: '200px',
    margin: '4rem 0',
    [`@media (max-width: 1020px)`]: {
      overflow: 'hidden',
      gridTemplateRows: 'repeat(3, 1fr)',
      gridTemplateColumns: '1fr',
      height: '600px',
      borderRadius: '.5rem',
    },
  },
  agencePhotosItem: {
    overflow: 'hidden',
    img: {
      objectFit: 'cover',
      display: 'block',
      flex: '1 0 0',
      height: '100%',
      width: '100%',
    },
  },
}));
