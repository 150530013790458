import {Card, Image, Text, Group, createStyles, Grid, Stack} from '@mantine/core';
import {IoPricetagOutline} from 'react-icons/io5';
import {MdOutlineSquareFoot} from 'react-icons/md';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FavoriteButton from 'src/app/components/favorite/Favorite';
import {IconLayout} from 'src/assets/icons/IconLayout';
import Transaction from 'src/services/transaction';
import ProperyCardSkeleton from './PropertyCardSkeleton';

type PropertyCardProps = {
  className: string;
  id: string;
  image: string;
  descriptionTitle: string;
  locationCity: string;
  propertyId: string;
  loading: boolean;
  priceAskingPriceAmount: number;
  priceAskingPriceCurrency: string;
  structureAreasLiveableSize: number;
  structureAreasLiveableUnits: string;
  numberOfRooms: number;
  transactionType: string;
};

type Options = {
  iconSize: number;
};

export default function PropertyCard({
  className,
  id,
  image,
  descriptionTitle,
  locationCity,
  propertyId,
  priceAskingPriceAmount,
  priceAskingPriceCurrency,
  structureAreasLiveableSize,
  structureAreasLiveableUnits,
  numberOfRooms,
  transactionType,
}: PropertyCardProps): JSX.Element {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const options: Options = {
    iconSize: 32,
  };

  function renderCard(): JSX.Element {
    return (
      <Card withBorder radius="md" p="md" className={classes.card}>
        <Card.Section>
          <Image src={image} alt="Property image" height={214} withPlaceholder />
        </Card.Section>

        <Card.Section className={classes.titleSection}>
          <Group className={classes.titleSectionGroup} position="apart" noWrap={true} align="center">
            <Stack spacing={0}>
              <Text size="lg" weight={500} lineClamp={2} align="left" className={classes.title}>
                {descriptionTitle || t('transaction.noTitle')}
              </Text>
              <Text size="lg" weight={300} align="left">
                {locationCity || 'Atlantis'}
              </Text>
            </Stack>

            <FavoriteButton propertyId={propertyId} />
          </Group>
        </Card.Section>

        <Card.Section className={classes.iconSection}>
          <Grid justify="center">
            <Grid.Col span="auto">
              <IconLayout className={classes.detailsIcon} />
              <Text className={classes.label}>
                {numberOfRooms || '?'} {t('transactionDetails.rooms')}
              </Text>
            </Grid.Col>

            <Grid.Col span="auto">
              <IoPricetagOutline className={classes.detailsIcon} size={options.iconSize} />

              <Text className={classes.label}>
                {Transaction.formatNumber(priceAskingPriceAmount || 0, t('transaction.formatNumber'))} {Transaction.currency(priceAskingPriceCurrency)}
              </Text>
            </Grid.Col>

            <Grid.Col span="auto">
              <MdOutlineSquareFoot className={classes.detailsIcon} size={options.iconSize} />

              <Text className={classes.label}>
                {structureAreasLiveableSize || '?'} {Transaction.surfaceArea(structureAreasLiveableUnits)}
              </Text>
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
    );
  }

  if (id === "loading")
    return <ProperyCardSkeleton iconSize={options.iconSize} />;

  else
    return (
      <Link className={className} to={`/${transactionType}/details/${id}`}>
        {renderCard()}
      </Link>
    );
}

const useStyles = createStyles(theme => ({
  card: {
    backgroundColor: theme.white,
  },
  titleSection: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    height: '60px',
    padding: theme.spacing.md,
    paddingTop: theme.spacing.lg,
    paddingBottom: theme.spacing.lg,
  },
  titleSectionGroup: {
    height: '100%',
  },
  iconSection: {
    boxSizing: 'border-box',
    padding: theme.spacing.md,
  },
  detailsIcon: {
    color: '#9b9b9b',
    display: 'block',
    width: '32px',
    height: '32px',
    margin: 'auto',
  },
  label: {
    marginTop: '.25rem',
    fontSize: theme.fontSizes.md,
    fontWeight: 400,
  },
  title: {
    cursor: 'pointer',
  },
}));
