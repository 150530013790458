import {createStyles, Group} from '@mantine/core';
import {useTranslation} from 'react-i18next';

export default function LegalNotice(): JSX.Element {
  const {classes} = useStyles();
  const {t} = useTranslation();

  return (
    <div className={classes.legalPage}>
      <div className={classes.title}>{t('home.legal')}</div>
      <div className={classes.titleSection}>{t('legal.companyNameSection.companyName')}</div>

      <ul>
        <li>{t('legal.companyNameSection.headOffice')}</li>
        <li>{t('legal.companyNameSection.rcs')}</li>
        <li>{t('legal.companyNameSection.corporateForm')}</li>
        <li>{t('legal.companyNameSection.vat')}</li>
        <li>{t('legal.companyNameSection.professionalCard')}</li>
        <li>{t('legal.companyNameSection.prefecture')}</li>
        <li>{t('legal.companyNameSection.capital')}</li>
        <li>{t('legal.companyNameSection.financialGuaranteeFund')}</li>
        <li>{t('legal.companyNameSection.financialGuarantee')}</li>
        <li>{t('legal.companyNameSection.mediatorName')}</li>
        <li>{t('legal.companyNameSection.mediatorAddress')}</li>
      </ul>
      <div>{t('legal.companyNameSection.unavailableData')}</div>
      <div className={classes.titleSection}>{t('legal.hosting.title')}</div>
      <ul>
        <li>{t('legal.hosting.server')}</li>
        <li>{t('legal.hosting.capital')}</li>
        <li>{t('legal.hosting.headOffice')}</li>
        <li>{t('legal.hosting.rcs')}</li>
        <li>{t('legal.hosting.phone')}</li>
      </ul>
      <div className={classes.titleSection}>{t('legal.editor.title')}</div>
      <ul>
        <li>{t('legal.editor.name')}</li>
        <li>{t('legal.editor.headOffice')}</li>
        <li>{t('legal.editor.phone')}</li>
        <li>{t('legal.editor.mail')}</li>
      </ul>
      <div className={classes.titleSection}>{t('legal.editorialContent.title')}</div>
      <div>{t('legal.editorialContent.content')}</div>
      <div className={classes.titleSection}>{t('legal.classifiedAds.title')}</div>
      <div>{t('legal.classifiedAds.content')}</div>
      <div className={classes.titleSection}>{t('legal.simulationServices.title')}</div>
      <div>{t('legal.simulationServices.content')}</div>
      <div className={classes.titleSection}>{t('legal.rgpd.title')}</div>
      <div>{t('legal.rgpd.content')}</div>
      <div className={classes.titleSection}>{t('legal.exchangeSafety.title')}</div>
      <div>{t('legal.exchangeSafety.content')}</div>
      <div className={classes.titleSection}>{t('legal.intellectualPropertyRights.title')}</div>
      <div>{t('legal.intellectualPropertyRights.content')}</div>
      <div className={classes.titleSection}>{t('legal.serviceAvailability.title')}</div>
      <div>{t('legal.serviceAvailability.content')}</div>
      <div className={classes.titleSection}>{t('legal.applicableLaw.title')}</div>
      <div>{t('legal.applicableLaw.content')}</div>
    </div>
  );
}

const useStyles = createStyles(theme => ({
  legalPage: {
    width: '1200px',
    maxWidth: '100%',
    margin: 'auto',
    padding: '2rem',
    paddingBottom: '4rem',
  },
  title: {
    fontSize: '2rem',
    padding: '0 2rem',
    marginBottom: '1rem',
    fontWeight: 700,
    justifyContent: 'center',
    display: 'flex',
  },
  titleSection: {
    fontSize: '1.7rem',
    paddingTop: '3rem',
    paddingBottom: '1rem',
    color: theme.colors.red,
    fontWeight: 600,
  },
}));
