import { AxiosProgressEvent } from 'axios';
import Http, {HttpMutators} from 'src/services/http';
import {Contact} from 'src/types/contact';

export default {
  postSendContact: (data: Contact, mutators: HttpMutators) =>
    Http.post(
      {
        url: '/Estimation/PostSendContact',
        data,
      },
      mutators,
    ),
  postSendVideo: (data: FormData, onUploadProgress: (event: AxiosProgressEvent) => void, mutators: HttpMutators) =>
    Http.postVideo(
      {
        url: '/Estimation/ImportFiles',
        data,
        onUploadProgress,
      },
      mutators,
    ),
};
