import {Accordion, Button, createStyles, Group, MediaQuery, Radio, ScrollArea, Title, Transition} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Filters, RangeValue} from 'src/types/filter';
import NumberSlider from 'src/app/components/number-slider/NumberSlider';
import FilterData from 'src/app/components/filter/FilterData';
import {forwardRef, useImperativeHandle, useState, useRef, useEffect} from 'react';
import ChipWrapper from '../chip/ChipWrapper';
import {useMediaQuery, useScrollLock} from '@mantine/hooks';

export type FilterRef = {
  toggle: () => void;
  isOpen: boolean;
};

type FilterProps = {
  filters: Filters;
  setFilters(value: Filters): void;
  budgetBounds: RangeValue;
  budgetStep: number;
  reset(): void;
};

type ClickOrigin = {
  originX: number;
  originY: number;
};

export default forwardRef(
  function Filter({filters, setFilters, budgetBounds, budgetStep, reset}: FilterProps, ref): JSX.Element {
    const {classes} = useStyles();
    const isDesktop = useMediaQuery("(min-width: 1020px)", undefined, { getInitialValueInEffect: false });
    const [refFilterRoot, setRefFilterRoot] = useState<HTMLDivElement | null>(null);
    const {t} = useTranslation();
    const [valueAccordion, setValueAccordion] = useState([t('transactionFilter.region')]);
    const [opened, setOpen] = useState(isDesktop);
    const clickOrigin = useRef<ClickOrigin | null>(null);

    useScrollLock(!isDesktop && opened);

    function updateFilter(field: string, value: string[]): void {
      setFilters({...filters, [field]: value});
    }

    useImperativeHandle(ref, () => ({
      toggle() {
        setOpen(!opened);
      },

      get isOpen(): boolean {
        return opened;
      },
    }), [opened]);

    useEffect(() => {
      clickOrigin.current = null;
      setOpen(isDesktop);
    }, [isDesktop]);

    useEffect(() => {
      if (refFilterRoot && opened) {

        function onPointerUp(): void {
          if (clickOrigin.current) {
            clickOrigin.current = null;

            if (refFilterRoot) {
              refFilterRoot.style.background = '';
              if (refFilterRoot.children[0])
                (refFilterRoot.children[0] as HTMLDivElement).style.transform = '';
            }

            setOpen(false);
          }
        }

        refFilterRoot.onpointerdown = (e): void => {
          if (e.target == refFilterRoot)
            clickOrigin.current = {
              originX: e.pageX,
              originY: e.pageY,
            };
        };

        refFilterRoot.onpointermove = (e): void => {
          if (clickOrigin.current && refFilterRoot) {
            const px = e.pageX - clickOrigin.current.originX;

            if (px < 0) {
              if (refFilterRoot.children[0])
                (refFilterRoot.children[0] as HTMLDivElement).style.transform = `translateX(${px}px) translateZ(0px)`;
              refFilterRoot.style.background = `rgba(0, 0, 0, ${Math.min(.6, .6 + (px / refFilterRoot.offsetWidth))})`;
            }
          }
        };

        window.addEventListener("pointerup", onPointerUp);

        return () => {
          window.removeEventListener("pointerup", onPointerUp);

          if (refFilterRoot) {
            refFilterRoot.onpointermove = null;
            refFilterRoot.onpointerdown = null;
          }
        };
      }
    }, [refFilterRoot, opened]);

    return (
      <Transition mounted={opened} transition={{
          in: { opacity: 1 },
          out: { opacity: 0 },
          transitionProperty: 'opacity',
        }} duration={200} timingFunction="ease">
        {(styles) => (
          <div className={classes.filterRoot} style={{ ...styles, transform: 'translateZ(0)' }} ref={setRefFilterRoot}>
            <div className={classes.filter}>
              <Group className={classes.filterTitle}>
                <Title order={3}>{t('transactionFilter.myCriteria')}</Title>

                <MediaQuery query="(min-width: 1020px)" styles={{ display: 'none' }}>
                  <Button className={classes.closeBtn} onClick={() => setOpen(false)}>
                    {t('transactionFilter.close')}
                  </Button>
                </MediaQuery>
              </Group>

              <ScrollArea classNames={{
                root: classes.filterScrollRoot,
                viewport: classes.filterScrollViewport,
                scrollbar: classes.filterScrollScrollbar,
              }}>
                <Accordion
                  chevronPosition="right"
                  multiple
                  classNames={{
                    content: classes.itemAccordionContent,
                    item: classes.itemAccordion,
                    itemTitle: classes.itemTitleAccordion,
                    control: classes.controlAccordion,
                  }}
                  value={valueAccordion}
                  onChange={setValueAccordion}>
                  <Accordion.Item value={t('transactionFilter.region')}>
                    <Accordion.Control>{t('transactionFilter.region')}</Accordion.Control>
                    <Accordion.Panel>
                      <Radio.Group value={filters.regionFilter} onChange={newRegion => setFilters({...filters, regionFilter: newRegion})}>
                        {FilterData.region.map((regionLine, i) => (
                          <Radio key={i} value={regionLine.value} label={t(regionLine.label)} size="sm" color="red" classNames={{label: classes.radioLabel, radio: classes.radioRadio, body: classes.radioBody}} />
                        ))}
                      </Radio.Group>
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.propertyType')}>
                    <Accordion.Control>{t('transactionFilter.propertyType')}</Accordion.Control>
                    <Accordion.Panel>
                      <ChipWrapper field="propertyTypeFilter" options={FilterData.propertyType} onChange={updateFilter} selectedFilters={filters.propertyTypeFilter} />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.area')}>
                    <Accordion.Control>{t('transactionFilter.area')}</Accordion.Control>
                    <Accordion.Panel>
                      <NumberSlider
                        min={FilterData.areaSlider.range[0]}
                        max={FilterData.areaSlider.range[1]}
                        onChange={value => setFilters({...filters, areaRange: value})}
                        units={FilterData.areaSlider.units}
                        sliderColor="red"
                        step={FilterData.areaSlider.step}
                        value={filters.areaRange}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.numberOfRooms')}>
                    <Accordion.Control>{t('transactionFilter.numberOfRooms')}</Accordion.Control>
                    <Accordion.Panel>
                      <ChipWrapper field="numberOfRoomsFilter" options={FilterData.numberOfRooms} onChange={updateFilter} selectedFilters={filters.numberOfRoomsFilter} />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.numberOfBedrooms')}>
                    <Accordion.Control>{t('transactionFilter.numberOfBedrooms')}</Accordion.Control>
                    <Accordion.Panel>
                      <ChipWrapper field="numberOfBedroomsFilter" options={FilterData.numberOfBedrooms} onChange={updateFilter} selectedFilters={filters.numberOfBedroomsFilter} />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.budget')}>
                    <Accordion.Control>{t('transactionFilter.budget')}</Accordion.Control>
                    <Accordion.Panel>
                      <NumberSlider
                        min={budgetBounds[0]}
                        max={budgetBounds[1]}
                        onChange={value => setFilters({...filters, budgetRange: value})}
                        units={FilterData.budgetSlider.units}
                        sliderColor="red"
                        step={budgetStep}
                        value={filters.budgetRange}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.commodities')}>
                    <Accordion.Control>{t('transactionFilter.commodities')}</Accordion.Control>
                    <Accordion.Panel>
                      <ChipWrapper field="commoditiesFilter" options={FilterData.commodities} onChange={updateFilter} selectedFilters={filters.commoditiesFilter} />
                    </Accordion.Panel>
                  </Accordion.Item>

                  <Accordion.Item value={t('transactionFilter.heating')}>
                    <Accordion.Control>{t('transactionFilter.heating')}</Accordion.Control>
                    <Accordion.Panel>
                      <ChipWrapper field="heatingFilter" options={FilterData.heating} onChange={updateFilter} selectedFilters={filters.heatingFilter} />
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </ScrollArea>

              <Group className={classes.filterFooter}>
                <Button className={classes.resetBtn} onClick={reset}>
                  {t('transactionFilter.reset')}
                </Button>

                <MediaQuery query="(min-width: 1020px)" styles={{ display: 'none' }}>
                  <Button className={classes.confirmBtn} onClick={() => setOpen(false)}>
                    {t('transactionFilter.confirm')}
                  </Button>
                </MediaQuery>
              </Group>
            </div>
          </div>
        )}
      </Transition>
    );
  }
);

const useStyles = createStyles((theme) => ({
  filterRoot: {
    flex: '0 0 350px',
    [`@media (max-width: 1020px)`]: {
      WebkitTapHighlightColor: 'transparent',
      background: 'rgba(0, 0, 0, .6)',
      cursor: "pointer",
      position: 'fixed',
      zIndex: 99,
      left: 0,
      top: '80px',
      right: 0,
      bottom: 0,
      flex: 'unset',
    },
  },
  filter: {
    boxSizing: 'border-box',
    color: theme.white,
    backgroundColor: theme.colors.local_blue[0],
    border: '1px solid #FFF',
    borderLeft: 0,
    borderRadius: '0 2rem 2rem 0',
    position: 'sticky',
    top: '110px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 110px - 2rem)',
    marginBottom: '2rem',
    [`@media (max-width: 1020px)`]: {
      pointerEvents: 'all',
      position: 'relative',
      top: 0,
      border: 0,
      borderRadius: 0,
      width: '80%',
      height: '100%',
    }
  },
  filterTitle: {
    background: 'rgba(255, 255, 255, .15)',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    padding: '1rem',
  },
  filterScrollRoot: {
    flex: '1 0 0',
  },
  filterScrollViewport: {
    boxSizing: 'border-box',
    width: 'auto',
    padding: '.5rem 1.25rem',
  },
  filterScrollScrollbar: {
    right: '.25rem !important',
    top: '.25rem !important',
    bottom: '.25rem !important',
  },
  radioLabel: {
    color: theme.white,
  },
  radioRadio: {
    background: theme.white,
  },
  radioBody: {
    margin: '.5rem 0',
  },
  controlAccordion: {
    fontFamily: 'inherit',
    color: theme.white,
    padding: 0,
    '&:hover': {
      color: theme.colors.gray[5],
      backgroundColor: 'transparent',
    },
  },
  itemTitleAccordion: {
    fontWeight: 800,
  },
  itemAccordionContent: {
    padding: '0 0 1rem 0',
  },
  itemAccordion: {
    fontFamily: 'inherit',
    border: 0,
    '&:not(:first-of-type)': {
      borderTop: '1px solid rgba(255, 255, 255, .5)',
    },
    '&[data-active]': {
      background: '',
    },
  },
  filterFooter: {
    background: 'rgba(255, 255, 255, .15)',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    padding: '1rem',
    '@media (min-width: 1020px)': {
      gridTemplateColumns: '1fr',
    },
  },
  closeBtn: {
    width: '100%',
  },
  confirmBtn: {
    width: '100%',
  },
  resetBtn: {
    borderColor: theme.white,
    backgroundColor: 'unset',
    color: theme.white,
    padding: 0,
    width: '100%',
    marginLeft: 'auto',
    '&:hover': {
      color: theme.colors.gray[5],
      borderColor: theme.colors.gray[5],
      backgroundColor: 'transparent !important',
    },
  },
}));
